/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import DatePicker from "react-datepicker";
import { InterviewForm } from "InterviewForm/InterviewForm";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { interviewActions } from "_actions";
import { getInterviewForm } from "_helpers/reduxSelector";
import ReferenceQuestionnaire from "./_components/ReferenceQuestionnaire";
import { latestInterviewFormVersion } from "_constants";

const DATE_FORMAT = "MM/DD/YYYY";

const refInterviewSchema = yup.object().shape({
  verified_check_1: yup.boolean(),
  verified_check_2: yup.boolean(),
  verified_check_3: yup.boolean(),
  verified_check_4: yup.boolean(),
  facebook_check: yup.boolean(),
  instagram_check: yup.boolean(),
  ref_1_notes: yup.string().max(350, "Must be less than 350 characters"),
  ref_2_notes: yup.string().max(350, "Must be less than 350 characters"),
  ref_3_notes: yup.string().max(350, "Must be less than 350 characters"),
  ref_4_notes: yup.string().max(350, "Must be less than 350 characters"),
  hire_1: yup
    .string()
    .matches(/^\s*\S.*$|^$/, "* This field cannot contain only blankspaces"),
  work_ethic_1: yup
    .string()
    .matches(/^\s*\S.*$|^$/, "* This field cannot contain only blankspaces"),
  consistency_1: yup
    .string()
    .matches(/^\s*\S.*$|^$/, "* This field cannot contain only blankspaces"),
  hire_2: yup
    .string()
    .matches(/^\s*\S.*$|^$/, "* This field cannot contain only blankspaces"),
  work_ethic_2: yup
    .string()
    .matches(/^\s*\S.*$|^$/, "* This field cannot contain only blankspaces"),
  consistency_2: yup
    .string()
    .matches(/^\s*\S.*$|^$/, "* This field cannot contain only blankspaces"),
  hire_3: yup
    .string()
    .matches(/^\s*\S.*$|^$/, "* This field cannot contain only blankspaces"),
  work_ethic_3: yup
    .string()
    .matches(/^\s*\S.*$|^$/, "* This field cannot contain only blankspaces"),
  consistency_3: yup
    .string()
    .matches(/^\s*\S.*$|^$/, "* This field cannot contain only blankspaces"),
  hire_4: yup
    .string()
    .matches(/^\s*\S.*$|^$/, "* This field cannot contain only blankspaces"),
  work_ethic_4: yup
    .string()
    .matches(/^\s*\S.*$|^$/, "* This field cannot contain only blankspaces"),
  consistency_4: yup
    .string()
    .matches(/^\s*\S.*$|^$/, "* This field cannot contain only blankspaces"),
  facebook_date: yup.string().nullable(),
  instagram_date: yup.string().nullable(),
  desc: yup
    .string()
    .matches(/^\s*\S.*$|^$/, "* This field cannot contain only blankspaces"),
});

const ReferenceForm = (props) => {
  const {
    hash,
    formId,
    user,
    applicant,
    setCurrentView,
    isNew,
    setIsNew,
    newFormId,
    setNewFormId,
    setIsSubmittable,
    isSubmittable,
  } = props;
  const interviewForm = useSelector((state) => getInterviewForm(state));

  const dispatch = useDispatch();

  const [key, setKey] = useState("referenceForm");
  const [isEnabled, setIsEnabled] = useState(false);
  const [isRefsOk, setIsRefsOk] = useState(false);
  const [loading, setLoading] = useState(false);
  const [version, setVersion] = useState(null);

  const {
    reset,
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(refInterviewSchema),
    defaultValues: {
      verified_check_1: false,
      ref_1_notes: "",
      verified_1_date: "",
      verified_check_2: false,
      ref_2_notes: "",
      verified_2_date: "",
      verified_check_3: false,
      ref_3_notes: "",
      verified_3_date: "",
      verified_check_4: false,
      ref_4_notes: "",
      verified_4_date: "",
      // --------------------------------//
      confirm_dates_1: "",
      key_strengths_1: "",
      prior_knowledge_1: "",
      reason_good_fit_1: "",
      hire_1: "",
      work_ethic_1: "",
      consistency_1: "",
      confirm_dates_2: "",
      key_strengths_2: "",
      prior_knowledge_2: "",
      reason_good_fit_2: "",
      hire_2: "",
      work_ethic_2: "",
      consistency_2: "",
      confirm_dates_3: "",
      key_strengths_3: "",
      prior_knowledge_3: "",
      reason_good_fit_3: "",
      hire_3: "",
      work_ethic_3: "",
      consistency_3: "",
      confirm_dates_4: "",
      key_strengths_4: "",
      prior_knowledge_4: "",
      reason_good_fit_4: "",
      hire_4: "",
      work_ethic_4: "",
      consistency_4: "",
      // --------------------------------//
      facebook: false,
      facebook_date: "",
      instagram: false,
      instagram_date: "",
      desc: "",
    },
  });

  const fetchInterviewForm = () => {
    dispatch(interviewActions.getInterviewForm(formId));
  };

  useEffect(() => {
    if (!isNew) {
      fetchInterviewForm();
    }
  }, []);

  const onSubmit = (data) => {
    setLoading(true);
    const form = {
      work_reference_1_employer: applicant?.reference1?.last_employee ?? "",
      work_reference_1_position: applicant?.reference1?.position ?? "",
      work_reference_1_supervisor: applicant?.reference1?.supervisor_name ?? "",
      work_reference_1_phone: applicant?.reference1?.telephone ?? "",
      work_reference_1_verified: verified_check_1 ? 1 : 0,
      work_reference_1_notes: data.ref_1_notes ?? "",
      work_reference_1_date_verified: data.verified_1_date?._d
        ? moment(data.verified_1_date._d).format(DATE_FORMAT)
        : "",
      work_reference_2_employer: applicant?.reference2?.last_employee ?? "",
      work_reference_2_position: applicant?.reference2?.position ?? "",
      work_reference_2_supervisor: applicant?.reference2?.supervisor_name ?? "",
      work_reference_2_phone: applicant?.reference2?.telephone ?? "",
      work_reference_2_verified: verified_check_2 ? 1 : 0,
      work_reference_2_notes: data.ref_2_notes ?? "",
      work_reference_2_date_verified: data.verified_2_date?._d
        ? moment(data.verified_2_date._d).format(DATE_FORMAT)
        : "",
      reference_1_name: applicant?.personalreference1?.name ?? "",
      reference_1_relation: applicant?.personalreference1?.relationship ?? "",
      reference_1_phone: applicant?.personalreference1?.phone ?? "",
      reference_1_verified: verified_check_3 ? 1 : 0,
      reference_1_notes: data.ref_3_notes ?? "",
      reference_1_date_verified: data.verified_3_date?._d
        ? moment(data.verified_3_date._d).format(DATE_FORMAT)
        : "",
      reference_2_name: applicant?.personalreference2?.name ?? "",
      reference_2_relation: applicant?.personalreference2?.relationship ?? "",
      reference_2_phone: applicant?.personalreference2?.phone ?? "",
      reference_2_verified: verified_check_4 ? 1 : 0,
      reference_2_notes: data.ref_4_notes ?? "",
      reference_2_date_verified: data.verified_4_date?._d
        ? moment(data.verified_4_date._d).format(DATE_FORMAT)
        : "",
      work_reference_one_question_1: data.hire_1 ?? "",
      work_reference_one_question_2: data.work_ethic_1 ?? "",
      work_reference_one_question_3: data.consistency_1 ?? "",
      work_reference_two_question_1: data.hire_2 ?? "",
      work_reference_two_question_2: data.work_ethic_2 ?? "",
      work_reference_two_question_3: data.consistency_2 ?? "",
      professional_reference_one_question_1: data.hire_3 ?? "",
      professional_reference_one_question_2: data.work_ethic_3 ?? "",
      professional_reference_one_question_3: data.consistency_3 ?? "",
      professional_reference_two_question_1: data.hire_4 ?? "",
      professional_reference_two_question_2: data.work_ethic_4 ?? "",
      professional_reference_two_question_3: data.consistency_4 ?? "",
      confirm_dates_1: data.confirm_dates_1 ?? "",
      confirm_dates_2: data.confirm_dates_2 ?? "",
      confirm_dates_3: data.confirm_dates_3 ?? "",
      confirm_dates_4: data.confirm_dates_4 ?? "",
      key_strengths_1: data.key_strengths_1 ?? "",
      key_strengths_2: data.key_strengths_2 ?? "",
      key_strengths_3: data.key_strengths_3 ?? "",
      key_strengths_4: data.key_strengths_4 ?? "",
      prior_knowledge_1: data.prior_knowledge_1 ?? "",
      prior_knowledge_2: data.prior_knowledge_2 ?? "",
      prior_knowledge_3: data.prior_knowledge_3 ?? "",
      prior_knowledge_4: data.prior_knowledge_4 ?? "",
      reason_good_fit_1: data.reason_good_fit_1 ?? "",
      reason_good_fit_2: data.reason_good_fit_2 ?? "",
      reason_good_fit_3: data.reason_good_fit_3 ?? "",
      reason_good_fit_4: data.reason_good_fit_4 ?? "",
      facebook_check: data.facebook_check ? 1 : 0,
      facebook_date: data.facebook_date
        ? moment(data.facebook_date).format(DATE_FORMAT)
        : "",
      instagram_check: data.instagram_check ? 1 : 0,
      instagram_date: data.instagram_date
        ? moment(data.instagram_date).format(DATE_FORMAT)
        : "",
      social_media_notes: data.desc ?? "",
    };

    const requestData = {
      applicant_id: hash,
      date_of_interview:
        applicant?.date_of_interview || moment().format(DATE_FORMAT),
      date_of_submission: moment().format(DATE_FORMAT),
      interviewer: user?.id,
      status: 2,
      form_id: formId,
      form: form,
    };

    dispatch(interviewActions.saveInterviewForm(requestData)).then((res) => {
      if (res.success) {
        setNewFormId(res.data?.form_id);
        setKey("interviewForm");
        setIsNew(false);
        setIsSubmittable(isFormFilled(form));
        return;
      } else {
        toast.error(res.message);
      }
    });
    // }
    setLoading(false);
  };

  const onCancelClick = () => {
    setCurrentView("listView");
    setIsNew(false);
  };

  const verified_check_1 = watch("verified_check_1");
  const verified_check_2 = watch("verified_check_2");
  const verified_check_3 = watch("verified_check_3");
  const verified_check_4 = watch("verified_check_4");

  useEffect(() => {
    if (applicant && interviewForm) {
      const form = {
        work_reference_1_employer: applicant?.reference1?.last_employee ?? "",
        work_reference_1_position: applicant?.reference1?.position ?? "",
        work_reference_1_supervisor:
          applicant?.reference1?.supervisor_name ?? "",
        work_reference_1_phone: applicant?.reference1?.telephone ?? "",
        work_reference_1_verified: verified_check_1 ? 1 : 0,
        work_reference_1_notes:
          interviewForm?.form?.work_reference_1_notes ?? "",
        work_reference_1_date_verified: interviewForm?.form
          ?.work_reference_1_date_verified
          ? moment(interviewForm?.form?.verified_1_date).format(DATE_FORMAT)
          : "",
        work_reference_2_employer: applicant?.reference2?.last_employee ?? "",
        work_reference_2_position: applicant?.reference2?.position ?? "",
        work_reference_2_supervisor:
          applicant?.reference2?.supervisor_name ?? "",
        work_reference_2_phone: applicant?.reference2?.telephone ?? "",
        work_reference_2_verified: verified_check_2 ? 1 : 0,
        work_reference_2_notes:
          interviewForm?.form?.work_reference_2_notes ?? "",
        work_reference_2_date_verified: interviewForm?.form
          ?.work_reference_2_date_verified
          ? moment(interviewForm?.form?.work_reference_2_date_verified).format(
              DATE_FORMAT
            )
          : "",
        reference_1_name: applicant?.personalreference1?.name ?? "",
        reference_1_relation: applicant?.personalreference1?.relationship ?? "",
        reference_1_phone: applicant?.personalreference1?.phone ?? "",
        reference_1_verified: verified_check_3 ? 1 : 0,
        reference_1_notes: interviewForm?.form?.reference_1_notes ?? "",
        reference_1_date_verified: interviewForm?.form
          ?.reference_1_date_verified
          ? moment(interviewForm?.form?.reference_1_date_verified).format(
              DATE_FORMAT
            )
          : "",
        reference_2_name: applicant?.personalreference2?.name ?? "",
        reference_2_relation: applicant?.personalreference2?.relationship ?? "",
        reference_2_phone: applicant?.personalreference2?.phone ?? "",
        reference_2_verified: verified_check_4 ? 1 : 0,
        reference_2_notes: interviewForm?.form?.reference_2_notes ?? "",
        reference_2_date_verified: interviewForm?.form
          ?.reference_2_date_verified
          ? moment(interviewForm?.form?.reference_2_date_verified).format(
              DATE_FORMAT
            )
          : "",
        work_reference_one_question_1:
          interviewForm?.form?.work_reference_one_question_1 ?? "",
        work_reference_one_question_2:
          interviewForm?.form?.work_reference_one_question_2 ?? "",
        work_reference_one_question_3:
          interviewForm?.form?.work_reference_one_question_3 ?? "",
        work_reference_two_question_1:
          interviewForm?.form?.work_reference_two_question_1 ?? "",
        work_reference_two_question_2:
          interviewForm?.form?.work_reference_two_question_2 ?? "",
        work_reference_two_question_3:
          interviewForm?.form?.work_reference_two_question_3 ?? "",
        professional_reference_one_question_1:
          interviewForm?.form?.professional_reference_one_question_1 ?? "",
        professional_reference_one_question_2:
          interviewForm?.form?.professional_reference_one_question_2 ?? "",
        professional_reference_one_question_3:
          interviewForm?.form?.professional_reference_one_question_3 ?? "",
        professional_reference_two_question_1:
          interviewForm?.form?.professional_reference_two_question_1 ?? "",
        professional_reference_two_question_2:
          interviewForm?.form?.professional_reference_two_question_2 ?? "",
        professional_reference_two_question_3:
          interviewForm?.form?.professional_reference_two_question_3 ?? "",
        facebook_check: interviewForm?.form?.facebook_check ? 1 : 0,
        facebook_date: interviewForm?.form?.facebook_date
          ? moment(interviewForm?.form?.facebook_date).format(DATE_FORMAT)
          : "",
        instagram_check: interviewForm?.form?.instagram_check ? 1 : 0,
        instagram_date: interviewForm?.form?.instagram_date
          ? moment(interviewForm?.form?.instagram_date).format(DATE_FORMAT)
          : "",
        social_media_notes: interviewForm?.form?.social_media_notes ?? "",
      };
      setIsSubmittable(isFormFilled(form));
    }
  }, [interviewForm]);

  useEffect(() => {
    // if isNew, user don't have access to only referenceForm first.
    if (isNew) {
      setIsEnabled(true);
      return;
    }
    setIsEnabled(false);
  }, [isNew]);

  useEffect(() => {
    setVersion(formId ? interviewForm?.version : latestInterviewFormVersion);
    if (interviewForm?.form && !isNew) {
      reset({
        verified_check_1: interviewForm?.form?.work_reference_1_verified ?? "",
        ref_1_notes: interviewForm?.form?.work_reference_1_notes ?? "",
        verified_1_date: interviewForm?.form?.work_reference_1_date_verified
          ? moment(interviewForm.form.work_reference_1_date_verified)
          : "",
        verified_check_2: interviewForm?.form?.work_reference_2_verified ?? "",
        ref_2_notes: interviewForm?.form?.work_reference_2_notes ?? "",
        verified_2_date: interviewForm?.form?.work_reference_2_date_verified
          ? moment(interviewForm.form.work_reference_2_date_verified)
          : "",
        verified_check_3: interviewForm?.form?.reference_1_verified ?? "",
        ref_3_notes: interviewForm?.form?.reference_1_notes ?? "",
        verified_3_date: interviewForm?.form?.reference_1_date_verified
          ? moment(interviewForm.form.reference_1_date_verified)
          : "",
        verified_check_4: interviewForm?.form?.reference_2_verified ?? "",
        ref_4_notes: interviewForm?.form?.reference_2_notes ?? "",
        verified_4_date: interviewForm?.form?.reference_2_date_verified
          ? moment(interviewForm.form.reference_2_date_verified)
          : "",
        hire_1: interviewForm?.form?.work_reference_one_question_1 ?? "",
        work_ethic_1: interviewForm?.form?.work_reference_one_question_2 ?? "",
        consistency_1: interviewForm?.form?.work_reference_one_question_3 ?? "",
        hire_2: interviewForm?.form?.work_reference_two_question_1 ?? "",
        work_ethic_2: interviewForm?.form?.work_reference_two_question_2 ?? "",
        consistency_2: interviewForm?.form?.work_reference_two_question_3 ?? "",
        hire_3:
          interviewForm?.form?.professional_reference_one_question_1 ?? "",
        work_ethic_3:
          interviewForm?.form?.professional_reference_one_question_2 ?? "",
        consistency_3:
          interviewForm?.form?.professional_reference_one_question_3 ?? "",
        hire_4:
          interviewForm?.form?.professional_reference_two_question_1 ?? "",
        work_ethic_4:
          interviewForm?.form?.professional_reference_two_question_2 ?? "",
        consistency_4:
          interviewForm?.form?.professional_reference_two_question_3 ?? "",
        facebook_check: interviewForm?.form?.facebook_check ?? "",
        facebook_date: interviewForm?.form?.facebook_date
          ? moment(interviewForm.form.facebook_date)
          : "",
        instagram_check: interviewForm?.form?.instagram_check ?? "",
        instagram_date: interviewForm?.form?.instagram_date
          ? moment(interviewForm.form.instagram_date)
          : "",
        confirm_dates_1: interviewForm?.form?.confirm_dates_1 ?? "",
        confirm_dates_2: interviewForm?.form?.confirm_dates_2 ?? "",
        confirm_dates_3: interviewForm?.form?.confirm_dates_3 ?? "",
        confirm_dates_4: interviewForm?.form?.confirm_dates_4 ?? "",
        key_strengths_1: interviewForm?.form?.key_strengths_1 ?? "",
        key_strengths_2: interviewForm?.form?.key_strengths_2 ?? "",
        key_strengths_3: interviewForm?.form?.key_strengths_3 ?? "",
        key_strengths_4: interviewForm?.form?.key_strengths_4 ?? "",
        prior_knowledge_1: interviewForm?.form?.prior_knowledge_1 ?? "",
        prior_knowledge_2: interviewForm?.form?.prior_knowledge_2 ?? "",
        prior_knowledge_3: interviewForm?.form?.prior_knowledge_3 ?? "",
        prior_knowledge_4: interviewForm?.form?.prior_knowledge_4 ?? "",
        reason_good_fit_1: interviewForm?.form?.reason_good_fit_1 ?? "",
        reason_good_fit_2: interviewForm?.form?.reason_good_fit_2 ?? "",
        reason_good_fit_3: interviewForm?.form?.reason_good_fit_3 ?? "",
        reason_good_fit_4: interviewForm?.form?.reason_good_fit_4 ?? "",
        desc: interviewForm?.form?.social_media_notes ?? "",
      });
    }
  }, [interviewForm]);

  const isFormFilled = (obj) => {
    for (const key in obj) {
      if (
        (obj.hasOwnProperty(key) &&
          (obj[key] === null || obj[key] === undefined || obj[key] === "")) ||
        obj[key] === 0
      ) {
        return false; // If any key has a null, undefined, or empty string value, return false
      }
    }
    return true; // If all keys have a value, return true
  };

  return (
    <>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3 interview_form_wrapper"
      >
        <Tab eventKey="referenceForm" title="Reference Form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="ref_Form_wrapper">
              <h3>Work/Personal References</h3>
              <p className="note_para">
                <b>Note:</b> It is mandatory to call and verify all the
                references.
                <div>
                  {!isRefsOk && (
                    <p className="refs_not_ok">
                      Please fill all the reference details for submitting the
                      interview form. Please edit the profile OR edit the job
                      application for entering the reference details.{" "}
                    </p>
                  )}
                </div>
              </p>{" "}
              <div></div>
              {(() => {
                let referenceCount = 0; 
                return [
                  {
                    references: [applicant?.reference1, applicant?.reference2],
                    type: "Past Work History",
                  },
                  {
                    references: [
                      applicant?.personalreference1,
                      applicant?.personalreference2,
                    ],
                    type: "Professional",
                  },
                ].flatMap(({ references, type }) =>
                  references
                    .filter(Boolean)
                    .map((reference) => (
                      <ReferenceQuestionnaire
                        version={version}
                        key={`${type}-${referenceCount + 1}`}
                        referenceData={reference}
                        referenceType={type}
                        index={referenceCount++}
                        register={register}
                        control={control}
                        errors={errors}
                      />
                    ))
                );
              })()}

              <div className="referenceCheckSection social_media">
                <h4>Social Media Check</h4>
                <Row className="show-grid">
                  <Col>
                    <div className="checkbox_wrap">
                      <p>Facebook</p>
                      <input
                        type="checkbox"
                        {...register("facebook_check")}
                        className={`checkbox_input ${
                          errors?.facebook_check?.message ? "error" : ""
                        }`}
                      />{" "}
                      <label className="checkbox_label"></label>
                    </div>
                    <div className="facebook_date">
                      <Controller
                        control={control}
                        name="facebook_date"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <DatePicker
                            // className="datepicker_ref"
                            {...register("facebook_date")}
                            className={`${
                              errors.facebook_date?.message ? "error" : ""
                            }`}
                            ref={ref}
                            placeholderText="Date"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            showMonthDropdown
                            showYearDropdown
                            maxDate={moment(new Date())}
                          />
                        )}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="checkbox_wrap">
                      <p>Instagram</p>
                      <input
                        type="checkbox"
                        {...register("instagram_check")}
                        className={`checkbox_input ${
                          errors?.instagram_check?.message ? "error" : ""
                        }`}
                      />{" "}
                      <label className="checkbox_label"></label>
                    </div>
                    <div className="facebook_date">
                      <Controller
                        control={control}
                        name="instagram_date"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <DatePicker
                            className={`${
                              errors.instagram_date?.message ? "error" : ""
                            }`}
                            {...register("instagram_date")}
                            ref={ref}
                            placeholderText="Date"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            showMonthDropdown
                            showYearDropdown
                            maxDate={moment(new Date())}
                          />
                        )}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={0}>
                    <p>Notes</p>
                    <textarea
                      className={`${errors.desc?.message ? "error" : ""}`}
                      id=""
                      name=""
                      rows="5"
                      cols="33"
                      {...register("desc")}
                    />
                  </Col>
                </Row>
              </div>
              <div className="ref_form_btn">
                <div className="interview_btn">
                  <button className="button" onClick={onCancelClick}>
                    {" "}
                    Cancel{" "}
                  </button>
                  <button className="button" type="submit" disabled={loading}>
                    {" "}
                    Next{" "}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Tab>
        <Tab
          eventKey="interviewForm"
          title="Interview Form"
          disabled={isEnabled}
        >
          <InterviewForm
            hash={props.hash}
            applicant={applicant}
            setCurrentView={setCurrentView}
            editMode={props.editMode}
            user={user}
            formId={props.formId}
            isNew={isNew}
            setIsNew={setIsNew}
            setKey={setKey}
            getInterviewFormList={props.getInterviewFormList}
            newFormId={newFormId}
            isSubmittable={isSubmittable}
          />
        </Tab>
      </Tabs>
    </>
  );
};

export default ReferenceForm;
