import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import _ from "lodash";
import cx from "classnames";
import moment from "moment";
import DatePicker from "react-datepicker";
import Modal from "react-responsive-modal";

import { applicantsActions, interviewActions } from "_actions";
import { validateForm } from "./formHandler.js";
import {
  getInterviewForm,
  isInterviewFomLoading,
  selectJobApplicant,
} from "_helpers/reduxSelector";
import styles from "./interview.module.scss";
import InterviewQuestionnaire from "./_components/InterviewQuestionnaire.jsx";
import { latestInterviewFormVersion } from "_constants/interview.constants.js";

const DATE_FORMAT = "MM/DD/YYYY";
const INP_DATE_FORMAT = "MM/DD/YYYY";
const TIME_FORMAT = "h:mm A";

const initialState = (applicant, user) => ({
  full_name: `${applicant?.first_name} ${applicant?.middle_initial} ${applicant?.last_name}`,
  interview_date: moment(),
  scheduled_interview_time: moment()?.startOf("hour"),
  candidate_arrival_time: moment()?.startOf("hour"),
  why_this_company: "",
  why_we_hire: "",
  interested_in_firefighter: "",
  personal_quality: "",
  areas_to_improve: "",
  team_experience: "",
  worked_successfully: "",
  extreme_work: "",
  mistake_and_fix: "",
  handle_situations: "",
  respond_situation: "",
  action_to_crude_story: "",
  biggest_prof_challenge: "",
  respond_to_vocal_tone: "",
  handle_conflict: "",
  respond_to_alcohol: "",
  inspire_others: "",
  where_five_years: "",
  your_goals: "",
  notes: "",
  candidate_information_sent_to_hr: "",
  candidate_information_sent_date: "",
  interviewer: user?.id,
  interviewer_date: moment(),
  student_status: "",
  spring_sem_completion_date: "",
  fall_sem_start_date: "",
  living_status: "",
  ca_move_date: "",
  out_of_state_move_date: "",
  living_status_sub: "",
  reference_1_verified: 0,
  reference_2_verified: 0,
  reference_3_verified: 0,
});

export const InterviewForm = ({
  setKey,
  hash,
  applicant,
  setCurrentView,
  user,
  editMode,
  formId,
  getInterviewFormList,
  isNew,
  setIsNew,
  newFormId,
  isSubmittable,
}) => {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const [
    {
      full_name,
      interview_date,
      scheduled_interview_time,
      candidate_arrival_time,
      why_this_company,
      why_we_hire,
      interested_in_firefighter,
      personal_quality,
      areas_to_improve,
      team_experience,
      worked_successfully,
      extreme_work,
      mistake_and_fix,
      handle_situations,
      respond_situation,
      action_to_crude_story,
      biggest_prof_challenge,
      respond_to_vocal_tone,
      handle_conflict,
      respond_to_alcohol,
      inspire_others,
      where_five_years,
      your_goals,
      notes,
      candidate_information_sent_to_hr,
      candidate_information_sent_date,
      interviewer,
      interviewer_date,
      student_status,
      spring_sem_completion_date,
      fall_sem_start_date,
      living_status,
      ca_move_date,
      out_of_state_move_date,
      living_status_sub,
    },
    setFormState,
  ] = useState(initialState(applicant, user));

  const [references, setReferences] = React.useState();
  const [confirmSubmissionOpen, setConfirmSubmissionOpen] =
    React.useState(false);
  const interviewForm = useSelector((state) => getInterviewForm(state));
  const [version, setFormVersion] = React.useState(null);

  const loading = useSelector((state) => isInterviewFomLoading(state));
  const jobApplicationDetails = useSelector(selectJobApplicant);

  useEffect(() => {
    setFormVersion(interviewForm?.version ?? latestInterviewFormVersion);
    const formData = interviewForm?.form;
    if (isNew) {
      setFormState(initialState(applicant, user));
      return;
    }
    if (editMode && formData) {
      Object.keys(formData).forEach((key) => {
        const value = formData[key];
        if (key.includes("_date") && value) {
          let dt = moment(new Date(value)).format(INP_DATE_FORMAT);
          setFormState((prevState) => ({ ...prevState, [key]: moment(dt) }));
        } else if (key.includes("_time") && value) {
          let tm = moment(value, TIME_FORMAT).toDate();
          setFormState((prevState) => ({ ...prevState, [key]: moment(tm) }));
        } else if (value || value === 0) {
          setFormState((prevState) => ({ ...prevState, [key]: String(value) }));
        }
      });
      let _references = { ...references };
      _references.reference_1_verified = formData?.reference_1_verified;
      _references.reference_2_verified = formData?.reference_2_verified;
      _references.reference_3_verified = formData?.reference_3_verified;
      setReferences(_references);
    }
  }, [interviewForm]);

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleDateChange = (name, value) => {
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const deleteIntForm = () => {
    const data = new FormData();
    data.append("interview_form_id", formId);
    dispatch(interviewActions.deleteInterviewForm(data)).then((res) => {
      if (res.success) {
        getInterviewFormList();
        setCurrentView("listView");
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    /**Reset error object */
    const submitter = event?.nativeEvent?.submitter?.name;

    if (version != latestInterviewFormVersion) {
      return toast.error(
        "A new version of interview form has been released. Please create a new interview form for this applicant."
      );
    }

    if (!isSubmittable && submitter !== "draft") {
      return toast.error(
        "Please fill out the reference form before proceeding"
      );
    }
    const submittedForm = event.target;
    if (submitter !== "draft") {
      let err = validateForm(submittedForm, {});
      if (err && !_.isEmpty(err)) {
        setErrors(err);
        return;
      } else {
        setErrors({});
      }
    } else {
      let err = validateForm(submittedForm, {});
      const mandatoryDraftFields = [
        "interview_date",
        "scheduled_interview_time",
        "candidate_arrival_time",
      ];
      let mandatoryDraftFieldErrors = {};
      mandatoryDraftFields?.forEach((key) => {
        if (err?.[key]) {
          mandatoryDraftFieldErrors[key] = err[key];
        }
      });
      if (mandatoryDraftFieldErrors && !_.isEmpty(mandatoryDraftFieldErrors)) {
        setErrors(mandatoryDraftFieldErrors);
        return;
      } else {
        setErrors({});
      }
    }

    if (submitter == "draft") {
      submitForm(submitter);
    } else {
      setConfirmSubmissionOpen(true);
    }
  };

  const closeConfirmSubmissionModal = () => {
    setConfirmSubmissionOpen(false);
  };

  const submitForm = (submitter) => {
    const submittedForm = formRef.current;

    closeConfirmSubmissionModal();
    const form = {
      interviewer: interviewer,
      ...references,
    };

    if (submittedForm) {
      Object.keys(submittedForm).forEach((index) => {
        let el = submittedForm[index];
        if (el.name) {
          if (el.type === "radio" && el.checked) {
            form[el.name] = el.value;
          } else if (el.type === "date" && el.value) {
            form[el.name] = moment(el.value).format(DATE_FORMAT);
          } else if (el.type !== "radio") {
            form[el.name] = el.value;
          }
        }
      });

      const data = {
        applicant_id: hash,
        status: submitter === "draft" ? 2 : 1,
        interviewer: interviewer,
        date_of_interview: moment(interview_date)?.isValid()
          ? moment(interview_date).format(DATE_FORMAT)
          : null,
        date_of_submission: moment().format(DATE_FORMAT),
        form,
        form_id: editMode && formId ? formId : newFormId,
        // form_id:formId.length? formId : newFormId
      };

      dispatch(interviewActions.saveInterviewForm(data)).then((res) => {
        if (res.success) {
          setCurrentView(
            submitter === "draft" ? "draftComplete" : "submitComplete"
          );
          if (submitter !== "draft") {
            dispatch(applicantsActions?.viewDetails(hash));
          }
        } else {
          toast.error(res.message);
        }
      });

      setIsNew(false);
    }
  };

  React.useEffect(() => {
    if (jobApplicationDetails) {
      const noOfReferences = 3;
      let _references = { ...references };
      Array(noOfReferences)
        .fill(0)
        ?.forEach((_, index) => {
          let currentReference = index + 1;
          _references[`reference_${currentReference}_name`] =
            jobApplicationDetails?.[
              `personalreference${currentReference}`
            ]?.name;
          _references[`reference_${currentReference}_phone`] =
            jobApplicationDetails?.[
              `personalreference${currentReference}`
            ]?.phone;
          _references[`reference_${currentReference}_relation`] =
            jobApplicationDetails?.[
              `personalreference${currentReference}`
            ]?.relationship;
        });
      setReferences(_references);
    }
  }, [jobApplicationDetails]);

  const fetchInterviewForm = () => {
    dispatch(
      interviewActions.getInterviewForm(editMode && formId ? formId : newFormId)
    );
  };

  useEffect(() => {
    if (!isNew) fetchInterviewForm();
  }, []);

  return (
    <>
      {editMode && (
        <div className={styles.interview_form_wrapper}>
          <form onSubmit={handleSubmit} noValidate ref={formRef}>
            <div className={styles.interview_form_body}>
              <div className={styles.form__wrapper}>
                <div className={styles.form__wrapper}>
                  <div className={styles.form_group_row}>
                    <div className={styles.form_group}>
                      <label htmlFor="full_name">Name of Candidate</label>
                      <input
                        type="text"
                        required
                        id="full_name"
                        name="full_name"
                        value={full_name}
                        onChange={handleChange}
                        disabled
                      />
                    </div>
                    <div className={cx(styles.form_group, "full_width_date")}>
                      <label htmlFor="interview_date">Date of Interview</label>
                      <DatePicker
                        type="date"
                        className={`${
                          errors["interview_date"] ? styles.error : ""
                        }`}
                        id="interview_date"
                        name="interview_date"
                        selected={
                          moment(interview_date)?.isValid()
                            ? moment(interview_date)
                            : null
                        }
                        onChange={(date) =>
                          handleDateChange("interview_date", date)
                        }
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        autoComplete="off"
                        required
                        maxDate={moment()}
                      />
                    </div>
                  </div>

                  <div className={styles.form_group_row}>
                    <div className={cx(styles.form_group, "full_width_date")}>
                      <label htmlFor="scheduled_interview_time">
                        Scheduled Interview Time
                      </label>
                      <DatePicker
                        id="scheduled_interview_time"
                        className={`${
                          errors["scheduled_interview_time"] ? styles.error : ""
                        }`}
                        name="scheduled_interview_time"
                        selected={
                          moment(scheduled_interview_time)?.isValid()
                            ? scheduled_interview_time
                            : null
                        }
                        onChange={(time) =>
                          handleDateChange("scheduled_interview_time", time)
                        }
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Select Time"
                        dateFormat="h:mm A"
                        required
                      />
                    </div>
                    <div className={cx(styles.form_group, "full_width_date")}>
                      <label htmlFor="candidate_arrival_time">
                        Candidate Arrival Time
                      </label>
                      <DatePicker
                        id="candidate_arrival_time"
                        className={`${
                          errors["candidate_arrival_time"] ? styles.error : ""
                        }`}
                        name="candidate_arrival_time"
                        selected={
                          moment(candidate_arrival_time)?.isValid()
                            ? moment(candidate_arrival_time)
                            : null
                        }
                        onChange={(time) =>
                          handleDateChange("candidate_arrival_time", time)
                        }
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Select Time"
                        dateFormat="h:mm A"
                        required
                      />
                    </div>
                  </div>

                  {version == 1 ? (
                    <InterviewQuestionnaire
                      lowRatingVerbiage={"Bad"}
                      highRatingVerbiage={"Good"}
                      questions={[
                        {
                          label: "Why do you want to work for this company?",
                          name: "why_this_company",
                          value: why_this_company,
                        },
                        {
                          label: "Why should we hire you?",
                          name: "why_we_hire",
                          value: why_we_hire,
                        },
                        {
                          label:
                            "What made you interested in wildland firefighting?",
                          name: "interested_in_firefighter",
                          value: interested_in_firefighter,
                        },
                        {
                          label:
                            "<b>What personal qualities or characteristics do you value most?</b>",
                          name: "personal_quality",
                          value: personal_quality,
                        },
                        {
                          label: "What are areas you know need improvement?",
                          name: "areas_to_improve",
                          value: areas_to_improve,
                        },
                        {
                          label:
                            "Describe a team experience you found rewarding.",
                          name: "team_experience",
                          value: team_experience,
                        },
                        {
                          label:
                            "Can you tell me about a time when you worked successfully as a part of a team?",
                          name: "worked_successfully",
                          value: worked_successfully,
                        },
                        {
                          label:
                            "Tell me how you are prepared for extreme physical work, long hours, and tough conditions while being away from your family and use of cell phones?",
                          name: "extreme_work",
                          value: extreme_work,
                        },
                        {
                          label:
                            "Tell me a time where you made a mistake and how did you fix it? What have you learned from making mistakes in the workplace?",
                          name: "mistake_and_fix",
                          value: mistake_and_fix,
                        },
                        {
                          label:
                            "We spent a lot of time in close quarters for many days under stressful and difficult situations how will you handle a crew member that has a bad attitude and is bringing the moral of the crew down?",
                          name: "handle_situations",
                          value: handle_situations,
                        },
                        {
                          label:
                            "While on assignment someone in the buggy turns around rudely disrespects you. How do you respond to that situation?",
                          name: "respond_situation",
                          value: respond_situation,
                        },
                        {
                          label:
                            "You and the crew are working on the fire line and you overhear another member of the crew telling a crude story, how would that make you feel? What actions might you take?",
                          name: "action_to_crude_story",
                          value: action_to_crude_story,
                        },
                        {
                          label:
                            "What was has been the biggest challenge professionally and how did you handle it?",
                          name: "biggest_prof_challenge",
                          value: biggest_prof_challenge,
                        },
                        {
                          label:
                            "Your crew boss was out ahead scouting the fire and on his way back he sees you are doing something he doesn’t like. He speaks to you in an assertive direct manner, how do you respond to his direction and vocal tone?",
                          name: "respond_to_vocal_tone",
                          value: respond_to_vocal_tone,
                        },
                        {
                          label:
                            "Have you resolved a conflict with a colleague or superior? How did you handle it?",
                          name: "handle_conflict",
                          value: handle_conflict,
                        },
                        {
                          label:
                            "<b>You see a coworker with a bag of weed or a bottle of alcohol while out on assignment, this may include when you’re “off the clock” what do you do?</b>",
                          name: "respond_to_alcohol",
                          value: respond_to_alcohol,
                        },
                        {
                          label:
                            "When have you had to take the lead and inspire others? How did you do this?",
                          name: "inspire_others",
                          value: inspire_others,
                        },
                        {
                          label:
                            "<b>Where do you want to be in five years?</b>",
                          name: "where_five_years",
                          value: where_five_years,
                        },
                        {
                          label: "Tell me about some of your goals?",
                          name: "your_goals",
                          value: your_goals,
                        },
                      ]}
                      handleChange={handleChange}
                      errors={errors}
                    />
                  ) : version === 2 ? (
                    <InterviewQuestionnaire
                      lowRatingVerbiage={"Unacceptable"}
                      highRatingVerbiage={"Excellent"}
                      questions={[
                        {
                          label:
                            "Tell me your story (Ex. life experenice, work background)",
                          name: "interested_in_firefighter",
                          value: interested_in_firefighter,
                        },
                        {
                          label: "Why do you want to work for this company?",
                          name: "why_this_company",
                          value: why_this_company,
                        },
                        {
                          label: "Why should we hire you?",
                          name: "why_we_hire",
                          value: why_we_hire,
                        },
                        {
                          label:
                            "<b>What personal qualities or characteristics do you value most? What do others value most about you?</b>",
                          name: "personal_quality",
                          value: personal_quality,
                        },
                        {
                          label: "What are areas you know need improvement?",
                          name: "areas_to_improve",
                          value: areas_to_improve,
                        },
                        {
                          label:
                            "Can you tell me about a time when you worked successfully as a part of a team?",
                          name: "worked_successfully",
                          value: worked_successfully,
                        },
                        {
                          label:
                            "How you handle a crew member that has a bad attitude and is bringing the moral of the crew down?",
                          name: "handle_situations",
                          value: handle_situations,
                        },
                        {
                          label:
                            "If you observe a co-worker in possession of prohibited substances (e.g., alcohol, marijuana, or other illegal paraphernalia) in the workplace, how would you handle the situation?",
                          name: "respond_to_alcohol",
                          value: respond_to_alcohol,
                        },
                        {
                          label:
                            "If you overhear a co-worker making an inappropriate comment, how would you handle the situation? What steps would you take to ensure a respectful workplace?",
                          name: "action_to_crude_story",
                          value: action_to_crude_story,
                        },
                        {
                          label:
                            "Your supervisor provides you with direct and assertive instructions. How do you respond to their direction, and how do you ensure tasks are completed effectively?",
                          name: "respond_to_vocal_tone",
                          value: respond_to_vocal_tone,
                        },
                        {
                          label: "Where do you want to be in 5 years?",
                          name: "where_five_years",
                          value: where_five_years,
                        },
                      ]}
                      handleChange={handleChange}
                      errors={errors}
                    />
                  ) : (
                    <></>
                  )}

                  <div className={`${styles.form_group} ${styles.notes}`}>
                    <label htmlFor="notes">Notes</label>
                    <textarea
                      name="notes"
                      id="notes"
                      rows="4"
                      value={notes}
                      onChange={handleChange}
                      required
                      className={`${errors["notes"] ? styles.error : ""}`}
                    ></textarea>
                  </div>

                  <div className={styles.continer_table_students}>
                    <div className={styles.table_outerdiv}>
                      <table className={styles.second_table}>
                        <tbody>
                          <tr>
                            <td
                              colSpan={4}
                              className={styles.second_table__head}
                            >
                              <div
                                className={`${styles.grouped_inputs} ${styles.grouped_inputs} `}
                              >
                                <p>
                                  <strong>
                                    Candidate information sent to HR for drug
                                    screen?
                                  </strong>
                                </p>
                                <div
                                  className={`${styles.form_group_row} ${styles.vertical_group} ver_grp`}
                                >
                                  <div
                                    className={`${styles.radio_btn_wrapper} ${styles.width__auto} radio_btns`}
                                  >
                                    <div
                                      className={`${styles.radio_group} first_radio_input`}
                                    >
                                      <label className={styles.container_radio}>
                                        <input
                                          className={`${styles.radio_inp} `}
                                          type="radio"
                                          required
                                          name="candidate_information_sent_to_hr"
                                          value={1}
                                          id="one"
                                          checked={
                                            candidate_information_sent_to_hr ===
                                            "1"
                                          }
                                          onChange={handleChange}
                                        />
                                        <span
                                          className={`${
                                            errors[
                                              "candidate_information_sent_to_hr"
                                            ]
                                              ? cx(
                                                  styles.error,
                                                  styles.radiobtn
                                                )
                                              : styles.radiobtn
                                          }`}
                                        ></span>
                                        <p>No</p>
                                      </label>
                                    </div>
                                    <div className={`${styles.radio_group}`}>
                                      <label className={styles.container_radio}>
                                        <input
                                          className={`${styles.radio_inp}`}
                                          type="radio"
                                          required
                                          name="candidate_information_sent_to_hr"
                                          value={2}
                                          id="one"
                                          checked={
                                            candidate_information_sent_to_hr ===
                                            "2"
                                          }
                                          onChange={handleChange}
                                        />
                                        <span
                                          className={`${
                                            errors[
                                              "candidate_information_sent_to_hr"
                                            ]
                                              ? cx(
                                                  styles.error,
                                                  styles.radiobtn
                                                )
                                              : styles.radiobtn
                                          }`}
                                        ></span>
                                        <p>Yes</p>
                                        {candidate_information_sent_to_hr ===
                                          "2" && (
                                          <div
                                            className={`${styles.form_group} ${styles.form__row}`}
                                          >
                                            <label
                                              className={`${styles.hidden_label}`}
                                              htmlFor="ds-date"
                                            >
                                              Date on Interview
                                            </label>
                                            <DatePicker
                                              type="text"
                                              required={
                                                candidate_information_sent_to_hr ===
                                                "2"
                                              }
                                              id="ds-date"
                                              placeholderText="Date"
                                              className={`${
                                                errors[
                                                  "candidate_information_sent_date"
                                                ]
                                                  ? styles.error
                                                  : ""
                                              }`}
                                              name="candidate_information_sent_date"
                                              selected={
                                                candidate_information_sent_date &&
                                                moment(
                                                  candidate_information_sent_date
                                                )
                                              }
                                              onChange={(date) =>
                                                handleDateChange(
                                                  "candidate_information_sent_date",
                                                  date
                                                )
                                              }
                                              showMonthDropdown
                                              showYearDropdown
                                              dropdownMode="select"
                                              autoComplete="off"
                                            />
                                          </div>
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td width="16%">
                              <p>
                                <strong>Student status</strong>
                              </p>
                            </td>
                            <td width="28%">
                              <div
                                className={`${styles.grouped_inputs} ${styles.grouped_inputs} second_row`}
                              >
                                <div
                                  className={`${styles.form_group_row} ${styles.vertical_group} ver_grp`}
                                >
                                  <div
                                    className={`${styles.radio_btn_wrapper}`}
                                  >
                                    <div className={`${styles.radio_group}`}>
                                      <label className={styles.container_radio}>
                                        <input
                                          className={`${styles.radio_inp}`}
                                          type="radio"
                                          required
                                          name="student_status"
                                          id="one"
                                          value={3}
                                          checked={student_status === "3"}
                                          onChange={handleChange}
                                        />
                                        <span
                                          className={`${
                                            errors["student_status"]
                                              ? cx(
                                                  styles.error,
                                                  styles.radiobtn
                                                )
                                              : styles.radiobtn
                                          }`}
                                        ></span>
                                        <p>
                                          <strong>Not a student</strong>
                                        </p>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td width="28%">
                              <div
                                className={`${styles.grouped_inputs} ${styles.grouped_inputs} second_row`}
                              >
                                <div
                                  className={`${styles.form_group_row} ${styles.vertical_group} ver_grp`}
                                >
                                  <div
                                    className={`${styles.radio_btn_wrapper}`}
                                  >
                                    <div className={`${styles.radio_group}`}>
                                      <label className={styles.container_radio}>
                                        <input
                                          className={`${styles.radio_inp}`}
                                          type="radio"
                                          required
                                          name="student_status"
                                          id="one"
                                          value={1}
                                          checked={student_status === "1"}
                                          onChange={handleChange}
                                        />
                                        <span
                                          className={`${
                                            errors["student_status"]
                                              ? cx(
                                                  styles.error,
                                                  styles.radiobtn
                                                )
                                              : styles.radiobtn
                                          }`}
                                        ></span>
                                        <p>
                                          <strong>Spring Semester</strong>
                                        </p>
                                        {student_status === "1" && (
                                          <div className={styles.form_group}>
                                            <label
                                              className={`${styles.hidden_label}`}
                                              htmlFor="ss-comple-date"
                                            >
                                              Completion Date
                                            </label>
                                            <DatePicker
                                              type="date"
                                              required={student_status === "1"}
                                              className={`${
                                                errors[
                                                  "spring_sem_completion_date"
                                                ]
                                                  ? styles.error
                                                  : ""
                                              }`}
                                              id="spring_sem_completion_date"
                                              placeholderText="Completion Date"
                                              name="spring_sem_completion_date"
                                              selected={
                                                spring_sem_completion_date &&
                                                moment(
                                                  spring_sem_completion_date
                                                )
                                              }
                                              onChange={(date) =>
                                                handleDateChange(
                                                  "spring_sem_completion_date",
                                                  date
                                                )
                                              }
                                              showMonthDropdown
                                              showYearDropdown
                                              dropdownMode="select"
                                              autoComplete="off"
                                            />
                                          </div>
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td width="28%">
                              <div
                                className={`${styles.grouped_inputs} ${styles.grouped_inputs} second_row`}
                              >
                                <div
                                  className={`${styles.form_group_row} ${styles.vertical_group} ver_grp`}
                                >
                                  <div
                                    className={`${styles.radio_btn_wrapper}`}
                                  >
                                    <div className={`${styles.radio_group}`}>
                                      <label className={styles.container_radio}>
                                        <input
                                          className={`${styles.radio_inp}`}
                                          type="radio"
                                          required
                                          name="student_status"
                                          id="one"
                                          value={2}
                                          checked={student_status === "2"}
                                          onChange={handleChange}
                                        />
                                        <span
                                          className={`${
                                            errors["student_status"]
                                              ? cx(
                                                  styles.error,
                                                  styles.radiobtn
                                                )
                                              : styles.radiobtn
                                          }`}
                                        ></span>
                                        <p>
                                          <strong>Fall Semester</strong>
                                        </p>
                                        {student_status === "2" && (
                                          <div className={styles.form_group}>
                                            <label
                                              className={`${styles.hidden_label}`}
                                              htmlFor="ss-start-date"
                                            >
                                              Start Date
                                            </label>
                                            <DatePicker
                                              type="date"
                                              required={student_status === "2"}
                                              id="fall_sem_start_date"
                                              placeholderText="Start Date"
                                              className={`${
                                                errors["fall_sem_start_date"]
                                                  ? styles.error
                                                  : ""
                                              }`}
                                              name="fall_sem_start_date"
                                              selected={
                                                fall_sem_start_date &&
                                                moment(fall_sem_start_date)
                                              }
                                              onChange={(date) =>
                                                handleDateChange(
                                                  "fall_sem_start_date",
                                                  date
                                                )
                                              }
                                              showMonthDropdown
                                              showYearDropdown
                                              dropdownMode="select"
                                              autoComplete="off"
                                            />
                                          </div>
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td width="16%">
                              <p>
                                <strong>Living status</strong>
                              </p>
                            </td>
                            <td width="28%">
                              <div
                                className={`${styles.grouped_inputs} ${styles.grouped_inputs} second_row`}
                              >
                                <div
                                  className={`${styles.form_group_row} ${styles.vertical_group} ver_grp`}
                                >
                                  <div
                                    className={`${styles.radio_btn_wrapper}`}
                                  >
                                    <div className={`${styles.radio_group}`}>
                                      <label className={styles.container_radio}>
                                        <input
                                          className={`${styles.radio_inp}`}
                                          type="radio"
                                          required
                                          name="living_status"
                                          id="one"
                                          value={3}
                                          checked={living_status === "3"}
                                          onChange={handleChange}
                                        />
                                        <span
                                          className={`${
                                            errors["living_status"]
                                              ? cx(
                                                  styles.error,
                                                  styles.radiobtn
                                                )
                                              : styles.radiobtn
                                          }`}
                                        ></span>
                                        <p>
                                          <strong>
                                            Currently within 2 hours of
                                          </strong>
                                        </p>
                                        {living_status === "3" && (
                                          <div
                                            className={`${styles.form_group_row} ${styles.vertical_group}`}
                                          >
                                            <div
                                              className={`${styles.radio_btn_wrapper}`}
                                            >
                                              <div
                                                className={`${styles.radio_group}`}
                                              >
                                                <label
                                                  className={
                                                    styles.container_radio
                                                  }
                                                >
                                                  <input
                                                    className={`${styles.radio_inp}`}
                                                    type="radio"
                                                    required={
                                                      living_status === "3"
                                                    }
                                                    id="ls-02"
                                                    name="living_status_sub"
                                                    value={2}
                                                    checked={
                                                      living_status_sub === "2"
                                                    }
                                                    onChange={handleChange}
                                                  />
                                                  <span
                                                    className={`${
                                                      errors[
                                                        "living_status_sub"
                                                      ]
                                                        ? cx(
                                                            styles.error,
                                                            styles.radiobtn
                                                          )
                                                        : styles.radiobtn
                                                    }`}
                                                  ></span>
                                                  <p className={styles.bold}>
                                                    Chico
                                                  </p>
                                                </label>
                                              </div>
                                              <div
                                                className={`${styles.radio_group}`}
                                              >
                                                <label
                                                  className={
                                                    styles.container_radio
                                                  }
                                                >
                                                  <input
                                                    className={`${styles.radio_inp}`}
                                                    type="radio"
                                                    required={
                                                      living_status === "3"
                                                    }
                                                    id="ls-03"
                                                    name="living_status_sub"
                                                    value={1}
                                                    checked={
                                                      living_status_sub === "1"
                                                    }
                                                    onChange={handleChange}
                                                  />
                                                  <span
                                                    className={`${
                                                      errors[
                                                        "living_status_sub"
                                                      ]
                                                        ? cx(
                                                            styles.error,
                                                            styles.radiobtn
                                                          )
                                                        : styles.radiobtn
                                                    }`}
                                                  ></span>
                                                  <p>Anderson</p>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td width="28%">
                              <div
                                className={`${styles.grouped_inputs} ${styles.grouped_inputs} second_row`}
                              >
                                <div
                                  className={`${styles.form_group_row} ${styles.vertical_group} ver_grp`}
                                >
                                  <div
                                    className={`${styles.radio_btn_wrapper}`}
                                  >
                                    <div className={`${styles.radio_group}`}>
                                      <label className={styles.container_radio}>
                                        <input
                                          className={`${styles.radio_inp}`}
                                          type="radio"
                                          required
                                          name="living_status"
                                          id="one"
                                          value={1}
                                          checked={living_status === "1"}
                                          onChange={handleChange}
                                        />
                                        <span
                                          className={`${
                                            errors["living_status"]
                                              ? cx(
                                                  styles.error,
                                                  styles.radiobtn
                                                )
                                              : styles.radiobtn
                                          }`}
                                        ></span>
                                        <p>
                                          <strong>Out of Area In CA</strong>
                                        </p>
                                        {living_status === "1" && (
                                          <div className={styles.form_group}>
                                            <label
                                              className={`${styles.hidden_label}`}
                                              htmlFor="ss-comple-date"
                                            >
                                              Move Date
                                            </label>
                                            <DatePicker
                                              type="date"
                                              required={living_status === "1"}
                                              className={`${
                                                errors["ca_move_date"]
                                                  ? styles.error
                                                  : ""
                                              }`}
                                              id="ca_move_date"
                                              placeholderText="Move Date"
                                              name="ca_move_date"
                                              selected={
                                                ca_move_date &&
                                                moment(ca_move_date)
                                              }
                                              onChange={(date) =>
                                                handleDateChange(
                                                  "ca_move_date",
                                                  date
                                                )
                                              }
                                              showMonthDropdown
                                              showYearDropdown
                                              dropdownMode="select"
                                              autoComplete="off"
                                            />
                                          </div>
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td width="28%">
                              <div
                                className={`${styles.grouped_inputs} ${styles.grouped_inputs} second_row`}
                              >
                                <div
                                  className={`${styles.form_group_row} ${styles.vertical_group} ver_grp`}
                                >
                                  <div
                                    className={`${styles.radio_btn_wrapper}`}
                                  >
                                    <div className={`${styles.radio_group}`}>
                                      <label className={styles.container_radio}>
                                        <input
                                          className={`${styles.radio_inp}`}
                                          type="radio"
                                          required
                                          name="living_status"
                                          id="one"
                                          value={2}
                                          checked={living_status === "2"}
                                          onChange={handleChange}
                                        />
                                        <span
                                          className={`${
                                            errors["living_status"]
                                              ? cx(
                                                  styles.error,
                                                  styles.radiobtn
                                                )
                                              : styles.radiobtn
                                          }`}
                                        ></span>
                                        <p>
                                          <strong>Out Of State</strong>
                                        </p>
                                        {living_status === "2" && (
                                          <div className={styles.form_group}>
                                            <label
                                              className={`${styles.hidden_label}`}
                                              htmlFor="ss-start-date"
                                            >
                                              Date on Interview
                                            </label>
                                            <DatePicker
                                              type="date"
                                              required={living_status === "2"}
                                              className={`${
                                                errors["out_of_state_move_date"]
                                                  ? styles.error
                                                  : ""
                                              }`}
                                              id="out_of_state_move_date"
                                              placeholderText="Move Date"
                                              name="out_of_state_move_date"
                                              selected={
                                                out_of_state_move_date &&
                                                moment(out_of_state_move_date)
                                              }
                                              onChange={(date) =>
                                                handleDateChange(
                                                  "out_of_state_move_date",
                                                  date
                                                )
                                              }
                                              showMonthDropdown
                                              showYearDropdown
                                              dropdownMode="select"
                                              autoComplete="off"
                                            />
                                          </div>
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className={styles.form_group_row}>
                    <div className={styles.form_group}>
                      <label htmlFor="interviewer_name">
                        Name of Interviewer
                      </label>
                      <input
                        type="text"
                        id=""
                        name=""
                        value={user?.full_name}
                        disabled
                      />
                    </div>
                    <div className={cx(styles.form_group, "full_width_date")}>
                      <label htmlFor="interviewer_date">
                        Date on Form Submission
                      </label>
                      <DatePicker
                        type="date"
                        className={styles.date_picker}
                        popperClassName={styles.date_picker}
                        wrapperClassName={styles.date_picker}
                        id="interviewer_date"
                        name="interviewer_date"
                        selected={interviewer_date}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        autoComplete="off"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.submit_action__wrapper}>
              <div
                className={cx(styles.input__wrapper, styles.no_margin_input)}
              >
                <input
                  type="button"
                  name="draft"
                  className={styles.button}
                  value="Back"
                  onClick={(e) => {
                    e.preventDefault();
                    // setCurrentView("listView");
                    setKey("referenceForm");
                  }}
                />
                {/* {editMode && ( */}
                {formId && (
                  <input
                    type="button"
                    name="draft"
                    className={styles.button}
                    value="Delete"
                    onClick={(e) => {
                      e.preventDefault();
                      deleteIntForm();
                    }}
                    disabled={loading}
                  />
                )}
              </div>
              {/* {leastOneReference && ( */}
              {
                <div className={styles.input__wrapper}>
                  <input
                    type="submit"
                    name="draft"
                    className={styles.button}
                    value="Save as Draft"
                    disabled={loading}
                  />
                  <input
                    type="submit"
                    name="submit"
                    className={styles.button}
                    value="Submit"
                    disabled={loading}
                  />
                </div>
              }
            </div>
          </form>
          <Modal
            open={confirmSubmissionOpen}
            onClose={closeConfirmSubmissionModal}
            little
          >
            <div id="deleteBullPopup">
              <div className="modal-head">
                <h4 className="modal-title">
                  Confirm Interview Form Submission
                </h4>
              </div>
              <div className="modal-body">
                <p>
                  By submitting this form, your responses will be finalized and
                  the form will become uneditable. Are you sure you want to
                  proceed with submitting the interview form for this applicant?
                </p>
                <div className="btn_list">
                  <button
                    className="button grey"
                    onClick={closeConfirmSubmissionModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="button"
                    onClick={() => submitForm("submit")}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};
