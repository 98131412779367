import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import logo from "assets/images/logo.png";
import { profileType } from "_constants";
import { smsService } from "_services";

const SmsConsentPrompt = () => {
  const dispatch = useDispatch();
  const [smsNotifyModal, setSmsNotifyModal] = useState(false);
  const [smsNotified, setSmsNotified] = useState(false);
  const [smsNotifyLoader, setSmsNotifyLoader] = useState(false);

  const currentUser = useSelector((state) => state?.user?.profile?.data);

  useEffect(() => {
    if (!currentUser) return; 

    const allowSmsNotification = currentUser?.sms_notify;

    if (allowSmsNotification == null && !smsNotified) {
      setSmsNotifyModal(true);
    }
  }, [currentUser, smsNotified]);


  const formatPhoneNumber = (phone) => {
    return phone ? phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3") : "";
  };

  const smsNotify = async (allowNotification) => {
    setSmsNotifyLoader(true);
    try {
      const data = new FormData();
      data.append("allow_notification", allowNotification);

      await smsService.changeSmsNotifyStatus(data, profileType.EMPLOYEE, currentUser?.id);
      setSmsNotifyLoader(false);
      setSmsNotified(true);
      setSmsNotifyModal(false);
      toast.success("Updated notification preference successfully.");
    } catch (error) {
      setSmsNotifyLoader(false);
      toast.error("Failed to update notification preference.");
    }
  };

  return (
    <ReactModal
      isOpen={smsNotifyModal}
    >
      <div id="statusPopup" className="resources-popup">
        <div className="modal-head sms-modal-head" style={{ backgroundColor: "#202020" }}>
          <img src={logo} alt="Firestorm" />
        </div>
        <div className="modal-body sms-modal scrollable-content">
          <div className="sms-modal-body">
            <h4>
              Stay informed about company-wide announcements, crew updates, and more.
            </h4>
            <br />
            <label>Name</label>
            <input
              type="text"
              readOnly
              disabled
              value={`${currentUser?.first_name || ""} ${currentUser?.last_name || ""}`}
            />
            <label>Email Address</label>
            <input
              type="text"
              readOnly
              disabled
              value={currentUser?.email || ""}
            />
            <label>Phone Number</label>
            <input
              type="text"
              readOnly
              disabled
              value={formatPhoneNumber(currentUser?.phone)}
            />
            <h5>
              By clicking "Agree", you consent to Firestorm Wildland Fire Suppression Inc. sending you SMS notifications regarding company updates.
            </h5>
            <h5>
              By clicking "Disagree", you opt out of receiving SMS. You can update this anytime in your profile.
            </h5>
          </div>
          <div className="sms-btn_list btn_list">
            <button
              className={`button ${smsNotifyLoader ? "disabled" : ""}`}
              onClick={() => smsNotify(true)}
              disabled={smsNotifyLoader}
            >
              Agree
            </button>
            <button
              className={`button red ${smsNotifyLoader ? "disabled" : ""}`}
              onClick={() => smsNotify(false)}
              disabled={smsNotifyLoader}
            >
              Disagree
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default SmsConsentPrompt;
