import React from "react";
import cx from "classnames";
import styles from "InterviewForm/interview.module.scss";

const InterviewQuestionnaire = ({ lowRatingVerbiage, highRatingVerbiage, questions, handleChange, errors }) => {
  return (
    <div className={styles.form_group}>
      <div className={styles.table_wrapper}>
        <table>
          <thead>
            <tr>
              <th colSpan="7"><span style={{ marginLeft: "16px" }}>Questions</span></th>
              <th>1 <span>({lowRatingVerbiage})</span></th>
              <th>2 <span class="empty"></span></th>
              <th>3 <span class="empty"></span></th>
              <th>4 <span class="empty"></span></th>
              <th>5 <span>({highRatingVerbiage})</span></th>
            </tr>
          </thead>
          <tbody>
            {questions.map((question, index) => (
              <tr key={index}>
                <td colSpan="7"><p dangerouslySetInnerHTML={{ __html: question.label }} /></td>
                {[1, 2, 3, 4, 5].map((value) => (
                  <td key={value}>
                    <div className={styles.radio_group}>
                      <label className={styles.container_radio}>
                        <input
                          className={styles.radio_inp}
                          type="radio"
                          required
                          name={question.name}
                          value={value}
                          onChange={handleChange}
                          checked={question.value === String(value)}
                        />
                        <span
                          className={cx(
                            errors[question.name]
                              ? cx(styles.error, styles.radiobtn)
                              : styles.radiobtn
                          )}
                        ></span>
                      </label>
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InterviewQuestionnaire;
