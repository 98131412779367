import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Col, Row } from "react-bootstrap";

const ReferenceQuestionnaire = ({
  version,
  key,
  referenceData,
  referenceType,
  index,
  register,
  control,
  errors,
}) => {
  const referenceFields =
    referenceType === "Past Work History"
      ? [
          { label: "Employer", value: referenceData?.last_employee },
          { label: "Position Held", value: referenceData?.position },
          { label: "Supervisor", value: referenceData?.supervisor_name },
          { label: "Cell Phone", value: referenceData?.telephone },
        ]
      : referenceType === "Professional"
        ? [
            { label: "Name", value: referenceData?.name },
            { label: "Relationship", value: referenceData?.relationship },
            { label: "Cell Phone", value: referenceData?.phone },
          ]
        : [];

  const referenceQuestions =
    version == 1
      ? [
          { label: "Would you hire them again?", name: "hire" },
          {
            label: "How would you describe their work ethic?",
            name: "work_ethic",
          },
          {
            label: "Did they show up on time consistently?",
            name: "consistency",
          },
        ]
      : version == 2
        ? [
            {
              label:
                "Can you confirm that the candidate’s job title and employment dates are correct? If not, provide accurate dates.",
              name: "confirm_dates",
            },
            {
              label:
                "Would you rehire the candidate if given the opportunity? Why or why not in one sentence?",
              name: "hire",
            },
            {
              label:
                "What are the candidate's key strengths? What areas need improvement?",
              name: "key_strengths",
            },
            {
              label:
                "How would you rate their reliability and dependability in one sentence? *Would you say they were the first or last on the job?*",
              name: "consistency",
            },
            {
              label:
                "Describe how the candidate interacted with co-workers, supervisors, and clients/customers.",
              name: "work_ethic",
            },
            {
              label:
                "What do you wish you knew about the candidate before you hired them?",
              name: "prior_knowledge",
            },
            {
              label:
                "Based on your experience, why or why not do you believe this candidate would be a good fit for this position?",
              name: "reason_good_fit",
            },
          ]
        : [];

  return (
    <Row key={key} className="show-grid interview__grid">
      <div className="interview__head">
        <h1>
          Reference #{index + 1} ({referenceType})
        </h1>
      </div>

      <Col className="col_table">
        <div className="interview__wrap">
          <table className="ref_table">
            <tbody>
              {referenceFields.map(({ label, value }) => (
                <tr key={label}>
                  <td>{label}</td>
                  <td>{value || "-"}</td>
                </tr>
              ))}

              <tr>
                <td>Verified</td>
                <td>
                  <div className="checkbox_wrap">
                    <input
                      type="checkbox"
                      {...register(`verified_check_${index + 1}`)}
                      className={`checkbox_input ${
                        errors?.[`verified_check_${index + 1}`]?.message ? "error" : ""
                      }`}
                    />
                    <label className="checkbox_label"></label>
                  </div>
                </td>
              </tr>

              <tr>
                <td>Verified Date</td>
                <td>
                  <div className="verified_date">
                    <Controller
                      control={control}
                      name={`verified_${index + 1}_date`}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <DatePicker
                          className="datepicker_ref"
                          placeholderText="Date"
                          ref={ref}
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          showMonthDropdown
                          showYearDropdown
                          maxDate={moment(new Date())}
                        />
                      )}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Col>

      <div className="referenceCheckSection">
        <Row className="show-grid">
          {referenceQuestions.map(({ label, name }) => (
            <Col key={name}>
              <p>{label}</p>
              <input
                type="text"
                {...register(`${name}_${index + 1}`)}
                className={`${errors?.[`${name}_${index + 1}`]?.message ? "error" : ""}`}
              />
            </Col>
          ))}
        </Row>
      </div>

      <Col className="col_note">
        <p>Notes</p>
        <textarea
          {...register(`ref_${index + 1}_notes`)}
          className={`${errors?.[`ref_${index + 1}_notes`]?.message ? "error" : ""}`}
        ></textarea>
        <span className="text-area-error-msg">
          {errors?.[`ref_${index + 1}_notes`]?.message}
        </span>
      </Col>
    </Row>
  );
};

export default ReferenceQuestionnaire;
