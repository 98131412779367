import { userConstants } from "../_constants";
import { userService } from "../_services";
import { alertActions } from "./";
import { history } from "../_helpers";
import { notificationService } from "../_services";
import { notificationConstants } from "../_constants";
import { getCurrentUserInfo } from "_helpers/reduxSelector";

export const userActions = {
  login,
  logout,
  register,
  register_new,
  getAll,
  getProfile,
  changeStatus,
  lockUserToggle,
  lockToggle,
  changeEmployeeStatus,
  removeEmployee,
  getEmployeeList,
  editProfile,
  resetPassword,
  newPassword,
  assignStaff,
  employeeStats,
  loginProfile,
  updateEmployeeNote,
  editSelfProfile,
  changeSelfStatus,
  getSingleBulletin,
  deleteBulletinFile,
  getWidgets,
  updateWidgets,
  changePassword,
  getUserRoles,
  importEmployees,
  addEmployeeForBypassing,
  addQualification,
  editQualification,
  updateQualification,
  removeQualification,
};

function updateWidgets(data) {
  return (dispatch) => {
    dispatch(request());
    userService.updateWidgets(data).then(
      (widgets) => dispatch(success(widgets)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: userConstants.WIDGET_UPDATE_REQUEST };
  }
  function success(widgets) {
    return { type: userConstants.WIDGET_UPDATE_SUCCESS, widgets };
  }
  function failure(error) {
    return { type: userConstants.WIDGET_UPDATE_FAILURE, error };
  }
}
function getCount() {
  return (dispatch) => {
    dispatch(request());

    notificationService.getCount().then(
      (count) => dispatch(success(count)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: notificationConstants.GETCOUNT_REQUEST };
  }
  function success(count) {
    return { type: notificationConstants.GETCOUNT_SUCCESS, count };
  }
  function failure(error) {
    return { type: notificationConstants.GETCOUNT_FAILURE, error };
  }
}
function getWidgets() {
  return (dispatch) => {
    dispatch(request());

    userService.getWidgets().then(
      (widgets) => dispatch(success(widgets)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: userConstants.WIDGET_REQUEST };
  }
  function success(widgets) {
    return { type: userConstants.WIDGET_SUCCESS, widgets };
  }
  function failure(error) {
    return { type: userConstants.WIDGET_FAILURE, error };
  }
}

function login(username, password) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request({ username }));
      userService.login(username, password).then(
        (res) => {
          if (res.success) {
            dispatch(success({ success: res.data }));
            let { type } = res.data;
            switch (type) {
              case "applicant":
                history.push({
                  pathname: "/register",
                  state: { email: username },
                });
                break;
              case "employee":
                history.push("/dashboard");
                break;
              default:
                history.push("/dashboard");
                break;
            }
          }
          resolve(res);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function resetPassword(data) {
  return (dispatch) => {
    dispatch(request());
    userService.resetPassword(data).then(
      // (data) => {
      //     dispatch(alert(data.message));
      //     // dispatch(alertActions.success('Registration successful'));
      // },
      (changePwd) => dispatch(success(changePwd)),
      (error) => {
        dispatch(failure(error));
        // dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: userConstants.RESET_REQUEST };
  }
  function success(changePwd) {
    return { type: userConstants.RESET_SUCCESS, changePwd };
  }
  function failure(error) {
    return { type: userConstants.RESET_FAILURE, error };
  }
}

function newPassword(data) {
  return (dispatch) => {
    dispatch(request());
    userService.newPassword(data).then(
      (data) => {
        if (!data.data) {
          dispatch(alertActions.error(data.message || ""));
        } else {
          dispatch(alertActions.success(data.message || ""));
        }
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
        error = JSON.parse(error);
        dispatch(alertActions.error(error.message));
      }
    );
  };

  function request() {
    return { type: userConstants.PASSWORD_REQUEST };
  }
  function success(data) {
    return { type: userConstants.PASSWORD_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.PASSWORD_FAILURE, error };
  }
}

function changePassword(data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request());
      userService.changePassword(data).then(
        (res) => {
          dispatch(success(res));
          resolve(res);
        },
        (error) => {
          dispatch(failure(error));
          error = JSON.parse(error);
          reject(error);
        }
      );
    });
  };

  function request() {
    return { type: userConstants.PASSWORD_REQUEST };
  }
  function success(data) {
    return { type: userConstants.PASSWORD_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.PASSWORD_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  return (dispatch) => {
    dispatch({ type: userConstants.LOGOUT });
  };
}

function editProfile(data, hash) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request(data));
      userService.editProfile(data).then(
        (saveProfile) => {
          dispatch(getProfile(hash));
          dispatch(success(saveProfile));
          resolve(saveProfile);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };

  function request(saveProfile) {
    return { type: userConstants.EDITPROFILE_REQUEST, saveProfile };
  }
  function success(saveProfile) {
    return { type: userConstants.EDITPROFILE_SUCCESS, saveProfile };
  }
  function failure(error) {
    return { type: userConstants.EDITPROFILE_FAILURE, error };
  }
}

function editSelfProfile(data, hash) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request(data));
      userService.editProfile(data).then(
        (saveProfile) => {
          dispatch(loginProfile());
          dispatch(success(saveProfile));
          resolve(saveProfile);
        }, (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };

  function request(saveProfile) {
    return { type: userConstants.EDITPROFILE_REQUEST, saveProfile };
  }
  function success(saveProfile) {
    return { type: userConstants.EDITPROFILE_SUCCESS, saveProfile };
  }
  function failure(error) {
    return { type: userConstants.EDITPROFILE_FAILURE, error };
  }
}

function register(user) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request(user));
      userService.register(user).then(
        (res) => {
          if (res.success) {
            dispatch({ type: userConstants.LOGIN_SUCCESS, user: res.data });
            dispatch(success());
            dispatch(alertActions.success("Registration successful"));
            localStorage.setItem("user", JSON.stringify({ success: res.data }));
            let { type } = res.data;
            switch (type) {
              case "applicant":
                history.push("/register");
                break;
              case "employee":
                history.push("/dashboard");
                break;
              default:
                history.push("/dashboard");
                break;
            }
          } else {
            dispatch(success(res.message));
          }
          resolve(res);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function register_new(user) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request(user));
      userService.register(user).then(
        (res) => {
          if (res.success) {
            resolve(res);
            dispatch(alertActions.success("Registration successful"));
            dispatch(success(res.message));
            // history.push("/login");
          } else {
            resolve(res);
            dispatch(success(res.message));
          }
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request());

    return userService.getAll().then(
      (user) => dispatch(success(user)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(user) {
    return { type: userConstants.GETALL_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}

function loginProfile() {
  return (dispatch) => {
    dispatch(request());

    userService.getAll().then(
      (profile) => dispatch(success(profile)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: userConstants.LOGDETAILS_REQUEST };
  }
  function success(profile) {
    return { type: userConstants.LOGDETAILS_SUCCESS, profile };
  }
  function failure(error) {
    return { type: userConstants.LOGDETAILS_FAILURE, error };
  }
}

function getProfile(hash = "") {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(request());
      userService.getProfile(hash).then(
        (user) => {
          resolve(user);
          dispatch(success(user));
          const state = getState()
          const currentUserInfo = getCurrentUserInfo(state)
          const isCurrentUser = !hash && (user?.data?.hash === currentUserInfo?.hash)
          if (isCurrentUser) {
            dispatch({ type: userConstants.LOGDETAILS_SUCCESS, profile: user })  // Bugfix: Profile was not updating after doc update for current user
          }
        },
        (error) => {
          reject(error);
          dispatch(failure(error));
        }
      );
    });

  function request() {
    return { type: userConstants.GETPROFILE_REQUEST };
  }
  function success(user) {
    return { type: userConstants.GETPROFILE_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GETPROFILE_FAILURE, error };
  }
}

function changeStatus(data, hash) {
  return (dispatch) => {
    dispatch(request());

    userService.changeStatus(data).then(
      // user => dispatch(getProfile(hash)),
      (user) => {
        dispatch(getProfile(hash));
        dispatch(getCount());
        dispatch(loginProfile());
        dispatch(success(user));
      },
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: userConstants.GETPROFILE_REQUEST };
  }
  function success(user) {
    return { type: userConstants.GETPROFILE_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GETPROFILE_FAILURE, error };
  }
}


function lockUserToggle(data, hash) {
  return (dispatch) => {
    dispatch(request());

    userService.lockUserToggle(data).then(
      (user) => {
        dispatch(getProfile(hash));
        dispatch(getCount());
        dispatch(success(user));
      },
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: userConstants.GETPROFILE_REQUEST };
  }
  function success(user) {
    return { type: userConstants.GETPROFILE_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GETPROFILE_FAILURE, error };
  }
}

// lock Employee toggle
function lockToggle(data) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(request());
      userService.lockUserToggle(data).then(
        (user) => {
          resolve(user);
          dispatch(success(user));
        },
        (error) => {
          reject(error);
          dispatch(failure(error));
        }
      );
    });

  function request() {
    return { type: userConstants.GETPROFILE_REQUEST };
  }
  function success(user) {
    return { type: userConstants.GETPROFILE_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GETPROFILE_FAILURE, error };
  }
}

function changeSelfStatus(data) {
  return (dispatch) => {
    dispatch(request());

    return userService.changeStatus(data).then(
      (user) => {
        dispatch(loginProfile());
        dispatch(getCount());
        dispatch(success(user));
        return user;
      },
      (error) => {
        dispatch(failure(error));
        throw error;
      }
    );
  };

  function request() {
    return { type: userConstants.EMPSELFSTATUS_REQUEST };
  }

  function success(empStatus) {
    return { type: userConstants.EMPSELFSTATUS_SUCCESS, empStatus };
  }

  function failure(error) {
    return { type: userConstants.EMPSELFSTATUS_FAILURE, error };
  }
}


function changeEmployeeStatus(data, hash) {
  return (dispatch) => {
    dispatch(request());

    userService.changeStatus(data).then(
      (user) => dispatch(),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: userConstants.GETPROFILE_REQUEST };
  }
  // function success(user) { return { type: userConstants.GETPROFILE_SUCCESS, user } }
  function failure(error) {
    return { type: userConstants.GETPROFILE_FAILURE, error };
  }
}

function removeEmployee(data) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(request());

      userService.removeEmployee(data).then(
        (removeUser) => {
          if (removeUser.success) {
            resolve(removeUser);
            dispatch(success(removeUser));
          } else {
            reject(removeUser);
            dispatch(failure(removeUser));
          }
        },
        // removeUser => dispatch(getEmployeeList()),
        (error) => {
          reject(error);
          dispatch(failure(error));
        }
      );
    });

  function request() {
    return { type: userConstants.REMOVEPROFILE_REQUEST };
  }
  function success(removeUser) {
    return { type: userConstants.REMOVEPROFILE_SUCCESS, removeUser };
  }
  function failure(error) {
    return { type: userConstants.REMOVEPROFILE_FAILURE, error };
  }
}

function getEmployeeList(data) {
  return (dispatch) => {
    dispatch(request());
    return userService.getEmployeeList(data).then(
      (user) => dispatch(success(user)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(user) {
    return { type: userConstants.GETALL_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}

function assignStaff(data) {
  return (dispatch) => {
    dispatch(request());

    userService.assignStaff(data).then(
      (user) => dispatch(success(user)),
      (error) => dispatch(failure(error))
    );
  };
  function request() {
    return { type: userConstants.ASSIGNSTAFF_REQUEST };
  }
  function success(user) {
    return { type: userConstants.ASSIGNSTAFF_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.ASSIGNSTAFF_FAILURE, error };
  }
}

function employeeStats() {
  return (dispatch) => {
    dispatch(request());

    userService.employeeStats().then(
      (stats) => dispatch(success(stats)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: userConstants.GETSTATS_REQUEST };
  }
  function success(stats) {
    return { type: userConstants.GETSTATS_SUCCESS, stats };
  }
  function failure(error) {
    return { type: userConstants.GETSTATS_FAILURE, error };
  }
}

function updateEmployeeNote(data, hash) {
  return (dispatch) => {
    dispatch(request());

    return userService.updateEmployeeNote(data, hash).then(
      (notes) => dispatch(getProfile(hash)),
      // notes => dispatch(getProfile(hash)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: userConstants.NOTES_REQUEST };
  }
  // function success(notes) { return { type: userConstants.NOTES_SUCCESS, notes } }
  function failure(error) {
    return { type: userConstants.NOTES_FAILURE, error };
  }
}

function getSingleBulletin(id) {
  return (dispatch) => {
    dispatch(request());

    userService.getSingleBulletin(id).then(
      (bullDetail) => dispatch(success(bullDetail)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: userConstants.GETBULLETIN_REQUEST };
  }
  function success(bullDetail) {
    return { type: userConstants.GETBULLETIN_SUCCESS, bullDetail };
  }
  function failure(error) {
    return { type: userConstants.GETBULLETIN_FAILURE, error };
  }
}

function deleteBulletinFile(id) {
  return (dispatch) => {
    dispatch(request());

    userService.deleteBulletinFile(id).then(
      (dltFile) => dispatch(success(dltFile)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: userConstants.DELETEFILE_REQUEST };
  }
  function success(dltFile) {
    return { type: userConstants.DELETEFILE_SUCCESS, dltFile };
  }
  function failure(error) {
    return { type: userConstants.DELETEFILE_FAILURE, error };
  }
}


function getUserRoles(id) {
  return (dispatch) => {
    dispatch(request());

    userService.getUserRoles(id).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: userConstants.GET_USER_ROLES_REQUEST };
  }
  function success(data) {
    return { type: userConstants.GET_USER_ROLES_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.GET_USER_ROLES_ERROR, error };
  }
}

// function importEmployees(data) {
//   return (dispatch) => {
//     dispatch(request());

//     userService.importEmployees(data).then(
//       (data) => dispatch(success(data)),
//       (error) => dispatch(failure(error))
//     );
//   };

//   function request() {
//     return { type: userConstants.IMPORT_EMPLOYEES_REQUEST };
//   }
//   function success(data) {
//     return { type: userConstants.IMPORT_EMPLOYEES_SUCCESS, data };
//   }
//   function failure(error) {
//     return { type: userConstants.IMPORT_EMPLOYEES_ERROR, error };
//   }
// }

function importEmployees(data) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const response = await userService.importEmployees(data);
      dispatch(success(response.data));
      return response;
    } catch (error) {
      dispatch(failure(error));
      throw error;
    }
  };

  function request() {
    return { type: userConstants.IMPORT_EMPLOYEES_REQUEST };
  }
  function success(data) {
    return { type: userConstants.IMPORT_EMPLOYEES_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.IMPORT_EMPLOYEES_ERROR, error };
  }
}


function addEmployeeForBypassing(data) {
  return async (dispatch) => {
    dispatch(request());

    // userService.addEmployeeForBypassing(data).then(
    //   (data) => dispatch(success(data)),
    //   (error) => dispatch(failure(error))
    // );

    try {
      const res = await userService.addEmployeeForBypassing(data);
      dispatch(success(res.data));
      return res;
    } catch (error) {
      dispatch(failure(error));
      throw error;
    }
  };

  function request() {
    return { type: userConstants.ADD_EMPLOYEE_REQUEST };
  }
  function success(data) {
    return { type: userConstants.ADD_EMPLOYEE_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.ADD_EMPLOYEE_ERROR, error };
  }
}


function addQualification(data) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const res = await userService.addQualifications(data);
      dispatch(success(res.data));
      return res;
    } catch (error) {
      dispatch(failure(error));
      throw error;
    }
  };

  function request() {
    return { type: userConstants.ADD_QUALIFICATION_REQUEST };
  }
  function success(data) {
    return { type: userConstants.ADD_QUALIFICATION_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.ADD_QUALIFICATION_ERROR, error };
  }
}

function editQualification(data) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const res = await userService.editQualifications(data);
      dispatch(success(res.data));
      return res;
    } catch (error) {
      dispatch(failure(error));
      throw error;
    }
  };

  function request() {
    return { type: userConstants.EDIT_QUALIFICATION_REQUEST };
  }
  function success(data) {
    return { type: userConstants.EDIT_QUALIFICATION_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.EDIT_QUALIFICATION_ERROR, error };
  }
}

function updateQualification(data) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const res = await userService.updateQualifications(data);
      dispatch(success(res.data));
      return res;
    } catch (error) {
      dispatch(failure(error));
      throw error;
    }
  };

  function request() {
    return { type: userConstants.UPDATE_QUALIFICATION_REQUEST };
  }
  function success(data) {
    return { type: userConstants.UPDATE_QUALIFICATION_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_QUALIFICATION_ERROR, error };
  }
}

function removeQualification(data) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const res = await userService.removeQualification(data);
      dispatch(success(res.data));
      return res;
    } catch (error) {
      dispatch(failure(error));
      throw error;
    }
  };

  function request() {
    return { type: userConstants.REMOVE_QUALIFICATION_REQUEST };
  }
  function success(data) {
    return { type: userConstants.REMOVE_QUALIFICATION_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.REMOVE_QUALIFICATION_ERROR, error };
  }
}