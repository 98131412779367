import { qualificationConstants } from '../_constants';
import { qualificationService } from '../_services';

export const qualificationActions = {
  fetchQualificationList,
  deleteQualification,
  editQualification,
  createQualification
};

// Fetch All Qualifications
function fetchQualificationList() {
  return (dispatch) => {
    dispatch(request());
    qualificationService.fetchAllQualifications().then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: qualificationConstants?.ALL_QUALIFICATIONS_REQUEST };
  }
  function success(response) {
    return {
      type: qualificationConstants?.ALL_QUALIFICATIONS_SUCCESS,
      data: response ?? [],
    };
  }
  function failure(error) {
    return { type: qualificationConstants?.ALL_QUALIFICATIONS_ERROR, error };
  }
}

// Delete Qualification
function deleteQualification(qualId) {
  return (dispatch) => {
    dispatch(request());
    qualificationService.deleteQualification(qualId).then(
      (data) => dispatch(success(qualId)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: qualificationConstants?.DELETE_QUALIFICATION_REQUEST };
  }
  function success(qualId) {
    return (dispatch) => {
      dispatch({ 
        type: qualificationConstants?.DELETE_QUALIFICATION_SUCCESS, 
        payload: { qualId } 
      });
  
      // Reset the error after a delay only if it has been shown in the toast
      setTimeout(() => {
        dispatch({ type: qualificationConstants?.RESET_QUALIFICATION_ERROR });
      }, 2000); // Adjust the delay as needed
    };
  }
  function failure(error) {
    return (dispatch) => {
      dispatch({ type: qualificationConstants?.DELETE_QUALIFICATION_ERROR, error });
  
      // Reset the error after a delay only if it has been shown in the toast
      setTimeout(() => {
        dispatch({ type: qualificationConstants?.RESET_QUALIFICATION_ERROR });
      }, 2000); // Adjust the delay as needed
    };
  }
}

// Edit Qualification
function editQualification(id, data) {
  return (dispatch) => {
    dispatch(request());
    qualificationService.editQualification(id, data).then(
      (message) => dispatch(success(id, message.data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: qualificationConstants?.EDIT_QUALIFICATION_REQUEST };
  }
  function success(id, data) {
    return (dispatch) => {
      dispatch({
        type: qualificationConstants?.EDIT_QUALIFICATION_SUCCESS,
        payload: { id, data }, 
      });
  
      // Reset the error after a delay only if it has been shown in the toast
      setTimeout(() => {
        dispatch({ type: qualificationConstants?.RESET_QUALIFICATION_ERROR });
      }, 2000); // Adjust the delay as needed
    };
  }
  function failure(error) {
    return (dispatch) => {
      dispatch({ type: qualificationConstants?.EDIT_QUALIFICATION_ERROR, error });
  
      // Reset the error after a delay only if it has been shown in the toast
      setTimeout(() => {
        dispatch({ type: qualificationConstants?.RESET_QUALIFICATION_ERROR });
      }, 2000); // Adjust the delay as needed
    };
  }
}

// Create Qualification
function createQualification(data) {
  return (dispatch) => {
    dispatch(request());
    qualificationService.createQualification(data).then(
      (message) => dispatch(success(message.data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: qualificationConstants?.CREATE_QUALIFICATION_REQUEST };
  }
  function success(qual) {
    return (dispatch) => {
      dispatch({
        type: qualificationConstants?.CREATE_QUALIFICATION_SUCCESS,
        payload: { qual }, 
      });
  
      // Reset the error after a delay only if it has been shown in the toast
      setTimeout(() => {
        dispatch({ type: qualificationConstants?.RESET_QUALIFICATION_ERROR });
      }, 2000); // Adjust the delay as needed
    };
  }
  function failure(error) {
    return (dispatch) => {
      dispatch({ type: qualificationConstants?.CREATE_QUALIFICATION_ERROR, error });
  
      // Reset the error after a delay only if it has been shown in the toast
      setTimeout(() => {
        dispatch({ type: qualificationConstants?.RESET_QUALIFICATION_ERROR });
      }, 2000); // Adjust the delay as needed
    };
  }
  
}