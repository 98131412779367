import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { config } from "_helpers/config";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { docsActions } from "_actions/docs.actions";
import { toast } from "react-toastify";
import { SignPad } from "Docs/Components";
import moment from "moment";
import { FormNav } from "./FormNav";
import { navigate, validate } from "./formHandlers";
import logo from "assets/images/logo-with-tagline.png";
import { dateTimeConstants, documentTypes } from "_constants";

class ArbitrationAgreement extends Component {
  constructor(props) {
    super(props);
    const options = {
      timeZone: dateTimeConstants.PST_TIMEZONE,
      month: dateTimeConstants.TWO_DIGIT_FORMAT,
      day: dateTimeConstants.TWO_DIGIT_FORMAT,
      year: dateTimeConstants.NUMERIC_FORMAT,
    };
    const formattedDate = new Date().toLocaleDateString(
      dateTimeConstants.LOCALE_DATE_STRING_FORMAT,
      options
    );
    this.handleDateChange = this.handleDateChange.bind(this);
    this.state = {
      rehire_doc: false,
      doc: {},
      form: {
        // Dates
        arbitration_agreement_employee_date: formattedDate,
        // Employee Signatures
        arbitration_agreement_employee_signature: null,
        // Employee names
        arbitration_agreement_employee_name: null,
      },
      errors: {},
      form_edited: false,
    };
    // Handlers
    this.handleFormChange = this.handleFormChange.bind(this);
    this.validate = validate.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleSignature = this.handleSignature.bind(this);
    this.navigate = navigate.bind(this);
    this.goBack = this.goBack.bind(this);
    // Refs
    this.form = React.createRef();
    this.signContainer = React.createRef();
  }

  // Handle Datepicker changes
  handleDateChange(field, date) {
    let nextState = this.state;
    if (date) {
      _.set(nextState, field, date.format("MM/DD/YYYY"));
    }
    this.setState({
      ...nextState,
      errors: {
        [field]: "",
      },
    });
  }
  // Handle Form Change
  handleFormChange(e) {
    // safety check for datepicker
    if (e.target && e.target.attributes["field"]) {
      let field = e.target.attributes["field"].value;
      let nextState = this.state;
      let { name, value, checked, type } = e.target;
      if (type == "checkbox") {
        _.set(nextState, field, checked);
      } else {
        _.set(nextState, field, value);
      }
      this.setState({
        ...nextState,
        errors: {
          ...this.state.errors,
          [field]: "",
        },
      });
    }
    !this.state.form_edited && this.setState({ form_edited: true });
  }
  submitForm(e) {
    e.preventDefault();
    // Check if required set of fields are filled
    let err = this.validate();
    if (err) {
      toast.error("Please complete the form.");
      return;
    }
    let { form, doc } = this.state;
    let data = {
      id: doc.id,
      doc_type: doc.docs_type_id,
      form,
    };

    if (this.props.adminMode) {
      data.admin_mode = this.props.adminMode;
    }
    this.props.single_doc
      ? (data.single_doc = true)
      : (data.single_doc = false);
    this.props.rehire ? (data.rehire = true) : (data.rehire = false);
    this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        if (this.props.mobileMode && !this.props.lastPage) {
          this.props.setScreen("sidebar");
          this.navigate("next", this.props.rehire);
        } else if (this.props.lastPage) {
          if (this.props.adminMode) {
            this.props.history.push(`/docs`);
          } else {
            this.props.history.push(`/dashboard`);
          }
        } else {
          this.navigate("next", this.props.rehire);
        }
      } else {
        if (this.props.mobileMode) this.props.toggleErrorPopup(res.message);
        else toast.error(res.message);
      }
    });
  }
  handleSignature(field, employee_signature) {
    let nextState = this.state;
    _.set(nextState, field, employee_signature);
    this.setState({
      ...nextState,
      errors: {
        ...this.state.errors,
        [field]: "",
      },
    });
  }
  // GET FORM data from api with id passed in props
  getFormData(id) {
    this.props.dispatch(docsActions.getForm(id)).then((res) => {
      if (res.success) {
        const currentState = this.state;
        let { form, user } = res.data;
        // date needs to a moment object or Datepicker breaks.
        form.arbitration_agreement_employee_date =
          form.arbitration_agreement_employee_date ||
          currentState.form.arbitration_agreement_employee_date;

        if (!form?.arbitration_agreement_employee_name) {
          form.arbitration_agreement_employee_name = user?.full_name ?? "";
        }

        // spread the data coming from API into state
        this.setState({
          form,
        });
      }
    });
  }
  goBack() {
    if (this.state.form_edited) {
      this.props.toggleCloseConfirmation(true);
    } else {
      this.props.setScreen("sidebar");
    }
  }
  
  componentDidMount() {
    let docSlug = this.props.match.params.doc;

    const allowedCategory = this.props.rehire ? documentTypes.Rehire : documentTypes.Onboarding;

    const doc = this.props.docs.userDocs.find(
      (doc) => doc.slug === docSlug && doc.docs_categories_id === allowedCategory
    );

    const height = this.signContainer.clientHeight;
    const width = this.signContainer.clientWidth;
    this.setState({
      doc,
      sign: {
        height: height,
        width: width,
      },
    });
    this.getFormData(doc.id);
  }
  render() {
    let { form, errors } = this.state;
    let { adminMode } = this.props;
    return (
      <div className="doc_info-rh">
        {/* Title */}
        <div className="doc-title meal-title">
          <img src={logo} className="doc-title-img" />
          <span className="close-button" onClick={this.goBack}>
            <img src={`${config.assetUrl}icons/back-btn.svg`} />
          </span>
          <h3>Arbitration Agreement</h3>
        </div>
        <div className="doc_info-form">
          <form
            className="info-form mpn"
            ref={this.form}
            onChange={this.handleFormChange}
            onSubmit={this.submitForm}
            name="mpn"
            noValidate
          >
            <div className="row">
              <div className="col-md-12">
                <p>
                  I understand that by signing this document, I am entering into
                  a mutual arbitration agreement with Firestorm Wildland Fire
                  Suppression, Inc. (the “Agreement”).
                </p>
                <p>
                  In the event of any dispute, claim or controversy (referred to
                  as “claim” or “claims”) between me and Firestorm Wildland Fire
                  Suppression, Inc. (or any of Firestorm Wildland Fire
                  Suppression, Inc.’s successors, assigns, subsidiaries,
                  divisions and/or affiliates and/or any of its respective
                  officers, shareholders, partners, directors, members,
                  managers, employees or agents or any of its benefit plans or
                  the plans’ sponsors, fiduciaries, administrators, affiliates
                  and agents) (collectively referred to as the “Company”)
                  whether or not arising out of or relating to this Agreement or
                  my recruitment, application, hiring (or non-selection for
                  employment opportunities) or my employment (or termination of
                  employment) with the Company, both the Company and I agree to
                  submit such claim to final and binding arbitration. To avoid
                  doubt, this Agreement shall include all claims that may have
                  already arisen between me and the Company.
                </p>
                <p>
                  This Agreement is governed by the Federal Arbitration Act, 9
                  U.S.C. § 1 et seq. (“FAA”). To the extent that the FAA is
                  inapplicable, the arbitration law of California shall apply.
                  Disputes covered by this Agreement include, but are not
                  limited to, unpaid wages, meal and rest breaks, breach of
                  contract, torts, violation of public policy, discrimination,
                  harassment (except for sexual harassment) or any other
                  employment-related claims, regardless of whether such dispute
                  is initiated by me or the Company. However, claims for
                  Worker’s Compensation benefits, state disability insurance,
                  unemployment insurance, sexual harassment and sexual assault
                  (or any other claims where mandatory arbitration is prohibited
                  by law) are not covered by this Agreement and such claims may
                  be presented by me to the appropriate court or government
                  agency. This Agreement does not limit an employee from
                  bringing a sexual harassment or sexual assault dispute before
                  an arbitrator if the employee chooses to do so.
                </p>
                <p>
                  Nothing in this Agreement shall be deemed to preclude or
                  excuse a party from bringing an administrative claim before
                  any agency, but only to the extent that such administrative
                  claim is legally required to exhaust administrative remedies
                  before making a claim in arbitration.
                </p>
                <p>
                  Any dispute between the parties, as described above, may only
                  be maintained in the parties’ individual capacity, and not as
                  a plaintiff or class member in any purported class or
                  representative proceeding (“Class Action Waiver”). This Class
                  Action Waiver also means that the arbitrator in any
                  arbitration may not consolidate my claims with any other
                  employee’s claims and may not otherwise preside over any form
                  of a representative or class proceeding, subject to the terms
                  concerning Private Attorneys General Act (“PAGA”) actions
                  discussed below. With this Class Action Waiver, I understand
                  that the arbitrator will not have authority to determine
                  whether a class action can proceed in arbitration.
                </p>
                <p>
                  Representative claims under PAGA are not covered within the
                  scope of this Class Action Waiver and may be maintained in a
                  court of law. However, I understand that I may be required to
                  bring my individual PAGA claims in arbitration under this
                  Agreement. If I am required to arbitrate my individual claims,
                  I understand that the parties agree that the litigation of the
                  representative PAGA claims shall be stayed pending the outcome
                  of the arbitration of my individual claims. Regardless of
                  anything else in this Agreement, any contention that all or
                  part of this provision concerning PAGA actions are
                  unenforceable, void, or voidable may be determined only by a
                  court and not the arbitrator.
                </p>
                <p>
                  Except as otherwise provided in this Agreement, the
                  arbitration will be resolved in accordance with the
                  then-current American Arbitration Association (“AAA”) rules
                  governing employment disputes. The current rules can be found
                  at www.adr.org. I understand that the Company will provide me
                  a written copy of those rules upon my request. I understand
                  that each party shall have the right to conduct reasonable
                  discovery consistent with the AAA rules.
                </p>
                <p>
                  The location of the arbitration proceeding shall be in Butte
                  County, California. The arbitration shall be conducted in
                  private, and, to the extent permitted by applicable law, the
                  evidence presented and the arbitration award shall be and
                  remain confidential. Nothing herein shall preclude or limit
                  the right of either party to make a report to an appropriate
                  government agency or commission, to his or her immediate
                  family, attorney, or accountant. <br /><br /> The arbitrator may award any
                  party any remedy to which that party is entitled under
                  applicable law, including an award of attorneys’ fees and an
                  award of costs to the prevailing party under California Code
                  of Civil Procedure Section 1032, but such remedies shall be
                  limited to those that would be available to a party in a court
                  of law for the claims presented to and decided by the
                  arbitrator. The arbitrator shall render a written arbitration
                  award that contains the essential findings of fact and
                  conclusions of law on which the award is based. The award and
                  judgment shall be binding and final.
                </p>
                <p>
                  Each party shall pay for its own costs and attorneys’ fees, if
                  any, subject to an award by the arbitrator. The Company will
                  pay the arbitrator’s fees as well as any other necessary costs
                  related to the arbitration that I would not be required to
                  bear if I brought the same action in a court otherwise having
                  jurisdiction. I understand that filing fees and witness
                  expenses are required in both arbitration and court settings
                  and therefore the Company will not pay these costs for me,
                  except to the extent that such costs exceed the cost that
                  would be paid in the court having jurisdiction over the
                  matter. For example, if the initiation fee for arbitration is
                  $500 and the filing fee in Superior Court is $435, the Company
                  would pay $65 toward the fee.
                </p>
                <p>
                  In the event any portion of this Agreement is
                  deemed unenforceable, the remainder of this Agreement will be
                  enforceable. All other provisions shall remain in full force
                  and effect based on the parties’ mutual intent to create a
                  binding agreement to arbitrate their disputes.{" "}
                </p>
                <p>
                  This Agreement shall continue during my employment and shall
                  survive the termination of that employment, continuing in full
                  force and effect thereafter regarding any claims.
                </p>
                <p>
                  <strong>
                    I understand that by signing this Agreement, both the
                    Company and I give up our right to a civil court trial and
                    our right to a trial by jury. I further acknowledge that I
                    have been given the opportunity to discuss this Agreement
                    with my private legal counsel and have availed myself of
                    that opportunity to the extent I wish to do so.
                  </strong>
                </p>
              </div>
            </div>
            {/*row ends*/}
            <div
              className="row form-row no_border"
              style={{ paddingBottom: 0 }}
            >
              <div className="col" style={{ marginBottom: 0 }}>
                
                <div className="inputs-col">
                  <div className="inputs-head">Employee Signature</div>
                  <div className="inputs-items">
                    <div
                      ref={(element) => {
                        this.signContainer = element;
                      }}
                      className="sign_here"
                    >
                      {this.state.sign && (
                        <Fragment>
                          <SignPad
                            height={this.state.sign.height}
                            width={this.state.sign.width}
                            handleSignature={this.handleSignature.bind(
                              this,
                              "form.arbitration_agreement_employee_signature"
                            )}
                            signature={
                              form.arbitration_agreement_employee_signature
                            }
                            disabled={adminMode}
                          />
                          <input
                            type="hidden"
                            value={
                              form.arbitration_agreement_employee_signature
                            }
                            name="arbitration_agreement_employee_signature"
                            field="form.arbitration_agreement_employee_signature"
                          ></input>
                          {errors[
                            "form.arbitration_agreement_employee_signature"
                          ] && (
                            <label
                              htmlFor="arbitration_agreement_employee_signature"
                              className="error_label"
                            >
                              {
                                errors[
                                  "form.arbitration_agreement_employee_signature"
                                ]
                              }
                            </label>
                          )}
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-head">Date</div>
                  <div className="inputs-items">
                    <DatePicker
                      className={`${errors["form.arbitration_agreement_employee_date"] ? "error" : ""} w-100`}
                      name="arbitration_agreement_employee_date"
                      id="arbitration_agreement_employee_date"
                      selected={
                        form.arbitration_agreement_employee_date
                          ? moment(form.arbitration_agreement_employee_date)
                          : null
                      }
                      field="form.arbitration_agreement_employee_date"
                      placeholder="mm/dd/yyyy"
                      onChange={this.handleDateChange.bind(
                        this,
                        "form.arbitration_agreement_employee_date"
                      )}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                    />
                    {errors["form.arbitration_agreement_employee_date"] && (
                      <label
                        htmlFor="arbitration_agreement_employee_date"
                        className="error_label"
                      >
                        {errors["form.arbitration_agreement_employee_date"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className="row form-row no_border" style={{ paddingTop: 0 }}>
              <div className="col">
                <div className="inputs-col input-printed-name" style={{ marginLeft: "inherit"}}>
                  {/*single input */}
                  <div className="inputs-head">Employee’s Printed Name</div>
                  <div className="inputs-items">
                    <input
                      type="text"
                      className={`${errors["form.arbitration_agreement_employee_name"] ? "error" : ""}`}
                      name="arbitration_agreement_employee_name"
                      id="arbitration_agreement_employee_name"
                      value={form.arbitration_agreement_employee_name}
                      field="form.arbitration_agreement_employee_name"
                      placeholder="- Enter here -"
                    />
                    {errors["form.arbitration_agreement_employee_name"] && (
                      <label
                        htmlFor="arbitration_agreement_employee_name"
                        className="error_label"
                      >
                        {errors["form.arbitration_agreement_employee_name"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <FormNav
              adminMode={adminMode}
              navigate={this.navigate.bind(this)}
              disclaimer_check={this.state.form.arbitration_agreement_employee_date && this.state.form.arbitration_agreement_employee_signature && this.state.form.arbitration_agreement_employee_name}
              mobileMode={this.props.mobileMode}
            />
          </form>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  let { docs } = state;
  return { docs };
}
let connectedArbitrationAgreement = compose(
  withRouter,
  connect(mapStateToProps)
)(ArbitrationAgreement);
export { connectedArbitrationAgreement as ArbitrationAgreement };
