/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import moment from "moment";
import ReactPaginate from "react-paginate";
import Modal from "react-modal";
import DropdownBtn from "_components/utils/DropdownBtn";
import CreatableAsyncSelect from "_components/utils/CreatableAsyncSelect/CreatableAsyncSelect";
import { handleErrorResponse } from "_helpers/helpers";
import { redirectToPdf } from "_helpers/redirectToPdf";
import { formatDate } from "_helpers/helpers";
import { preventNegativeValueInDocs } from "_helpers/helpers";
import {
  resourceActions,
  manifestActions,
  permissionActions,
  assignmentStatsActions,
  userActions,
  inventoryActions,
  dashboardActions,
} from "../_actions";
import "../assets/styles/resource.css";
// import { Resources } from './Resources';
import { authHeader, config } from "../_helpers";
import { manifestService, resourceService } from "_services";
import { Document, Page } from "react-pdf";
import ReactModal from "react-modal";
import { AlertPopup } from "../_components/AlertPopup";
import Accordian from "_components/Accordian";
import EmployeeList from "Employees/EmployeeList";
import "../assets/styles/your-crew.css";
import AllManifest from "_components/AllManifest";
import RadioButton from "Docs/Components/RadioButton";
import FireHistoryEmployeeAssignDragAndDrop from "Resources/FireHistoryEmployeeAssignDragAndDrop";
import { getCurrentDatePST } from "_helpers/helpers";
import AsyncSelect from "react-select/async";
import CustomToolTip from "_components/CustomToolTip";

// a little function to help us with reordering the result
ReactModal.setAppElement("#app");

const unDefined = "undefined";

const currentDate = getCurrentDatePST();

const MENU_WIDTH = 300;

const selectStyles = {
  control: (css) => ({
    ...css,
    width: MENU_WIDTH || "auto",
    opacity: MENU_WIDTH ? 1 : 0,
    zIndex: 9999,
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  menu: (provided) => ({
    ...provided,
    opacity: MENU_WIDTH ? 1 : 0,
    zIndex: 9999,
  }),
  option: (css) => ({ ...css, paddingRight: 36 + 8, zIndex: 9999 }),
};

const FIRE_HISTORY_TYPE_SLUG = {
  WITH_MANIFEST: "with_manifest",
  AUTO_ASSIGN_MANIFEST: "auto_assign",
  MANUAL_ASSIGN_MANIFEST: "manual_assign",
};

const assignmentStatuses = [
  { label: "Assignment Status", value: "" },
  { label: "Committed", value: "Committed" },
  { label: "Available", value: "Available" },
  { label: "Unavailable", value: "Unavailable" },
];

const RESOURCE_ORDER_FILE_TYPE = {
  CREATE_FORM: 1,
  UPLOADED_FORM: 2,
};
class ResourceDetails extends React.Component {
  constructor(props) {
    super(props);
    this.sortYourCrewRef = React.createRef();
    this.state = {
      typeSlug: FIRE_HISTORY_TYPE_SLUG?.AUTO_ASSIGN_MANIFEST,
      editMode: false,
      keyword: "",
      id: this.props.props,
      mailFireCardsList: [],
      dispatchMailList: [],
      mailListDefault: [],
      mailList: [],
      searchList: [],
      emailOpen: false,
      emailFireCardsOpen: false,
      viewOpen: false,
      statusOpen: false,
      deleteOpen: false,
      textarea: true,
      search: false,
      linkClick: false,
      mid: "",
      action: "hide",
      action_id: "",
      viewStatus: false,
      hideSearch: "hide",
      numPages: null,
      pageNumber: 1,
      to: "",
      employeeList: [],
      flag: true,
      resultData: [],
      alertPop: false,
      resource_name: "",
      inputValue: false,
      emailSuccess: "",
      manifestPageNum: 1,
      assignmentStatusOptions: ["Available", "Unavailable", "Committed"],
      currentAssignmentStatus: "Available", //varible to save current assignment status
      tempAssignmentStatus: "", //varible to save assignment status when the user change in the popup , for displaying the form fields based on the selection
      currentProjectDetails: [], //array to store all details about current project
      unavailable_reason_id: "",
      unavailable_reasons: [],
      onDate: moment(currentDate),
      offDate: "",
      unavailable_start_date: "",
      unavailable_end_date: "",
      preventAssignmentPopupClick: false,
      formSubmission: false,
      currentAssignmentDetails: {
        name: "",
        date_off: "",
        date_on: "",
        assignment_type_id: "",
        assignment_type: "",
        shifts: "",
        notes: "",
        id: 0,
      },
      errorMessage: "",
      deleteHistoryOpen: false,
      historyId: "",
      assigmentCategoryOptions: [],
      assigmentStatusHistory: { data: [] },
      createdROData: [],
      uploadROData: [],
      incidentProjects: [],
      projectId: null,
      projectName: "",
      projectNote: "",
      projectShift: "",
      tempAssignmentType: "",
      assignmentHistoryId: "",
      validManifestMail: false,
      manifestEmail: "",
      inventoryPage: 1,
      inventoryLastPage: 0,
      inventoryPerPage: 1,
      inventoryList: [],
      location: "",
      complexity: "",
      fuel_type: "",
      fire_size: "",
      hotline_shifts: "",
      deleteROModal: false,
      roCreatedPage: 0,
      roCreatedLastPage: 0,
      roUploadPage: 0,
      roUploadLastPage: 0,
      isCrewCommitted: false,
      printOption: "",
      selectedEmployees: [],
      confirmEditAssignment: false,
      displayEditAssignmentConfirmPopup: false,
      selectedResource: null,
      copySelectedResource: null,
      editROModal: false,
      selectedUploadedROFile: null,
      latestAssignmentHistory: null,
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.statusOpenModal = this.statusOpenModal.bind(this);
    this.statusCloseModal = this.statusCloseModal.bind(this);
    this.deleteOpenModal = this.deleteOpenModal.bind(this);
    this.deleteCloseModal = this.deleteCloseModal.bind(this);
    this.renderTotalShifts = this.renderTotalShifts.bind(this);
    this.handlePrintOption = this.handlePrintOption.bind(this);
    this.handleCancelEmailClick = this.handleCancelEmailClick.bind(this);
    this.onClickRemoveEmployeeFromMailList =
      this.onClickRemoveEmployeeFromMailList.bind(this);
    this.handleSelectEmployees = this.handleSelectEmployees.bind(this);
    this.handleOnWheel = this.handleOnWheel.bind(this);
    this.deleteHistoryCloseModal = this.deleteHistoryCloseModal.bind(this);

    this.emailOpenModal = this.emailOpenModal.bind(this);
    this.emailCloseModal = this.emailCloseModal.bind(this);
    this.emailFireCardsOpenModal = this.emailFireCardsOpenModal.bind(this);
    this.emailFireCardsCloseModal = this.emailFireCardsCloseModal.bind(this);
    this.viewOpenModal = this.viewOpenModal.bind(this);
    this.viewCloseModal = this.viewCloseModal.bind(this);
    this.keyUpFn = this.keyUpFn.bind(this);
    this.goBack = this.goBack.bind(this);
    this.remove = this.remove.bind(this);
    this.profile = this.profile.bind(this);
    this.editResource = this.editResource.bind(this);
    this.createManifest = this.createManifest.bind(this);
    this.printFireCards = this.printFireCards.bind(this);
    this.emailFireCards = this.emailFireCards.bind(this);
    this.editManifest = this.editManifest.bind(this);
    this.deleteManifest = this.deleteManifest.bind(this);
    this.printManifest = this.printManifest.bind(this);
    this.emailManifest = this.emailManifest.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.setMailer = this.setMailer.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.emailChangeFn = this.emailChangeFn.bind(this);
    this.removeList = this.removeList.bind(this);
    this.viewManifest = this.viewManifest.bind(this);
    this.viewAction = this.viewAction.bind(this);
    // this.onSortEnd = this.onSortEnd.bind(this);
    this.onImageError = this.onImageError.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.editResourceHistory = this.editResourceHistory.bind(this);
    this.selectProject = this.selectProject.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.addManifestEmail = this.addManifestEmail.bind(this);
    this.isValidEmail = this.isValidEmail.bind(this);
    this.inventoryPagination = this.inventoryPagination.bind(this);
    this.getInventoryList = this.getInventoryList.bind(this);
    this.getUnavailableReasons = this.getUnavailableReasons.bind(this);
    this.employeeListUpdated = this.employeeListUpdated.bind(this);
    this.handleChooseResources = this.handleChooseResources.bind(this);
  }

  handlePrintOption = (selectedOption) => {
    this.setState({ printOption: selectedOption });
  };

  handleCancelEmailClick = () => {
    this.setState({
      selectedEmployees: [],
      emailFireCardsOpen: false,
      printOption: "",
    });
  };

  onClickRemoveEmployeeFromMailList = (data) => {
    this.setState((prevState) => ({
      selectedEmployees: prevState.selectedEmployees.filter(
        (item) => item.label !== data.label
      ),
    }));
  };

  handleSelectEmployees = (selected) => {
    // Filter out selected values that are already in the selectedEmployees array
    const uniqueSelected = selected.filter(
      (item) => !this.state.selectedEmployees.includes(item)
    );

    // Update selectedEmployees array by concatenating the unique selected values with the previous values
    this.setState((prevState) => ({
      selectedEmployees: prevState.selectedEmployees.concat(uniqueSelected),
    }));
  };

  statusOpenModal() {
    this.setState({ statusOpen: true, statusStep: "1" });
  }

  statusCloseModal() {
    this.setState({ statusOpen: false });
  }

  deleteOpenModal(id) {
    this.setState({ deleteOpen: true, mid: id });
  }

  deleteCloseModal() {
    this.setState({ deleteOpen: false });
  }

  deleteHistoryOpenModal = (id) => {
    this.setState({ deleteHistoryOpen: true, historyId: id });
  };

  handleEditRO = async () => {
    this.setState({
      formSubmission: true,
    });
    const data = new FormData();
    this.state.selectedResource.forEach((resource) => {
      data.append("resource_ids[]", resource.value);
    });

    this.props
      .dispatch(
        resourceActions.updateROResources(
          this.state.selectedUploadedROFile?.id,
          data
        )
      )
      .then((response) => {
        if (response.success) {
          this.getUploadRODetails();
          toast.success(
            response.message || "Resource order updated successfully."
          );
          this.setState({
            editROModal: false,
            selectedResource: null,
          });
        } else {
          this.setState({
            selectedResource: this.state.copySelectedResource,
          });
          toast.error(
            "Failed to update RO - " + response.message ||
              "An error occurred while updating the resource order."
          );
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
    this.setState({
      formSubmission: false,
    });
  };

  deleteHistoryCloseModal() {
    this.setState({ deleteHistoryOpen: false, historyId: "" });
  }

  handleEditRof(rof_id) {
    let formData = new FormData();
    formData.append("rof_id", rof_id);
    manifestService?.getResourceOrderContent(formData).then(({ data }) => {
      if (data) {
        this.props.editRof(data?.[0]);
      }
    });
  }

  handleOnWheel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.blur();
  };

  renderTotalShifts = (onDate, offDate) => {
    if (offDate && onDate) {
      const diffInDays =
        offDate.startOf("day").diff(onDate.startOf("day"), "days") + 1;
      // const diffInDays = offDate.diff(onDate, 'days') + 1;
      this.setState({ projectShift: diffInDays });
      return;
    }
    this.setState({ projectShift: "" });
    return;
  };

  handleDeleteRof = ({ id, type }) => {
    let formData = new FormData();
    formData.append("rof_id", id);
    formData.append("rof_type", type);
    manifestService
      ?.deleteResourceOrderPermanently(formData)
      .then(({ success, message }) => {
        this.setState({ deleteROModal: false });
        if (success) {
          toast.success(message);
          if (type === 1) {
            this.getCreatedRODetails(this.state.page + 1);
          } else {
            this.getUploadRODetails(this.state.page + 1);
          }
          this.fetchData();
        } else {
          toast.error(message);
        }
      })
      .catch(() => {});
  };

  handleROCreatedPageChange = (newPage) => {
    this.getCreatedRODetails(newPage?.selected + 1);
    this.setState({ roCreatedPage: newPage?.selected });
  };

  handleROUploadPageChange = (newPage) => {
    this.getUploadRODetails(newPage?.selected + 1);
    this.setState({ roUploadPage: newPage?.selected });
  };

  onImageError(e) {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
  }

  isValidEmail(email) {
    let re = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    return re.test(email);
  }

  viewOpenModal(id) {
    this.setState({ viewOpen: true, mid: id });
  }

  viewCloseModal() {
    this.setState({ viewOpen: false });
  }

  emailOpenModal() {
    this.setState({
      emailOpen: true,
      mailListDefault: this.state.dispatchMailList,
    });
  }

  emailFireCardsOpenModal() {
    this.setState({
      emailFireCardsOpen: true,
    });
  }

  closeAlert() {
    this.setState({
      alertPop: false,
    });
  }

  onDocumentLoad(numPages) {
    this.setState({ numPages });
  }

  emailCloseModal() {
    this.setState({ emailOpen: false });
    this.setState({ mailList: [], textarea: true, to: "" });
    document.getElementById("txtSearchManiEmployees").value = "";
    // document.getElementById('multiple_emails').value = "";
  }

  emailFireCardsCloseModal() {
    this.setState({ emailFireCardsOpen: false });
    this.setState({
      mailFireCardsList: [],
      textarea: true,
      to: "",
      selectedEmployees: [],
    });
  }
  viewAction(id) {
    this.setState({
      action_id: id,
      action: "show",
      viewStatus: !this.state.viewStatus,
    });
  }

  inventoryPagination() {
    if (this.state.inventoryList && this.state.inventoryList.length > 0) {
      let pages = [];
      // let totalPages = Math.ceil(x / y);
      let currentPage = this.state.inventoryPage;
      let startingIndex = currentPage - 2 > 0 ? currentPage - 2 : 1;
      let lastPageIndex =
        currentPage + 2 < this.state.inventoryLastPage
          ? currentPage + 2
          : this.state.inventoryLastPage;
      let nextPage =
        currentPage + 1 > this.state.inventoryLastPage
          ? this.state.inventoryLastPage
          : currentPage + 1;
      let prevPage = currentPage - 1 > 0 ? currentPage - 1 : 1;

      if (currentPage > 3) {
        pages.push(
          <li
            className="inactive"
            key={0}
            onClick={() => {
              this.getInventoryList(1);
            }}
          >
            <a>1</a>
          </li>
        );
      }
      if (currentPage > 4) {
        pages.push(
          <li className="inactive" key={1}>
            <a>. . .</a>
          </li>
        );
      }
      for (let i = startingIndex; i <= lastPageIndex; i++) {
        // getManifestList
        pages.push(
          <li
            className={i == currentPage ? "active" : "inactive"}
            key={i}
            onClick={() => {
              this.getInventoryList(i);
            }}
          >
            <a>{i}</a>
          </li>
        );
      }
      if (lastPageIndex + 1 < this.state.inventoryLastPage) {
        pages.push(
          <li className="inactive" key={this.state.inventoryLastPage + 1}>
            <a>. . .</a>
          </li>
        );
      }
      if (lastPageIndex < this.state.inventoryLastPage) {
        pages.push(
          <li
            className="inactive"
            key={this.state.inventoryLastPage}
            onClick={() => {
              this.getInventoryList(this.state.inventoryLastPage);
            }}
          >
            <a>{this.state.inventoryLastPage}</a>
          </li>
        );
      }

      if (pages && pages.length > 0) {
        return (
          <div
            className="fs-pagination-wrapper-outer custome_old_pagination"
            key={7}
          >
            <div className="fs-pagination-wrapper">
              <span
                className={`prev ${
                  prevPage === 1 && currentPage === 1 ? "disabled" : ""
                }`}
                onClick={() => {
                  this.getInventoryList(prevPage);
                }}
              >
                <img
                  alt="previous"
                  src={require("../assets/icons/next_arrow.svg").default}
                />
              </span>
              <ul className="fs-pagination">{pages}</ul>
              <span
                className={`next ${
                  nextPage == this.state.assigmentStatusHistory.data.last_page
                    ? "disabled"
                    : ""
                }`}
                onClick={() => {
                  this.getInventoryList(nextPage);
                }}
              >
                <img
                  alt="previous"
                  src={require("../assets/icons/next_arrow.svg").default}
                />
              </span>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="applicant_item" key="noEmployees">
          <p>No Inventory Assigned!</p>
        </div>
      );
    }
  }

  editResourceHistory(item) {
    let assignment = this.state.assigmentStatusHistory.data.find(
      (i) => i.id === item.id
    );
    this.setState({
      unavailable_end_date: null,
      offDate: null,
    });

    if (assignment) {
      let { id, status, incident_projects, notes, resource_unavailability } =
        assignment;
      if (status == 3) {
        this.setState({
          editMode: true,
          preventAssignmentPopupClick: false,
          assignmentHistoryId: id,
          tempAssignmentStatus: this.state.assignmentStatusOptions[status - 1],
          tempAssignmentType: incident_projects.assignment_type_id,
          initialStatusId: status,
          projectId: incident_projects.id,
          projectName: incident_projects.name || "",
          projectShift: incident_projects.shifts || "",
          onDate: moment(incident_projects.date_on),
          offDate: incident_projects.date_off
            ? moment(incident_projects.date_off)
            : null,
          projectNote: notes || "",
          location: incident_projects.location || "",
          complexity: incident_projects.complexity || "",
          fuel_type: incident_projects.fuel_type || "",
          fire_size: incident_projects.fire_size || "",
          hotline_shifts: incident_projects.hotline_shifts || "",
        });
      } else if (status == 2) {
        this.setState({
          editMode: true,
          preventAssignmentPopupClick: false,
          assignmentHistoryId: id,
          tempAssignmentStatus: this.state.assignmentStatusOptions[status - 1],
          unavailable_reason_id: resource_unavailability.unavailable_reason_id,
          unavailable_start_date: moment(resource_unavailability.start_date),
          unavailable_end_date: resource_unavailability.end_date
            ? moment(resource_unavailability.end_date)
            : "",
          initialStatusId: status,
          projectNote: notes || "",
        });
      }

      document.body.className += " " + "inv-sidebar-opened";
    }
  }

  getInventoryList(page) {
    let { inventoryLastPage } = this.state;
    if (page && page >= 1 && page <= inventoryLastPage) {
      this.props
        .dispatch(
          inventoryActions.getPaginatedList({ id: this.state.id, page })
        )
        .then((res) => {
          this.setState({
            inventoryPage: res.current_page,
            inventoryLastPage: res.last_page,
            inventoryPerPage: res.per_page,
          });
        });
    }
  }

  selectProject(e) {
    let id = e.target.value;
    this.setState({
      projectId: id || null,
    });
  }

  handleSelect(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  addManifestEmail() {
    if (this.state.validManifestMail) {
      this.setState({
        mailSearchKeyword: "",
        hideSearch: "hide",
        mailList: [
          ...this.state.mailList,
          {
            first_name: "-",
            last_name: "-",
            email: this.state.manifestEmail,
          },
        ],
      });
    }
  }

  // get unavailable reasons
  getUnavailableReasons() {
    const _requestOptions = {
      method: "GET",
      headers: authHeader(),
    };
    const _url = `${config.apiUrl}/api/getlist/unavailablereason`;
    fetch(_url, _requestOptions).then((response) =>
      response.json().then(
        (res) => {
          //
          this.setState({ unavailable_reasons: res });
        },
        (err) => {}
      )
    );
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    var body = document.body;
    body.classList.remove("inv-sidebar-opened");
  }

  fetchData() {
    let data = new FormData();
    this.getUnavailableReasons();
    this.getCreatedRODetails();
    this.getUploadRODetails();
    this.props.dispatch(manifestActions.dispatchMailList());
    this.props.dispatch(resourceActions.getResourceDetails(this.props.props));
    this.props.dispatch(resourceActions.getProjects());
    this.props.dispatch(assignmentStatsActions.getAssignemntStatusTypes());
    this.props.dispatch(
      assignmentStatsActions.getAssignemntStatus(this.state.id)
    );
    this.props.dispatch(
      assignmentStatsActions.getAssignemntHistory(this.state.id, 1)
    );

    data.append("crew_identifier_id", this.state.id);
    this.props.dispatch(resourceActions.getResourceVehicles(this.state.id));

    data.append("active", true);
    data.append("locked", true);
    data.append("guest", true);
    data.append("pagination", false);

    this.props.dispatch(resourceActions.employeeList(data));
    // this.props.dispatch(resourceActions.employeeList(data));

    this.props.dispatch(permissionActions.getPermission());
    this.props.dispatch(userActions.getAll());

    let data2 = new FormData();
    data2.append("resource_id", this.state.id);
    this.getManifestList();
    // this.props.dispatch(manifestActions.manifestList(data2, manifestPageNum));
    this.props.dispatch(manifestActions.getVehicles(data2));
    this.props
      .dispatch(
        inventoryActions.getPaginatedList({ id: this.state.id, page: 1 })
      )
      .then((res) => {
        this.setState({
          inventoryPage: res.current_page,
          inventoryLastPage: res.last_page,
          inventoryPerPage: res.per_page,
        });
      });

    this.props.dispatch(assignmentStatsActions.getAssignmentComplexity());
    this.props.dispatch(assignmentStatsActions.getAssignmentFireSize());
    this.props.dispatch(assignmentStatsActions.getAssignmentFuelType());
  }
  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { printOption } = this.state;
    if (printOption !== prevState.printOption) {
      if (printOption === "pdf") {
        this.printFireCards();
        setTimeout(() => {
          this.setState({ printOption: null });
        }, 1000);
      } else if (printOption === "email") {
        this.setState({ emailFireCardsOpen: true });
      }
    }
  }

  // Get Created RO List Details
  getCreatedRODetails(pageNumber) {
    const formData = new FormData();
    formData.append("page", pageNumber || 0);
    formData.append("id", this.state.id);
    this.props
      .dispatch(dashboardActions.getCreatedResourceOrderForm(formData))
      .then(({ data: { resource_order_forms } }) => {
        this.setState({
          createdROData: resource_order_forms,
          roCreatedLastPage: resource_order_forms.last_page,
        });
      });
  }

  // Get Resource Order List Details
  getUploadRODetails(pageNumber) {
    const formData = new FormData();
    formData.append("page", pageNumber || 0);
    formData.append("id", this.state.id);
    this.props
      .dispatch(dashboardActions.getResourceOrderForms(formData))
      .then(({ data: { resource_order_form_files } }) => {
        this.setState({
          uploadROData: resource_order_form_files,
          roUploadLastPage: resource_order_form_files.last_page,
        });
      });
  }
  // View created RO
  viewCreatedRO = (rofId) => {
    let data = new FormData();
    data.append("rof_id", rofId);
    manifestService
      ?.viewResourceOrderForm(data)
      .then((response) => this.redirectToPdf(response));
  };

  redirectToPdf = (data) => {
    if (data?.success) {
      const pdfLink = data.data;
      var link = document.createElement("a");
      if (
        window.navigator.userAgent.match(/iPad/i) ||
        window.navigator.userAgent.match(/iPhone/i)
      ) {
        link.href = pdfLink;
      } else {
        link.href = pdfLink;
        link.target = "_blank";
      }
      document.body.appendChild(link);
      link.click();
      setTimeout(function () {
        document.body.removeChild(link);
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(pdfLink);
      }, 100);
    }
  };

  // call after doing some actions in EmployeeList
  employeeListUpdated() {
    let data = new FormData();
    data.append("crew_identifier_id", this.state.id);
    data.append("active", true);
    data.append("locked", true);
    data.append("guest", true);
    data.append("pagination", false);
    this.props.dispatch(resourceActions.employeeList(data));
  }

  getManifestList = (manifestPageNum = 1) => {
    let data2 = new FormData();
    data2.append("resource_id", this.state.id);
    this.props.dispatch(manifestActions.manifestList(data2, manifestPageNum));
  };

  createManifest() {
    this.props.createManifest();
  }

  printFireCards() {
    const { id, printOption, selectedEmployees } = this.state;
    let data = new FormData();
    data.append("crew_id", id);
    data.append("type", "Crew");
    data.append("print", printOption);

    if (printOption == "email") {
      selectedEmployees?.forEach((employee, index) => {
        data.append(`email[${index}]`, employee.email);
      });
    }
    resourceService
      .printFireCards(data)
      .then((res) => {
        if (printOption == "pdf") {
          redirectToPdf(res);
        }
        if (res.success) {
          toast.success(res.message);
          this.setState({
            selectedEmployees: [],
            emailFireCardsOpen: false,
            printOption: "",
          });
        } else {
          handleErrorResponse(res);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  editManifest(id, type) {
    this.props.editManifest(id, type);
  }

  deleteManifest = async () => {
    let data = new FormData();
    data.append("manifest_id", this.state.mid);
    let data2 = new FormData();
    data2.append("resource_id", this.state.id);
    await this.props.dispatch(manifestActions.deleteManifest(data, data2));
    this.fetchData();
    this.setState({ deleteOpen: false });
  };

  printFireCards(id) {
    var data =
      "?manifest_id=" +
      this.state.action_id +
      "&crew_id=" +
      this.props.props +
      "&print=pdf" +
      "&type=Crew";
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
    };
    var self = this;
    fetch(config.apiUrl + "/api/getFireCards" + data, requestOptions)
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        if (data.success) {
          const pdfLink = data.data;
          var link = document.createElement("a");
          if (
            window.navigator.userAgent.match(/iPad/i) ||
            window.navigator.userAgent.match(/iPhone/i)
          ) {
            link.href = pdfLink;
          } else {
            link.href = pdfLink;
            link.target = "_blank";
          }
          document.body.appendChild(link);
          link.click();
          setTimeout(function () {
            document.body.removeChild(link);
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(pdfLink);
          }, 100);
        } else {
          self.setState({
            alertPop: true,
            alertHead: "Error",
            alertMsg: data.message,
            alertFunction: "",
          });
        }
      });
  }

  emailFireCards() {
    let data = new FormData();
    data.append("manifest_id", this.state.action_id);
    data.append("crew_id", this.props.props);
    data.append("print", "email");
    data.append("type", "Crew");

    this.state.selectedEmployees?.forEach((employee, index) => {
      data.append(`email[${index}]`, employee.email);
    });
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: data,
    };

    resourceService
      .printFireCards(data)
      .then((res) => {
        if (res.success) {
          toast.success(res.message);
          this.setState({
            selectedEmployees: [],
            emailFireCardsOpen: false,
            printOption: "",
            action_id: "",
          });
        } else {
          handleErrorResponse(res);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  printManifest(id) {
    var data =
      "?manifest_id=" +
      this.state.action_id +
      "&resource_id=" +
      this.props.props;
    // this.props.dispatch(manifestActions.printManifest(data));
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
    };
    var self = this;
    fetch(config.apiUrl + "/api/manifest/pdf" + data, requestOptions)
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        if (data.success) {
          const pdfLink = data.data;
          var link = document.createElement("a");
          if (
            window.navigator.userAgent.match(/iPad/i) ||
            window.navigator.userAgent.match(/iPhone/i)
          ) {
            link.href = pdfLink;
          } else {
            link.href = pdfLink;
            link.target = "_blank";
          }
          document.body.appendChild(link);
          link.click();
          setTimeout(function () {
            document.body.removeChild(link);
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(pdfLink);
          }, 100);
        } else {
          self.setState({
            alertPop: true,
            alertHead: "Error",
            alertMsg: data.message,
            alertFunction: "",
          });
        }
      });
  }

  emailManifest() {
    let data = new FormData();
    data.append("manifest_id", this.state.action_id);
    data.append("resource_id", this.props.props);
    //data.append("to", this.state.to);

    let toList = this.state.mailListDefault;

    if (this.state.mailList && this.state.mailList.length > 0) {
      toList = [...toList, ...this.state.mailList.map((item) => item.email)];
    }

    data.append("to", toList.join());

    this.props.dispatch(manifestActions.emailManifest(data));
    this.props.details(this.props.props);
    this.setState({ emailOpen: false, emailSuccess: "true" });

    this.setState({ mailList: [], textarea: true, to: "" });
    document.getElementById("txtSearchManiEmployees").value = "";
    // document.getElementById('multiple_emails').value = "";
  }

  handleScroll(event) {
    var wrap = document.getElementById("right_sidebar"),
      contentHeight = wrap.offsetHeight,
      yOffset = window.pageYOffset,
      y = Math.ceil(yOffset + window.innerHeight),
      applicationItem = [],
      sortVariable = "";

    if (this.state.sort !== "" && this.state.sort) {
      sortVariable =
        "&sort=" + this.state.sort + "&order=" + this.state.orderAll
          ? this.state.orderAll
          : "asc";
    }

    if (y >= contentHeight) {
      let data = this.state.resultData;

      // const { permission } = this.props;
      // var permList = []
      // if(permission.permissions) {
      //     permList = permission.permissions.data
      // }

      let reqData = new FormData();
      reqData.append("crew_identifier_id", this.state.id);
      if (data.current_page < data.last_page && this.state.flag !== false) {
        const requestOptions = {
          method: "POST",
          headers: authHeader(),
          body: reqData,
        };

        fetch(data.next_page_url + sortVariable, requestOptions)
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            this.setState({ resultData: data.data });

            if (data.data.current_page === data.data.last_page) {
              this.setState({ flag: false });
            }

            var appInf = [];

            this.setState({
              employeeList: [...this.state.employeeList, ...data.data.data],
            });

            data.data.data.forEach((item, index) => {
              appInf[item.hash] = item.applicant_info;
              this.setState((state) => ({
                applicant_info: { ...state.applicant_info, ...appInf },
              }));

              applicationItem.push(
                <div className="employee_item">
                  <div className="list_item image-holder second_width">
                    <img
                      alt={item.first_name + " " + item.last_name}
                      className={item.active}
                      src={
                        item.profile_photo
                          ? config.apiUrl + "/" + item.profile_photo
                          : config.apiUrl + "/" + config.altImg
                      }
                      onError={this.onImageError.bind(this)}
                    />
                  </div>
                  <div className="list_item second_width second_width">
                    <span className={item.colorcode + " color_code"} />
                    <span className="emp_name">
                      {item.first_name + " " + item.last_name}
                    </span>
                  </div>
                  <div className="list_item second_width">{item.email}</div>
                  <div
                    className="list_item sendMail_btn second_width"
                    onClick={this.sendMail.bind(this, item.email)}
                  >
                    Send Mail
                  </div>
                  <div
                    className="list_item close_mail second_width"
                    onClick={this.removeList.bind(this, item.email)}
                  >
                    <img
                      alt="close"
                      src={require("../assets/images/close_button.png").default}
                      onError={this.onImageError.bind(this)}
                    />
                  </div>
                </div>
              );
            });

            this.setState({
              applicationItem: [
                ...this.state.applicationItem,
                ...applicationItem,
              ],
            });
          })
          .catch((err) => {});
      }
    }
  }
  /**
   * function to close slider to edit the assignment status
   */
  closeStatusPopup = (doNotReset) => {
    document.body.classList.remove("inv-sidebar-opened");

    if (!doNotReset) {
      this.setState({
        location: "",
        complexity: "",
        fuel_type: "",
        fire_size: "",
        hotline_shifts: "",
      });
    }
  };

  editAssignmentConfirmation = (e) => {
    this.setState(
      {
        displayEditAssignmentConfirmPopup: false,
        confirmEditAssignment: true,
      },
      () => {
        this.saveAssignmentChanges(e);
      }
    );
  };

  closeEditAssignmentConfirmPopup = () => {
    this.setState({
      displayEditAssignmentConfirmPopup: false,
    });
  };

  // Submit Assignment Changes
  saveAssignmentChanges = (event) => {
    event?.preventDefault();
    if (this.state.editMode && !this.state.confirmEditAssignment) {
      return this.setState({
        displayEditAssignmentConfirmPopup: true,
      });
    }
    if (!this.state.tempAssignmentStatus) {
      return toast.error("Please select an assignment status.");
    }
    if (!this.state.editMode) {
      if (
        this.state.currentAssignmentStatus == "Committed" &&
        !this.state.hotline_shifts
      ) {
        return toast.error("Hotline Shifts is a required field.");
      }
      if (
        this.state.currentAssignmentStatus == "Unavailable" &&
        !this.state.unavailable_end_date
      ) {
        return toast.error("Unavailable end date is a required field.");
      }
    }

    this.setState({
      preventAssignmentPopupClick: true,
    });

    let index = this.state.assignmentStatusOptions.findIndex(
      (x) => x == this.state.tempAssignmentStatus
    );

    let data = new FormData();
    data.append("status", index + 1);
    data.append("resource_identifier_id", this.state.id);
    data.append("location", this.state.location);
    data.append("complexity", this.state.complexity);
    data.append("fuel_type", this.state.fuel_type);
    data.append("fire_size", this.state.fire_size);
    if (this.state.hotline_shifts) {
      data.append("hotline_shifts", this.state.hotline_shifts);
    }

    if (this.state.offDate) {
      data.append("date_off", moment(this.state.offDate).format("YYYY-MM-DD"));
    }

    if (this.state.unavailable_start_date) {
      data.append(
        "unavailable_start_date",
        moment(this.state.unavailable_start_date).format("YYYY-MM-DD")
      );
    }

    if (this.state.unavailable_end_date) {
      data.append(
        "unavailable_end_date",
        moment(this.state.unavailable_end_date).format("YYYY-MM-DD")
      );
    }

    if (this.state.editMode == true) {
      data.append("assignment_history_id", this.state.assignmentHistoryId);
    }

    if (this.state.tempAssignmentStatus == "Committed") {
      if (!this.refs.AssignmentType.value) {
        this.setState({
          preventAssignmentPopupClick: false,
        });
        return toast.error("Assigment Type is a required field");
      }
      if (!this.state.editMode && !this.state?.fireHistoryManifestId) {
        this.setState({
          preventAssignmentPopupClick: false,
        });
        return toast.error("Manifest is a required field");
      }
      data.append("assignment_type", this.refs.AssignmentType.value);

      if (this.state.projectId) {
        // data.append("incident_projects_id", this.state.projectId);
      }

      if (this.state.editMode == true) {
        if (this.refs.ProjectName) {
          data.append("name", this.refs.ProjectName.value);
          data.append(
            "date_on",
            moment(this.state.onDate).format("YYYY-MM-DD")
          );

          if (this.refs.totalShifts?.value) {
            data.append("shifts", this.refs.totalShifts.value);
          }
        }
        data.append("notes", this.refs.note.value);
      } else if (this.state.editMode == false && this.state.projectId) {
        // data.append("incident_projects_id", this.state.projectId);
      } else if (this.state.editMode == false) {
        data.append("name", this.refs.ProjectName.value);
        data.append("assignment_type", this.refs.AssignmentType.value);
        data.append("date_on", moment(this.state.onDate).format("YYYY-MM-DD"));
        if (this.state.offDate) {
          data.append(
            "date_off",
            moment(this.state.offDate).format("YYYY-MM-DD")
          );
        }
        if (this.refs.totalShifts?.value) {
          data.append("shifts", this.refs.totalShifts.value);
        }
        data.append("notes", this.refs.note.value);
      }
    }

    this.state.tempAssignmentStatus === "Unavailable" &&
      data.append("unavailable_reason_id", this.state.unavailable_reason_id);

    if (this.state.tempAssignmentStatus !== "Committed") {
      data.append("notes", this.refs.note.value);
    }

    // Fire history
    if (this.state.tempAssignmentStatus == "Committed") {
      data.append("type_slug", this.state?.typeSlug);
      data.append("manifest_id", this.state?.fireHistoryManifestId);
      // } else if (
      //   this.state?.typeSlug === FIRE_HISTORY_TYPE_SLUG?.AUTO_ASSIGN_MANIFEST
      // ) {
      //   data.append("type_slug", this.state?.typeSlug);
      // } else if (
      //   this.state?.typeSlug ===
      //     FIRE_HISTORY_TYPE_SLUG?.MANUAL_ASSIGN_MANIFEST &&
      //   this?.state?.manualAssignManifestIds?.length
      // ) {
      //   data.append("type_slug", this.state?.typeSlug);
      //   data.append(
      //     "employees_fire_report",
      //     JSON.stringify(this.state?.manualAssignManifestIds)
      //   );
      // } else {
      //   this.closeStatusPopup(true);
      //   return this.setState({
      //     enableAssignEmployeeModal: true,
      //     typeSlug: FIRE_HISTORY_TYPE_SLUG?.AUTO_ASSIGN_MANIFEST,
      //   });
      // }
    }

    this.handleSaveStatus(data);
    if (this.refs.note) this.refs.note.value = "";
    // this.closeStatusPopup();
  };

  showEditRoPopup = (item) => {
    const selectedResource = item?.resources.map((resource) => ({
      value: resource.id,
      label: resource.resource_name,
    }));

    this.setState({
      selectedUploadedROFile: item,
      selectedResource: selectedResource,
      copySelectedResource: selectedResource,
      editROModal: true,
    });
  };

  handleSaveStatus = async (data) => {
    try {
      await this.props.dispatch(assignmentStatsActions.saveStatus(data));

      this.setState(
        {
          preventAssignmentPopupClick: false,
        },
        () => {
          this.fetchData();
          toast.success("Assignment status saved successfully!");
          this.closeStatusPopup();
        }
      );
    } catch (error) {
      this.setState({
        preventAssignmentPopupClick: false,
      });
      const parsedError = JSON.parse(error);
      if (typeof parsedError.message == "string") {
        toast.error(`Failed to save assignment status: ${parsedError.message}`);
      } else {
        toast.error(`Failed to save assignment status`);
      }
    }
  };

  viewManifest(id, type) {
    this.props.viewManifest(id, type.toString());
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  goBack() {
    this.props.goBack();
  }

  profile(hash) {
    this.setState({
      linkClick: hash,
    });
  }

  editResource() {
    this.props.editResource(this.props.props.id);
  }

  keyUpFn(event) {
    var value = event.target.value;
    clearTimeout(this.state.timeout);
    this.state.timeout = setTimeout(
      function () {
        this.handleSearch(value, false);
      }.bind(this),
      1000
    );
  }

  emailChangeFn(event) {
    var value = event.target.value;
    this.setState(
      {
        mailSearchKeyword: value,
        validManifestMail: false,
      },
      () => {
        clearTimeout(this.state.timeout);
        this.state.timeout = setTimeout(
          function () {
            this.handleSearch(value, true);
          }.bind(this),
          1000
        );
      }
    );
  }

  handleSearch(text, email) {
    let data = new FormData();
    data.append("keyword", text);

    if (text === "") {
      this.setState({ inputValue: false });
    } else {
      this.setState({ inputValue: true });
    }
    if (!email) {
      data.append("crew_identifier_id", this.state.id);
      data.append("active", true);
      data.append("locked", true);
      data.append("guest", true);
      data.append("pagination", false);
      this.props.dispatch(resourceActions.employeeList(data));
    } else {
      let nextState = {
        search: true,
      };

      if (this.isValidEmail(text)) {
        nextState.validManifestMail = true;
        nextState.manifestEmail = text;
      } else {
        nextState.validManifestMail = false;
        nextState.manifestEmail = "";
      }

      this.props.dispatch(resourceActions.employeeEmailList(data));
      this.setState(nextState);
    }
  }

  remove() {
    this.statusCloseModal();
    this.props.dispatch(resourceActions.removeResource(this.state.id));
    // this.setState({
    //   redirect: "/resources"
    // });
  }

  sendMail(email) {
    this.setState(
      { to: email, manifest_id: this.state.action_id, emailSuccess: "true" },
      function () {
        this.emailManifest();
        this.statusCloseModal();
      }
    );
  }

  setMailer(item) {
    this.setState({
      textarea: false,
      mailList: [...this.state.mailList, item],
      searchList: [],
      hideSearch: "hide",
    });
  }

  removeList(index) {
    if (index >= 0) {
      let mailList = Object.assign([], this.state.mailList);
      mailList.splice(index, 1);
      this.setState({ mailList: mailList, textarea: true, to: "" });
      document.getElementById("txtSearchManiEmployees").value = "";
      // document.getElementById('multiple_emails').value = "";
    }
  }

  removeDispatchList(index) {
    if (index >= 0) {
      let mailList = Object.assign([], this.state.mailListDefault);
      mailList.splice(index, 1);
      this.setState({ mailListDefault: mailList, textarea: true, to: "" });
      document.getElementById("txtSearchManiEmployees").value = "";
      // document.getElementById('multiple_emails').value = "";
    }
  }

  componentWillReceiveProps(nextProps) {
    window.addEventListener("scroll", this.handleScroll);
    const { vehicles, assignmentStats, user, inventory, permission } =
      nextProps;

    var vehiclesOption = [];

    if (nextProps.resource) {
      if (nextProps.resource.remove) {
        const remove_status = nextProps.resource.remove;

        // alert(nextProps.resource.remove.message)

        if (remove_status.success) {
          this.setState({
            redirect: "/resources",
          });
        } else {
          this.setState({
            alertPop: true,
            alertHead: "Error",
            alertMsg: nextProps.resource.remove.message,
            //alertFunction: nextProps.goBack()
          });
        }
      }

      if (nextProps.resource.projects) {
        this.setState({
          incidentProjects: nextProps.resource.projects,
        });
      }
    }

    if (nextProps.singleresource.items) {
      if (nextProps.singleresource.items.data) {
        this.setState({
          resource_name: nextProps.singleresource.items.data.resource_name,
        });
      }
    }

    if (vehicles.items) {
      this.setState({ outerVehicle: vehicles.items });
      if (vehicles.items.data && vehicles.items.data.length > 0) {
        vehicles.items.data.forEach((item, index) => {
          vehiclesOption.push(
            <div className="applicant_item" key={index}>
              <div className="basic_info">
                <a
                  href={config.baseUrl + "inventory/inventory-view/" + item.eid}
                  className="vehicleLink"
                >
                  <div className="appln_name">{item.name}</div>
                  <div className="appln_email">
                    {item.user
                      ? item.user.first_name + " " + item.user.last_name
                      : ""}
                  </div>
                  <div className="appln_phone">
                    {item.user ? item.user.drivers_license : ""}
                  </div>
                  <div className="appln_date">{item.vehicle_model_or_make}</div>
                  <div className="appln_area">
                    {item.vehicle_license === unDefined
                      ? ""
                      : item.vehicle_license}
                  </div>
                </a>
              </div>
            </div>
          );
        });
      } else {
        vehiclesOption.push(
          <div className="applicant_item" key="noVehicles">
            <p>No Vehicles added!</p>
          </div>
        );
      }
      this.setState({ vehiclesOption });
    }

    if (vehicles.vehicleList) {
      this.setState({ vehicleList: vehicles.vehicleList });
    }

    const { manifest, employee, email } = nextProps;

    if (this.state.search && email.employee) {
      var empOptions = [];
      email.employee.data.data.forEach((item, index) => {
        empOptions.push(
          <li key={index} onClick={this.setMailer.bind(this, item)}>
            {item.first_name + " " + item.last_name}
          </li>
        );
      });
      if (this.state.inputValue && empOptions.length > 0) {
        this.setState({
          searchList: empOptions,
          search: false,
          hideSearch: "show",
        });
      } else {
        this.setState({
          searchList: empOptions,
          search: false,
          hideSearch: "hide",
        });
      }
    }

    if (employee.list) {
      if (
        employee.list.data &&
        Array.isArray(employee?.list?.data?.data) &&
        employee?.list?.data?.data?.length > 0
      ) {
        this.setState({
          employeeList: employee.list.data.data,
          resultData: employee.list.data,
          flag: true,
        });
      } else {
        this.setState({ employeeList: [], resultData: [] });
      }
    }
    if (email) {
      if (email.manifestEmail) {
        var head = "";
        if (email.manifestEmail.success) {
          head = "Success";
        } else {
          head = "Error";
        }
        if (this.state.emailSuccess === "true") {
          this.setState({
            alertPop: true,
            alertHead: head,
            alertMsg: JSON.parse(JSON.stringify(email.manifestEmail.message)),
            alertFunction: "",
            emailSuccess: "",
          });
        }
      }
    }
    if (manifest) {
      if (manifest.print) {
      }

      if (manifest.dispatchMailList) {
        this.setState({ dispatchMailList: manifest.dispatchMailList });
      }

      if (manifest.ManifestList) {
        this.setState({ ManifestList: manifest.ManifestList.data });
      }
    }
    if (assignmentStats.applicantStats) {
      let { assignmentStatusOptions } = this.state;

      let currentAssignmentDetails = {};
      if (
        assignmentStats.applicantStats.data.summary &&
        assignmentStats.applicantStats.data.summary.length > 0
      ) {
        currentAssignmentDetails = {
          name: assignmentStats.applicantStats.data.summary[0].name,
          date_off: assignmentStats.applicantStats.data.summary[0].date_off,
          date_on: assignmentStats.applicantStats.data.summary[0].date_on,
          assignment_type_id:
            assignmentStats.applicantStats.data.summary[0].assignment_type_id,
          assignment_type:
            assignmentStats.applicantStats.data.summary[0].assignment_type,
          shifts: assignmentStats.applicantStats.data.summary[0].shifts,
          id: assignmentStats.applicantStats.data.summary[0].id,
          location: assignmentStats.applicantStats.data.summary[0].location,
          complexity: assignmentStats.applicantStats.data.summary[0].complexity,
          fuelType: assignmentStats.applicantStats.data.summary[0].fuel_type,
          fireSize: assignmentStats.applicantStats.data.summary[0].fire_size,
          hotlineShifts:
            assignmentStats.applicantStats.data.summary[0].hotline_shifts,
        };
        if (currentAssignmentDetails?.date_off) {
          const currentDate = new Date();
          this.setState({
            isCrewCommitted:
              new Date(currentAssignmentDetails?.date_off) > currentDate,
          });
        }
      }

      if (assignmentStats.applicantStats.data.notes) {
        currentAssignmentDetails.notes =
          assignmentStats.applicantStats.data.notes;
      }

      this.setState({
        currentAssignmentStatus:
          assignmentStatusOptions[
            assignmentStats.applicantStats.data.status - 1
          ],
        currentAssignmentDetails,
      });
    }
    if (assignmentStats.types) {
      this.setState({ assigmentCategoryOptions: assignmentStats.types });
    }
    if (assignmentStats.assignmentHistory) {
      this.setState({
        assigmentStatusHistory: assignmentStats.assignmentHistory.data,
        latestAssignmentHistory: assignmentStats.assignmentHistory.additional_data?.latestAssignmentHistory,
      });
    }

    if (assignmentStats.assignmentStatusSaved) {
      if (assignmentStats.assignmentStatusSaved.success) {
        this.props.dispatch(
          assignmentStatsActions.getAssignemntStatus(this.state.id)
        );
        this.employeeListUpdated();
        this.props.dispatch(
          assignmentStatsActions.getAssignemntHistory(this.state.id, 1)
        );
        this.setState({
          tempAssignmentStatus: {
            name: "",
            date_off: "",
            date_on: "",
            assignment_type_id: "",
            assignment_type: "",
            shifts: "",
            notes: "",
            id: 0,
          },
        });
      } else {
        var message = assignmentStats.assignmentStatusSaved.message || {},
          err = [];
        if (Object.keys(message).length) {
          for (var key in message) {
            var ele = message[key];
            var _err = err.push(Array.isArray(ele) ? ele.join(",") : ele);
          }
        }

        if (err.length) {
          this.setState({
            errorMessage: err.join(","),
          });
          setTimeout(() => {
            this.setState({
              errorMessage: "",
            });
            this.props.dispatch(assignmentStatsActions.resetStatus());
          }, 3000);
        }
      }
    }
    if (assignmentStats.historyDelete) {
      this.props.dispatch(
        assignmentStatsActions.getAssignemntStatus(this.state.id)
      );
      this.props.dispatch(
        assignmentStatsActions.getAssignemntHistory(this.state.id, 1)
      );
    }

    if (inventory.list && inventory.list.length) {
      this.setState({
        inventoryList: inventory.list,
      });
    }
  }
  getHistoryList = (pageNum) => {
    this.props.dispatch(
      assignmentStatsActions.getAssignemntHistory(this.state.id, pageNum)
    );
  };

  handleChooseResources = async (text) => {
    let data = new FormData();
    data.append("keyword", text);
    const response = await this.props.dispatch(
      resourceActions.searchResource(data)
    );
    let resourceList = [];
    const items = response.data;
    var res = [];
    for (var key in items) {
      var arr = items[key];
      if (arr.length) {
        res = res.concat(arr);
      }
    }
    if (res.length > 0) {
      res.forEach((item) => {
        let resource = {
          label: item.resource_name,
          value: item.id,
        };
        resourceList.push(resource);
      });
    }
    return resourceList;
  };

  handleViewResourceOrderForm(manifest) {
    switch (manifest.resource_order_form_type) {
      case RESOURCE_ORDER_FILE_TYPE?.UPLOADED_FORM:
        window.open(manifest.resource_order_form, "_blank").focus();
        break;
      case RESOURCE_ORDER_FILE_TYPE?.CREATE_FORM:
        this.printResourceOrderForm(manifest);
        break;
      default:
        break;
    }
  }

  printResourceOrderForm(manifest) {
    let data = new FormData();
    data.append("manifest_id", manifest.mid);
    manifestService
      ?.viewCreatedResourceOrderForm(data)
      .then((response) => this?.redirectToPdf(response));
  }

  unassignOldManifest(manifestId, index) {
    manifestService?.unassignManifest(manifestId).then((response) => {
      // Show success message
      toast.success(
        "Manifest has been unassigned successfully. You can now assign this manifest to the crew."
      );

      // Update assignment_status to 1 for the unassigned item
      this.setState((prevState) => {
        const updatedManifestList = [...prevState.ManifestList.data];
        updatedManifestList[index].assignment_status = 1;

        return {
          ManifestList: {
            ...prevState.ManifestList,
            data: updatedManifestList,
          },
        };
      });
    });
  }

  redirectToPdf(data) {
    if (data?.success) {
      const pdfLink = data.data;
      var link = document.createElement("a");
      if (
        window.navigator.userAgent.match(/iPad/i) ||
        window.navigator.userAgent.match(/iPhone/i)
      ) {
        link.href = pdfLink;
      } else {
        link.href = pdfLink;
        link.target = "_blank";
      }
      document.body.appendChild(link);
      link.click();
      setTimeout(function () {
        document.body.removeChild(link);
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(pdfLink);
      }, 100);
    }
  }

  deleteHistory = () => {
    if (this.state.historyId == "") {
      return;
    }
    this.setState({ deleteHistoryOpen: false });
    this.props.dispatch(
      assignmentStatsActions.historyDelete(this.state.historyId)
    );
  };

  /**
   * function to open slider to edit the assignment status
   */
  editStatus = () => {
    let offDate = null;
    let unavailable_end_date = null;
    if (this.state.latestAssignmentHistory) {
      if (
        this.state.latestAssignmentHistory.incident_projects?.date_on &&
        this.state.currentAssignmentStatus == "Committed"
      ) {
        offDate =
          currentDate >=
          this.state.latestAssignmentHistory.incident_projects?.date_on
            ? currentDate
            : this.state.latestAssignmentHistory.incident_projects?.date_on;
      } else if (
        this.state.latestAssignmentHistory.resource_unavailability?.start_date &&
        this.state.currentAssignmentStatus == "Unavailable"
      ) {
        unavailable_end_date =
          currentDate >=
          this.state.latestAssignmentHistory.resource_unavailability?.start_date
            ? currentDate
            : this.state.latestAssignmentHistory.resource_unavailability?.start_date;
      }
    }

    this.setState({
      editMode: false,
      preventAssignmentPopupClick: false,
      tempAssignmentStatus: "",
      tempAssignmentType: "",
      projectId: null,
      projectName: "",
      projectShift: "",
      initialStatusId: null,
      onDate: moment(currentDate),
      offDate: offDate ? moment(offDate) : null,
      unavailable_end_date: unavailable_end_date
        ? moment(unavailable_end_date)
        : null,
      projectNote: "",
      location: "",
      complexity: "",
      fuel_type: "",
      fire_size: "",
      hotline_shifts: "",
    });

    document.body.className += " " + "inv-sidebar-opened";
  };

  handleAssignmentChange = (event) => {
    this.setState({
      tempAssignmentStatus: event.target.value,
      unavailable_start_date:
        event.target.value == "Unavailable" ? moment(currentDate) : null,
    });
  };

  handleFireHistoryAssignModal = () => {
    switch (this.state?.typeSlug) {
      case FIRE_HISTORY_TYPE_SLUG?.AUTO_ASSIGN_MANIFEST:
        this.saveAssignmentChanges();
        this.setState({ enableAssignEmployeeModal: false });
        break;
      case FIRE_HISTORY_TYPE_SLUG?.MANUAL_ASSIGN_MANIFEST:
        this?.sortYourCrewRef?.current?.toVehicle((res) => {
          if (res?.length) {
            this.setState(
              {
                manualAssignManifestIds: res?.map((item) => item?.userid),
                enableAssignEmployeeModal: false,
              },
              () => {
                this.saveAssignmentChanges();
              }
            );
          }
        });
        break;
    }
  };

  handleAutoAssignModalClose() {
    this.setState({ enableAssignEmployeeModal: false, typeSlug: null });
  }

  render() {
    if (this.state.linkClick !== false)
      return <Redirect to={this.state.linkClick} />;
    let crew_data = null;
    this.props.assignmentStats &&
      this.props.assignmentStats.applicantStats &&
      (crew_data = this.props.assignmentStats.applicantStats.data);
    const { pageNumber } = this.state;
    const { permission } = this.props;

    const { persist } = this.props.assignmentStats;
    const { complexity, fuelType, fireSize } = persist;

    // var vehiclesOption = [];
    var manifestsOption = [];
    // var employeesOption = [];
    var permList = [];
    let pages = [];
    let paginationHtml = [];
    let paginationHistoryHtml = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
    }
    if (this.state.redirect === "/resources") {
      return <Redirect to="/resources" />;
    }

    let { inventoryList, inventoryPerPage, inventoryPage } = this.state;

    if (
      this.state.assigmentStatusHistory.data &&
      this.state.assigmentStatusHistory.data.length > 0
    ) {
      let pages = [];
      // let totalPages = Math.ceil(x / y);
      let currentPage = this.state.assigmentStatusHistory.current_page;
      let startingIndex = currentPage - 2 > 0 ? currentPage - 2 : 1;
      let lastPageIndex =
        currentPage + 2 < this.state.assigmentStatusHistory.last_page
          ? currentPage + 2
          : this.state.assigmentStatusHistory.last_page;
      let nextPage =
        currentPage + 1 > this.state.assigmentStatusHistory.last_page
          ? this.state.assigmentStatusHistory.last_page
          : currentPage + 1;
      let prevPage = currentPage - 1 > 0 ? currentPage - 1 : 1;
      if (currentPage > 3) {
        pages.push(
          <li
            className="inactive"
            key={0}
            onClick={() => {
              this.getHistoryList(1);
            }}
          >
            <a>1</a>
          </li>
        );
      }
      if (currentPage > 4) {
        pages.push(
          <li className="inactive" key={1}>
            <a>. . .</a>
          </li>
        );
      }
      for (let i = startingIndex; i <= lastPageIndex; i++) {
        // getManifestList
        pages.push(
          <li
            className={i == currentPage ? "active" : "inactive"}
            key={i}
            onClick={() => {
              this.getHistoryList(i);
            }}
          >
            <a>{i}</a>
          </li>
        );
      }
      if (
        lastPageIndex + 1 <
        this.state.assigmentStatusHistory.data.last_page
      ) {
        pages.push(
          <li
            className="inactive"
            key={this.state.assigmentStatusHistory.data.last_page + 1}
          >
            <a>. . .</a>
          </li>
        );
      }
      if (lastPageIndex < this.state.assigmentStatusHistory.data.last_page) {
        pages.push(
          <li
            className="inactive"
            key={this.state.assigmentStatusHistory.data.last_page}
            onClick={() => {
              this.getHistoryList(
                this.state.assigmentStatusHistory.data.last_page
              );
            }}
          >
            <a>{this.state.assigmentStatusHistory.data.last_page}</a>
          </li>
        );
      }

      if (pages && pages.length > 0) {
        paginationHistoryHtml.push(
          <div
            className="fs-pagination-wrapper-outer custome_old_pagination"
            key={7}
          >
            <div className="fs-pagination-wrapper">
              <span
                className={`prev ${
                  prevPage === 1 && currentPage === 1 ? "disabled" : ""
                }`}
                onClick={() => {
                  this.getHistoryList(prevPage);
                }}
              >
                <img
                  alt="previous"
                  src={require("../assets/icons/next_arrow.svg").default}
                />
              </span>
              <ul className="fs-pagination">{pages}</ul>
              <span
                className={`next ${
                  nextPage == this.state.assigmentStatusHistory.last_page
                    ? "disabled"
                    : ""
                }`}
                onClick={() => {
                  this.getHistoryList(nextPage);
                }}
              >
                <img
                  alt="previous"
                  src={require("../assets/icons/next_arrow.svg").default}
                />
              </span>
            </div>
          </div>
        );
      }
    }

    if (this.state.ManifestList) {
      if (
        this.state.ManifestList.data &&
        this.state.ManifestList.data.length > 0
      ) {
        // let totalPages = Math.ceil(x / y);
        let currentPage = this.state.ManifestList.current_page;
        let startingIndex = currentPage - 2 > 0 ? currentPage - 2 : 1;
        let lastPageIndex =
          currentPage + 2 < this.state.ManifestList.last_page
            ? currentPage + 2
            : this.state.ManifestList.last_page;
        let nextPage =
          currentPage + 1 > this.state.ManifestList.last_page
            ? this.state.ManifestList.last_page
            : currentPage + 1;
        let prevPage = currentPage - 1 > 0 ? currentPage - 1 : 1;
        //
        if (currentPage > 3) {
          pages.push(
            <li
              className="inactive"
              key={0}
              onClick={() => {
                this.getManifestList(1);
              }}
            >
              <a>1</a>
            </li>
          );
        }
        if (currentPage > 4) {
          pages.push(
            <li
              className="inactive"
              key={this.state.ManifestList.last_page + 1}
            >
              <a>. . .</a>
            </li>
          );
        }
        for (let i = startingIndex; i <= lastPageIndex; i++) {
          // getManifestList
          pages.push(
            <li
              className={i == currentPage ? "active" : "inactive"}
              key={i}
              onClick={() => {
                this.getManifestList(i);
              }}
            >
              <a>{i}</a>
            </li>
          );
        }
        if (lastPageIndex + 1 < this.state.ManifestList.last_page) {
          pages.push(
            <li
              className="inactive"
              key={this.state.ManifestList.last_page + 1}
            >
              <a>. . .</a>
            </li>
          );
        }
        if (lastPageIndex < this.state.ManifestList.last_page) {
          pages.push(
            <li
              className="inactive"
              key={this.state.ManifestList.last_page}
              onClick={() => {
                this.getManifestList(this.state.ManifestList.last_page);
              }}
            >
              <a>{this.state.ManifestList.last_page}</a>
            </li>
          );
        }
        if (pages && pages.length > 0) {
          paginationHtml.push(
            <div
              className="fs-pagination-wrapper-outer custome_old_pagination"
              key={7}
            >
              <div className="fs-pagination-wrapper">
                <span
                  className={`prev ${
                    prevPage === 1 && currentPage === 1 ? "disabled" : ""
                  }`}
                  onClick={() => {
                    this.getManifestList(prevPage);
                  }}
                >
                  <img
                    alt="previous"
                    src={require("../assets/icons/next_arrow.svg").default}
                  />
                </span>
                <ul className="fs-pagination">{pages}</ul>
                <span
                  className={`next ${
                    nextPage == this.state.assigmentStatusHistory.data.last_page
                      ? "disabled"
                      : ""
                  }`}
                  onClick={() => {
                    this.getManifestList(nextPage);
                  }}
                >
                  <img
                    alt="previous"
                    src={require("../assets/icons/next_arrow.svg").default}
                  />
                </span>
              </div>
            </div>
          );
        }
        manifestsOption.push(
          <div
            id="manifest-delete-popup"
            className="fs-toast"
            key={`recently_deleted`}
          >
            Manifest Deleted successfully
          </div>
        );
        this.state.ManifestList.data.forEach((item, index) => {
          manifestsOption.push(
            // <div className="employee_item" key={index} onClick={this.editManifest.bind(this,item.mid)}>
            <div
              className={`manifest_items ${
                !index ? "fs_manifest_item_one" : ""
              }`}
              key={index}
            >
              <div className="employee_item table-view">
                <div className="list_item">{item.abbreviation}</div>
                <div className="list_item">{item.contract_number}</div>
                <div className="list_item">{item.incident_name}</div>
                <div className="list_item">{item.resource_number}</div>
                <div className="list_item">
                  {item.resource_order_form_exists === 1 ? (
                    <CustomToolTip
                      label={item.resource_order_name}
                      customClass={"fs-tooltip"}
                    >
                      <a
                        class=""
                        style={{ cursor: "pointer" }}
                        onClick={() => this.handleViewResourceOrderForm(item)}
                      >
                        View RO
                      </a>
                    </CustomToolTip>
                  ) : (
                    "-"
                  )}
                </div>
                <div className="list_item">{formatDate(item.added_date)}</div>
                <div
                  className="list_item action_icon"
                  onClick={this.viewAction.bind(this, item.mid)}
                >
                  {/*  <span className="action_icon"></span> */}
                </div>
                {/* <div className="appln_email" onClick={this.profile.bind(this,item.hash)}>Profile View</div> */}
              </div>
              {this.state.action_id === item.mid && (
                // <div className={"action_list "+this.state.action}>
                <div
                  className={
                    this.state.viewStatus
                      ? "action_list show"
                      : "action_list hide"
                  }
                >
                  {permList.includes("Manage Manifests") && (
                    <p onClick={this.printManifest.bind(this, item.mid)}>
                      Print
                    </p>
                  )}
                  {permList.includes("Manage Manifests") && (
                    <p
                      onClick={this.editManifest.bind(this, item.mid, item.bid)}
                    >
                      Edit
                    </p>
                  )}
                  {/*<p onClick={this.deleteManifest.bind(this, item.mid)}>Delete</p>*/}
                  {permList.includes("Manage Manifests") && (
                    <p onClick={this.deleteOpenModal.bind(this, item.mid)}>
                      Delete
                    </p>
                  )}
                  {/* <p onClick={this.viewOpenModal.bind(this, item.mid)}>View</p> */}
                  <p onClick={this.viewManifest.bind(this, item.mid, item.bid)}>
                    View
                  </p>
                  {permList.includes("Manage Manifests") && (
                    <p onClick={this.emailOpenModal.bind(this, item.mid)}>
                      Email
                    </p>
                  )}
                  {permList.includes("Manage Employees") && (
                    <p onClick={this.printFireCards.bind(this, item.mid)}>
                      Print Fire Cards
                    </p>
                  )}
                  {permList.includes("Manage Employees") && (
                    <p onClick={this.emailFireCardsOpenModal.bind(this)}>
                      Email Fire Cards
                    </p>
                  )}
                  {item.assignment_status == 2 && (
                    <p
                      title="Unassign Old Manifest"
                      onClick={this.unassignOldManifest.bind(
                        this,
                        item.mid,
                        index
                      )}
                    >
                      Unassign
                    </p>
                  )}
                </div>
              )}
            </div>
          );
        });
      } else {
        manifestsOption.push(
          <div className="applicant_item" key="noEmployees">
            <p>No Manifest added!</p>
          </div>
        );
      }
    }

    var obj = {
      url:
        config.apiUrl +
        "/api/manifest/pdf?manifest_id=" +
        this.state.mid +
        "&resource_id=" +
        this.state.id,
      httpHeaders: authHeader(),
    };

    // let employeeList = this.state.employeeList
    //   .sort((employeeA, employeeB) => {
    //     return employeeA.is_locked - employeeB.is_locked;
    //   })
    //   .sort((employeeA, employeeB) => {
    //     return employeeA.is_guest - employeeB.is_guest;
    //   })
    //   .map((employee, index) => {
    //     return {
    //       ...employee,
    //       index,
    //     };
    //   });

    const availableEmployees = this.state.employeeList?.filter(
      (employee) => !employee.is_locked && !employee.is_guest
    );
    const pendingEmployees = this.state.employeeList?.filter(
      (employee) => employee.is_guest
    );
    const lockedEmployees = this.state.employeeList?.filter(
      (employee) => employee.is_locked && !employee.is_guest
    );

    // if(employee.list) {
    //     if(employee.list.data.data.length > 0){
    return (
      <div className="resource_container resource_details">
        <div className="breadcrumbs">
          <Link className="green" to={"/resources"}>
            Resources
          </Link>{" "}
          / Details
        </div>
        <div className="page_title float">
          <h1>
            {this.state.resource_name
              ? this.state.resource_name
              : this.props.props.resource_name}
          </h1>
          <div className="milage__btn-wraper">
            {/* {permList.includes("Add/Edit/Delete Resource") && (
              <Link to={"/create-resource"} className="create_resource button">
                New Resource
              </Link>
            )} */}
            {permList.includes("Manage Manifests") && (
              <button
                className="print_button"
                onClick={this.createManifest.bind(this)}
                style={{ marginLeft: 0 }}
              >
                Create Manifest
              </button>
            )}
            {permList.includes("Add/Edit Resource") &&
              this.props.props.id !== 1 && (
                <button
                  className="print_button"
                  onClick={this.editResource.bind(this)}
                >
                  Edit Resource
                </button>
              )}
            {permList.includes("Archive Resource") &&
              this.props.props.id !== 1 && (
                <button
                  className="print_button"
                  onClick={this.statusOpenModal.bind(this)}
                >
                  Archive Resource
                </button>
              )}
            {permList.includes("Manage Employees") && (
              <DropdownBtn
                setPrintOption={this.handlePrintOption}
                label={"Fire Cards"}
                options={[
                  { label: "Email", value: "email" },
                  {
                    label: "Print",
                    value: "pdf",
                  },
                ]}
              />
            )}
          </div>
        </div>

        <div className="applicants_inner_head crew_outer">
          <div className="inner_head">
            <input
              type="text"
              name="keyword"
              className="resource_search"
              placeholder="Search Employees..."
              onKeyUp={this.keyUpFn.bind(this)}
              autoComplete="off"
            />
          </div>
          <div
            className="inner_container col-lg-12 crew_wrapper"
            id="applicants_inner"
          >
            <React.Fragment>
              <div className="assignment-status">
                {this.state.errorMessage != "" && (
                  <div
                    className="message-display-div alert alert-danger"
                    role="alert"
                  >
                    {this.state.errorMessage}
                  </div>
                )}

                <h5>Assignment Status</h5>
                <div className="status-wrapper">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="status-left-box">
                        <div className="current-status">
                          <h5>Current Status</h5>
                          {this.state.currentAssignmentStatus ==
                            "Committed" && (
                            <div className="committed">
                              <span className="status-image">
                                <img
                                  src={
                                    require("../assets/images/committed.svg")
                                      .default
                                  }
                                />
                              </span>
                              {this.state.currentAssignmentStatus}
                            </div>
                          )}
                          {this.state.currentAssignmentStatus ==
                            "Available" && (
                            <div className="available">
                              <span className="status-image">
                                <img
                                  src={
                                    require("../assets/images/tick.svg").default
                                  }
                                />
                              </span>
                              {this.state.currentAssignmentStatus}
                            </div>
                          )}
                          {this.state.currentAssignmentStatus ==
                            "Unavailable" && (
                            <div className="unavailable">
                              <span className="status-image">
                                <img
                                  src={
                                    require("../assets/images/unavailable.svg")
                                      .default
                                  }
                                />
                              </span>
                              {this.state.currentAssignmentStatus}
                            </div>
                          )}
                          {permList.includes("Status Change Resource") && (
                            <span className="editor" onClick={this.editStatus}>
                              <img
                                src={
                                  require("../assets/images/edit.svg").default
                                }
                              />
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-8">
                      <div className="summary-box">
                        <h5>Summary</h5>
                        {this.state.currentAssignmentStatus == "Committed" && (
                          <React.Fragment>
                            <p className="shifts">
                              <strong>Incident Name:</strong>{" "}
                              {this.state.currentAssignmentDetails.name}
                            </p>

                            <div className="lists-outer-row">
                              <div className="shifts">
                                <strong>Total Shifts:</strong>{" "}
                                {this.state.currentAssignmentDetails.shifts}
                              </div>
                              <div className="shifts">
                                <strong>Location:</strong>{" "}
                                {this.state.currentAssignmentDetails.location}
                              </div>
                              <div className="shifts">
                                <strong>Complexity:</strong>{" "}
                                {this.state.currentAssignmentDetails.complexity}
                              </div>
                              <div className="shifts">
                                <strong>Fuel Type:</strong>{" "}
                                {this.state.currentAssignmentDetails.fuelType}
                              </div>
                              <div className="shifts">
                                <strong>Fire Size:</strong>{" "}
                                {this.state.currentAssignmentDetails.fireSize}
                              </div>
                              <div className="shifts">
                                <strong>Hotline Shifts:</strong>{" "}
                                {
                                  this.state.currentAssignmentDetails
                                    .hotlineShifts
                                }
                              </div>
                              <div className="shifts">
                                <strong>Type Of Assignment:</strong>{" "}
                                {
                                  this.state.currentAssignmentDetails
                                    .assignment_type
                                }{" "}
                              </div>
                              <div className="shifts">
                                <strong>Date On:</strong>{" "}
                                {formatDate(
                                  this.state.currentAssignmentDetails.date_on
                                )}
                              </div>
                              <div className="shifts">
                                <strong>Date Off:</strong>{" "}
                                {formatDate(
                                  this.state.currentAssignmentDetails.date_off
                                )}
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                        {this.state.currentAssignmentStatus == "Unavailable" &&
                          crew_data && (
                            <React.Fragment>
                              <p className="shifts">
                                <strong>Unavailable Reason:</strong>{" "}
                                {crew_data.unavailable_reason}
                              </p>
                            </React.Fragment>
                          )}
                        <p className="shifts">
                          <strong>Notes:</strong>{" "}
                          {this.state.currentAssignmentDetails.notes}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="inv-sidemenu-box">
                <div className="change-stat-head">
                  <h5>
                    {this.state.editMode
                      ? "Edit Assignment History"
                      : "Change Status"}
                  </h5>
                </div>
                <div
                  className={`change-status-pop ${
                    this.state.preventAssignmentPopupClick ? "disabled" : ""
                  }`}
                >
                  <form
                    onSubmit={this.saveAssignmentChanges}
                    id="assignment-status-form"
                  >
                    <div className="form-group">
                      <label htmlFor="exampleFormControlInput1">
                        Select Status *
                      </label>
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        value={this.state.tempAssignmentStatus}
                        onChange={this.handleAssignmentChange}
                        disabled={this.state.editMode}
                        required
                      >
                        {!this.state.editMode &&
                          assignmentStatuses
                            .filter(
                              (status) =>
                                status.value !==
                                this.state.currentAssignmentStatus
                            )
                            .map((status) => (
                              <option key={status.value} value={status.value}>
                                {status.label}
                              </option>
                            ))}
                        {this.state.editMode &&
                          assignmentStatuses.map((status) => (
                            <option key={status.value} value={status.value}>
                              {status.label}
                            </option>
                          ))}
                      </select>
                    </div>

                    {/* Committed */}
                    {this.state.tempAssignmentStatus == "Committed" && (
                      <React.Fragment>
                        <div className="form-group">
                          <label htmlFor="exampleFormControlInput1">
                            Type of Assignment *
                          </label>
                          <select
                            className="form-control"
                            name="tempAssignmentType"
                            value={this.state.tempAssignmentType}
                            ref="AssignmentType"
                            onChange={this.handleSelect}
                            required
                          >
                            <option value="">Type of Assignment</option>
                            {this.state.assigmentCategoryOptions.map(
                              (item, index) => (
                                <option key={index} value={item.id}>
                                  {item.value}
                                </option>
                              )
                            )}
                          </select>
                        </div>

                        {/* Select Existing Project */}
                        {/* {
                          this.state.initialStatusId !== 3 && 
                          <select 
                            className="form-control"
                            ref="ExistingProject"
                            value={this.state.projectId || ""}
                            onChange={this.selectProject}>
                            <option value="">Choose Existing Project</option>
                            {
                              this.state.incidentProjects
                                .map((item, index) => <option key={index} value={item.id}>{item.name}</option>)
                            }
                          </select>
                        } */}
                        {/* Manifest Selection "Select Manifest" */}
                        {!this.state.editMode && (
                          <AllManifest
                            resourceId={this.state.id}
                            onSelect={(manifestId) => {
                              this.setState({
                                typeSlug: manifestId
                                  ? FIRE_HISTORY_TYPE_SLUG?.WITH_MANIFEST
                                  : null,
                                fireHistoryManifestId: manifestId,
                              });
                            }}
                            setIncident={(incident) => {
                              this.setState({ projectName: incident });
                            }}
                          />
                        )}
                        {/* End */}
                        {(this.state.projectId == null ||
                          (this.state.editMode == true &&
                            this.state.initialStatusId == 3)) && (
                          <div>
                            <div className="form-group name-list-wrapper">
                              <label htmlFor="exampleFormControlInput1">
                                Incident Name *
                              </label>
                              <input
                                required
                                ref="ProjectName"
                                name="projectName"
                                value={this.state.projectName}
                                onChange={this.handleChange}
                                type="text"
                                className="form-control"
                                disabled
                                readonly
                              />
                            </div>
                            <div className="form-group name-list-wrapper">
                              <label htmlFor="exampleFormControlInput1">
                                Location{" "}
                              </label>
                              <input
                                ref="Location"
                                name="location"
                                value={this.state.location}
                                onChange={this.handleChange}
                                type="text"
                                className="form-control"
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="exampleFormControlInput1">
                                Complexity
                              </label>
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                name="complexity"
                                value={this.state.complexity}
                                onChange={this.handleChange}
                              >
                                <option value="">
                                  Choose Complexity Level
                                </option>

                                {complexity.map((item, index) => (
                                  <option key={index} value={item.value}>
                                    {item.value}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="form-group">
                              <label htmlFor="exampleFormControlInput1">
                                Fuel Type
                              </label>
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                name="fuel_type"
                                value={this.state.fuel_type}
                                onChange={this.handleChange}
                              >
                                <option value="">Choose Fuel Type</option>

                                {fuelType.map((item, index) => (
                                  <option key={index} value={item.value}>
                                    {item.value}- {item.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="form-group">
                              <label htmlFor="exampleFormControlInput1">
                                Fire Size
                              </label>
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                name="fire_size"
                                value={this.state.fire_size}
                                onChange={this.handleChange}
                              >
                                <option value="">Choose Fire Size</option>

                                {fireSize.map((item, index) => (
                                  <option key={index} value={item.value}>
                                    {item.value}- {item.label}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="form-group">
                              <label htmlFor="exampleFormControlInput1">
                                Date On *
                              </label>
                              <span className="date-outer">
                                <span
                                  onClick={() => {
                                    this.refs.dateon.setFocus();
                                  }}
                                  className="datepicker-image"
                                >
                                  <img
                                    src={
                                      require("../assets/images/icon_calendar.svg")
                                        .default
                                    }
                                  />
                                </span>
                                <DatePicker
                                  className="txt "
                                  ref="dateon"
                                  id="Dateon"
                                  name="Dateon"
                                  selected={this.state.onDate}
                                  onChange={(date) => {
                                    this.setState({ onDate: date }, () => {
                                      if (
                                        this.state.editMode &&
                                        permList.includes(
                                          "Edit Assignment History"
                                        )
                                      ) {
                                        this.renderTotalShifts(
                                          this.state.onDate,
                                          this.state.offDate
                                        );
                                      }
                                    });
                                  }}
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  placeholderText="Select Date"
                                  onKeyDown={(e) => e.preventDefault()}
                                />
                              </span>
                            </div>
                            {this.state.editMode &&
                              permList.includes("Edit Assignment History") && (
                                <>
                                  <div className="form-group">
                                    <label htmlFor="exampleFormControlInput1">
                                      Date Off {this.state.offDate ? "*" : ""}
                                    </label>
                                    <span className="date-outer">
                                      <span
                                        onClick={() => {
                                          this.refs.dateoff.setFocus();
                                        }}
                                        className="datepicker-image"
                                      >
                                        <img
                                          src={
                                            require("../assets/images/icon_calendar.svg")
                                              .default
                                          }
                                        />
                                      </span>
                                      <DatePicker
                                        className="txt "
                                        ref="dateoff"
                                        id="Dateoff"
                                        name="assignDate"
                                        selected={this.state.offDate}
                                        onChange={(date) => {
                                          this.setState(
                                            { offDate: date },
                                            () => {
                                              this.renderTotalShifts(
                                                this.state.onDate,
                                                this.state.offDate
                                              );
                                            }
                                          );
                                        }}
                                        minDate={this.state.onDate}
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        placeholderText="Select Date"
                                        onKeyDown={(e) => e.preventDefault()}
                                      />
                                    </span>
                                  </div>
                                  <div className="form-group name-list-wrapper">
                                    <label htmlFor="exampleFormControlInput1">
                                      Hotline Shifts{" "}
                                    </label>
                                    <input
                                      ref="Location"
                                      name="hotline_shifts"
                                      value={this.state.hotline_shifts}
                                      onChange={this.handleChange}
                                      type="number"
                                      className="form-control"
                                      min={0}
                                      onWheel={this.handleOnWheel}
                                      onKeyDown={preventNegativeValueInDocs}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label htmlFor="exampleFormControlInput1">
                                      Total Shifts
                                    </label>
                                    <input
                                      type="number"
                                      name="projectShift"
                                      value={this.state.projectShift}
                                      disabled
                                      ref="totalShifts"
                                      className="form-control"
                                    />
                                  </div>
                                </>
                              )}
                          </div>
                        )}
                      </React.Fragment>
                    )}

                    {/* Unavailable */}
                    {this.state.tempAssignmentStatus === "Unavailable" && (
                      <React.Fragment>
                        <div className="form-group">
                          <label htmlFor="unavailable_reason">
                            Unavailable Reason *
                          </label>
                          <select
                            className="form-control"
                            id="unavailable_reason_id"
                            name="unavailable_reason_id"
                            value={this.state.unavailable_reason_id}
                            onChange={this.handleChange}
                            required
                          >
                            <option value="">-no reason-</option>
                            {this.state.unavailable_reasons.map((reason) => {
                              return (
                                <option value={reason.id} key={reason.id}>
                                  {reason.value}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleFormControlInput1">
                            Unavailable Start Date *
                          </label>
                          <span className="date-outer">
                            <span
                              onClick={() => {
                                this.refs.unavailable_start_date.setFocus();
                              }}
                              className="datepicker-image"
                            >
                              <img
                                src={
                                  require("../assets/images/icon_calendar.svg")
                                    .default
                                }
                              />
                            </span>
                            <DatePicker
                              className="txt"
                              ref="unavailable_start_date"
                              id="unavailable_start_date"
                              name="unavailable_start_date"
                              selected={this.state.unavailable_start_date}
                              onChange={(date) =>
                                this.setState({ unavailable_start_date: date })
                              }
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              placeholderText="Select Date"
                              required
                              onKeyDown={(e) => e.preventDefault()}
                            />
                          </span>
                        </div>
                        {this.state.editMode &&
                          permList.includes("Edit Assignment History") && (
                            <>
                              <div className="form-group">
                                <label htmlFor="exampleFormControlInput1">
                                  Unavailable End Date{" "}
                                  {this.state.unavailable_end_date ? "*" : ""}
                                </label>
                                <span className="date-outer">
                                  <span
                                    onClick={() => {
                                      this.refs.unavailable_end_date.setFocus();
                                    }}
                                    className="datepicker-image"
                                  >
                                    <img
                                      src={
                                        require("../assets/images/icon_calendar.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  <DatePicker
                                    className="txt"
                                    ref="unavailable_end_date"
                                    id="unavailable_end_date"
                                    name="unavailable_end_date"
                                    selected={this.state.unavailable_end_date}
                                    onChange={(date) =>
                                      this.setState({
                                        unavailable_start_date: date,
                                      })
                                    }
                                    minDate={
                                      this.state.unavailable_start_date
                                        ? moment(
                                            this.state.unavailable_start_date
                                          )
                                        : null
                                    }
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Select Date"
                                    onKeyDown={(e) => e.preventDefault()}
                                  />
                                </span>
                              </div>
                            </>
                          )}
                      </React.Fragment>
                    )}

                    {this.state.currentAssignmentStatus == "Unavailable" &&
                      this.state.editMode == false && (
                        <div className="form-group">
                          <label htmlFor="exampleFormControlInput1">
                            Unavailable End Date *
                          </label>
                          <span className="date-outer">
                            <span
                              onClick={() => {
                                this.refs.unavailable_end_date.setFocus();
                              }}
                              className="datepicker-image"
                            >
                              <img
                                src={
                                  require("../assets/images/icon_calendar.svg")
                                    .default
                                }
                              />
                            </span>
                            <DatePicker
                              className="txt"
                              ref="unavailable_end_date"
                              id="unavailable_end_date"
                              name="unavailable_end_date"
                              selected={this.state.unavailable_end_date}
                              onChange={(date) =>
                                this.setState({ unavailable_end_date: date })
                              }
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              placeholderText="Select Date"
                              required
                              onKeyDown={(e) => e.preventDefault()}
                              minDate={
                                this.state.latestAssignmentHistory &&
                                this.state.latestAssignmentHistory.resource_unavailability?.start_date
                                  ? moment(
                                      this.state.latestAssignmentHistory.resource_unavailability?.start_date
                                    )
                                  : null
                              }
                            />
                          </span>
                        </div>
                      )}

                    {this.state.currentAssignmentStatus == "Committed" &&
                      this.state.editMode == false && (
                        <>
                          <div className="form-group">
                            <label htmlFor="exampleFormControlInput1">
                              Date Off *
                            </label>
                            <span className="date-outer">
                              <span
                                onClick={() => {
                                  this.refs.dateoff.setFocus();
                                }}
                                className="datepicker-image"
                              >
                                <img
                                  src={
                                    require("../assets/images/icon_calendar.svg")
                                      .default
                                  }
                                />
                              </span>
                              <DatePicker
                                className="txt"
                                ref="dateoff"
                                id="Dateoff"
                                name="assignDate"
                                selected={this.state.offDate}
                                onChange={(date) =>
                                  this.setState({ offDate: date })
                                }
                                minDate={
                                  this.state.latestAssignmentHistory &&
                                  this.state.latestAssignmentHistory.incident_projects?.date_on
                                    ? moment(
                                        this.state.latestAssignmentHistory.incident_projects.date_on
                                      )
                                    : null
                                }
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="Select Date"
                                required
                                onKeyDown={(e) => e.preventDefault()}
                              />
                            </span>
                          </div>
                          <div className="form-group name-list-wrapper">
                            <label htmlFor="exampleFormControlInput1">
                              Hotline Shifts *
                            </label>
                            <input
                              ref="Location"
                              name="hotline_shifts"
                              value={this.state.hotline_shifts}
                              onChange={this.handleChange}
                              className="form-control"
                              required
                              type="number"
                              min={0}
                              onWheel={this.handleOnWheel}
                              onKeyDown={preventNegativeValueInDocs}
                            />
                          </div>
                        </>
                      )}

                    {(this.state.projectId == null ||
                      this.state.editMode == true) && (
                      <div className="form-group">
                        <label htmlFor="exampleFormControlTextarea1">
                          Notes
                        </label>
                        <textarea
                          ref="note"
                          name="projectNote"
                          value={this.state.projectNote}
                          onChange={this.handleChange}
                          className="form-control"
                          rows="3"
                        ></textarea>
                      </div>
                    )}
                    {/* {this.state.editMode && (
                      <Link
                        className="green"
                        to={{
                          pathname: `/employee-fire-history/${this.state?.assignmentHistoryId}`,
                          state: {
                            resourceId: this.state.id,
                            nav: "resource",
                          },
                        }}
                      >
                        Edit Employee Mapping
                      </Link>
                    )} */}
                    <div className="submit-area" style={{ marginTop: 15 }}>
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                      <button
                        onClick={(event) => {
                          this.closeStatusPopup();
                          event.preventDefault();
                        }}
                        className="btn btn-primary cancel"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              {permList.includes("List Resource Manifests") && (
                <Accordian title="Manifest" persistKey="resource-accordion-manifest">
                  <div className="resource_accordion_wrapper">
                    <div className="manifest_list outer_list table-view">
                      <div className="list_header table-view">
                        <div className="list_item_head">Contract Type</div>
                        <div className="list_item_head">Contract Number</div>
                        <div className="list_item_head">Incident Name</div>
                        <div className="list_item_head">Resource Number</div>
                        <div className="list_item_head">RO</div>
                        <div className="list_item_head">Date Added</div>
                        <div className="list_item_head action_icon" />
                      </div>
                      {manifestsOption}
                      {paginationHtml}
                    </div>
                  </div>
                </Accordian>
              )}
              {/** Accordian for Created RO */}
              {
                <Accordian
                  title="Created Resource Order"
                  persistKey="resource-accordion-created-ro"
                >
                  <div className="resource_accordion_wrapper">
                    <div className="manifest_list outer_list">
                      <div className="list_header table-view">
                        <div className="list_item_head">Name</div>
                        <div className="list_item_head">Created Date</div>
                        <div className="list_item_head">Resources</div>
                        {permList.includes("Resource Order Forms") && (
                          <>
                            <div className="list_item_head list_item_head_trash"></div>
                            <div className="list_item_head list_item_head_trash"></div>
                          </>
                        )}
                      </div>
                      <div className="history_lists outer_list">
                        <div>
                          {this.state.createdROData?.data?.length ? (
                            this.state.createdROData?.data?.map(
                              (item, index) => (
                                <div
                                  className="status_item resource_history"
                                  key={index}
                                >
                                  <div className="list_item ro_table_row">
                                    <a
                                      href="#"
                                      onClick={(event) => {
                                        this.viewCreatedRO(item?.id);
                                        event.preventDefault();
                                      }}
                                    >
                                      {item.project_name || "-"}
                                    </a>
                                  </div>
                                  <div className="list_item ro_table_row">
                                    {formatDate(item.created_at) || "-"}
                                  </div>
                                  <div className="list_item ro_table_row">
                                    {item?.resources
                                      ?.map(
                                        (resource) => resource?.resource_name
                                      )
                                      .join(", ")}
                                  </div>
                                  {permList.includes(
                                    "Resource Order Forms"
                                  ) && (
                                    <>
                                      <div className="list_item list_item_trash">
                                        <a
                                          href="#"
                                          onClick={() =>
                                            this.handleEditRof(item?.id)
                                          }
                                        >
                                          <img
                                            src={
                                              require("../assets/images/edit-black.svg")
                                                .default
                                            }
                                            alt=""
                                          />
                                        </a>
                                      </div>
                                      <div className="list_item list_item_trash">
                                        <a
                                          href="#"
                                          onClick={(event) => {
                                            this.setState({
                                              selectedRO: {
                                                id: item?.id,
                                                type: 1,
                                              },
                                              deleteROModal: true,
                                            });
                                            event.preventDefault();
                                          }}
                                        >
                                          <img
                                            alt=""
                                            src={
                                              require("../assets/images/trash.svg")
                                                .default
                                            }
                                          />
                                        </a>
                                      </div>
                                    </>
                                  )}
                                </div>
                              )
                            )
                          ) : (
                            <div className="status_item resource_history no_ro_data">
                              <p>No Created RO found!</p>
                            </div>
                          )}
                          {this.state.createdROData?.data?.length > 0 && (
                            <div className="fs-pagination-wrapper-outer customised-pagination right-align ro-paginate">
                              <ReactPaginate
                                forcePage={this.state.roCreatedPage}
                                previousLabel={""}
                                nextLabel={""}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={this.state.roCreatedLastPage}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={(data) =>
                                  this.handleROCreatedPageChange(data)
                                }
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordian>
              }

              {/** Accordian for Uploaded RO */}
              {
                <Accordian
                  title="Uploaded Resource Order"
                  persistKey="resource-accordion-uploaded-ro"
                >
                  <div className="resource_accordion_wrapper">
                    <div className="manifest_list outer_list">
                      <div className="list_header table-view">
                        <div className="list_item_head">Name</div>
                        <div className="list_item_head">Created Date</div>
                        <div className="list_item_head">Resources</div>
                        <div className="list_item_head list_item_head_trash"></div>
                        <div className="list_item_head list_item_head_trash"></div>
                      </div>
                      <div className="history_lists outer_list ">
                        <div>
                          {this.state.uploadROData?.data?.length ? (
                            this.state.uploadROData?.data?.map(
                              (item, index) => (
                                <div
                                  className="status_item resource_history"
                                  key={index}
                                >
                                  <div className="list_item ro_table_row">
                                    <a
                                      href={`${config?.s3BucketUrl}${item?.file_hash}`}
                                      rel="noopener noreferrer"
                                      target="_blank"
                                    >
                                      {item.file_name || "-"}
                                    </a>
                                  </div>
                                  <div className="list_item ro_table_row">
                                    {formatDate(item.created_at) || "-"}
                                  </div>
                                  <div className="list_item ro_table_row">
                                    {item?.resources
                                      ?.map(
                                        (resource) => resource.resource_name
                                      )
                                      .join(", ")}
                                  </div>
                                  {permList.includes(
                                    "Resource Order Forms"
                                  ) && (
                                    <>
                                      <div className="list_item list_item_trash">
                                        <a
                                          href="#"
                                          onClick={() =>
                                            this.showEditRoPopup(item)
                                          }
                                        >
                                          <img
                                            src={
                                              require("../assets/images/edit-black.svg")
                                                .default
                                            }
                                            alt=""
                                          />
                                        </a>
                                      </div>
                                      <div className="list_item list_item_trash">
                                        <a
                                          href="#"
                                          onClick={(event) => {
                                            this.setState({
                                              selectedRO: {
                                                id: item?.id,
                                                type: 2,
                                              },
                                              deleteROModal: true,
                                            });
                                            event.preventDefault();
                                          }}
                                        >
                                          <img
                                            alt=""
                                            src={
                                              require("../assets/images/trash.svg")
                                                .default
                                            }
                                          />
                                        </a>
                                      </div>
                                    </>
                                  )}
                                </div>
                              )
                            )
                          ) : (
                            <div className="status_item resource_history no_ro_data">
                              <p>No Uploaded RO found!</p>
                            </div>
                          )}
                          {this.state.uploadROData?.data?.length > 0 && (
                            <div className="fs-pagination-wrapper-outer customised-pagination right-align ro-paginate">
                              <ReactPaginate
                                forcePage={this.state.roUploadPage}
                                previousLabel={""}
                                nextLabel={""}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={this.state.roUploadLastPage}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={(data) =>
                                  this.handleROUploadPageChange(data)
                                }
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordian>
              }

              {
                <Accordian title="History" persistKey="resource-accordion-history">
                  <div className="resource_accordion_wrapper">
                    <div className="history_lists outer_list ">
                      <div className="mobile_hide">
                        <div className="list_header">
                          {/* Status - Type of assignment - Project name - Location - complexity - fuel type - fire size - hotline - dates */}
                          <div className="list_item_head">
                            Type of Assignment
                          </div>
                          <div className="list_item_head">Incident Name</div>
                          <div className="list_item_head">Location</div>
                          <div className="list_item_head">Complexity</div>
                          <div className="list_item_head">Fuel Type</div>
                          <div className="list_item_head">Fire Size</div>
                          <div className="list_item_head">Hotline Shifts</div>
                          <div className="list_item_head">Date On</div>
                          <div className="list_item_head">Date Off</div>
                          {/* <div className="list_item_head">Notes</div> */}
                          <div className="list_item_head list_item_head_trash"></div>
                          <div className="list_item_head list_item_head_trash"></div>
                          <div className="list_item_head list_item_head_trash"></div>
                        </div>
                        <div className="history_items">
                          {this.state.assigmentStatusHistory?.data?.length >
                          0 ? (
                            this.state.assigmentStatusHistory.data.map(
                              (item, index) => (
                                <div
                                  className="status_item resource_history"
                                  key={index}
                                >
                                  <div className="list_item">
                                    {item.incident_projects
                                      ? item.incident_projects.assignment_type
                                      : "-"}
                                  </div>
                                  <div className="list_item">
                                    {item.incident_projects
                                      ? item.incident_projects.name
                                      : "-"}
                                  </div>
                                  <div className="list_item">
                                    {item.incident_projects &&
                                    item.incident_projects.location
                                      ? item.incident_projects.location
                                      : "-"}
                                  </div>
                                  <div className="list_item">
                                    {item.incident_projects &&
                                    item.incident_projects.complexity
                                      ? item.incident_projects.complexity
                                      : "-"}
                                  </div>
                                  <div className="list_item">
                                    {item.incident_projects &&
                                    item.incident_projects.fuel_type
                                      ? item.incident_projects.fuel_type
                                      : "-"}
                                  </div>
                                  <div className="list_item">
                                    {item.incident_projects &&
                                    item.incident_projects.fire_size
                                      ? item.incident_projects.fire_size
                                      : "-"}
                                  </div>
                                  <div className="list_item">
                                    {item.incident_projects &&
                                    item.incident_projects.hotline_shifts
                                      ? item.incident_projects.hotline_shifts
                                      : "-"}
                                  </div>
                                  <div className="list_item">
                                    {item.incident_projects
                                      ? formatDate(
                                          item.incident_projects.date_on
                                        )
                                      : item.unavailable_start_date
                                      ? formatDate(item.unavailable_start_date)
                                      : "-"}
                                  </div>
                                  <div className="list_item">
                                    {item.incident_projects
                                      ? formatDate(
                                          item.incident_projects.date_off
                                        )
                                      : item.unavailable_end_date
                                      ? formatDate(item.unavailable_end_date)
                                      : "-"}
                                  </div>

                                  {/* <div className="list_item note_item">
                                <div className="notes_ellipsis">
                                  {item.notes}
                                </div>
                                <div className="note_full">{item.notes}</div>
                              </div> */}
                                  <div className="list_item list_item_trash">
                                    <Link
                                      to={`/employee-fire-history/${item.id}`}
                                    >
                                      <img
                                        style={{
                                          display:
                                            item.status != 3 ? "none" : "",
                                        }}
                                        src={
                                          require("../assets/images/person-black.svg")
                                            .default
                                        }
                                        alt="Person Icon"
                                      />
                                    </Link>
                                  </div>

                                  {permList.includes(
                                    "Edit Assignment History"
                                  ) && (
                                    <>
                                      <div className="list_item list_item_trash">
                                        <a
                                          href="#"
                                          onClick={() => {
                                            this.editResourceHistory(item);
                                          }}
                                        >
                                          <img
                                            src={
                                              require("../assets/images/edit-black.svg")
                                                .default
                                            }
                                          />
                                        </a>
                                      </div>
                                      <div className="list_item list_item_trash">
                                        <a
                                          href="#"
                                          onClick={(event) => {
                                            this.deleteHistoryOpenModal(
                                              item.id
                                            );
                                            event.preventDefault();
                                          }}
                                        >
                                          <img
                                            alt=""
                                            src={
                                              require("../assets/images/trash.svg")
                                                .default
                                            }
                                          />
                                        </a>
                                      </div>
                                    </>
                                  )}
                                </div>
                              )
                            )
                          ) : (
                            <div className="status_item resource_history">
                              No data to display.
                            </div>
                          )}
                        </div>
                        {paginationHistoryHtml}
                      </div>
                    </div>
                    {this.state.assigmentStatusHistory.data.map(
                      (item, index) => (
                        <React.Fragment key={index}>
                          <hr></hr>
                          <div className="for_mobile_history_list">
                            <div className="history_items">
                              <div className="status_item">
                                <div className="list_item">
                                  <strong>Type of Assignment</strong>
                                </div>
                                <div className="list_item">
                                  {item.incident_projects
                                    ? item.incident_projects.assignment_type
                                    : "-"}
                                </div>
                              </div>
                              <div className="status_item">
                                <div className="list_item">
                                  <strong>Incident Name</strong>
                                </div>
                                <div className="list_item">
                                  {item.incident_projects
                                    ? item.incident_projects.name
                                    : "-"}
                                </div>
                              </div>
                              <div className="status_item">
                                <div className="list_item">
                                  <strong>Location</strong>
                                </div>
                                <div className="list_item">
                                  {item.incident_projects &&
                                  item.incident_projects.location
                                    ? item.incident_projects.location
                                    : "-"}
                                </div>
                              </div>
                              <div className="status_item">
                                <div className="list_item">
                                  <strong>Complexity</strong>
                                </div>
                                <div className="list_item">
                                  {item.incident_projects &&
                                  item.incident_projects.complexity
                                    ? item.incident_projects.complexity
                                    : "-"}
                                </div>
                              </div>
                              <div className="status_item">
                                <div className="list_item">
                                  <strong>Fuel Type</strong>
                                </div>
                                <div className="list_item">
                                  {item.incident_projects &&
                                  item.incident_projects.fuel_type
                                    ? item.incident_projects.fuel_type
                                    : "-"}
                                </div>
                              </div>
                              <div className="status_item">
                                <div className="list_item">
                                  <strong>Fire Size</strong>
                                </div>
                                <div className="list_item">
                                  {item.incident_projects &&
                                  item.incident_projects.fire_size
                                    ? item.incident_projects.fire_size
                                    : "-"}
                                </div>
                              </div>
                              <div className="status_item">
                                <div className="list_item">
                                  <strong>Hotline Shifts</strong>
                                </div>
                                <div className="list_item">
                                  {item.incident_projects &&
                                  item.incident_projects.hotline_shifts
                                    ? item.incident_projects.hotline_shifts
                                    : "-"}
                                </div>
                              </div>
                              <div className="status_item">
                                <div className="list_item">
                                  <strong>Date On</strong>
                                </div>
                                <div className="list_item">
                                  {item.incident_projects
                                    ? formatDate(item.incident_projects.date_on)
                                    : item.unavailable_start_date
                                    ? formatDate(item.unavailable_start_date)
                                    : "-"}
                                </div>
                              </div>
                              <div className="status_item">
                                <div className="list_item">
                                  <strong>Date Off</strong>
                                </div>
                                <div className="list_item">
                                  {item.incident_projects
                                    ? formatDate(
                                        item.incident_projects.date_off
                                      )
                                    : item.unavailable_end_date
                                    ? formatDate(item.unavailable_end_date)
                                    : "-"}
                                </div>
                              </div>
                              <div className="status_item">
                                <div className="list_item">
                                  <div className="list_item list_item_trash">
                                    <Link
                                      to={`/employee-fire-history/${item.id}`}
                                    >
                                      <img
                                        style={{
                                          display:
                                            item.status != 3 ? "none" : "",
                                        }}
                                        src={
                                          require("../assets/images/person-black.svg")
                                            .default
                                        }
                                        alt="Person Icon"
                                      />
                                    </Link>
                                  </div>
                                </div>

                                {permList.includes(
                                  "Edit Assignment History"
                                ) && (
                                  <>
                                    <div className="list_item">
                                      <div className="list_item list_item_trash">
                                        <a
                                          href="#"
                                          onClick={() => {
                                            this.editResourceHistory(item);
                                          }}
                                        >
                                          <img
                                            src={
                                              require("../assets/images/edit-black.svg")
                                                .default
                                            }
                                          />
                                        </a>
                                      </div>
                                    </div>
                                    <div className="list_item">
                                      <div className="list_item list_item_trash">
                                        <a
                                          href="#"
                                          onClick={(event) => {
                                            this.deleteHistoryOpenModal(
                                              item.id
                                            );
                                            event.preventDefault();
                                          }}
                                        >
                                          <img
                                            alt=""
                                            src={
                                              require("../assets/images/trash.svg")
                                                .default
                                            }
                                          />
                                        </a>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>

                              {/* <div className="status_item">
                            <div className="list_item">
                              <strong>Notes</strong>
                            </div>
                            <div className="list_item">{item.notes}</div>
                          </div> */}
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    )}
                    <div className="for_mobile_history_list">
                      {paginationHistoryHtml}
                    </div>
                  </div>
                </Accordian>
              }
            </React.Fragment>

            {permList.includes("Inventory") && (
              <Accordian title="Inventory" persistKey="resource-accordion-inventory">
                <div className="resource_accordion_wrapper">
                  <div className="employee_list outer_list">
                    <div className="list_header resource-view">
                      <div className="list_item_head image-holder">
                        Inventory
                      </div>
                      <div className="list_item_head">Item Name</div>
                      <div className="list_item_head">Cost</div>
                      <div className="list_item_head">Assigned To</div>
                    </div>
                    {inventoryList.map((item, index) => {
                      return (
                        <div className="empployeeItem" key={index}>
                          <div className="emp_link_resource crew_wrapper resource_details bg-fill">
                            <div className="employee_item ">
                              <div className="list_item image-holder">
                                <span className="pos_counter">
                                  {(inventoryPage - 1) * inventoryPerPage +
                                    index +
                                    1}
                                </span>
                                <img
                                  alt=""
                                  className={`${
                                    item.user && item.user.hash
                                      ? "Available"
                                      : ""
                                  }`}
                                  src={
                                    item.pic
                                      ? config.s3BucketUrl + "/" + item.pic
                                      : config.apiUrl + "/" + config.altImg
                                  }
                                  onError={this.onImageError.bind(this)}
                                />
                              </div>
                              <div className="list_item item_width">
                                <Link
                                  className="link"
                                  to={`/inventory/inventory-view/${item.id}`}
                                >
                                  {item.unique_id}
                                </Link>
                              </div>
                              <div className="list_item item_width">
                                {item.cost || "--"}
                              </div>
                              <div className="list_item item_width">
                                {item.user ? (
                                  <Link
                                    className="link"
                                    to={`/resource-profile/${item.user.hash}/profile`}
                                  >
                                    {item.user.first_name +
                                      " " +
                                      item.user.last_name}
                                  </Link>
                                ) : (
                                  "--"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {this.inventoryPagination()}
                  </div>
                </div>
              </Accordian>
            )}

            {/*<div className="goback" onClick={this.goBack.bind(this)}><p>Back</p></div>*/}
            {permList.includes("List Vehicles") && (
              <Accordian title="Vehicles" persistKey="resource-accordion-vehicles">
                <div className="resource_accordion_wrapper">
                  <div className="vehicle_list outer_list">
                    <div className="">
                      <div className="list_header resource-view">
                        <div className="list_item_head">Unique Name</div>
                        <div className="list_item_head">Driver</div>
                        <div className="list_item_head">Driver License</div>
                        <div className="list_item_head appln_date">
                          Vehicle Make/Model
                        </div>
                        <div className="list_item_head">Vehicle License</div>
                      </div>
                      {this.state.vehiclesOption}
                    </div>
                  </div>
                </div>
              </Accordian>
            )}

            {/* Employee List */}
            {parseInt(localStorage.getItem("avail")) === 1 && (
              <Accordian
                title="Employees Available"
                persistKey="resource-accordion-employees-available"
              >
                <div className="resource_accordion_wrapper">
                  <div className="resource_table_wrapper">
                    <EmployeeList
                      {...this.props}
                      className="available"
                      type="available"
                      permList={permList}
                      resourceId={this.state.id}
                      employeeList={availableEmployees}
                      startIndex={1}
                      profile={this.profile.bind(this)}
                      updateList={this.employeeListUpdated}
                    />
                  </div>
                </div>
              </Accordian>
            )}
            {parseInt(localStorage.getItem("avail")) === 1 && (
              <Accordian
                title="Pending Employees"
                persistKey="resource-accordion-employees-pending"
              >
                <div className="resource_accordion_wrapper">
                  <div className="resource_table_wrapper">
                    <EmployeeList
                      {...this.props}
                      className="locked"
                      type="guest"
                      permList={permList}
                      resourceId={this.state.id}
                      employeeList={pendingEmployees}
                      startIndex={availableEmployees.length + 1}
                      profile={this.profile.bind(this)}
                      updateList={this.employeeListUpdated}
                    />
                  </div>
                </div>
              </Accordian>
            )}
            {parseInt(localStorage.getItem("avail")) === 1 && (
              <Accordian
                title="Employees Locked"
                persistKey="resource-accordion-employees-locked"
              >
                <div className="resource_accordion_wrapper">
                  <div className="resource_table_wrapper">
                    <EmployeeList
                      {...this.props}
                      className="locked"
                      type="locked"
                      permList={permList}
                      resourceId={this.state.id}
                      employeeList={lockedEmployees}
                      startIndex={
                        pendingEmployees.length + availableEmployees.length + 1
                      }
                      profile={this.profile.bind(this)}
                      updateList={this.employeeListUpdated}
                    />
                  </div>
                </div>
              </Accordian>
            )}
          </div>
        </div>
        <ReactModal
          isOpen={this.state.editROModal}
          onClose={() => this.setState({ editROModal: false })}
        >
          <div id="statusPopup" className="popupModal">
            <div className="modal-head">
              <h4 className="modal-title">Edit Uploaded Resource Order</h4>
            </div>
            <div className="modal-body">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h5>File: {this.state.selectedUploadedROFile?.file_name}</h5>
                <div className="crew_select_card">
                  <label>Choose Resources</label>
                  <AsyncSelect
                    cacheOptions
                    loadOptions={this.handleChooseResources}
                    onChange={(selection) =>
                      this.setState({ selectedResource: selection })
                    }
                    isMulti
                    defaultOptions
                    styles={selectStyles}
                    value={this.state.selectedResource}
                  />
                </div>
                <div className="resource_order_form_delete_modal_action">
                  <button
                    className="button grey"
                    onClick={() => this.setState({ editROModal: false })}
                  >
                    Cancel
                  </button>
                  <button
                    name="save"
                    className={`button ${
                      !this.state.selectedResource?.length ? "grey" : ""
                    }`}
                    onClick={() =>
                      this.handleEditRO(this.state.selectedUploadedROFile)
                    }
                    disabled={
                      !this.state.selectedResource?.length ||
                      this.state.formSubmission
                    }
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={this.state.statusOpen}
          onClose={this.statusCloseModal.bind(this)}
          overlayClassName="deletetPopup deletetPopup-resources"
        >
          {/*<Modal style={"width: 200px"} id="status_popup" className="status_popup" open={this.state.statusOpen} onClose={this.statusCloseModal.bind(this)} little>*/}
          <div id="statusPopup" className="resources-popup">
            <div className="modal-head">
              <h4 className="modal-title">Confirm</h4>
              <img
                alt="close"
                className="closeModal"
                onClick={this.statusCloseModal.bind(this)}
                src={require("../assets/images/close_green.png").default}
              />
            </div>
            <div className="modal-body">
              <div className="res_delete step1">
                <label>Do you really want to archive this resource?</label>
                <br />
                <div className="btn_list">
                  <button
                    className="button"
                    onClick={this.remove.bind(this, true)}
                  >
                    OK
                  </button>
                  <button
                    className="button grey"
                    onClick={this.statusCloseModal.bind(this)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/*</Modal>*/}
        </ReactModal>
        <ReactModal
          isOpen={this.state.deleteOpen}
          onClose={this.deleteCloseModal.bind(this)}
          overlayClassName="deletetPopup"
        >
          {/*<Modal style={"width: 200px"} id="status_popup" className="status_popup" open={this.state.deleteOpen} onClose={this.deleteCloseModal.bind(this)} little>*/}
          <div id="deletePopup">
            <div className="modal-head">
              <h4 className="modal-title">Confirm</h4>
              <img
                alt="close"
                className="closeModal"
                onClick={this.deleteCloseModal.bind(this)}
                src={require("../assets/images/close_green.png").default}
              />
            </div>
            <div className="modal-body">
              <div className="status_step step1">
                <label>
                  Do you really want to remove this manifest? Doing so will
                  remove the assignment history and modify the employees' fire
                  records.
                </label>
                <br />
                <div className="btn_list_popup">
                  <button
                    className="button grey"
                    onClick={this.deleteCloseModal.bind(this)}
                  >
                    Cancel
                  </button>
                  <button
                    className="button"
                    onClick={this.deleteManifest.bind(this)}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={this.state.displayEditAssignmentConfirmPopup}
          onClose={this.closeEditAssignmentConfirmPopup.bind(this)}
          overlayClassName="closeEditAssignmentPopup"
        >
          {/*<Modal style={"width: 200px"} id="status_popup" className="status_popup" open={this.state.deleteOpen} onClose={this.deleteCloseModal.bind(this)} little>*/}
          <div id="deletePopup">
            <div className="modal-head">
              <h4 className="modal-title">
                Confirm Edit of Assignment History
              </h4>
              <img
                alt="close"
                className="closeModal"
                onClick={this.closeEditAssignmentConfirmPopup.bind(this)}
                src={require("../assets/images/close_green.png").default}
              />
            </div>
            <div className="modal-body">
              <div className="status_step step1">
                <label>
                  Please note that any changes to the Date On and Date Off
                  fields will impact the employees' fire history records.
                </label>
                <br />
                <div className="btn_list_popup">
                  <button
                    className="button grey"
                    onClick={this.closeEditAssignmentConfirmPopup.bind(this)}
                  >
                    Cancel
                  </button>
                  <button
                    className="button"
                    onClick={this.editAssignmentConfirmation.bind(this)}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={this.state.deleteHistoryOpen}
          onClose={this.deleteHistoryCloseModal.bind(this)}
          overlayClassName="deletetPopup"
        >
          {/*<Modal style={"width: 200px"} id="status_popup" className="status_popup" open={this.state.deleteOpen} onClose={this.deleteCloseModal.bind(this)} little>*/}
          <div id="deletePopup">
            <div className="modal-head">
              <h4 className="modal-title">Confirm</h4>
              <img
                alt="close"
                className="closeModal"
                onClick={this.deleteHistoryCloseModal.bind(this)}
                src={require("../assets/images/close_green.png").default}
              />
            </div>

            <div className="modal-body">
              <div className="status_step step1">
                <label>
                  Are you sure you want to remove this history? Doing so will
                  affect the employees' fire records.
                </label>
                <br />
                <div className="btn_list_popup">
                  <button
                    className="button grey"
                    onClick={this.deleteHistoryCloseModal.bind(this)}
                  >
                    Cancel
                  </button>
                  <button className="button" onClick={this.deleteHistory}>
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ReactModal>
        {/*</Modal><Modal style={"width: 200px"} id="status_popup" className="status_popup" open={this.state.emailOpen} onClose={this.emailCloseModal.bind(this)} little>*/}
        <ReactModal
          isOpen={this.state.emailOpen}
          onClose={this.emailCloseModal.bind(this)}
          contentLabel="Minimal Modal Example"
          overlayClassName="emailManifestPopup"
        >
          <div id="statusPopup" className="popupModal">
            <div className="modal-head">
              <h4 className="modal-title">Email Manifest</h4>
              {/* <img className="closeModal" onClick={this.statusCloseModal.bind(this)} src={require("../assets/images/close_green.png")}/>  */}
              <img
                alt="close"
                className="closeModal"
                onClick={this.emailCloseModal.bind(this)}
                src={require("../assets/images/close_green.png").default}
                onError={this.onImageError.bind(this)}
              />
            </div>
            <div className="modal-body">
              <div className="mail_div">
                <div className="left">
                  <input
                    type="text"
                    className="txtSearchManiEmployees"
                    id="txtSearchManiEmployees"
                    name="Emailkeyword"
                    value={this.state.mailSearchKeyword}
                    placeholder="Search and add employees for sending emails....."
                    onChange={this.emailChangeFn.bind(this)}
                    autoComplete="off"
                  />
                  {this.state.validManifestMail &&
                    this.state.searchList.length === 0 && (
                      <button
                        className="button add_mail"
                        onClick={this.addManifestEmail}
                      >
                        Add Email
                      </button>
                    )}
                  <ul className={"searchResultUl " + this.state.hideSearch}>
                    {this.state.searchList}
                  </ul>
                </div>
                {/* <div className="right">
                  {this.state.textarea && (
                    <div>
                      <textarea
                        id="multiple_emails"
                        name="to"
                        rows="3"
                        cols="10"
                        placeholder="Enter email-id and use comma for multiple emails..."
                        value={this.state.to}
                        onChange={this.handleChange.bind(this)}
                      />
                      <button
                        className="button send_mail"
                        onClick={this.emailManifest.bind(this)}
                      >
                        Send
                      </button>
                    </div>
                  )}

                  <button className="button grey" onClick={this.emailCloseModal.bind(this)}>Cancel</button>
                </div> */}
                <div className="clearFix" />
                <div className="mail-maifest-wrapper">
                  <div className="mail-manifest wrapper">
                    <div className="head">
                      <div className="emp">Employee</div>
                      <div className="name">Name</div>
                      <div className="email">Email</div>
                      {/* <div className="send-mail" /> */}
                      <div className="close" />
                    </div>
                    {/* Dispatch */}
                    {this.state.mailListDefault.map((item, index) => {
                      return (
                        <div key={index} className="employee employee_item">
                          <div className="pic image-holder">
                            <img
                              src={config.apiUrl + "/" + config.altImg}
                              onError={this.onImageError.bind(this)}
                            />
                          </div>
                          <div className="name">
                            <span className="">Dispatch Email</span>
                          </div>
                          <div className="email">{item}</div>
                          <div
                            className="close-btn"
                            onClick={this.removeDispatchList.bind(this, index)}
                          >
                            <img
                              alt="close"
                              src={
                                require("../assets/images/close_button.png")
                                  .default
                              }
                              onError={this.onImageError.bind(this)}
                            />
                          </div>
                        </div>
                      );
                    })}
                    {/* From List */}
                    {this.state.mailList.map((item, index) => {
                      return (
                        <div className="employee employee_item" key={index}>
                          <div className="pic image-holder">
                            <img
                              alt={item.first_name + " " + item.last_name}
                              className={item.active}
                              src={
                                item.profile_photo
                                  ? config.apiUrl + "/" + item.profile_photo
                                  : config.apiUrl + "/" + config.altImg
                              }
                              onError={this.onImageError.bind(this)}
                            />
                          </div>
                          <div className="name">
                            <span className="">
                              <span
                                className={item.colorcode + " color_code"}
                              />
                              {item.first_name + " " + item.last_name}
                            </span>
                          </div>
                          <div className="email">{item.email}</div>
                          {/* <div
                            className="send-button sendMail_btn"
                            onClick={this.sendMail.bind(this, item.email)}
                          >
                            Send Mail
                          </div> */}
                          <div
                            className="close-btn"
                            onClick={this.removeList.bind(this, index)}
                          >
                            <img
                              alt="close"
                              src={
                                require("../assets/images/close_button.png")
                                  .default
                              }
                              onError={this.onImageError.bind(this)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {/* {!this.state.textarea && <div>{this.state.mailList}</div>} */}
                {/* <p className="sig_message">This email will automatically be sent to the Dispatch email as well.</p> */}
                <br />
                <button
                  className="button send_mail"
                  onClick={this.emailManifest.bind(this)}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
          {/*</Modal>*/}
        </ReactModal>

        <ReactModal
          isOpen={this.state.viewOpen}
          onClose={this.viewCloseModal.bind(this)}
          contentLabel="Minimal Modal Example"
          overlayClassName="viewManifestPopup"
        >
          <div id="statusPopup" className="popupModal">
            <div className="modal-head">
              <h4 className="modal-title">View Manifest</h4>
              <img
                alt="close"
                className="closeModal"
                onClick={this.viewCloseModal.bind(this)}
                src={require("../assets/images/close_green.png").default}
                onError={this.onImageError.bind(this)}
              />
            </div>
            <div id="viewPdf">
              <Document file={obj}>
                <Page pageNumber={pageNumber} width={800} />
              </Document>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={this.state.deleteROModal}
          onClose={() => this.setState({ deleteROModal: false })}
        >
          <div id="statusPopup" className="popupModal">
            <div className="modal-head">
              <h4 className="modal-title">Confirm Action</h4>
            </div>
            <div className="modal-body">
              <p>
                {
                  "Deleting RO will remove all the details from corresponding manifests, are you sure want to continue?"
                }
              </p>
              <div className="resource_order_form_delete_modal_action">
                <button
                  className="button grey"
                  onClick={() => this.setState({ deleteROModal: false })}
                >
                  Cancel
                </button>
                <button
                  name="save"
                  className="button"
                  onClick={() => this.handleDeleteRof(this.state.selectedRO)}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
        {this.state.alertPop === true && (
          <AlertPopup
            head={this.state.alertHead}
            message={this.state.alertMsg}
            alertFunction={this.state.alertFunction}
            close={this.closeAlert.bind(this)}
          />
        )}
        <Modal
          isOpen={this?.state?.enableAssignEmployeeModal}
          className="SignatureModal fire_history_assign_modal"
          contentLabel="Example Modal"
        >
          <section>
            <div className="modal-header">
              <p className="sign_close">Action Required.</p>
              <div
                className="close-btn"
                onClick={() => this.handleAutoAssignModalClose()}
              >
                <span>&times;</span>
              </div>
            </div>
            <hr />
            <div style={{ padding: "0px 15px" }}>
              <RadioButton
                checked={
                  this?.state?.typeSlug ===
                  FIRE_HISTORY_TYPE_SLUG?.AUTO_ASSIGN_MANIFEST
                }
                value={FIRE_HISTORY_TYPE_SLUG?.AUTO_ASSIGN_MANIFEST}
                onChange={(e) => this.setState({ typeSlug: e?.target?.value })}
                label={"Auto Assign Employees"}
              />
              <RadioButton
                checked={
                  this?.state?.typeSlug ===
                  FIRE_HISTORY_TYPE_SLUG?.MANUAL_ASSIGN_MANIFEST
                }
                value={FIRE_HISTORY_TYPE_SLUG?.MANUAL_ASSIGN_MANIFEST}
                onChange={(e) => this.setState({ typeSlug: e?.target?.value })}
                label={"Assign employees Manually"}
              />
              {this?.state?.typeSlug ===
                FIRE_HISTORY_TYPE_SLUG?.MANUAL_ASSIGN_MANIFEST && (
                <div>
                  <FireHistoryEmployeeAssignDragAndDrop
                    resourceId={this.state.id}
                    ref={this.sortYourCrewRef}
                  />
                </div>
              )}
            </div>
            <div>
              <div
                style={{ display: "flex", justifyContent: "end", padding: 20 }}
              >
                <button
                  onClick={(event) => {
                    this.handleAutoAssignModalClose();
                    event.preventDefault();
                  }}
                  className="btn  cancel"
                  style={{
                    marginRight: 5,
                    backgroundColor: "#f0f0f0",
                    color: "#202020",
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="button green"
                  onClick={(e) => this.handleFireHistoryAssignModal(e)}
                >
                  Submit
                </button>
              </div>
            </div>
          </section>
        </Modal>
        <ReactModal
          isOpen={this.state.emailFireCardsOpen}
          onClose={() => this.setState({ emailFireCardsOpen: false })}
        >
          <div id="statusPopup" className="popupModal">
            <div className="modal-head ro-email-modal-head">
              <h4 className="modal-title">Email Fire Cards</h4>
            </div>
            <div className="modal-body ro-modal-body">
              <CreatableAsyncSelect
                setSelectedEmployees={this.handleSelectEmployees}
                selectedEmployees={this.state.selectedEmployees}
              />
              {!!this.state.selectedEmployees.length && (
                <div className="mail-maifest-wrapper">
                  <div className="mail-manifest wrapper">
                    <div className="head">
                      <div
                        className="name ro-email-modal-name"
                        style={{ fontWeight: "bold" }}
                      >
                        Name
                      </div>
                      <div className="email" style={{ fontWeight: "bold" }}>
                        Email
                      </div>
                    </div>
                    {this.state.selectedEmployees?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="employee employee_item selected-employee"
                        >
                          <div className="pic image-holder ro-profile-pic-wrapper">
                            {item.profilePic ? (
                              <img
                                src={config.apiUrl + "/" + item.profilePic}
                                onError={(e) =>
                                  (e.target.src = `${
                                    config.apiUrl + "/" + config.altImg
                                  }`)
                                }
                                alt="prof-pic"
                              />
                            ) : item.name ? (
                              <img
                                src={config.apiUrl + "/" + config.altImg}
                                alt="prof-pic"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="name">
                            <span className="">{item.name}</span>
                          </div>
                          <div className="email">{item.email}</div>
                          <div className="close-btn">
                            <img
                              alt="close"
                              src={
                                require("../assets/images/close_button.png")
                                  .default
                              }
                              onClick={() =>
                                this.onClickRemoveEmployeeFromMailList(item)
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              <div className="resource_order_form_delete_modal_action">
                <button
                  className="button grey"
                  onClick={this.emailFireCardsCloseModal}
                >
                  Cancel
                </button>
                <button
                  name="save"
                  className="button"
                  onClick={this.emailFireCards}
                  disabled={!this.state.selectedEmployees.length}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    vehicles,
    employee,
    manifest,
    email,
    resource,
    permission,
    singleresource,
    assignmentStats,
    inventory,
    user,
  } = state;
  const { print } = state.manifest;
  return {
    vehicles,
    employee,
    manifest,
    email,
    resource,
    print,
    permission,
    singleresource,
    assignmentStats,
    user,
    inventory,
  };
}

const connectedResourcePage = connect(mapStateToProps)(ResourceDetails);
export { connectedResourcePage as ResourceDetails };
