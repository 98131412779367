/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import { config } from "_helpers";
import { chatActions, docsActions, userActions } from "_actions";
import {
  CandidateInfoPage,
  EmployeeAgreement,
  EmployeeAgreement_GISS,
  MPN,
  PaycheckDistribution,
  DisabilityStatus,
  CoveredVeteran,
  WageRateAgreement,
  WageRateAgreement_GISS,
  F8850,
  DisclosureStatement,
  RightOfWorkers,
  JobDescription,
  MerchandiseOrder,
  PayDates,
  I9,
  W4,
  EmployeeRehireForm,
  AuthorizationForDirectDeposit,
  DCRHandbook,
  FWFSEmployeeHandbook,
  MealPeriodInformationAndWaiver,
  OnDutyMealPeriodAgreement,
} from "./Forms";
import { SidebarAdmin } from "./Components";
import { Pdf } from "./Components";
import BeneficiaryForm from "./Forms/BeneficiaryForm";
import I9Rehire from "./Forms/I9-rehire";
import { SidebarUserMobile } from 'HomePage/Docs/SidebarUserMobile';
import GeneralConsentAndReleaseDoc from "./Forms/GeneralConsentAndReleaseDoc";
import { ArbitrationAgreement } from "./Forms/ArbitrationAgreement";
import { filter } from "lodash";
import { docStatusConstants, documentTypes } from "_constants";
import { toast } from "react-toastify";

class Doc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rehire: this.props.rehire || false,
      category_id: null,
      giss: false,
      lastPage: "merchandise-order-form",
      loading: true,
      currentForm: null,
      selectedDoc: null,
      currentSlug: "",
      edit: false,
      doc: {},
      pdf: {},
      adminMode: false,
      user: null,
      mobile: {
        screen: 'sidebar',
        enabled: false,
        closeConfirmationModal: false,
      },
    };
    this.getPdf = this.getPdf.bind(this);
    this.getForm = this.getForm.bind(this);
    this.toggleEditForm = this.toggleEditForm.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.redirectToDocs = this.redirectToDocs.bind(this);
    this.toggleCloseConfirmation = this.toggleCloseConfirmation.bind(this);
    this.setScreen = this.setScreen.bind(this);
    this.setMobileDetails = this.setMobileDetails.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.resizeTimeOut = null;
    this.handleResize();
  }

  // Get Active Form
  getPdf() {
    let lastPage = false;
    let docSlug = this.props.match.params.doc;
    const allowedCategories = this.state.rehire 
      ? [documentTypes.Rehire, documentTypes.GISS_Rehire] 
      : [documentTypes.Onboarding, documentTypes.GISS_Onboarding];

    const selectedDoc = this.props.docs.userDocs.find(
      (userDoc) => userDoc.slug === docSlug && allowedCategories.includes(userDoc.docs_categories_id)
    );

    if (!selectedDoc) {
      return <Redirect to={{ pathname: `/dashboard` }} />;
    }

    let sampleUid = this.props.user.profile ? this.props.user.profile.data.hash : "";

    let params = {
      id: selectedDoc.id,
      uid: sampleUid, // the user who logged in
      signature: this.props.chat.settings.signature,
    };
    // setting up url for pdf
    let url = new URL(`${config.apiUrl}/docs/pdf/${selectedDoc.slug}`);
    Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));

    this.state.rehire && selectedDoc.slug === this.state.lastPage && (lastPage = true); // for rehire
    !this.state.rehire && selectedDoc.slug === this.state.lastPage && (lastPage = true); // for onboarding
    
    let types = this.props.docs.userDocs || [];

    // Filter based on rehire state
    let filteredDocs = types.filter(type =>
      (!this.state.rehire && (type.docs_categories_id == 1 || type.docs_categories_id == 4)) ||
      (this.state.rehire && (type.docs_categories_id == 2 || type.docs_categories_id == 5))
    );

    // Get the slug of the last document in the filtered list
    let lastPageSlug = filteredDocs.length > 0 ? filteredDocs[filteredDocs.length - 1].slug : null;
    if(selectedDoc.slug === lastPageSlug)lastPage = true
    
    return (
      <Pdf
        url={url.href}
        lastPage={lastPage}
        rehire={this.state.rehire}
        giss={this.state.giss}
      />
    );
  }

  // toggle form and pdf
  toggleEditForm() {
    this.setState({ edit: !this.state.edit });
  }

  resetForm() {
    this.setState({ edit: false });
  }

  // Find Form based on slug provided
  getForm() {
    let docSlug = this.props.match.params.doc;

    const allowedCategories = this.state.rehire 
      ? [documentTypes.Rehire, documentTypes.GISS_Rehire] 
      : [documentTypes.Onboarding, documentTypes.GISS_Onboarding];

    const selectedDoc = this.props.docs.userDocs.find(
      (userDoc) => userDoc.slug === docSlug && allowedCategories.includes(userDoc.docs_categories_id)
    );

    if (
      (this.props?.user?.profile?.data?.is_admin === 1 ||
        this.props?.user?.profile?.data?.is_admin === 2) &&
      selectedDoc?.status === 2 &&
      this.props?.docs?.activeForm?.user?.id ===
        this.props?.user?.profile?.data?.id
    ) {
      return this.getPdf();
    }

    const isApproved = selectedDoc?.status === 3

    if(isApproved){
      return this.getPdf();
    }

  if (selectedDoc){
    if (selectedDoc.version != selectedDoc.docs_type.latest_version) {
      this.resetForm();
      if (selectedDoc.status == docStatusConstants.STARTED_FILLING) {
        toast.error("This form is outdated. Please contact the support team to receive the latest forms.");
      }
    }

    let { slug, id } = selectedDoc;
    let { adminMode } = this.state;

    const commonProps = {
      id : id,
      adminMode : adminMode,
      resetForm : this.resetForm,
      rehire : this.state.rehire,
      setScreen : this.setScreen,
      toggleCloseConfirmation : this.toggleCloseConfirmation,
      toggle : this.toggleEditForm
    }

    switch (slug) { 
      case "employee-information-page":
        return (
          <CandidateInfoPage
            giss={this.state.giss}
            {...commonProps}
          />
        );
        case "general-consent-and-release":
        return (
          <GeneralConsentAndReleaseDoc
            giss={this.state.giss}
            doc={selectedDoc}
            docs={this.props.docs}
            {...commonProps}
          />
        );
        case "beneficiary-form":
        return (
          <BeneficiaryForm
            giss={this.state.giss}
            doc={selectedDoc}
            docs={this.props.docs}
            {...commonProps}
          />
        );
      case "employee-agreement":
        return (
          <EmployeeAgreement
            giss={this.state.giss}
            {...commonProps}
          />
        );
      case "giss-employee-agreement":
        return (
          <EmployeeAgreement_GISS
            giss={this.state.giss}
            {...commonProps}
          />
        );
      case "meal-period-information-and-waiver":
        return (
          <MealPeriodInformationAndWaiver
            {...commonProps}
          />
        );
      case "arbitration-agreement":
        return (
          <ArbitrationAgreement
            giss={this.state.giss}
            {...commonProps}
          />
        )
      case "on-duty-meal-period-agreement":
        return (
          <OnDutyMealPeriodAgreement
          {...commonProps}
          />
        );
      case "mpn":
        return (
          <MPN
            giss={this.state.giss}
            {...commonProps}
          />
        );
      case "dcr-employee-handbook-acknowledge-of-receipt":
        return (
          <DCRHandbook
            giss={this.state.giss}
            {...commonProps}
          />
        );
      case "fwfs-employee-handbook-acknowledgement-of-receipt":
        return (
          <FWFSEmployeeHandbook
            giss={this.state.giss}
            {...commonProps}
          />
        );
      case "paycheck-distribution-form":
        return (
          <PaycheckDistribution
            giss={this.state.giss}
            {...commonProps}
          />
        );
      case "covered-veteran":
        return (
          <CoveredVeteran
            giss={this.state.giss}
            {...commonProps}
          />
        );
      case "disability-status":
        return (
          <DisabilityStatus
            giss={this.state.giss}
            {...commonProps}
          />
        );
      case "8850":
        return (
          <F8850
            giss={this.state.giss}
            {...commonProps}
          />
        );
      case "wage-rate-agreement":
        return (
          <WageRateAgreement
            giss={this.state.giss}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
            {...commonProps}
          />
        );
      case "giss-wage-rate-agreement":
        return (
          <WageRateAgreement_GISS
            giss={this.state.giss}
            {...commonProps}
          />
        );
      case "disclosure-statement-work-agreement":
        return (
          <DisclosureStatement
            giss={this.state.giss}
            {...commonProps}
          />
        );
      case "rights-of-workers":
        return (
          <RightOfWorkers
            giss={this.state.giss}
            {...commonProps}
          />
        );
      case "job-description":
        return (
          <JobDescription
            giss={this.state.giss}
            {...commonProps}
          />
        );
      case "pay-dates":
        return (
          <PayDates
            giss={this.state.giss}
            {...commonProps}
          />
        );
      case "i-9":
        return (
          <I9
            giss={this.state.giss}
            {...commonProps}
          />
        );
      case "employee-re-hire-form":
        return (
          <EmployeeRehireForm
            giss={this.state.giss}
            {...commonProps}
          />
        );
        case "i-9-rehire":
          return (
            <I9Rehire
              giss={this.state.giss}
              doc={selectedDoc}
              docs={this.props.docs}
              {...commonProps}
            />
          );
      case "authorization-for-direct-deposit":
        return (
          <AuthorizationForDirectDeposit
            giss={this.state.giss}
            {...commonProps}
          />
        );
      case "w-4":
        return (
          <W4
            giss={this.state.giss}
            lastPage={this.state.lastPage === "w-4"}
            {...commonProps}
          />
        );
      case "merchandise-order-form":
        return (
          <MerchandiseOrder
            giss={this.state.giss}
            lastPage={this.state.lastPage === "merchandise-order-form"}
            {...commonProps}
          />
        );
      default:
        return <Redirect to={{ pathname: `/docs` }} />;
    }
    }
    else {
      return <Redirect to={{ pathname: `/docs` }} />;
    }
  }

  setScreen(screen) {
    this.setMobileDetails({ screen });
  }

  setMobileDetails(obj) {
    this.setState({mobile:{...this.state.mobile, ...obj}})
  }

  handleResize() {
    clearTimeout(this.resizeTimeOut);
    this.resizeTimeOut = setTimeout(() => {
      if (isMobileOnly && window.screen.width < 768) {
        this.setState({ mobile: { ...this.state.mobile, enabled: true } });
      } else {
        this.setState({ mobile: { ...this.state.mobile, enabled: false } });
      }
    }, 100);
  }
  
  closeConfirmation(goBack) {
    if (goBack) {
      this.setMobileDetails({ screen: "sidebar", closeConfirmationModal: false })
    } else {
      this.setMobileDetails({ closeConfirmationModal:false })
    }
  }

  toggleCloseConfirmation(flag) {
    this.setMobileDetails({closeConfirmationModal:flag})
  }

  componentWillUnmount() {
    document.body.classList.remove("document-active");
    window.removeEventListener("resize", this.handleResize);
  }


  // Get Doc types
  getUserDocs(hash) {
    this.props.dispatch(docsActions.getUserDocs(hash)).then((res) => {
      this.setState({ loading: false });
    });
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search);
    const rehire = urlParams.get("rehire");
    const hash = `?hash=${this.props.match.params.hash}`;
    const { permission } = this.props;
    this.handleResize()
    window.addEventListener("resize", this.handleResize);

    if (permission.permissions) {
      if (permission.permissions.data && permission.permissions.data.includes("Manage Docs")) {
        this.setState({
          adminMode: true,
        });
      }
    }

    this.props.dispatch(docsActions.getFormOptions());
    this.props
      .dispatch(userActions.getProfile(hash)) // hash should be provide as ?hash=value
      .then((res) => {
        // getting user docs with user hash passed from DocsList component as params (aka: parent)
        // to get signature
        this.setState({ user: res.data, giss: (res.data.user_type_id === 11 || res.data.user_type_id === 12) });
        this.props.dispatch(chatActions.getConversationSettings()).then((res) => {
          this.getUserDocs(this.props.match.params.hash);
        });
      });
  }

  componentWillReceiveProps(nextProps) {
    let category_id,
      user = { user_type_id: null },
      slug = "merchandise-order-form";

    nextProps.docs &&
      nextProps.docs.activeForm &&
      nextProps.docs.activeForm.user &&
      (user = nextProps.docs.activeForm.user);

    user && (user.user_type_id === 11 || user.user_type_id === 12)
      ? nextProps.rehire
        ? (category_id = 5)
        : (category_id = 4)
      : nextProps.rehire
      ? (category_id = 2)
      : (category_id = 1);

    nextProps.docs &&
      nextProps.docs.userDocs.forEach((doc) => {
        doc.docs_categories_id == category_id && (slug = doc.slug);
      });

    this.setState({ lastPage: slug, rehire: nextProps.rehire, category_id: category_id });
  }

  componentDidUpdate(prevProps) {
    let { mobile } = this.state;
    const { permission } = this.props;

    if (mobile.enabled && mobile.screen === "document") {
      document.body.classList.add("document-active");
    } else {
      document.body.classList.remove("document-active");
    }
    if (permission.permissions != prevProps.permission.permissions) {
      if (permission.permissions.data && permission.permissions.data.includes("Manage Docs")) {
        this.setState({
          adminMode: true,
        });
      }
    }
  }

  redirectToDocs =(hash)=>{
    let { search } = this.props.location;
    if (search){
      this.props.history.push(`/docs/${hash}/${String(search) === "?rehire=true" ? "employee-re-hire-form" : "employee-information-page"}${search}`);
    } else {
      this.props.history.push(`/docs/${hash}/employee-information-page?rehire=false`);
    }
    return;
  }

  render() {
    let { loading, edit } = this.state;

    let { hash, doc } = this.props.match.params;    

    // if not doc slug is provided
    if (hash && !doc) {
      this.redirectToDocs(hash);
    }
    return (
      !loading && (
        <section className='d-flex w-100'>
          {isMobileOnly && window.screen.width < 930 ? (
            <div className="doc_info">
              {this.state.mobile.screen === 'sidebar' && (
                <SidebarUserMobile
                  rehire={this.state.rehire}
                  giss={this.state.giss}
                  setScreen={this.setScreen}
                  toggle={this.toggleEditForm}
                />
              )}
             {/* DOCUMENT */}
            {this.state.mobile.screen === "document" &&
                (edit ? this.getForm() : this.getPdf())
                }
                </div>
          ) : (
            <div className='doc_info'>
              <SidebarAdmin
                active={this.props.match.params.doc}
                toggle={this.toggleEditForm}
                resetForm={this.resetForm}
                giss={this.state.giss}
                rehire={this.state.rehire}
              />
              {this.state.user && (edit ? this.getForm() : this.getPdf())}
            </div>
          )}
        </section>
      )
    );
  }
}

function mapStateToProps(state) {
  const { chat, docs, permission, user } = state;
  return { chat, docs, permission, user };
}

let connectedDocs = connect(mapStateToProps, null, null, { forwardRef: true })(Doc);
export { connectedDocs as Doc };
