import { authHeader, config } from "_helpers";

const changeSmsNotifyStatus = (data, type, id) => {
  const requestOptions = {
    headers: authHeader(),
    method: "POST",
    body: data,
  };

  return fetch(
    `${config.apiUrl}/api/${type}/${id}/sms/notify`,
    requestOptions
  ).then(handleResponse, handleError);
};

const fetchProviderNumber = () => {
  const requestOptions = {
    headers: authHeader(),
    method: "GET",
  };

  return fetch(
    `${config.apiUrl}/api/sms/provider`,
    requestOptions
  ).then(handleResponse, handleError);
};

export const smsService = {
  changeSmsNotifyStatus,
  fetchProviderNumber
};

const handleResponse = (response) => {
  if (response.status === 401) {
    localStorage.removeItem("user");
    window.location.href = "/login";
  } else {
    return new Promise((resolve, reject) => {
      if (response.ok) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          response.json().then(resolve);
        } else {
          resolve();
        }
      } else {
        response.text().then(reject);
      }
    });
  }
};

const handleError = (error) => Promise.reject(error?.message);
