import React, { useState, useEffect, Fragment } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import moment from "moment";
import { getCurrentUserInfo } from "_helpers/reduxSelector";

import { config } from "_helpers";
import { interviewService } from "_services";
import { interviewActions } from "_actions";
import { NoInterviewForm } from "./NoInterviewForm";

import styles from "./interview.module.scss";

const TABLE_HEADER = [
  {
    title: "Name of Interviewer",
    className: "nameClass",
    sortValue: "interviewer"
  },
  {
    title: "Status",
    className: "statusClass",
    sortValue: "status"
  },
  {
    title: "Date of Interview",
    className: "intDateClass",
    sortValue: "date_of_interview"
  },
  {
    title: "Date of Submission",
    className: "subDateClass",
    sortValue: "date_of_submission"
  },
  {
    title: "",
    className: "",
    sortValue: ""
  }
];

const ASC_CLASS = "sort-arrow asc";

const DESC_CLASS = "sort-arrow desc";

const initialStyleState = {
  nameClass: ASC_CLASS,
  statusClass: ASC_CLASS,
  intDateClass: ASC_CLASS,
  subDateClass: ASC_CLASS
};

const DATE_FORMAT = "MM/DD/YYYY";

export const InterviewFormList = ({
  getInterviewFormList,
  lastPage,
  interviewFormList = [],
  setCurrentView,
  setFormId,
  ...props
}) => {
  const dispatch = useDispatch();
  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("");
  const [{ nameClass, statusClass, intDateClass, subDateClass }, setStyleState] = useState(initialStyleState);
  const [page, setPage] = useState(0);
  const user = useSelector((state) => getCurrentUserInfo(state));

  useEffect(() => {
    const sortOrder = order ? "asc" : "desc";
    if (sortItem) {
      getInterviewFormList(page, sortItem, sortOrder);
    }
  }, [sortItem, order]);

  const handlePageChange = (newPage) => {
    const sortOrder = order ? "asc" : "desc";
    getInterviewFormList(newPage?.selected + 1, sortItem, sortOrder);
    setPage(newPage?.selected);
  };

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
  };

  const getClassName = (className) => {
    switch (className) {
      case "nameClass":
        return nameClass;
      case "statusClass":
        return statusClass;
      case "intDateClass":
        return intDateClass;
      case "subDateClass":
        return subDateClass;
      default:
        break;
    }
  };

  const handleFormEdit = (e, id) => {
    e.preventDefault();
    dispatch(interviewActions.getInterviewForm(id));
    setFormId(id);
    setCurrentView("referenceForm");
  };

  const handlePDFDownload = (e, id) => {
    e.preventDefault();
    interviewService.generateInterviewPdf(id).then((response) => redirectToPdf(response));
  };

  const redirectToPdf = (data) => {
    if (data?.success) {
      const pdfLink = data.data;
      var link = document.createElement("a");
      if (window.navigator.userAgent.match(/iPad/i) || window.navigator.userAgent.match(/iPhone/i)) {
        link.href = pdfLink;
      } else {
        link.href = pdfLink;
        link.target = "_blank";
      }
      document.body.appendChild(link);
      link.click();
      setTimeout(function () {
        document.body.removeChild(link);
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(pdfLink);
      }, 100);
    }
  };

  return (
    <>
      {interviewFormList.length ? (
        <div className={styles.int_list_container}>
          <div className={styles.int_list_tbl}>
            <table className={cx("mobile_hide", styles.int_list_tbl)}>
              <thead>
                <tr>
                  {TABLE_HEADER.map(({ title, className, sortValue }, i) => (
                    <th onClick={() => sortList(className, getClassName(className), sortValue)} key={i}>
                      <div className={styles.header_txt}>
                        <span>{title}</span>
                        <span className={getClassName(className)} />
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {interviewFormList?.map((list, i) => (
                  <tr key={i}>
                    <td>{`${list?.first_name} ${list.last_name}` ?? "-"}</td>
                    <td>
                      {list?.status === 1
                        ? "Submitted"
                        : list?.status === 2
                        ? Number(user?.id) === Number(list?.interviewer)
                          ? "Saved as draft"
                          : "In Progress"
                        : "_"}
                    </td>
                    <td>{list?.date_of_interview ? moment(list?.date_of_interview).format(DATE_FORMAT) : "-"}</td>
                    <td>{list?.date_of_submission ? moment(list?.date_of_submission).format(DATE_FORMAT) : "-"}</td>
                    <td>
                      <div className={styles.icon_container}>
                        {list?.status === 1 && (
                          <>
                            {/* <div className="item_cell">
                              <a
                                href={`${config.baseUrl}docs/pdf/${props.slug}?id=${
                                  props.id
                                }&uid=${""}&signature=${""}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  alt="view"
                                  src={require("../assets/images/view.png").default}
                                  className={styles.icons_style}
                                />
                              </a>
                            </div> */}
                            <div className="item_cell">
                              <a href="/" onClick={(e) => handlePDFDownload(e, list?.id)}>
                                <img src={`${config.assetUrl}icons/doc-admin-download.svg`} alt="bell" />
                              </a>
                            </div>{" "}
                          </>
                        )}
                        {list?.status === 2 && Number(user?.id) === Number(list?.interviewer) && (
                          <div className="item_cell">
                            <a href="/" onClick={(e) => handleFormEdit(e, list?.id)}>
                              <img
                                alt="edit"
                                src={require("../assets/images/edit_form.svg").default}
                                className={styles.icons_style}
                              />
                            </a>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={5} className={styles.pagination_row}>
                    <div className="fs-pagination-wrapper-outer" key={7}>
                      <div className="customised-pagination right-align">
                        <ReactPaginate
                          forcePage={page}
                          previousLabel={""}
                          nextLabel={""}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={lastPage}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={(data) => handlePageChange(data)}
                          containerClassName={cx("pagination", styles.pagination)}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <InterviewFormListMobile
              data={interviewFormList}
              getInterviewFormList={getInterviewFormList}
              handlePDFDownload={handlePDFDownload}
              handleFormEdit={handleFormEdit}
            />
          </div>
        </div>
      ) : (
        <NoInterviewForm />
      )}
    </>
  );
};

const InterviewFormListMobile = ({ data, getInterviewFormList, handlePDFDownload,handleFormEdit }) => {
  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("unique_id");
  const [{ nameClass, statusClass, intDateClass, subDateClass }, setStyleState] = useState(initialStyleState);

  useEffect(() => {
    const sortOrder = order ? "asc" : "desc";
    if (sortItem) {
      getInterviewFormList(0, sortItem, sortOrder);
    }
  }, [sortItem, order]);

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
  };

  return data.map((item, index) => (
    <Fragment key={index}>
      <div className="for_mobile_history_list">
        <hr></hr>
        <div className="history_items">
          <div className="status_item">
            <div className="list_item" onClick={() => sortList("nameClass", nameClass, "unique_id")}>
              <strong>Name of Interviewer</strong>
              <span className={nameClass} />
            </div>
            <div className="list_item">{item?.interviewer ?? "-"}</div>
          </div>

          <div className="status_item">
            <div className="list_item" onClick={() => sortList("statusClass", statusClass, "unique_id")}>
              <strong>Status</strong>
              <span className={statusClass} />
            </div>
            <div className="list_item">
              {item?.status === 1 ? "Submitted" : item?.status === 2 ? "Saved as draft" : "_"}
            </div>
          </div>

          <div className="status_item">
            <div className="list_item" onClick={() => sortList("intDateClass", intDateClass, "unique_id")}>
              <strong>Date of Interview</strong>
              <span className={intDateClass} />
            </div>
            <div className="list_item">
              {item?.date_of_interview ? moment(item?.date_of_interview).format(DATE_FORMAT) : "-"}
            </div>
          </div>

          <div className="status_item">
            <div className="list_item" onClick={() => sortList("subDateClass", subDateClass, "unique_id")}>
              <strong>Date of Submission</strong>
              <span className={subDateClass} />
            </div>
            <div className="list_item">
              {item?.date_of_interview ? moment(item?.date_of_interview).format(DATE_FORMAT) : "-"}
            </div>
          </div>
          { (
            <div className="status_item">
              <div className="list_item">
                {item?.status === 1 ? (
                  <div className="item_cell">
                    <a href="/" onClick={(e) => handlePDFDownload(e, item?.id)}>
                      <img src={`${config.assetUrl}icons/doc-admin-download.svg`} alt="bell" />
                    </a>
                  </div>
                ) : (
                  <div className="item_cell">
                    <a href="/" onClick={(e) => handleFormEdit(e, item?.id)}>
                      <img src={require("../assets/images/edit_form.svg").default} alt="bell" />
                    </a>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  ));
};
