/* eslint-disable eqeqeq */
// Libraries
import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import moment from "moment";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Modal from "react-responsive-modal";

// User Components
import { config } from "_helpers/config";
import { docsActions } from "_actions/docs.actions";
import { usPhone } from "_utils/formatter";
import { SignPad } from "Docs/Components";
import { FormNav } from "./FormNav";
import { navigate, validate } from "./formHandlers";
import checkIfDocsShouldBeLocked from "_helpers/lockDocs";
import { getCurrentUserInfo } from "_helpers/reduxSelector";


const KLEEN_KANTEEN_PRICE = {
  "20 oz": 32,
  "32 oz": 36
}

const BLACKHAT_INITIAL={
  name: "",
  price: 8,
  qty: 0,
  bo: "",
}

class MerchandiseOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rehire_doc: false,
      isListAOpen: false,
      isListBOpen: false,
      pages: [1],
      page: 1,
      user: {
        mailing_address: {
          country: "",
        },
      },
      form: {
        employee_printed_name: "",
        date: moment().format("MM/DD/YYYY"),
        employee_signature: "",
        employee_signature_date: moment().format("MM/DD/YYYY"),
        superintendent_signature: "",
        superintendent_signature_date: moment().format("MM/DD/YYYY"),
        phone: "",
        deliver_to: "chico",
        tshirt: {
          gender: "Male", // will be disabled
          price: 1,
          size: "",
          qty: 0,
          bo: "",
        },
        long_sleeve_tshirt: {
          price: 1,
          size: "",
          qty: 0,
          bo: "",
        },
        sweater: {
          gender: "Male", // will be disabled
          price: 1,
          size: "",
          qty: 0,
          bo: "",
        },
        crew_neck_sweater: {
          price: 1,
          size: "",
          qty: 0,
          bo: "",
        },
        tactical_belt: {
          price: 1,
          size: "",
          qty: 0,
          bo: "",
        },
        bh_rappeling: {
          price: 1,
          size: "",
          qty: 0,
          bo: "",
        },
        beanie: {
          cuff: "with cuff",
          price: 1,
          qty: 0,
          bo: "",
        },
        fs_trucker_hat: {
          price: 1,
          qty: 0,
          bo: "",
        },
        fs_blackwool_hat: {
          price: 1,
          size: "",
          qty: 0,
          bo: "",
        },
        blackhat: BLACKHAT_INITIAL,
        forest_ranger_quart: {
          price: 1,
          qty: 0,
          bo: "",
        },
        // headlamp: {
        //   price: 1,
        //   qty: 0,
        //   bo: "",
        // },
        // tent: {
        //   price: 1,
        //   qty: 0,
        //   bo: "",
        // },
        cabellas: {
          price: 1,
          qty: 0,
          bo: "",
        },
        kleen_kanteen:{
          price: KLEEN_KANTEEN_PRICE["20 oz"],
          size: "",
          qty: 0,
          bo: "",
        },
        item_total: 0,
        paid: "payroll",
        ck_number: "000000",
        filled: "",
        back_order: "",
        monograms: "",
        rappelling_belt: "",
        pg_bag: "",
        filled_delivered: "",
        back_order_ordered: "",
        monograms_ordered: "",
        rappelling_belt_ordered: "",
        pg_bag_ordered: "",
        filled_posted: "",
        back_order_filled: "",
        monogram_filled: "",
        rappelling_belt_filled: "",
        pg_bag_filled: "",
        filled_prior: "",
        back_order_posted: "",
        monogram_posted: "",
        rappelling_belt_posted: "",
        pg_bag_posted: "",
        filled_upto: "",
        back_order_upto: "",
        monogram_upto: "",
        rappelling_belt_upto: "",
        pg_bag_upto: "",
        disclaimer_check: false,
        skip_confirmation: false,
      },
      errors: {},
      form_edited: false,
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.validate = validate.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleSignature = this.handleSignature.bind(this);
    this.navigate = navigate.bind(this);
    this.navigatePage = this.navigatePage.bind(this);
    this.toggleList = this.toggleList.bind(this);
    this.skipHandler = this.skipHandler.bind(this);
    this.multiply = this.multiply.bind(this);
    this.computeTotal = this.computeTotal.bind(this);
    this.addonCalculation = this.addonCalculation.bind(this);
    this.goBack = this.goBack.bind(this);
    this.handleOnWheel = this.handleOnWheel.bind(this);
    // Refs
    this.form = React.createRef();
    this.signContainer = React.createRef();
  }

  // Properties
  items = [
    "tshirt",
    "long_sleeve_tshirt",
    "sweater",
    "crew_neck_sweater",
    "tactical_belt",
    "bh_rappeling",
    "beanie",
    "fs_trucker_hat",
    "fs_blackwool_hat",
    "blackhat",
    "forest_ranger_quart",
    "kleen_kanteen"
    // "headlamp",
    // "tent",
    // "cabellas",
  ];

  // Methods
  toggleList(list) {
    switch (list) {
      case "privacy-1":
        this.state.isListAOpen ? this.setState({ isListAOpen: false }) : this.setState({ isListAOpen: true });
        break;
      case "privacy-2":
        this.state.isListBOpen ? this.setState({ isListBOpen: false }) : this.setState({ isListBOpen: true });
        break;
      default:
        this.setState({
          isListOpen: false,
        });
        break;
    }
  }

  showSkipConfirmation() {
    this.setState({
      skip_confirmation: true
    });
  }

  closeSkipModal() {
    this.setState({
      skip_confirmation: false
    });
  }

  handleSignature(field, employee_signature) {
    let nextState = this.state;
    _.set(nextState, field, employee_signature);
    this.setState(
      {
        ...nextState,
        errors: {
          ...this.state.errors,
          [field]: "",
        },
      },
      () => {
        // might need to call some handlers later
      }
    );
  }

  //Handle function to Handle OnWheel
  handleOnWheel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.blur();
  };
  //Ends handleOnWheel

  handleDateChange(field, date) {
    let nextState = this.state;
    if (date !== null) {
      _.set(nextState, field, date.format("MM/DD/YYYY"));

      this.setState({
        ...nextState,
        errors: {
          [field]: "",
        },
      });
    }
  }

  navigatePage(to) {
    let { page } = this.state;

    switch (to) {
      case "next":
        this.setState({ page: page + 1 });
        return;
      case "prev":
        this.setState({ page: page - 1 });
        return;
      default:
        return;
    }
  }

  handleFormChange(e) {
    // safety check for datepicker
    const inputType = e?.target?.type
    const disablePreventDefault = inputType === "radio" || inputType === "checkbox"
    if (!disablePreventDefault){
      e.preventDefault();
    }
    if (e.target && e.target.attributes["field"]) {
      let field = e.target.attributes["field"].value;
      let nextState = this.state;
      let { value, checked, type } = e.target;
      
      if (type == "checkbox") {
        _.set(nextState, field, checked);
      } else {
        _.set(nextState, field, value);
      }

      this.setState({
        ...nextState,
        errors: {
          ...this.state.errors,
          [field]: "",
        },
      });
    }
    !this.state.form_edited && this.setState({ form_edited: true });
  }

  handlePhoneChange(e) {
    e.target.value = usPhone(e.target.value);
  }

  /**
   * merch order form is now not required form, can be skipped
   */
  skipHandler() {
    this.setState({
      skip_confirmation: false
    });
    let { doc } = this.state;

    let data = {
      id: doc.id,
      doc_type: doc.docs_type_id,
      skippedPages: [1, 2],
    };

    if (this.props.adminMode) {
      data.admin_mode = this.props.adminMode;
    }
    this.state.rehire_doc && (data.rehire = true);
    this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        if (this.props.mobileMode) {
          this.props.setScreen("sidebar");
        } else {
          if (this.props.lastPage) {
            if (this.props.adminMode) {
              // this.props.single_doc
              //   ? this.props.tabChange("list") // single_doc
              //   : this.props.history.push(`/docs`);
              // this.props.history.push(`/dashboard`);
              this.props.toggle()
            } else {
              this.props.single_doc
                ? this.props.tabChange("list") // single_doc
                : this.props.history.push(`/dashboard`);
            }
          } else {
            this.navigate("next", this.props.rehire);
          }
        }
      } else {
        toast.error(res.message);
      }
      document.documentElement.style.width = '';
      document.documentElement.style.position = '';
      document.documentElement.style.top = '';
      document.documentElement.style.overflow = '';
    });
  }

  submitForm(e) {
    let single_doc = this.props.single_doc ? true : false;
    e.preventDefault();

    let err = this.validate();

    if (err) return;

    let { form, doc, user, page, pages } = this.state;
    let { adminMode } = this.props;

    for (let key in form) {
      this.items.includes(key) && form[key].qty === "" && (form[key].qty = 0);
    }

    if (adminMode && !single_doc && pages && page < pages.length) {
      // single_doc
      this.navigatePage("next");
      return;
    }

    let data = {
      id: doc.id,
      doc_type: doc.docs_type_id,
      form,
      single_doc,
    };

    // Check if blackwool is empty
    const isBlackwoolEmpty = form && form.fs_blackwool_hat && form.fs_blackwool_hat.qty < 1
    if (isBlackwoolEmpty) {
      form.blackhat = BLACKHAT_INITIAL
    }


    if (this.props.adminMode) {
      data.admin_mode = this.props.adminMode;
    }
    this.props.single_doc ? (data.single_doc = true) : (data.single_doc = false);
    this.props.rehire ? (data.rehire = true) : (data.rehire = false);

    // API CALL
    this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        // this.navigate("next");
        if (this.props.mobileMode && !this.props.lastPage) {
          this.props.setScreen("sidebar");
          this.navigate("next", this.props.rehire);
        } else if (this.props.lastPage) {
          if (adminMode) {
            this.props.single_doc
              ? this.props.tabChange("list") // single_doc
              // : this.props.history.push(`/docs`);
                  :this.props.history.push(`/profile/${this?.state?.doc?.hash}`);
          } else {
            this.props.single_doc
              ? this.props.tabChange("list") // single_doc
              : this.props.history.push(`/dashboard`);
          }
        } else {
          this.navigate("next", this.props.rehire);
        }
      } else {
        if (this.props.mobileMode) this.props.toggleErrorPopup(res.message);
        else toast.error(res.message);
      }
    });
  }

  multiply(quantity, price, addon_flag = false, value, possibilities, addon) {
    const decimalPlaces = 2;
    quantity = Number(Math.round(parseFloat(quantity + "e" + decimalPlaces)) + "e-" + decimalPlaces).toFixed(
      decimalPlaces
    );
    price = Number(Math.round(parseFloat(price + "e" + decimalPlaces)) + "e-" + decimalPlaces).toFixed(decimalPlaces);

    let product = Number(Math.round(parseFloat(quantity * price + "e" + decimalPlaces)) + "e-" + decimalPlaces).toFixed(
      decimalPlaces
    );

    isNaN(product) && (product = parseFloat(0).toFixed(decimalPlaces));

    if (addon_flag) {
      !possibilities.includes(value) && (addon = 0);
      addon = parseFloat(addon * quantity);
      product = Number(
        Math.round(parseFloat(parseFloat(addon) + parseFloat(product) + "e" + decimalPlaces)) + "e-" + decimalPlaces
      ).toFixed(decimalPlaces);
    }

    isNaN(product) && (product = parseFloat(0).toFixed(decimalPlaces));

    return product;
  }

  addonCalculation(value, possibilities, addon) {
    const decimalPlaces = 2;
    !possibilities.includes(value) && (addon = 0);
    addon = Number(Math.round(parseFloat(addon + "e" + decimalPlaces)) + "e-" + decimalPlaces).toFixed(decimalPlaces);
    return addon;
  }

  computeTotal() {
    const decimalPlaces = 2;
    let item_total = 0;
    let addon = 0;
    // 
    this.items.forEach((item) => {
      if (
        (item === "tshirt" || item === "long_sleeve_tshirt") &&
        (this.state.form[item].size === "xxl" || this.state.form[item].size === "xxxl")
      ) {
        addon = Number(
          Math.round(parseFloat(parseFloat(addon) + parseFloat(this.state.form[item].qty * 2) + "e" + decimalPlaces)) +
            "e-" +
            decimalPlaces
        ).toFixed(decimalPlaces);
      } else if (
        (item === "sweater" || item === "crew_neck_sweater") &&
        (this.state.form[item].size === "xxl" || this.state.form[item].size === "xxxl")
      ) {
        addon = Number(
          Math.round(parseFloat(parseFloat(addon) + parseFloat(this.state.form[item].qty * 5) + "e" + decimalPlaces)) +
            "e-" +
            decimalPlaces
        ).toFixed(decimalPlaces);
      }

      // 
      // if (item !== "tshirt") {
      //   item_total = item_total + this.state.form[item].price * this.state.form[item].qty;
      // }

      item_total = Number(
        Math.round(
          parseFloat(
            parseFloat(item_total) +
              parseFloat(this.state.form[item].price * this.state.form[item].qty) +
              "e" +
              decimalPlaces
          )
        ) +
          "e-" +
          decimalPlaces
      ).toFixed(decimalPlaces);
    });

    // return item_total + addon;
    return Number(
      Math.round(parseFloat(parseFloat(item_total) + parseFloat(addon) + "e" + decimalPlaces)) + "e-" + decimalPlaces
    ).toFixed(decimalPlaces);
  }
  // GET FORM data from api with id passed in props
  getFormData(id) {
    this.props.dispatch(docsActions.getForm(id)).then((res) => {
      if (res.success) {
        const currentState = this.state;
        let { user, form } = res.data;   
        // date needs to a moment object or Datepicker breaks.
        form.date = form.date || currentState.form.date;
        form.employee_signature_date = form.employee_signature_date || currentState.form.employee_signature_date;
        form.superintendent_signature_date =
        form.superintendent_signature_date || currentState.form.superintendent_signature_date;
        const defaultGenderRequired = !form.tshirt.gender || !form.sweater.gender;
        if (defaultGenderRequired){
          form.tshirt.gender = user?.gender;
          form.sweater.gender = user?.gender;
          form.long_sleeve_tshirt.gender=user?.gender;
          form.crew_neck_sweater.gender=user?.gender;
        }


        if (!form?.employee_printed_name) {
          form.employee_printed_name = user?.full_name ?? ""
        }
        if (!form?.phone) {
          form.phone = user?.phone ?? ""
        }
        // spread the data coming from API into state
        this.setState({ user, form });
      }
    });
  }

  goBack() {
    // this.props.resetForm();
    if (this.state.form_edited) {
      this.props.toggleCloseConfirmation(true);
    } else {
      this.props.setScreen("sidebar");
    }
  }

  componentDidMount() {
    let docSlug = this.props.slug ? this.props.slug : this.props.match.params.doc;
    let doc = null;

    if (this.props.giss) {
      this.props.single_doc
        ? (doc = this.props.docs.userDocs.find((doc) => doc.slug == docSlug && doc.docs_categories_id === 3)) // other document 3
        : this.props.rehire
        ? (doc = this.props.docs.userDocs.find((doc) => doc.slug === docSlug && doc.docs_categories_id === 5))
        : (doc = this.props.docs.userDocs.find((doc) => doc.slug === docSlug && doc.docs_categories_id === 4));
    } else {
      this.props.single_doc
        ? (doc = this.props.docs.userDocs.find((doc) => doc.slug == docSlug && doc.docs_categories_id === 3)) // other document 3
        : this.props.rehire
        ? (doc = this.props.docs.userDocs.find((doc) => doc.slug === docSlug && doc.docs_categories_id === 2))
        : (doc = this.props.docs.userDocs.find((doc) => doc.slug === docSlug && doc.docs_categories_id === 1));
    }
    // let doc = this.props.docs.userDocs.find((doc) => doc.slug == docSlug);
    this.props.single_doc && doc && (doc.id = this.props.new_doc_id);
    const height = this.signContainer.clientHeight;
    const width = this.signContainer.clientWidth;

    this.setState(
      {
        doc,
        sign: {
          height: height,
          width: width,
        },
      },
      () => {
        //(!this.props.single_doc || this.props.view_doc) &&
        doc && this.getFormData(doc.id);
      } // single_doc
    );
  }

  // Handle Kleen Kanteen Price Change
  handleKleenKanteenOnChange(event) {
    let form = { ...this.state.form };
    form.kleen_kanteen[event.target.name] = event.target.value;
    form.kleen_kanteen.price = KLEEN_KANTEEN_PRICE[event.target.value];
    this.setState({ form })
  }

  render() {
    
    let { user, form, errors, page, pages } = this.state;
    let { adminMode, single_doc, currentUserInfo} = this.props;
    // user.gender && (form.tshirt.gender = user.gender);
    // user.gender && (form.sweater.gender = user.gender);
    let required = {
      tshirtSize: !form.tshirt.size && form.tshirt.qty > 0,
      longSleeveTshirtSize: !form.long_sleeve_tshirt.size && form.long_sleeve_tshirt.qty > 0,
      sweaterSize: !form.sweater.size && form.sweater.qty > 0,
      crewNeckSweaterSize: !form.crew_neck_sweater.size && form.crew_neck_sweater.qty > 0,
      tacticalBeltSize:!form.tactical_belt.size&&form.tactical_belt.qty>0,
      bhRappelingSize:!form.bh_rappeling.size&&form.bh_rappeling.qty>0,
      fsBlackwoolHatSize:!form.fs_blackwool_hat.size&&form.fs_blackwool_hat.qty>0

    }

    const shouldLockDocs = checkIfDocsShouldBeLocked(user, form, adminMode)
    const docBelongsToAdmin = user?.hash === currentUserInfo?.hash

    return (
      <div className="doc_info-rh">
        {/* Title */}
        <div className="doc-title">
          <img src={`${config.assetUrl}icons/doc-title-img.svg`} className="doc-title-img" />
          <span className="close-button" onClick={single_doc ? this.props.viewList : this.goBack}>
            <img src={`${config.assetUrl}icons/back-btn.svg`} />
          </span>
          <h3>Merchandise Order Form &nbsp;&nbsp;<span className='opt_text'>OPTIONAL</span></h3>
        </div>

        {/* static HTML markup */}
        <div className="doc_info-form">
          <form
            noValidate
            className="info-form merchandise"
            ref={this.form}
            onChange={this.handleFormChange}
            onSubmit={this.submitForm}
          >
                                 <fieldset disabled={shouldLockDocs}>

            <div className="row form-row">
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">Employee Name</div>
                  <div className="inputs-items">
                    <input
                      type="text"
                      className={`${errors["form.employee_printed_name"] ? "error" : ""}`}
                      name="employee_printed_name"
                      id="employee_printed_name"
                      value={form.employee_printed_name}
                      field="form.employee_printed_name"
                      placeholder="- Enter here -"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">Date</div>
                  <div className="inputs-items">
                    <DatePicker
                      className={`${errors["form.date"] ? "error" : ""} w-100`}
                      name="date"
                      id="date"
                      required
                      selected={moment(form.date)}
                      field="form.date"
                      placeholderText="mm/dd/yyyy"
                      onChange={this.handleDateChange.bind(this, "form.date")}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="on"
                      disabled={true}
                    />
                    {errors["form.date"] && (
                      <label htmlFor="date" className="error_label">
                        {errors["form.date"]}
                      </label>
                    )}
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-head">Cell Phone</div>
                  <div className="inputs-items">
                    <input
                      type="tel"
                      className={`${errors["form.phone"] ? "error" : ""}`}
                      name="phone"
                      id="phone"
                      value={form.phone}
                      field="form.phone"
                      placeholder="- Enter here -"
                      required
                      minLength="10"
                      onChange={this.handlePhoneChange}
                    />
                    {errors["form.phone"] && (
                      <label htmlFor="phone" className="error_label">
                        {errors["form.phone"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row form-row">
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">Deliver To</div>
                  <div className="check_radio_group deliver_to">
                    <div className="check_radio_item agreement_radio">
                      <input
                        type="radio"
                        name="deliver_to"
                        value="chico"
                        id="deliver_to_chico"
                        className="discl_input"
                        field="form.deliver_to"
                        checked={form.deliver_to === "chico"}
                        required
                      />
                      <span className="radio_checkbox"></span>
                      <label for="deliver_to_chico">Chico</label>
                    </div>
                    <div className="check_radio_item agreement_radio">
                      <input
                        type="radio"
                        name="deliver_to"
                        value="supervisor_pickup"
                        id="deliver_to_supervisor_pickup"
                        className="discl_input"
                        field="form.deliver_to"
                        checked={form.deliver_to === "supervisor_pickup"}
                        required
                      />
                      <span className="radio_checkbox"></span>
                      <label for="deliver_to_supervisor_pickup">Superviser Pick Up</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row form-row show-mob">
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">Items</div>
                </div>
              </div>
            </div>

            {/* Accessories Table */}
            {!this.props.mobileMode && (
              <div className="row form-row no-border pb-2">
                <div className="col">
                  <div className="inputs-col">
                    <table className="inputs_table">
                      <tbody>
                        {/* Heading */}
                        <tr>
                          <td width="41.5%" style={{ "text-align": "left" }}>
                            <b>Item</b>
                          </td>
                          <td width="10.6%" className="price_amt">
                            Price
                          </td>
                          <td width="13.7%">
                            <b>Size</b>
                          </td>
                          <td width="8%">
                            <b>Qty</b>
                          </td>
                          <td width="13.3%">
                            <b>Cost</b>
                          </td>
                          <td width="12.9%">
                            <b>Filled B/O</b>
                          </td>
                        </tr>

                        {/* Tshirt */}
                        <tr>
                          <td>
                            <div className="inputs-head">T Shirt</div>
                            <div className="check_radio_group">
                              <div className="check_radio_item">
                                <input
                                  type="radio"
                                  name="tshirt_gender"
                                  value="Male"
                                  id="tshirt_gender_male"
                                  className="discl_input"
                                  field="form.tshirt.gender"
                                  checked={form.tshirt.gender === "Male"}
                                  // disabled
                                />
                                <span className="radio_checkbox"></span>
                                <label for="M">M</label>
                              </div>
                              <div className="check_radio_item agreement_radio">
                                <input
                                  type="radio"
                                  name="tshirt_gender"
                                  value="Female"
                                  id="tshirt_gender_female"
                                  className="discl_input"
                                  field="form.tshirt.gender"
                                  checked={form.tshirt.gender === "Female"}
                                  // disabled
                                />
                                <span className="radio_checkbox"></span>
                                <label for="f">F</label>
                              </div>
                            </div>

                            <span className="sizes">
                              XS • S • M • L • XL • (<strong>Add’l $2</strong>- XXL • XXXL)
                            </span>
                          </td>
                          <td className="price_amt">${form.tshirt.price}</td>
                          <td>
                            <div className="inputs-items">
                              <select
                                name="tshirt_size"
                                id="tshirt_size"
                                field="form.tshirt.size"
                                value={form.tshirt.size}
                                required={required.tshirtSize}
                                className={`${required.tshirtSize&&errors["form.tshirt.size"] ? "error" : ""}`}
                              >
                                <option value="">Select</option>
                                <option value="xs">XS</option>
                                <option value="s">S</option>
                                <option value="m">M</option>
                                <option value="l">L</option>
                                <option value="xl">XL</option>
                                <option value="xxl">XXL</option>
                                <option value="xxxl">XXXL</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="number"
                                className="qty"
                                name="tshirt_qty"
                                id="tshirt_qty"
                                field="form.tshirt.qty"
                                placeholder="0"
                                value={form.tshirt.qty}
                                min={0}
                                onWheel={this.handleOnWheel}
                                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="tshirt_price"
                                id="tshirt_price"
                                placeholder="0"
                                disabled
                                value={this.multiply(
                                  form.tshirt.qty,
                                  form.tshirt.price,
                                  true,
                                  form.tshirt.size,
                                  ["xxl", "xxxl"],
                                  2
                                )}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="tshirt.bo"
                                id="tshirt.bo"
                                field="form.tshirt.bo"
                                placeholder="B/O"
                                disabled={!adminMode}
                                value={form.tshirt.bo}
                              />
                            </div>
                          </td>
                        </tr>

                        {/* Long Sleeve T-Shirt */}
                        <tr>
                          <td>
                            <div className="inputs-head mb-0">Long Sleeve T-Shirt</div>
                            <div className="check_radio_group">
                              <div className="check_radio_item">
                                <input
                                  type="radio"
                                  name="long_sleeve_tshirt_gender"
                                  value="Male"
                                  id="long_sleeve_tshirt_gender_male"
                                  className="discl_input"
                                  field="form.long_sleeve_tshirt.gender"
                                  checked={form.long_sleeve_tshirt.gender === "Male"}
                                  // disabled
                                />
                                <span className="radio_checkbox"></span>
                                <label for="M">M</label>
                              </div>
                              <div className="check_radio_item agreement_radio">
                                <input
                                  type="radio"
                                  name="long_sleeve_tshirt_gender"
                                  value="Female"
                                  id="long_sleeve_tshirt_gender_female"
                                  className="discl_input"
                                  field="form.long_sleeve_tshirt.gender"
                                  checked={form.long_sleeve_tshirt.gender === "Female"}
                                  // disabled
                                />
                                <span className="radio_checkbox"></span>
                                <label for="f">F</label>
                              </div>
                            </div>
                            <span className="sizes">
                              XS • S • M • L • XL • (<strong>Add’l $2</strong>- XXL • XXXL)
                            </span>
                          </td>
                          <td className="price_amt">${form.long_sleeve_tshirt.price}</td>
                          <td>
                            <div className="inputs-items">
                              <select
                                name="long_sleeve_tshirt_size"
                                id="long_sleeve_tshirt_size"
                                field="form.long_sleeve_tshirt.size"
                                value={form.long_sleeve_tshirt.size}
                                className={`${required.longSleeveTshirtSize&&errors["form.long_sleeve_tshirt.size"] ? "error" : ""}`}
                                required={required.longSleeveTshirtSize}
                              >
                                <option value="">Select</option>
                                <option value="xs">XS</option>
                                <option value="s">S</option>
                                <option value="m">M</option>
                                <option value="l">L</option>
                                <option value="xl">XL</option>
                                <option value="xxl">XXL</option>
                                <option value="xxxl">XXXL</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="number"
                                className="qty"
                                name="long_sleeve_tshirt_qty"
                                id="long_sleeve_tshirt_qty"
                                field="form.long_sleeve_tshirt.qty"
                                placeholder="0"
                                value={form.long_sleeve_tshirt.qty}
                                min={0}
                                onWheel={this.handleOnWheel}
                                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="long_sleeve_tshirt_price"
                                id="long_sleeve_tshirt_price"
                                placeholder="0"
                                disabled
                                value={this.multiply(
                                  form.long_sleeve_tshirt.qty,
                                  form.long_sleeve_tshirt.price,
                                  true,
                                  form.long_sleeve_tshirt.size,
                                  ["xxl", "xxxl"],
                                  2
                                )}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="long_sleeve_tshirt.bo"
                                id="long_sleeve_tshirt.bo"
                                field="form.long_sleeve_tshirt.bo"
                                placeholder="B/O"
                                disabled={!adminMode}
                                value={form.long_sleeve_tshirt.bo}
                              />
                            </div>
                          </td>
                        </tr>

                        {/* ZIP Up Sweater */}
                        <tr>
                          <td>
                            <div className="inputs-head">Zip Up Sweater</div>

                            <div className="check_radio_group">
                              <div className="check_radio_item">
                                <input
                                  type="radio"
                                  name="sweater_gender"
                                  value="Male"
                                  id="sweater_gender_male"
                                  className="discl_input"
                                  field="form.sweater.gender"
                                  checked={form.sweater.gender === "Male"}
                                  // disabled
                                />
                                <span className="radio_checkbox"></span>
                                <label for="M">M</label>
                              </div>
                              <div className="check_radio_item agreement_radio">
                                <input
                                  type="radio"
                                  name="sweater_gender"
                                  value="Female"
                                  id="sweater_gender_female"
                                  className="discl_input"
                                  field="form.sweater.gender"
                                  checked={form.sweater.gender === "Female"}
                                  // disabled
                                />
                                <span className="radio_checkbox"></span>
                                <label for="f">F</label>
                              </div>
                            </div>

                            <span className="sizes">
                              {" "}
                              XS • S • M • L • XL • (<strong>Add’l $5</strong>- XXL • XXXL)
                            </span>
                          </td>
                          <td className="price_amt">${form.sweater.price}</td>
                          <td>
                            <div className="inputs-items">
                              <select
                                name="sweater_shirt_size"
                                id="sweater_shirt_size"
                                field="form.sweater.size"
                                value={form.sweater.size}
                                className={`${required.sweaterSize&&errors["form.sweater.size"] ? "error" : ""}`}
                                required={required.sweaterSize}
                              >
                                <option value="">Select</option>
                                <option value="xs">XS</option>
                                <option value="s">S</option>
                                <option value="m">M</option>
                                <option value="l">L</option>
                                <option value="xl">XL</option>
                                <option value="xxl">XXL</option>
                                <option value="xxxl">XXXL</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="number"
                                className="qty"
                                name="sweater_qty"
                                id="sweater_qty"
                                field="form.sweater.qty"
                                placeholder="0"
                                value={form.sweater.qty}
                                min={0}
                                onWheel={this.handleOnWheel}
                                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="sweater_price"
                                id="sweater_price"
                                placeholder="0"
                                disabled
                                value={this.multiply(
                                  form.sweater.qty,
                                  form.sweater.price,
                                  true,
                                  form.sweater.size,
                                  ["xxl", "xxxl"],
                                  5
                                )}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="sweater.bo"
                                id="sweater.bo"
                                field="form.sweater.bo"
                                placeholder="B/O"
                                disabled={!adminMode}
                                value={form.sweater.bo}
                              />
                            </div>
                          </td>
                        </tr>

                        {/* Crew Neck Sweatshirt */}
                        <tr>
                          <td>
                            <div className="inputs-head mb-0">Crew Neck Sweatshirt</div>
                            <div className="check_radio_group">
                              <div className="check_radio_item">
                                <input
                                  type="radio"
                                  name="crew_neck_sweater_gender"
                                  value="Male"
                                  id="crew_neck_sweater_gender_male"
                                  className="discl_input"
                                  field="form.crew_neck_sweater.gender"
                                  checked={form.crew_neck_sweater.gender === "Male"}
                                  // disabled
                                />
                                <span className="radio_checkbox"></span>
                                <label for="M">M</label>
                              </div>
                              <div className="check_radio_item agreement_radio">
                                <input
                                  type="radio"
                                  name="crew_neck_sweater_gender"
                                  value="Female"
                                  id="crew_neck_sweater_gender_female"
                                  className="discl_input"
                                  field="form.crew_neck_sweater.gender"
                                  checked={form.crew_neck_sweater.gender === "Female"}
                                  // disabled
                                />
                                <span className="radio_checkbox"></span>
                                <label for="f">F</label>
                              </div>
                            </div>
                            <span className="sizes">
                              XS • S • M • L • XL • (<strong>Add’l $5</strong>- XXL • XXXL)
                            </span>
                          </td>
                          <td className="price_amt">${form.crew_neck_sweater.price}</td>
                          <td>
                            <div className="inputs-items">
                              <select
                                name="crew_neck_sweater_size"
                                id="crew_neck_sweater_size"
                                field="form.crew_neck_sweater.size"
                                value={form.crew_neck_sweater.size}
                                className={`${required.crewNeckSweaterSize&&errors["form.crew_neck_sweater.size"] ? "error" : ""}`}
                                required={required.crewNeckSweaterSize}
                              >
                                <option value="">Select</option>
                                <option value="xs">XS</option>
                                <option value="s">S</option>
                                <option value="m">M</option>
                                <option value="l">L</option>
                                <option value="xl">XL</option>
                                <option value="xxl">XXL</option>
                                <option value="xxxl">XXXL</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="number"
                                className="qty"
                                name="crew_neck_sweater_qty"
                                id="crew_neck_sweater_qty"
                                field="form.crew_neck_sweater.qty"
                                placeholder="0"
                                value={form.crew_neck_sweater.qty}
                                min={0}
                                onWheel={this.handleOnWheel}
                                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="crew_neck_sweater_price"
                                id="crew_neck_sweater_price"
                                placeholder="0"
                                disabled
                                value={this.multiply(
                                  form.crew_neck_sweater.qty,
                                  form.crew_neck_sweater.price,
                                  true,
                                  form.crew_neck_sweater.size,
                                  ["xxl", "xxxl"],
                                  5
                                )}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="crew_neck_sweater.bo"
                                id="crew_neck_sweater.bo"
                                field="form.crew_neck_sweater.bo"
                                placeholder="B/O"
                                disabled={!adminMode}
                                value={form.crew_neck_sweater.bo}
                              />
                            </div>
                          </td>
                        </tr>

                        {/* Tactical Belt */}
                        <tr>
                          <td>
                            <div className="inputs-head mb-0">5.11 Tactical Belt</div>

                            <span className="sizes">S 28-30” • M 32-34” • L 36-38” • XL 40-42”</span>
                          </td>
                          <td className="price_amt">${form.tactical_belt.price}</td>
                          <td>
                            <div className="inputs-items">
                              <select
                                name="tactical_belt_size"
                                id="tactical_belt_size"
                                field="form.tactical_belt.size"
                                value={form.tactical_belt.size}
                                className={`${required.tacticalBeltSize&&errors["form.tactical_belt.size"] ? "error" : ""}`}
                                required={required.tacticalBeltSize}
                              >
                                <option value="">Select</option>
                                <option value="s">S 28"-30"</option>
                                <option value="m">M 32"-34"</option>
                                <option value="l">L 36"-38"</option>
                                <option value="xl">XL 40"-42"</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="number"
                                className="qty"
                                name="tactical_belt_qty"
                                id="tactical_belt_qty"
                                field="form.tactical_belt.qty"
                                placeholder="0"
                                value={form.tactical_belt.qty}
                                min={0}
                                onWheel={this.handleOnWheel}
                                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="tactical_belt_price"
                                id="tactical_belt_price"
                                placeholder="0"
                                disabled
                                value={this.multiply(form.tactical_belt.qty, form.tactical_belt.price)}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="tactical_belt.bo"
                                id="tactical_belt.bo"
                                field="form.tactical_belt.bo"
                                placeholder="B/O"
                                disabled={!adminMode}
                                value={form.tactical_belt.bo}
                              />
                            </div>
                          </td>
                        </tr>

                        {/* Black Hawk Rappelling Belt */}
                        <tr>
                          <td>
                            <div className="inputs-head mb-0">BlackHawk Rappelling Belt</div>

                            <span className="sizes">S to 34” • M to 41” • L to 51”</span>
                          </td>
                          <td className="price_amt">${form.bh_rappeling.price}</td>
                          <td>
                            <div className="inputs-items">
                              <select
                                name="bh_rappeling_size"
                                id="bh_rappeling_size"
                                field="form.bh_rappeling.size"
                                value={form.bh_rappeling.size}
                                className={`${required.bhRappelingSize&&errors["form.bh_rappeling.size"] ? "error" : ""}`}
                                required={required.bhRappelingSize}
                              >
                                <option value="">Select</option>
                                <option value="s">S to 34</option>
                                <option value="m">M to 41</option>
                                <option value="l">L to 51</option>
                              </select>
                            </div>
                            {}
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="number"
                                className="qty"
                                name="bh_rappeling_qty"
                                id="bh_rappeling_qty"
                                field="form.bh_rappeling.qty"
                                placeholder="0"
                                value={form.bh_rappeling.qty}
                                min={0}
                                onWheel={this.handleOnWheel}
                                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="bh_rappeling_price"
                                id="bh_rappeling_price"
                                placeholder="0"
                                disabled
                                value={this.multiply(form.bh_rappeling.qty, form.bh_rappeling.price)}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="bh_rappeling.bo"
                                id="bh_rappeling.bo"
                                field="form.bh_rappeling.bo"
                                placeholder="B/O"
                                disabled={!adminMode}
                                value={form.bh_rappeling.bo}
                              />
                            </div>
                          </td>
                        </tr>

                        {/* Beanie */}
                        <tr>
                          <td>
                            <div className="inputs-head">Beanie</div>
                            <div className="check_radio_group">
                              <div className="check_radio_item">
                                <input
                                  type="radio"
                                  name="beanie_cuff"
                                  value="cuffless"
                                  id="beanie_cuffless"
                                  className="discl_input"
                                  field="form.beanie.cuff"
                                  checked={form.beanie.cuff === "cuffless"}
                                />
                                <span className="radio_checkbox"></span>
                                <label for="beanie_cuffless">Cuffless</label>
                              </div>
                              <div className="check_radio_item agreement_radio">
                                <input
                                  type="radio"
                                  name="beanie_cuff"
                                  value="with cuff"
                                  id="beanie_cuff"
                                  className="discl_input"
                                  field="form.beanie.cuff"
                                  checked={form.beanie.cuff === "with cuff"}
                                />
                                <span className="radio_checkbox"></span>
                                <label for="beanie_cuff">With Cuff</label>
                              </div>
                            </div>
                          </td>
                          <td className="price_amt">${form.beanie.price}</td>
                          <td></td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="number"
                                className="qty"
                                name="beanie_qty"
                                id="beanie_qty"
                                field="form.beanie.qty"
                                placeholder="0"
                                value={form.beanie.qty}
                                min={0}
                                onWheel={this.handleOnWheel}
                                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="beanie_price"
                                id="beanie_price"
                                placeholder="0"
                                disabled
                                value={this.multiply(form.beanie.qty, form.beanie.price)}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="beanie.bo"
                                id="beanie.bo"
                                field="form.beanie.bo"
                                placeholder="B/O"
                                disabled={!adminMode}
                                value={form.beanie.bo}
                              />
                            </div>
                          </td>
                        </tr>
                     

                        {/* Firestorm Hat – Mesh Back (Trucker’s Hat) */}
                        <tr>
                          <td>
                            <div className="inputs-head mb-0">Firestorm Hat – Mesh Back (Trucker’s Hat)</div>
                          </td>
                          <td className="price_amt">${form.fs_trucker_hat.price}</td>
                          <td>
                            <div className="inputs-items"></div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="number"
                                className="qty"
                                name="fs_trucker_hat_qty"
                                id="fs_trucker_hat_qty"
                                field="form.fs_trucker_hat.qty"
                                placeholder="0"
                                value={form.fs_trucker_hat.qty}
                                min={0}
                                onWheel={this.handleOnWheel}
                                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="fs_trucker_hat_price"
                                id="fs_trucker_hat_price"
                                placeholder="0"
                                disabled
                                value={this.multiply(form.fs_trucker_hat.qty, form.fs_trucker_hat.price)}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="fs_trucker_hat.bo"
                                id="fs_trucker_hat.bo"
                                field="form.fs_trucker_hat.bo"
                                placeholder="B/O"
                                disabled={!adminMode}
                                value={form.fs_trucker_hat.bo}
                              />
                            </div>
                          </td>
                        </tr>

                        {/* Firestorm Hat – Black Wool */}
                        <tr>
                          <td>
                            <div className="inputs-head">Firestorm Hat – Black Wool</div>
                            <span className="sizes">S/M • L/XL • ADJ.</span>
                          </td>
                          <td className="price_amt">${form.fs_blackwool_hat.price}</td>
                          <td>
                            <div className="inputs-items">
                              <select
                                name="fs_blackwool_hat_size"
                                id="fs_blackwool_hat_size"
                                field="form.fs_blackwool_hat.size"
                                value={form.fs_blackwool_hat.size}
                                className={`${required.fsBlackwoolHatSize&&errors["form.fs_blackwool_hat.size"] ? "error" : ""}`}
                                required={required.fsBlackwoolHatSize}
                              >
                                <option value="">Select</option>
                                <option value="s/m">S/M</option>
                                <option value="l/xl">L/XL</option>
                                <option value="adj">ADJ</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="number"
                                className="qty"
                                name="fs_blackwool_hat_qty"
                                id="fs_blackwool_hat_qty"
                                field="form.fs_blackwool_hat.qty"
                                placeholder="0"
                                value={form.fs_blackwool_hat.qty}
                                min={0}
                                onWheel={this.handleOnWheel}
                                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="fs_blackwool_hat_price"
                                id="fs_blackwool_hat_price"
                                placeholder="0"
                                disabled
                                value={this.multiply(form.fs_blackwool_hat.qty, form.fs_blackwool_hat.price)}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="fs_blackwool_hat.bo"
                                id="fs_blackwool_hat.bo"
                                field="form.fs_blackwool_hat.bo"
                                placeholder="B/O"
                                disabled={!adminMode}
                                value={form.fs_blackwool_hat.bo}
                              />
                            </div>
                          </td>
                        </tr>

                        {/* Personalization (Black hat only) PRINT CLEARLY: */}
                        {form.fs_blackwool_hat.size&&Number(form.fs_blackwool_hat.qty)>0?(
                          <tr>
                          <td>
                            <div className="inputs-head">Personalization (Black hat only) PRINT CLEARLY:</div>

                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="blackhat_name"
                                id="blackhat_name"
                                field="form.blackhat.name"
                                placeholder="Enter here-"
                                value={form.blackhat.name}
                              />
                            </div>
                          </td>
                          <td className="price_amt">${form.blackhat.price}</td>
                          <td>&nbsp;</td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="number"
                                className="qty"
                                name="blackhat_qty"
                                id="blackhat_qty"
                                field="form.blackhat.qty"
                                placeholder="0"
                                value={form.blackhat.qty}
                                min={0}
                                onWheel={this.handleOnWheel}
                                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="blackhat_price"
                                id="blackhat_price"
                                placeholder="0"
                                disabled
                                value={this.multiply(form.blackhat.qty, form.blackhat.price)}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="blackhat.bo"
                                id="blackhat.bo"
                                field="form.blackhat.bo"
                                placeholder="B/O"
                                disabled={!adminMode}
                                value={form.blackhat.bo}
                              />
                            </div>
                          </td>
                        </tr>
                        ):null}
                        

                        {/* Canteen – Forest Ranger 1 Quart */}
                        <tr>
                          <td>
                            <div className="inputs-head mb-0"> Canteen – Forest Ranger 1 Quart</div>
                          </td>
                          <td className="price_amt">${form.forest_ranger_quart.price}</td>
                          <td></td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="number"
                                className="qty"
                                name="forest_ranger_quart_qty"
                                id="forest_ranger_quart_qty"
                                field="form.forest_ranger_quart.qty"
                                placeholder="0"
                                value={form.forest_ranger_quart.qty}
                                min={0}
                                onWheel={this.handleOnWheel}
                                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="forest_ranger_quart_price"
                                id="forest_ranger_quart_price"
                                placeholder="0"
                                disabled
                                value={this.multiply(form.forest_ranger_quart.qty, form.forest_ranger_quart.price)}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="forest_ranger_quart.bo"
                                id="forest_ranger_quart.bo"
                                field="form.forest_ranger_quart.bo"
                                placeholder="B/O"
                                disabled={!adminMode}
                                value={form.forest_ranger_quart.bo}
                              />
                            </div>
                          </td>
                        </tr>

                        {/* Black Diamond Icon Headlamp */}
                        {/* <tr>
                          <td>
                            <div className='inputs-head mb-0'>Black Diamond Icon Headlamp</div>

                            <span className='sizes'>
                              (MSRP $99.95) 4 AA - 175 hours – Waterproof – 3yr Warranty
                            </span>
                          </td>
                          <td className='price_amt'>${form.headlamp.price}</td>
                          <td></td>
                          <td>
                            <div className='inputs-items'>
                              <input
                                type='number'
                                className='qty'
                                name='headlamp_qty'
                                id='headlamp_qty'
                                field='form.headlamp.qty'
                                placeholder='0'
                                value={form.headlamp.qty}
                              />
                            </div>
                          </td>
                          <td>
                            <div className='inputs-items'>
                              <input
                                type='text'
                                className=''
                                name='headlamp_price'
                                id='headlamp_price'
                                placeholder='0'
                                disabled
                                value={this.multiply(form.headlamp.qty, form.headlamp.price)}
                              />
                            </div>
                          </td>
                          <td>
                            <div className='inputs-items'>
                              <input
                                type='text'
                                className=''
                                name='headlamp.bo'
                                id='headlamp.bo'
                                field='form.headlamp.bo'
                                placeholder='B/O'
                                disabled={!adminMode}
                                value={form.headlamp.bo}
                              />
                            </div>
                          </td>
                        </tr> */}

                        {/* “The Falcon” 60Second Tent by MMI Outdoor */}
                        {/* <tr>
                          <td>
                            <div className='inputs-head mb-0'>
                              “The Falcon” 60Second Tent by MMI Outdoor
                            </div>

                            <span className='sizes'>
                              Sleeps: 2-3.Wt: 7.5 lbs Center Height: 52" Floor Area: 7' x 8'
                            </span>
                          </td>
                          <td className='price_amt'>${form.tent.price}</td>
                          <td>&nbsp;</td>
                          <td>
                            <div className='inputs-items'>
                              <input
                                type='number'
                                className='qty'
                                name='tent_qty'
                                id='tent_qty'
                                field='form.tent.qty'
                                placeholder='0'
                                value={form.tent.qty}
                                min={0}
                                onWheel={this.handleOnWheel}
                                onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                              />
                            </div>
                          </td>
                          <td>
                            <div className='inputs-items'>
                              <input
                                type='text'
                                className=''
                                name='tent_price'
                                id='tent_price'
                                placeholder='0'
                                disabled
                                value={this.multiply(form.tent.qty, form.tent.price)}
                              />
                            </div>
                          </td>
                          <td>
                            <div className='inputs-items'>
                              <input
                                type='text'
                                className=''
                                name='tent.bo'
                                id='tent.bo'
                                field='form.tent.bo'
                                placeholder='B/O'
                                disabled={!adminMode}
                                value={form.tent.bo}
                              />
                            </div>
                          </td>
                        </tr> */}

                        {/* Personal Gear Bag - Cabellas */}
                        {/* <tr>
                        <td>
                          <div className="inputs-head mb-0">Personal Gear Bag - Cabellas</div>

                          <span className="sizes">Large Rip Cord Duffle Bag – 96 Liters</span>
                        </td>
                        <td className="price_amt">${form.cabellas.price}</td>
                        <td></td>
                        <td>
                          <div className="inputs-items">
                            <input
                              type="number"
                              className="qty"
                              name="cabellas_qty"
                              id="cabellas_qty"
                              field="form.cabellas.qty"
                              placeholder="0"
                              value={form.cabellas.qty}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="inputs-items">
                            <input
                              type="text"
                              className=""
                              name="cabellas_price"
                              id="cabellas_price"
                              placeholder="0"
                              disabled
                              value={this.multiply(form.cabellas.qty, form.cabellas.price)}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="inputs-items">
                            <input
                              type="text"
                              className=""
                              name="cabellas.bo"
                              id="cabellas.bo"
                              field="form.cabellas.bo"
                              placeholder="B/O"
                              disabled={!adminMode}
                              value={form.cabellas.bo}
                            />
                          </div>
                        </td>
                      </tr> */}

                       {/* Firestorm Kleen Kanteen */}
                       <tr>
                          <td>
                            <div className="inputs-head">Firestorm Kleen Kanteen</div>
                            <div className="check_radio_group">
                              <div className="check_radio_item">
                                <input
                                  type="radio"
                                  name="size"
                                  value="20 oz"
                                  id="kleen_kanteen_20oz"
                                  className="discl_input"
                                  onChange={(event)=>this.handleKleenKanteenOnChange(event)}
                                  checked={!!(form.kleen_kanteen.size === "20 oz")}
                                />
                                <span className="radio_checkbox"></span>
                                <label for="kleen_kanteen_20oz">20 oz</label>
                              </div>
                              <div className="check_radio_item agreement_radio">
                                <input
                                  type="radio"
                                  name="size"
                                  value="32 oz"
                                  id="kleen_kanteen_32oz"
                                  className="discl_input"
                                  onChange={(event)=>this.handleKleenKanteenOnChange(event)}
                                  checked={!!(form.kleen_kanteen.size === "32 oz")}
                                />
                                <span className="radio_checkbox"></span>
                                <label for="kleen_kanteen_32oz">32 oz</label>
                              </div>
                            </div>
                          </td>
                          <td className="price_amt">${form.kleen_kanteen.price}</td>
                          <td></td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="number"
                                className="qty"
                                name="kleen_kanteen_qty"
                                id="kleen_kanteen_qty"
                                field="form.kleen_kanteen.qty"
                                placeholder="0"
                                value={form.kleen_kanteen.qty}
                                min={0}
                                onWheel={this.handleOnWheel}
                                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="kleen_kanteen_price"
                                id="kleen_kanteen_price"
                                placeholder="0"
                                disabled
                                value={this.multiply(form.kleen_kanteen.qty, form.kleen_kanteen.price)}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="kleen_kanteen.bo"
                                id="kleen_kanteen.bo"
                                field="form.kleen_kanteen.bo"
                                placeholder="B/O"
                                disabled={!adminMode}
                                value={form.kleen_kanteen.bo}
                              />
                            </div>
                          </td>
                        </tr>

                        {/* Total */}
                        <tr>
                          <td colspan="4" className="totals-info">
                            <p>
                              Orders must be paid in full before merchandise will be given. Payment should accompany
                              merchandise order. If currently working, payroll deduction may be authorized.
                            </p>
                          </td>
                          <td colspan="2" className="totals-count">
                            <div className="inputs-head">Total</div>

                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="item_total"
                                id="item_total"
                                field="form.item_total"
                                placeholder="Total"
                                value={`$ ${this.computeTotal()}`}
                                disabled
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}

            {/* Table (Mobile) */}
            {this.props.mobileMode && (
              <section>
                <Accordion allowZeroExpanded className="custom-accordion col">
                  {/* TShirt */}
                  <AccordionItem className="accordion-item">
                    {/* Accordion Heading */}
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button">
                        <p>T-Shirt</p>
                        <p>
                          <span>X&nbsp;{form.tshirt.qty}</span>
                          <span>
                            {this.multiply(
                              form.tshirt.qty,
                              form.tshirt.price,
                              true,
                              form.tshirt.size,
                              ["xxl", "xxxl"],
                              2
                            )}
                          </span>
                          <span>
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    {/* Accordion Content */}
                    <AccordionItemPanel className="accordion-content">
                      <div className="content">
                        <section>
                          <p className="inputs-head">Item</p>
                          <p className="inputs-col">
                            <div className="inputs-head">T Shirt</div>
                            <div className="check_radio_group">
                              <div className="check_radio_item">
                                <input
                                  type="radio"
                                  name="tshirt_gender"
                                  value="Male"
                                  id="tshirt_gender_male"
                                  className="discl_input"
                                  field="form.tshirt.gender"
                                  checked={form.tshirt.gender === "Male"}
                                  // disabled
                                />
                                <span className="radio_checkbox"></span>
                                <label for="M">M</label>
                              </div>
                              <div className="check_radio_item agreement_radio">
                                <input
                                  type="radio"
                                  name="tshirt_gender"
                                  value="Female"
                                  id="tshirt_gender_female"
                                  className="discl_input"
                                  field="form.tshirt.gender"
                                  checked={form.tshirt.gender === "Female"}
                                  // disabled
                                />
                                <span className="radio_checkbox"></span>
                                <label for="f">F</label>
                              </div>
                            </div>
                            <span className="sizes">
                              XS • S • M • L • XL • (<strong>Add’l $2</strong>- XXL • XXXL)
                            </span>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Price</p>
                          <p>${form.tshirt.price}</p>
                        </section>
                        <section>
                          <p className="inputs-head">Size</p>
                          <p>
                            <div className="inputs-items">
                              <select
                                className={`${required.tshirtSize&&errors["form.tshirt.size"] ? "error" : ""}`}
                                required={required.tshirtSize}
                                name="tshirt_size"
                                id="tshirt_size"
                                field="form.tshirt.size"
                                value={form.tshirt.size}
                              >
                                <option value="">Select</option>
                                <option value="xs">XS</option>
                                <option value="s">S</option>
                                <option value="m">M</option>
                                <option value="l">L</option>
                                <option value="xl">XL</option>
                                <option value="xxl">XXL</option>
                                <option value="xxxl">XXXL</option>
                              </select>
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Qty</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="number"
                                className="qty"
                                name="tshirt_qty"
                                id="tshirt_qty"
                                field="form.tshirt.qty"
                                placeholder="0"
                                min={0}
                                value={form.tshirt.qty}
                                onWheel={this.handleOnWheel}
                                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                              />
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Cost</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="tshirt_price"
                                id="tshirt_price"
                                placeholder="0"
                                disabled
                                value={this.multiply(
                                  form.tshirt.qty,
                                  form.tshirt.price,
                                  true,
                                  form.tshirt.size,
                                  ["xxl", "xxxl"],
                                  2
                                )}
                              />
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Filled B/O</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="tshirt.bo"
                                id="tshirt.bo"
                                field="form.tshirt.bo"
                                placeholder="B/O"
                                disabled={!adminMode}
                                value={form.tshirt.bo}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>

                  {/* Long Sleeve TShirt */}
                  <AccordionItem className="accordion-item">
                    {/* Accordion Heading */}
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button">
                        <p>Long Sleeve T-Shirt</p>
                        <p>
                          <span>X&nbsp;{form.long_sleeve_tshirt.qty}</span>
                          <span>
                            {this.multiply(
                              form.long_sleeve_tshirt.qty,
                              form.long_sleeve_tshirt.price,
                              true,
                              form.long_sleeve_tshirt.size,
                              ["xxl", "xxxl"],
                              2
                            )}
                          </span>
                          <span>
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    {/* Accordion Content */}
                    <AccordionItemPanel className="accordion-content">
                      <div className="content">
                        <section>
                          <p className="inputs-head">Item</p>
                          <p>
                            <div className="inputs-head mb-0">Long Sleeve T-Shirt</div>

                            <span className="sizes">
                              XS • S • M • L • XL • (<strong>Add’l $2</strong>- XXL • XXXL)
                            </span>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Price</p>
                          <p>${form.long_sleeve_tshirt.price}</p>
                        </section>
                        <section>
                          <p className="inputs-head">Size</p>
                          <p>
                            <div className="inputs-items">
                              <select
                                className={`${required.longSleeveTshirtSize&&errors["form.long_sleeve_tshirt.size"] ? "error" : ""}`}
                                required={required.longSleeveTshirtSize}
                                name="long_sleeve_tshirt_size"
                                id="long_sleeve_tshirt_size"
                                field="form.long_sleeve_tshirt.size"
                                value={form.long_sleeve_tshirt.size}
                              >
                                <option value="">Select</option>
                                <option value="xs">XS</option>
                                <option value="s">S</option>
                                <option value="m">M</option>
                                <option value="l">L</option>
                                <option value="xl">XL</option>
                                <option value="xxl">XXL</option>
                                <option value="xxxl">XXXL</option>
                              </select>
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Qty</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="number"
                                className="qty"
                                name="long_sleeve_tshirt_qty"
                                id="long_sleeve_tshirt_qty"
                                field="form.long_sleeve_tshirt.qty"
                                placeholder="0"
                                value={form.long_sleeve_tshirt.qty}
                                min={0}
                                onWheel={this.handleOnWheel}
                                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                              />
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Cost</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="long_sleeve_tshirt_price"
                                id="long_sleeve_tshirt_price"
                                placeholder="0"
                                disabled
                                value={this.multiply(
                                  form.long_sleeve_tshirt.qty,
                                  form.long_sleeve_tshirt.price,
                                  true,
                                  form.long_sleeve_tshirt.size,
                                  ["xxl", "xxxl"],
                                  2
                                )}
                              />
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Filled B/O</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="long_sleeve_tshirt.bo"
                                id="long_sleeve_tshirt.bo"
                                field="form.long_sleeve_tshirt.bo"
                                placeholder="B/O"
                                disabled={!adminMode}
                                value={form.long_sleeve_tshirt.bo}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>

                  {/* ZIP Up Sweater */}
                  <AccordionItem className="accordion-item">
                    {/* Accordion Heading */}
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button">
                        <p>Zip Up Sweater</p>
                        <p>
                          <span>X&nbsp;{form.sweater.qty}</span>
                          <span>
                            {this.multiply(
                              form.sweater.qty,
                              form.sweater.price,
                              true,
                              form.sweater.size,
                              ["xxl", "xxxl"],
                              5
                            )}
                          </span>
                          <span>
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    {/* Accordion Content */}
                    <AccordionItemPanel className="accordion-content">
                      <div className="content">
                        <section>
                          <p className="inputs-head">Item</p>
                          <p className="inputs-col">
                            <div className="inputs-head">Zip Up Sweater</div>

                            <div className="check_radio_group">
                              <div className="check_radio_item">
                                <input
                                  type="radio"
                                  name="sweater_gender"
                                  value="Male"
                                  id="sweater_gender_male"
                                  className="discl_input"
                                  field="form.sweater.gender"
                                  checked={form.sweater.gender === "Male"}
                                  // disabled
                                />
                                <span className="radio_checkbox"></span>
                                <label for="M">M</label>
                              </div>
                              <div className="check_radio_item agreement_radio">
                                <input
                                  type="radio"
                                  name="sweater_gender"
                                  value="Female"
                                  id="sweater_gender_female"
                                  className="discl_input"
                                  field="form.sweater.gender"
                                  checked={form.sweater.gender === "Female"}
                                  // disabled
                                />
                                <span className="radio_checkbox"></span>
                                <label for="f">F</label>
                              </div>
                            </div>

                            <span className="sizes">
                              {" "}
                              XS • S • M • L • XL • (<strong>Add’l $5</strong>- XXL • XXXL)
                            </span>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Price</p>
                          <p>${form.sweater.price}</p>
                        </section>
                        <section>
                          <p className="inputs-head">Size</p>
                          <p>
                            <div className="inputs-items">
                              <select
                                className={`${required.sweaterSize&&errors["form.sweater.size"] ? "error" : ""}`}
                                required={required.sweaterSize}
                                name="sweater_shirt_size"
                                id="sweater_shirt_size"
                                field="form.sweater.size"
                                value={form.sweater.size}
                              >
                                <option value="">Select</option>
                                <option value="xs">XS</option>
                                <option value="s">S</option>
                                <option value="m">M</option>
                                <option value="l">L</option>
                                <option value="xl">XL</option>
                                <option value="xxl">XXL</option>
                                <option value="xxxl">XXXL</option>
                              </select>
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Qty</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="number"
                                className="qty"
                                name="sweater_qty"
                                id="sweater_qty"
                                field="form.sweater.qty"
                                placeholder="0"
                                value={form.sweater.qty}
                                min={0}
                                onWheel={this.handleOnWheel}
                                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                              />
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Cost</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="sweater_price"
                                id="sweater_price"
                                placeholder="0"
                                disabled
                                value={this.multiply(
                                  form.sweater.qty,
                                  form.sweater.price,
                                  true,
                                  form.sweater.size,
                                  ["xxl", "xxxl"],
                                  5
                                )}
                              />
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Filled B/O</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="sweater.bo"
                                id="sweater.bo"
                                field="form.sweater.bo"
                                placeholder="B/O"
                                disabled={!adminMode}
                                value={form.sweater.bo}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>

                  {/* Crew Neck Sweatshirt */}
                  <AccordionItem className="accordion-item">
                    {/* Accordion Heading */}
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button">
                        <p>Crew Neck Sweatshirt</p>
                        <p>
                          <span>X&nbsp;{form.crew_neck_sweater.qty}</span>
                          <span>
                            {this.multiply(
                              form.crew_neck_sweater.qty,
                              form.crew_neck_sweater.price,
                              true,
                              form.crew_neck_sweater.size,
                              ["xxl", "xxxl"],
                              5
                            )}
                          </span>
                          <span>
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    {/* Accordion Content */}
                    <AccordionItemPanel className="accordion-content">
                      <div className="content">
                        <section>
                          <p className="inputs-head">Item</p>
                          <p>
                            <div className="inputs-head mb-0">Crew Neck Sweatshirt</div>

                            <span className="sizes">
                              XS • S • M • L • XL • (<strong>Add’l $5</strong>- XXL • XXXL)
                            </span>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Price</p>
                          <p>${form.crew_neck_sweater.price}</p>
                        </section>
                        <section>
                          <p className="inputs-head">Size</p>
                          <p>
                            <div className="inputs-items">
                              <select
                                className={`${required.crewNeckSweaterSize&&errors["form.crew_neck_sweater.size"] ? "error" : ""}`}
                                required={required.crewNeckSweaterSize}
                                name="crew_neck_sweater_size"
                                id="crew_neck_sweater_size"
                                field="form.crew_neck_sweater.size"
                                value={form.crew_neck_sweater.size}
                              >
                                <option value="">Select</option>
                                <option value="xs">XS</option>
                                <option value="s">S</option>
                                <option value="m">M</option>
                                <option value="l">L</option>
                                <option value="xl">XL</option>
                                <option value="xxl">XXL</option>
                                <option value="xxxl">XXXL</option>
                              </select>
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Qty</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="number"
                                className="qty"
                                name="crew_neck_sweater_qty"
                                id="crew_neck_sweater_qty"
                                field="form.crew_neck_sweater.qty"
                                placeholder="0"
                                value={form.crew_neck_sweater.qty}
                                min={0}
                                onWheel={this.handleOnWheel}
                                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                              />
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Cost</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="crew_neck_sweater_price"
                                id="crew_neck_sweater_price"
                                placeholder="0"
                                disabled
                                value={this.multiply(
                                  form.crew_neck_sweater.qty,
                                  form.crew_neck_sweater.price,
                                  true,
                                  form.crew_neck_sweater.size,
                                  ["xxl", "xxxl"],
                                  5
                                )}
                              />
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Filled B/O</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="crew_neck_sweater.bo"
                                id="crew_neck_sweater.bo"
                                field="form.crew_neck_sweater.bo"
                                placeholder="B/O"
                                disabled={!adminMode}
                                value={form.crew_neck_sweater.bo}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>

                  {/* Tactical Belt */}
                  <AccordionItem className="accordion-item">
                    {/* Accordion Heading */}
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button">
                        <p>5.11 Tactical Belt</p>
                        <p>
                          <span>X&nbsp;{form.tactical_belt.qty}</span>
                          <span>{this.multiply(form.tactical_belt.qty, form.tactical_belt.price)}</span>
                          <span>
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    {/* Accordion Content */}
                    <AccordionItemPanel className="accordion-content">
                      <div className="content">
                        <section>
                          <p className="inputs-head">Item</p>
                          <p>
                            <div className="inputs-head mb-0">5.11 Tactical Belt</div>
                            <span className="sizes">S 28-30” • M 32-34” • L 36-38” • XL 40-42”</span>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Price</p>
                          <p>${form.tactical_belt.price}</p>
                        </section>
                        <section>
                          <p className="inputs-head">Size</p>
                          <p>
                            <div className="inputs-items">
                              <select
                                className={`${required.tacticalBeltSize&&errors["form.tactical_belt.size"] ? "error" : ""}`}
                                required={required.tacticalBeltSize}
                                name="tactical_belt_size"
                                id="tactical_belt_size"
                                field="form.tactical_belt.size"
                                value={form.tactical_belt.size}
                              >
                                <option value="">Select</option>
                                <option value="s">S 28"-30"</option>
                                <option value="m">M 32"-34"</option>
                                <option value="l">L 36"-38"</option>
                                <option value="xl">XL 40"-42"</option>
                              </select>
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Qty</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="number"
                                className="qty"
                                name="tactical_belt_qty"
                                id="tactical_belt_qty"
                                field="form.tactical_belt.qty"
                                placeholder="0"
                                value={form.tactical_belt.qty}
                                min={0}
                                onWheel={this.handleOnWheel}
                                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                              />
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Cost</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="tactical_belt_price"
                                id="tactical_belt_price"
                                placeholder="0"
                                disabled
                                value={this.multiply(form.tactical_belt.qty, form.tactical_belt.price)}
                              />
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Filled B/O</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="tactical_belt.bo"
                                id="tactical_belt.bo"
                                field="form.tactical_belt.bo"
                                placeholder="B/O"
                                disabled={!adminMode}
                                value={form.tactical_belt.bo}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>

                  {/* Black Hawk Rappelling Belt */}
                  <AccordionItem className="accordion-item">
                    {/* Accordion Heading */}
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button">
                        <p>BlackHawk Rappelling Belt</p>
                        <p>
                          <span>X&nbsp;{form.bh_rappeling.qty}</span>
                          <span>{this.multiply(form.bh_rappeling.qty, form.bh_rappeling.price)}</span>
                          <span>
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    {/* Accordion Content */}
                    <AccordionItemPanel className="accordion-content">
                      <div className="content">
                        <section>
                          <p className="inputs-head">Item</p>
                          <p>
                            <div className="inputs-head mb-0">BlackHawk Rappelling Belt</div>
                            <span className="sizes">S to 34” • M to 41” • L to 51”</span>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Price</p>
                          <p>${form.bh_rappeling.price}</p>
                        </section>
                        <section>
                          <p className="inputs-head">Size</p>
                          <p>
                            <div className="inputs-items">
                              <select
                                className={`${required.bhRappelingSize&&errors["form.bh_rappeling.size"] ? "error" : ""}`}
                                required={required.bhRappelingSize}
                                name="bh_rappeling_size"
                                id="bh_rappeling_size"
                                field="form.bh_rappeling.size"
                                value={form.bh_rappeling.size}
                              >
                                <option value="">Select</option>
                                <option value="s">S to 34</option>
                                <option value="m">M to 41</option>
                                <option value="l">L to 51</option>
                              </select>
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Qty</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="number"
                                className="qty"
                                name="bh_rappeling_qty"
                                id="bh_rappeling_qty"
                                field="form.bh_rappeling.qty"
                                placeholder="0"
                                value={form.bh_rappeling.qty}
                                min={0}
                                onWheel={this.handleOnWheel}
                                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                              />
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Cost</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="bh_rappeling_price"
                                id="bh_rappeling_price"
                                placeholder="0"
                                disabled
                                value={this.multiply(form.bh_rappeling.qty, form.bh_rappeling.price)}
                              />
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Filled B/O</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="bh_rappeling.bo"
                                id="bh_rappeling.bo"
                                field="form.bh_rappeling.bo"
                                placeholder="B/O"
                                disabled={!adminMode}
                                value={form.bh_rappeling.bo}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>

                  {/* Beanie */}
                  <AccordionItem className="accordion-item">
                    {/* Accordion Heading */}
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button">
                        <p>Beanie</p>
                        <p>
                          <span>X&nbsp;{form.beanie.qty}</span>
                          <span>{this.multiply(form.beanie.qty, form.beanie.price)}</span>
                          <span>
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    {/* Accordion Content */}
                    <AccordionItemPanel className="accordion-content">
                      <div className="content">
                        <section>
                          <p className="inputs-head">Item</p>
                          <p className="inputs-col">
                            <div className="inputs-head">Beanie</div>
                            <div className="check_radio_group">
                              <div className="check_radio_item">
                                <input
                                  type="radio"
                                  name="beanie_cuff"
                                  value="cuffless"
                                  id="beanie_cuffless"
                                  className="discl_input"
                                  field="form.beanie.cuff"
                                  checked={form.beanie.cuff === "cuffless"}
                                />
                                <span className="radio_checkbox"></span>
                                <label for="beanie_cuffless">Cuffless</label>
                              </div>
                              <div className="check_radio_item agreement_radio">
                                <input
                                  type="radio"
                                  name="beanie_cuff"
                                  value="with cuff"
                                  id="beanie_cuff"
                                  className="discl_input"
                                  field="form.beanie.cuff"
                                  checked={form.beanie.cuff === "with cuff"}
                                />
                                <span className="radio_checkbox"></span>
                                <label for="beanie_cuff">With Cuff</label>
                              </div>
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Price</p>
                          <p>${form.beanie.price}</p>
                        </section>

                        <section>
                          <p className="inputs-head">Qty</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="number"
                                className="qty"
                                name="beanie_qty"
                                id="beanie_qty"
                                field="form.beanie.qty"
                                placeholder="0"
                                value={form.beanie.qty}
                                min={0}
                                onWheel={this.handleOnWheel}
                                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                              />
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Cost</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="beanie_price"
                                id="beanie_price"
                                placeholder="0"
                                disabled
                                value={this.multiply(form.beanie.qty, form.beanie.price)}
                              />
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Filled B/O</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="beanie.bo"
                                id="beanie.bo"
                                field="form.beanie.bo"
                                placeholder="B/O"
                                disabled={!adminMode}
                                value={form.beanie.bo}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>

                  {/* Firestorm Hat – Mesh Back (Trucker’s Hat) */}
                  <AccordionItem className="accordion-item">
                    {/* Accordion Heading */}
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button">
                        <p>Firestorm Hat – Mesh Back (Trucker’s Hat)</p>
                        <p>
                          <span>X&nbsp;{form.fs_trucker_hat.qty}</span>
                          <span>{this.multiply(form.fs_trucker_hat.qty, form.fs_trucker_hat.price)}</span>
                          <span>
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    {/* Accordion Content */}
                    <AccordionItemPanel className="accordion-content">
                      <div className="content">
                        <section>
                          <p className="inputs-head">Item</p>
                          <p>
                            <div className="inputs-head mb-0">Firestorm Hat – Mesh Back (Trucker’s Hat)</div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Price</p>
                          <p>${form.fs_trucker_hat.price}</p>
                        </section>
                        <section>
                          <p className="inputs-head">Qty</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="number"
                                className="qty"
                                name="fs_trucker_hat_qty"
                                id="fs_trucker_hat_qty"
                                field="form.fs_trucker_hat.qty"
                                placeholder="0"
                                value={form.fs_trucker_hat.qty}
                                min={0}
                                onWheel={this.handleOnWheel}
                                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                              />
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Cost</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="fs_trucker_hat_price"
                                id="fs_trucker_hat_price"
                                placeholder="0"
                                disabled
                                value={this.multiply(form.fs_trucker_hat.qty, form.fs_trucker_hat.price)}
                              />
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Filled B/O</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="fs_trucker_hat.bo"
                                id="fs_trucker_hat.bo"
                                field="form.fs_trucker_hat.bo"
                                placeholder="B/O"
                                disabled={!adminMode}
                                value={form.fs_trucker_hat.bo}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>

                  {/* Firestorm Hat – Black Wool */}
                  <AccordionItem className="accordion-item">
                    {/* Accordion Heading */}
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button">
                        <p>Firestorm Hat – Black Wool</p>
                        <p>
                          <span>X&nbsp;{form.fs_blackwool_hat.qty}</span>
                          <span>{this.multiply(form.fs_blackwool_hat.qty, form.fs_blackwool_hat.price)}</span>
                          <span>
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    {/* Accordion Content */}
                    <AccordionItemPanel className="accordion-content">
                      <div className="content">
                        <section>
                          <p className="inputs-head">Item</p>
                          <p>
                            <div className="inputs-head">Firestorm Hat – Black Wool</div>
                            <span className="sizes">S/M • L/XL • ADJ.</span>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Price</p>
                          <p>${form.fs_blackwool_hat.price}</p>
                        </section>
                        <section>
                          <p className="inputs-head">Size</p>
                          <p>
                            <div className="inputs-items">
                              <select
                                className={`${required.fsBlackwoolHatSize&&errors["form.fs_blackwool_hat.size"] ? "error" : ""}`}
                                required={required.fsBlackwoolHatSize}
                                name="fs_blackwool_hat_size"
                                id="fs_blackwool_hat_size"
                                field="form.fs_blackwool_hat.size"
                                value={form.fs_blackwool_hat.size}
                              >
                                <option value="">Select</option>
                                <option value="s/m">S/M</option>
                                <option value="l/xl">L/XL</option>
                                <option value="adj">ADJ</option>
                              </select>
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Qty</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="number"
                                className="qty"
                                name="fs_blackwool_hat_qty"
                                id="fs_blackwool_hat_qty"
                                field="form.fs_blackwool_hat.qty"
                                placeholder="0"
                                value={form.fs_blackwool_hat.qty}
                                min={0}
                                onWheel={this.handleOnWheel}
                                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                              />
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Cost</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="fs_blackwool_hat_price"
                                id="fs_blackwool_hat_price"
                                placeholder="0"
                                disabled
                                value={this.multiply(form.fs_blackwool_hat.qty, form.fs_blackwool_hat.price)}
                              />
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Filled B/O</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="fs_blackwool_hat.bo"
                                id="fs_blackwool_hat.bo"
                                field="form.fs_blackwool_hat.bo"
                                placeholder="B/O"
                                disabled={!adminMode}
                                value={form.fs_blackwool_hat.bo}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>

                  {/* Personalization (Black hat only) PRINT CLEARLY: */}
                  {form.fs_blackwool_hat.size && Number(form.fs_blackwool_hat.qty) > 0 ? (
                    <AccordionItem className="accordion-item">
                    {/* Accordion Heading */}
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button">
                        <p> Personalization (Black hat only) PRINT CLEARLY:</p>
                        <p>
                          <span>X&nbsp;{form.blackhat.qty}</span>
                          <span>{this.multiply(form.blackhat.qty, form.blackhat.price)}</span>
                          <span>
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    {/* Accordion Content */}
                    <AccordionItemPanel className="accordion-content">
                      <div className="content">
                        <section>
                          <p className="inputs-head">Item</p>
                          <p>
                            <div className="inputs-head">Personalization (Black hat only) PRINT CLEARLY:</div>

                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="blackhat_name"
                                id="blackhat_name"
                                field="form.blackhat.name"
                                placeholder="Enter here-"
                                value={form.blackhat.name}
                              />
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Price</p>
                          <p>${form.blackhat.price}</p>
                        </section>
                        <section>
                          <p className="inputs-head">Qty</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="number"
                                className="qty"
                                name="blackhat_qty"
                                id="blackhat_qty"
                                field="form.blackhat.qty"
                                placeholder="0"
                                value={form.blackhat.qty}
                                min={0}
                                onWheel={this.handleOnWheel}
                                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                              />
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Cost</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="blackhat_price"
                                id="blackhat_price"
                                placeholder="0"
                                disabled
                                value={this.multiply(form.blackhat.qty, form.blackhat.price)}
                              />
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Filled B/O</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="blackhat.bo"
                                id="blackhat.bo"
                                field="form.blackhat.bo"
                                placeholder="B/O"
                                disabled={!adminMode}
                                value={form.blackhat.bo}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                    </AccordionItem>
                  ) : null}


                  {/* Canteen – Forest Ranger 1 Quart */}
                  <AccordionItem className="accordion-item">
                    {/* Accordion Heading */}
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button">
                        <p>Canteen – Forest Ranger 1 Quart</p>
                        <p>
                          <span>X&nbsp;{form.forest_ranger_quart.qty}</span>
                          <span>{this.multiply(form.forest_ranger_quart.qty, form.forest_ranger_quart.price)}</span>
                          <span>
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    {/* Accordion Content */}
                    <AccordionItemPanel className="accordion-content">
                      <div className="content">
                        <section>
                          <p className="inputs-head">Item</p>
                          <p>
                            <div className="inputs-head mb-0"> Canteen – Forest Ranger 1 Quart</div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Price</p>
                          <p>${form.forest_ranger_quart.price}</p>
                        </section>
                        <section>
                          <p className="inputs-head">Qty</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="number"
                                className="qty"
                                name="forest_ranger_quart_qty"
                                id="forest_ranger_quart_qty"
                                field="form.forest_ranger_quart.qty"
                                placeholder="0"
                                value={form.forest_ranger_quart.qty}
                                min={0}
                                onWheel={this.handleOnWheel}
                                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                              />
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Cost</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="forest_ranger_quart_price"
                                id="forest_ranger_quart_price"
                                placeholder="0"
                                disabled
                                value={this.multiply(form.forest_ranger_quart.qty, form.forest_ranger_quart.price)}
                              />
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Filled B/O</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="forest_ranger_quart.bo"
                                id="forest_ranger_quart.bo"
                                field="form.forest_ranger_quart.bo"
                                placeholder="B/O"
                                disabled={!adminMode}
                                value={form.forest_ranger_quart.bo}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>





                  {/* Firestorm Kleen Kanteen */}
                  <AccordionItem className="accordion-item">
                    {/* Accordion Heading */}
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button">
                        <p>Firestorm Kleen Kanteen</p>
                        <p>
                          <span>X&nbsp;{form.kleen_kanteen.qty}</span>
                          <span>{this.multiply(form.kleen_kanteen.qty, form.kleen_kanteen.price)}</span>
                          <span>
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    {/* Accordion Content */}
                    <AccordionItemPanel className="accordion-content">
                      <div className="content">
                        <section>
                          <p className="inputs-head">Item</p>
                          <p className="inputs-col">
                            <div className="inputs-head">Firestorm Kleen Kanteen&nbsp;</div>
                            <div className="check_radio_group">
                              <div className="check_radio_item">
                                <input
                                  type="radio"
                                  name="size"
                                  value="20 oz"
                                  id="kleen_kanteen_20oz"
                                  className="discl_input"
                                  onChange={(event) => this.handleKleenKanteenOnChange(event)}
                                  checked={!!(form.kleen_kanteen.size === "20 oz")}
                                />
                                <span className="radio_checkbox"></span>
                                <label for="kleen_kanteen_20oz">20 oz</label>
                              </div>
                              <div className="check_radio_item agreement_radio">
                                <input
                                  type="radio"
                                  name="size"
                                  value="32 oz"
                                  id="kleen_kanteen_32oz"
                                  className="discl_input"
                                  onChange={(event) => this.handleKleenKanteenOnChange(event)}
                                  checked={!!(form.kleen_kanteen.size === "32 oz")}
                                />
                                <span className="radio_checkbox"></span>
                                <label for="kleen_kanteen_32oz">32 oz</label>
                              </div>
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Price</p>
                          <p>${form.kleen_kanteen.price}</p>
                        </section>

                        <section>
                          <p className="inputs-head">Qty</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="number"
                                className="qty"
                                name="kleen_kanteen_qty"
                                id="kleen_kanteen_qty"
                                field="form.kleen_kanteen.qty"
                                placeholder="0"
                                value={form.kleen_kanteen.qty}
                                min={0}
                                onWheel={this.handleOnWheel}
                                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                              />
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Cost</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="kleen_kanteen_price"
                                id="kleen_kanteen_price"
                                placeholder="0"
                                disabled
                                value={this.multiply(form.kleen_kanteen.qty, form.kleen_kanteen.price)}
                              />
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className="inputs-head">Filled B/O</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className=""
                                name="kleen_kanteen.bo"
                                id="kleen_kanteen.bo"
                                field="form.kleen_kanteen.bo"
                                placeholder="B/O"
                                disabled={!adminMode}
                                value={form.kleen_kanteen.bo}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>





                  {/* Black Diamond Icon Headlamp */}
                  {/* <AccordionItem className='accordion-item'>
                    {/* Accordion Heading */}
                  {/* <AccordionItemHeading className='accordion-heading'>
                      <AccordionItemButton className='accordion-button'>
                        <p>Black Diamond Icon Headlamp</p>
                        <p>
                          <span>X&nbsp;{form.headlamp.qty}</span>
                          <span>{this.multiply(form.headlamp.qty, form.headlamp.price)}</span>
                          <span>
                            <i className='fa fa-chevron-right'></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    {/* Accordion Content */}
                  {/* <AccordionItemPanel className='accordion-content'>
                      <div className='content'>
                        <section>
                          <p className='inputs-head'>Item</p>
                          <p>
                            <div className='inputs-head mb-0'>Black Diamond Icon Headlamp</div>

                            <span className='sizes'>
                              (MSRP $99.95) 4 AA - 175 hours – Waterproof – 3yr Warranty
                            </span>
                          </p>
                        </section>
                        <section>
                          <p className='inputs-head'>Price</p>
                          <p>${form.headlamp.price}</p>
                        </section>
                        <section>
                          <p className='inputs-head'>Qty</p>
                          <p>
                            <div className='inputs-items'>
                              <input
                                type='number'
                                className='qty'
                                name='headlamp_qty'
                                id='headlamp_qty'
                                field='form.headlamp.qty'
                                placeholder='0'
                                value={form.headlamp.qty}
                              />
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className='inputs-head'>Cost</p>
                          <p>
                            <div className='inputs-items'>
                              <input
                                type='text'
                                className=''
                                name='headlamp_price'
                                id='headlamp_price'
                                placeholder='0'
                                disabled
                                value={this.multiply(form.headlamp.qty, form.headlamp.price)}
                              />
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className='inputs-head'>Filled B/O</p>
                          <p>
                            <div className='inputs-items'>
                              <input
                                type='text'
                                className=''
                                name='headlamp.bo'
                                id='headlamp.bo'
                                field='form.headlamp.bo'
                                placeholder='B/O'
                                disabled={!adminMode}
                                value={form.headlamp.bo}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem> */}

                  {/* “The Falcon” 60Second Tent by MMI Outdoor */}
                  {/* <AccordionItem className='accordion-item'>
                    {/* Accordion Heading */}
                  {/*<AccordionItemHeading className='accordion-heading'>
                      <AccordionItemButton className='accordion-button'>
                        <p>“The Falcon” 60Second Tent by MMI Outdoor</p>
                        <p>
                          <span>X&nbsp;{form.tent.qty}</span>
                          <span>{this.multiply(form.tent.qty, form.tent.price)}</span>
                          <span>
                            <i className='fa fa-chevron-right'></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    {/* Accordion Content */}
                  {/*<AccordionItemPanel className='accordion-content'>
                      <div className='content'>
                        <section>
                          <p className='inputs-head'>Item</p>
                          <p>
                            <div className='inputs-head mb-0'>
                              “The Falcon” 60Second Tent by MMI Outdoor
                            </div>

                            <span className='sizes'>
                              Sleeps: 2-3.Wt: 7.5 lbs Center Height: 52" Floor Area: 7' x 8'
                            </span>
                          </p>
                        </section>
                        <section>
                          <p className='inputs-head'>Price</p>
                          <p>${form.tent.price}</p>
                        </section>
                        <section>
                          <p className='inputs-head'>Qty</p>
                          <p>
                            <div className='inputs-items'>
                              <input
                                type='number'
                                className='qty'
                                name='tent_qty'
                                id='tent_qty'
                                field='form.tent.qty'
                                placeholder='0'
                                value={form.tent.qty}
                                min={0}
                                onWheel={this.handleOnWheel}
                                onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                              />
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className='inputs-head'>Cost</p>
                          <p>
                            <div className='inputs-items'>
                              <input
                                type='text'
                                className=''
                                name='tent_price'
                                id='tent_price'
                                placeholder='0'
                                disabled
                                value={this.multiply(form.tent.qty, form.tent.price)}
                              />
                            </div>
                          </p>
                        </section>
                        <section>
                          <p className='inputs-head'>Filled B/O</p>
                          <p>
                            <div className='inputs-items'>
                              <input
                                type='text'
                                className=''
                                name='tent.bo'
                                id='tent.bo'
                                field='form.tent.bo'
                                placeholder='B/O'
                                disabled={!adminMode}
                                value={form.tent.bo}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem> */}

                  {/* End of Accordion */}
                </Accordion>

                {/* Total */}
                <p className="accessories-total">
                  <h3 className="inputs-head">Total</h3>
                  <div className="inputs-items">
                    <input
                      type="text"
                      className=""
                      name="item_total"
                      id="item_total"
                      field="form.item_total"
                      placeholder="Total"
                      value={`$ ${this.computeTotal()}`}
                      disabled
                    />
                  </div>
                </p>

                <div className="accessories-total-text">
                  <p>
                    Orders must be paid in full before merchandise will be given. Payment should accompany merchandise
                    order. If currently working, payroll deduction may be authorized.
                  </p>
                </div>
              </section>
            )}

            {/* Paid */}
            <div className="row form-row paid-checkbox">
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">Paid</div>
                  <div className="check_radio_group">
                    <div className="col mb-0">
                      <div className="check_radio_item">
                        <input
                          type="radio"
                          name="paid"
                          value="ck"
                          id="paid_ck"
                          className="discl_input"
                          field="form.paid"
                          checked={form.paid === "ck"}
                        />
                        <span className="radio_checkbox"></span>
                        <label for="ck">CK</label>
                      </div>

                      <div className="paid-inputitems">
                        <p>#</p>{" "}
                        <input
                          type="text"
                          className={form.paid !== "ck" && "disabled"}
                          name="ck_number"
                          id="ck_number"
                          field="form.ck_number"
                          placeholder="Enter here-"
                          value={form.ck_number}
                          disabled={form.paid !== "ck"}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="check_radio_group">
                    <div className="col">
                      <div className="check_radio_item">
                        <input
                          type="radio"
                          name="paid"
                          value="cash"
                          id="paid_cash"
                          className="discl_input"
                          field="form.paid"
                          checked={form.paid === "cash"}
                        />
                        <span className="radio_checkbox"></span>
                        <label for="cash">CASH</label>
                      </div>
                    </div>
                  </div>

                  <div className="check_radio_group">
                    <div className="col">
                      <div className="check_radio_item">
                        <input
                          type="radio"
                          name="paid"
                          value="cc"
                          id="paid_cc"
                          className="discl_input"
                          field="form.paid"
                          checked={form.paid === "cc"}
                        />
                        <span className="radio_checkbox"></span>
                        <label for="cash">CC</label>
                      </div>
                    </div>
                  </div>

                  <div className="check_radio_group">
                    <div className="col">
                      <div className="check_radio_item">
                        <input
                          type="radio"
                          name="paid"
                          value="payroll"
                          id="paid_payroll"
                          className="discl_input"
                          field="form.paid"
                          checked={form.paid === "payroll"}
                        />
                        <span className="radio_checkbox"></span>
                        <label for="cash">PAYROLL DEDUCTION (MUST SIGN BELOW)</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Employee Signature */}
            {/* form.paid === "payroll" */}
            {true && (
              <div className="row form-row">
                <div className="col mb-2">
                  <span className="notes">
                    I hereby authorize Firestorm to deduct from my payroll check, the total amount of the
                    purchase/repayment of merchandise expenses. Payroll deductions to commence immediately and continue
                    until fully paid. I attest that I have not been required by Firestorm to purchase this merchandise.
                  </span>
                </div>
                <div className="col">
                  <div className="inputs-col">
                    <div className="inputs-head">
                      Employee Signature
                      <span className="required-star">&#x2A;</span>
                    </div>
                    <div className="inputs-items">
                      <div
                        ref={(element) => {
                          this.signContainer = element;
                        }}
                        className="sign_here"
                      >
                        {this.state.sign && (
                          <Fragment>
                            <SignPad
                              id="merchEmpSignPad"
                              height={this.state.sign.height}
                              width={this.state.sign.width}
                              handleSignature={this.handleSignature.bind(this, "form.employee_signature")}
                              signature={this.state.form.employee_signature}
                              disabled={adminMode ? !docBelongsToAdmin : adminMode}
                            />
                            <input
                              type="hidden"
                              value={form.employee_signature}
                              name="employee_signature"
                              field="form.employee_signature"
                              required
                            ></input>
                            {errors["form.employee_signature"] && (
                              <label htmlFor="employee_signature" className="error_label">
                                {errors["form.employee_signature"]}
                              </label>
                            )}
                          </Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="inputs-col">
                    <div className="inputs-head">
                      Date<span className="required-star">&#x2A;</span>
                    </div>
                    <div className="inputs-items">
                      <div className="inputs-items">
                        <DatePicker
                          className={`${errors["form.employee_signature_date"] ? "error" : ""} w-100`}
                          name="employee_signature_date"
                          id="employee_signature_date"
                          required
                          selected={moment(form.employee_signature_date)}
                          field="form.employee_signature_date"
                          placeholderText="mm/dd/yyyy"
                          onChange={this.handleDateChange.bind(this, "form.employee_signature_date")}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          autoComplete="off"
                          disabled={true}
                        />
                        {errors["form.employee_signature_date"] && (
                          <label htmlFor="employee_signature_date" className="error_label">
                            {errors["form.employee_signature_date"]}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Superintendent  */}
            {/* {adminMode && (
              <div className="row form-row">
                <div className="col">
                  <div className="inputs-col">
                    <div className="inputs-head">
                      Sup.Signature<span className="required-star">&#x2A;</span>
                    </div>
                    <div className="inputs-items">
                      <div
                        ref={(element) => {
                          this.signContainer = element;
                        }}
                        className="sign_here"
                      >
                        {this.state.sign && (
                          <Fragment>
                            <SignPad
                              id="merchSupSignPad"
                              height={this.state.sign.height}
                              width={this.state.sign.width}
                              handleSignature={this.handleSignature.bind(this, "form.superintendent_signature")}
                              signature={this.state.form.superintendent_signature}
                            />
                            <input
                              type="hidden"
                              value={form.superintendent_signature}
                              name="superintendent_signature"
                              field="form.superintendent_signature"
                              required
                            ></input>
                            {errors["form.superintendent_signature"] && (
                              <label htmlFor="superintendent_signature" className="error_label">
                                {errors["form.superintendent_signature"]}
                              </label>
                            )}
                          </Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="inputs-col">
                    <div className="inputs-head">
                      Date<span className="required-star">&#x2A;</span>
                    </div>
                    <div className="inputs-items">
                      <div className="inputs-items">
                        <DatePicker
                          className={`${errors["form.superintendent_signature_date"] ? "error" : ""} w-100`}
                          name="superintendent_signature_date"
                          id="superintendent_signature_date"
                          required
                          selected={moment(form.employee_superintendent_signature_date)}
                          field="form.superintendent_signature_date"
                          placeholderText="mm/dd/yyyy"
                          onChange={this.handleDateChange.bind(this, "form.superintendent_signature_date")}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          autoComplete="off"
                        />
                        {errors["form.superintendent_signature_date"] && (
                          <label htmlFor="superintendent_signature_date" className="error_label">
                            {errors["form.superintendent_signature_date"]}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )} */}

            {/* Office Use */}
            {adminMode && ( //commented out as per new doc change request 2023
              <>
                <h4>For Office Use Only:</h4>
                <div className="row form-row  pb-2">

                  <div className="col">
                    <div className="inputs-col">
                      <table className="filledorder" border="1">
                        <tr>
                          <td>
                            <div className="inputs-col">
                              <div className="inputs-head">Filled</div>
                              <div className="inputs-items">
                                <input
                                  type="text"
                                  className=""
                                  name="filled"
                                  id="filled"
                                  field="form.filled"
                                  placeholder="Enter here-"
                                  value={form.filled}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="inputs-col">
                              <div className="inputs-head">Back Order</div>
                              <div className="inputs-items">
                                <input
                                  type="text"
                                  className=""
                                  name="back_order"
                                  id="back_order"
                                  field="form.back_order"
                                  placeholder="Enter here-"
                                  value={form.back_order}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="inputs-col">
                              <div className="inputs-head">Monogram(s)</div>
                              <div className="inputs-items">
                                <input
                                  type="text"
                                  className=""
                                  name="monograms"
                                  id="monograms"
                                  field="form.monograms"
                                  placeholder="Enter here-"
                                  value={form.monograms}
                                />
                              </div>
                            </div>
                          </td>
                          {/* <td>
                            <div className="inputs-col">
                              <div className="inputs-head">Rappelling Belt</div>
                              <div className="inputs-items">
                                <input
                                  type="text"
                                  className=""
                                  name="rappelling_belt"
                                  id="rappelling_belt"
                                  field="form.rappelling_belt"
                                  placeholder="Enter here-"
                                  value={form.rappelling_belt}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="inputs-col">
                              <div className="inputs-head">PG Bag</div>
                              <div className="inputs-items">
                                <input
                                  type="text"
                                  className=""
                                  name="pg_bag"
                                  id="pg_bag"
                                  field="form.pg_bag"
                                  placeholder="Enter here-"
                                  value={form.pg_bag}
                                />
                              </div>
                            </div>
                          </td> */}
                        </tr>

                        <tr>
                          <td>
                            <div className="inputs-col">
                              <div className="inputs-head txt_light">Delivered</div>
                              <div className="inputs-items">
                                <input
                                  type="text"
                                  className=""
                                  name="filled_delivered"
                                  id="filled_delivered"
                                  field="form.filled_delivered"
                                  placeholder="Enter here-"
                                  value={form.filled_delivered}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="inputs-col">
                              <div className="inputs-head txt_light">Ordered</div>
                              <div className="inputs-items">
                                <input
                                  type="text"
                                  className=""
                                  name="back_order_ordered"
                                  id="back_order_ordered"
                                  field="form.back_order_ordered"
                                  placeholder="Enter here-"
                                  value={form.back_order_ordered}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="inputs-col">
                              <div className="inputs-head txt_light">Ordered</div>
                              <div className="inputs-items">
                                <input
                                  type="text"
                                  className=""
                                  name="monograms_ordered"
                                  id="monograms_ordered"
                                  field="form.monograms_ordered"
                                  placeholder="Enter here-"
                                  value={form.monograms_ordered}
                                />
                              </div>
                            </div>
                          </td>
                          {/* <td>
                            <div className="inputs-col">
                              <div className="inputs-head txt_light">Ordered</div>
                              <div className="inputs-items">
                                <input
                                  type="text"
                                  className=""
                                  name="rappelling_belt_ordered"
                                  id="rappelling_belt_ordered"
                                  field="form.rappelling_belt_ordered"
                                  placeholder="Enter here-"
                                  value={form.rappelling_belt_ordered}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="inputs-col">
                              <div className="inputs-head txt_light">Ordered</div>
                              <div className="inputs-items">
                                <input
                                  type="text"
                                  className=""
                                  name="pg_bag_ordered"
                                  id="pg_bag_ordered"
                                  field="form.pg_bag_ordered"
                                  placeholder="Enter here-"
                                  value={form.pg_bag_ordered}
                                />
                              </div>
                            </div>
                          </td> */}
                        </tr>

                        <tr>
                          <td>
                            <div className="inputs-col">
                              <div className="inputs-head txt_light">Posted</div>
                              <div className="inputs-items">
                                <input
                                  type="text"
                                  className=""
                                  name="filled_posted"
                                  id="filled_posted"
                                  field="form.filled_posted"
                                  placeholder="Enter here-"
                                  value={form.filled_posted}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="inputs-col">
                              <div className="inputs-head txt_light">Filled</div>
                              <div className="inputs-items">
                                <input
                                  type="text"
                                  className=""
                                  name="back_order_filled"
                                  id="back_order_filled"
                                  field="form.back_order_filled"
                                  placeholder="Enter here-"
                                  value={form.back_order_filled}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="inputs-col">
                              <div className="inputs-head txt_light">Filled</div>
                              <div className="inputs-items">
                                <input
                                  type="text"
                                  className=""
                                  name="monogram_filled"
                                  id="monogram_filled"
                                  field="form.monogram_filled"
                                  placeholder="Enter here-"
                                  value={form.monogram_filled}
                                />
                              </div>
                            </div>
                          </td>
                          {/* <td>
                            <div className="inputs-col">
                              <div className="inputs-head txt_light">Filled</div>
                              <div className="inputs-items">
                                <input
                                  type="text"
                                  className=""
                                  name="rappelling_belt_filled"
                                  id="rappelling_belt_filled"
                                  field="form.rappelling_belt_filled"
                                  placeholder="Enter here-"
                                  value={form.rappelling_belt_filled}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="inputs-col">
                              <div className="inputs-head txt_light">Filled</div>
                              <div className="inputs-items">
                                <input
                                  type="text"
                                  className=""
                                  name="pg_bag_filled"
                                  id="pg_bag_filled"
                                  field="form.pg_bag_filled"
                                  placeholder="Enter here-"
                                  value={form.pg_bag_filled}
                                />
                              </div>
                            </div>
                          </td> */}
                        </tr>

                        <tr>
                          <td>
                            <div className="inputs-col">
                              <div className="inputs-head txt_light">Prior $</div>
                              <div className="inputs-items">
                                <input
                                  type="text"
                                  className=""
                                  name="filled_prior"
                                  id="filled_prior"
                                  field="form.filled_prior"
                                  placeholder="Enter here-"
                                  value={form.filled_prior}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="inputs-col">
                              <div className="inputs-head txt_light">Posted</div>
                              <div className="inputs-items">
                                <input
                                  type="text"
                                  className=""
                                  name="back_order_posted"
                                  id="back_order_posted"
                                  field="form.back_order_posted"
                                  placeholder="Enter here-"
                                  value={form.back_order_posted}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="inputs-col">
                              <div className="inputs-head txt_light">Posted</div>
                              <div className="inputs-items">
                                <input
                                  type="text"
                                  className=""
                                  name="monogram_posted"
                                  id="monogram_posted"
                                  field="form.monogram_posted"
                                  placeholder="Enter here-"
                                  value={form.monogram_posted}
                                />
                              </div>
                            </div>
                          </td>
                          {/* <td>
                            <div className="inputs-col">
                              <div className="inputs-head txt_light">Posted</div>
                              <div className="inputs-items">
                                <input
                                  type="text"
                                  className=""
                                  name="rappelling_belt_posted"
                                  id="rappelling_belt_posted"
                                  field="form.rappelling_belt_posted"
                                  placeholder="Enter here-"
                                  value={form.rappelling_belt_posted}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="inputs-col">
                              <div className="inputs-head txt_light">Posted</div>
                              <div className="inputs-items">
                                <input
                                  type="text"
                                  className=""
                                  name="pg_bag_posted"
                                  id="pg_bag_posted"
                                  field="form.pg_bag_posted"
                                  placeholder="Enter here-"
                                  value={form.pg_bag_posted}
                                />
                              </div>
                            </div>
                          </td> */}
                        </tr>

                        <tr>
                          <td>
                            <div className="inputs-col">
                              <div className="inputs-head txt_light">Up to $</div>
                              <div className="inputs-items">
                                <input
                                  type="text"
                                  className=""
                                  name="filled_upto"
                                  id="filled_upto"
                                  field="form.filled_upto"
                                  placeholder="Enter here-"
                                  value={form.filled_upto}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="inputs-col">
                              <div className="inputs-head txt_light">Up to $</div>
                              <div className="inputs-items">
                                <input
                                  type="text"
                                  className=""
                                  name="back_order_upto"
                                  id="back_order_upto"
                                  field="form.back_order_upto"
                                  placeholder="Enter here-"
                                  value={form.back_order_upto}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="inputs-col">
                              <div className="inputs-head txt_light">Up to $</div>
                              <div className="inputs-items">
                                <input
                                  type="text"
                                  className=""
                                  name="monogram_upto"
                                  id="monogram_upto"
                                  field="form.monogram_upto"
                                  placeholder="Enter here-"
                                  value={form.monogram_upto}
                                />
                              </div>
                            </div>
                          </td>
                          {/* <td>
                            <div className="inputs-col">
                              <div className="inputs-head txt_light">Up to $</div>
                              <div className="inputs-items">
                                <input
                                  type="text"
                                  className=""
                                  name="rappelling_belt_upto"
                                  id="rappelling_belt_upto"
                                  field="form.rappelling_belt_upto"
                                  placeholder="Enter here-"
                                  value={form.rappelling_belt_upto}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="inputs-col">
                              <div className="inputs-head txt_light">Up to $</div>
                              <div className="inputs-items">
                                <input
                                  type="text"
                                  className=""
                                  name="pg_bag_upto"
                                  id="pg_bag_upto"
                                  field="form.pg_bag_upto"
                                  placeholder="Enter here-"
                                  value={form.pg_bag_upto}
                                />
                              </div>
                            </div>
                          </td> */}
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            )}
            {/* Disclaimer check */}
            <div className="row form-row">
              <div className="col">
                <div className="disclaimers">
                  <strong className="diss">Disclaimer: </strong>You must read and carefully understand the documents
                  that you are filling out and signing. These are legally binding documents and must be accurate. By
                  filling these forms out, you are agreeing to our{" "}
                  <a href='https://firestormfire.com/terms-of-use/' target="blank">Terms of Use, Privacy Policy and Employee Agreements.</a>
                </div>
              </div>
            </div>

            <div className="row form-row last">
              <div className="col">
                <div className="inputs-col">
                  <div className="check_radio_group check_disclaimer">
                    <div className="check_radio_item">
                      <input
                        type="checkbox"
                        name="disclaimer_check"
                        id="disclaimer_check"
                        field="form.disclaimer_check"
                        checked={form.disclaimer_check}
                        required
                      />
                      <span className="checkbox_checkbox"></span>
                      <label htmlFor="disclaimer_check">I have confirmed the above details are true and valid.</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End of Disclaimer check */}

            {/* Footer */}
            <FormNav
              single_doc={this.props.single_doc}
              view_doc={this.props.view_doc}
              adminMode={adminMode}
              skip={true}
              skipHandler={this.showSkipConfirmation.bind(this)}
              // skipHandler={this.skipHandler.bind(this)}
              navigate={this.navigate.bind(this)}
              disclaimer_check={form.disclaimer_check}
              lastPage={true}
              mobileMode={this.props.mobileMode}
            />
            </fieldset>
          </form>

          <Modal
            id="remove_popup"
            className="status_popup"
            open={this.state.skip_confirmation}
            onClose={this.closeSkipModal.bind(this)}
            little
          >
            <div id="removePopup">
              <div className="modal-head">
                <h4 className="modal-title">
                  Skip Merchandise Order Form 
                </h4>
              </div>
              <div className="modal-body">
                <label>Are you sure you want to skip the Merchandise Order Form?</label>
                <br />
                <button className="button grey" onClick={this.closeSkipModal.bind(this)}>
                  Cancel
                </button>
                <button className="button" onClick={this.skipHandler.bind(this)}>
                  Skip
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { docs } = state;
  return { docs, currentUserInfo: getCurrentUserInfo(state) };
}

let connectedMerchandiseOrder = compose(withRouter, connect(mapStateToProps))(MerchandiseOrder);
export { connectedMerchandiseOrder as MerchandiseOrder };
