import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import { smsService } from "_services";

const SmsNotification = ({
  initialStatus,
  providerOptedOutStatus,
  type,
  id,
  customClass,
}) => {
  const [smsNotifyModal, setSmsNotifyModal] = useState(false);
  const [smsNotifyLoader, setSmsNotifyLoader] = useState(false);
  const [smsNotifyStatus, setSmsNotifyStatus] = useState(initialStatus);
  const [providerNumber, setProviderNumber] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setSmsNotifyStatus(initialStatus);
  }, [initialStatus]);

  const handleToggleSmsNotification = async () => {
    setSmsNotifyLoader(true);

    try {
      const newStatus = !smsNotifyStatus;
      const data = new FormData();
      data.append("allow_notification", newStatus);

      await smsService.changeSmsNotifyStatus(data, type, id); // Dispatch Redux action
      setSmsNotifyStatus(newStatus);
      toast.success("Updated notification preference successfully.");
    } catch (error) {
      console.error("Error updating SMS notification:", error);
      alert("An error occurred while updating the status.");
    } finally {
      setSmsNotifyLoader(false);
      setSmsNotifyModal(false);
    }
  };

  useEffect(() => {
    if (providerOptedOutStatus) {
      smsService.fetchProviderNumber().then((res) => { 
        if (res.success) {
          setProviderNumber(res.data.provider_number);
        }
      }).catch((error) => {
        console.error("Error fetching provider number:", error);
      });
    }
  }, [providerOptedOutStatus]);

  return (
    <>
      <button
        className={`button ${customClass}`}
        style={{ padding: "6px 16px" }}
        onClick={() => setSmsNotifyModal(true)}
      >
        SMS Notification
      </button>
      {smsNotifyModal && (
        <ReactModal
          isOpen={smsNotifyModal}
          contentLabel="SMS Notification"
          overlayClassName="alertPopup"
        >
          <div id="statusPopup" className="popupModal">
            <div className="modal-head">
              <h4 className="modal-title">SMS Notification</h4>
            </div>
            <div className="modal-body">
              {providerOptedOutStatus && !smsNotifyStatus ? (
                <>
                  <p>You've opted out of SMS notifications with our provider. To opt back in, please reply 'START' to {providerNumber}. Once done, your SMS notifications will be enabled.</p>
                  <div className="btn_list">
                    <button
                      className={`button grey`}
                      onClick={() => setSmsNotifyModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <p>
                    Would you like to {smsNotifyStatus ? "disable" : "enable"}{" "}
                    SMS notifications?
                  </p>
                  <div className="btn_list">
                    <button
                      className={`button ${smsNotifyStatus ? "red" : ""} ${smsNotifyLoader ? "disabled" : ""}`}
                      onClick={handleToggleSmsNotification}
                    >
                      {smsNotifyStatus ? "Disable" : "Enable"}
                    </button>
                    <button
                      className={`button grey ${smsNotifyLoader ? "disabled" : ""}`}
                      onClick={() => setSmsNotifyModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </ReactModal>
      )}
    </>
  );
};

export default SmsNotification;
