import React from "react";
import { connect } from "react-redux";
import "../assets/styles/inventory.css";
import {
  inventoryActions,
  permissionActions,
  resourceActions,
} from "../_actions";
import { config } from "../_helpers";
import Dropzone from "react-dropzone";
import DatePicker from "react-datepicker";
import moment from "moment";
import { AlertPopup } from "../_components/AlertPopup";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import ReactModal from "react-modal";
import NumberFormat from "react-number-format";
import { inventoryService } from "_services";
import { getCurrentUserInfo } from "_helpers/reduxSelector";
import { INVENTORY_SUB_PACKAGE } from "_constants";

ReactModal.setAppElement("#app");

const validImageTypes = ["jpg", "jpeg", "png", "gif", "heic", "heif"];
const errorMessages = {
  invalidType: "Invalid file type",
  invalidImageType:
    "File type must be in jpg, jpeg, png, gif, heic, heif format",
};

const IS_SKIP_AND_SAVE = 0;
const mileageDateFormat = "ddd MMM Do, YYYY  h:mm A";
const CROP_CANVAS_OPTIONS = {
  width: 600,
  height: 600,
  minWidth: 256,
  minHeight: 256,
  maxWidth: 4096,
  maxHeight: 4096,
};
class AddEquipment extends React.Component {
  constructor(props) {
    super(props);
    this.mileageRef = React.createRef();
    this.soldToRef = React.createRef();
    this.state = {
      mainHtml: "",
      type_list: [],
      equip_type: "",
      subPackages: [],
      type: "",
      pre_type: "",
      name: "",
      cost: "",
      license_no: "",
      mspa_date: "",
      sold_date: null,
      milage_hobbs_date: null,
      condition: "",
      make_model: "",
      notes: "",
      pic: "",
      subtype: "",
      imgPreview: "",
      crop: { x: 20, y: 10, width: 150, height: 150 },
      pixelCrop: {
        x: "",
        y: "",
        width: "",
        height: "",
      },
      alertPop: false,
      statusOpen: false,
      resource_show: "hide",
      driver_show: "hide",
      resourceFlag: true,
      driverFlag: true,
      drivers_list: [],
      driverClick: false,
      resourceClick: false,
      year: "",
      saveClick: false,
      delClick: false,
      file_list: [],
      resFiles: [],
      file_list_view: [],
      division: "",
      mileage: "",
      asDateShow: false,
      resource_identifier: "",
      driver_name: "",
      serial_num: "",
      description: "",
      vehicle_model_or_make: "",
      driver_hash: "",
      licenseNum: "",
      gvw: "",
      engineFamily: "",
      engineModel: "",
      engineSerial: "",
      engineDescription: "",
      abr: "",
      non_op: "",
      sold_value: "",
      sold_to: "",
      assignedDate: "",
      yearPurchase: "",
      engineCert: "",
      resource_identifier_id: "",
      equipImgs: [],
      cropImgIndex: null,
      submitDisabled: false,
      EquipmentType: [],
      permList: [],
      imageLoading: false,
      loading: false,
      state_of_licensure: "",
      equipment_owner: "",
      insurance: "",
      job_code: "",
      class_code: "",
      arb_program: "",
      mileageMeterUploads: [],
      milage: "",
      shouldSkipAndSave: false,
      mileageFieldsRequiredTypes: [1, 14, 5, 10, 6, 7, 9],
      equipmentArchive: false,
      isMileageHistoryModalOpen: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.onCropChange = this.onCropChange.bind(this);
    this.onCropComplete = this.onCropComplete.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.goBack = this.goBack.bind(this);
    this.apply = this.apply.bind(this);
    this.statusCloseModal = this.statusCloseModal.bind(this);
    this.mileageHistoryModalClose = this.mileageHistoryModalClose.bind(this);
    this.mileageHistoryModal = this.mileageHistoryModal.bind(this);
    this.keyUpFnResource = this.keyUpFnResource.bind(this);
    this.setCrew = this.setCrew.bind(this);
    this.keyUpFnDriver = this.keyUpFnDriver.bind(this);
    this.deleteInventory = this.deleteInventory.bind(this);
    this.dltInvOpenModal = this.dltInvOpenModal.bind(this);
    this.dltInvCloseModal = this.dltInvCloseModal.bind(this);
    this.handleImgDelete = this.handleImgDelete.bind(this);
    this.onImageError = this.onImageError.bind(this);
    this.getMileageHistory = this.getMileageHistory.bind(this);
  }
  componentDidMount() {
    this.props.dispatch(permissionActions.getPermission());
    this.props.dispatch(inventoryActions.getListEquipments());
    this.setState({
      type: "",
      pre_type: "",
      name: "",
      condition: "",
      subtype: "",
      cost: "",
      license_no: "",
      notes: "",
      imgPreview: "",
      mspa_date: "",
      make_model: "",
      driver_name: "",
      resource_identifier: "",
      hasUserInteractedWithDriver: false,
    });
    if (this.props.equip_id) {
      this.props
        .dispatch(inventoryActions.equipmentDetails(this.props.equip_id))
        .then((res) => {
          this.setState({
            equipImgs: res.data.equipment_files.map((file) => {
              return {
                loading: false,
                id: file.id,
                imgName: file.file_original_hash,
                new: false,
                thumb: file.file_hash,
              };
            }),
            mileageMeterUploads: res.data.inventory_files.map((file) => {
              return {
                loading: false,
                id: file.id,
                imgName: file.file_original_hash,
                new: false,
                thumb: file.file_hash,
              };
            }),
          });
        });
    }
    if (this.props.status === "edit") {
      this.setState({ resource_show: "hide", driver_show: "hide" });
    }
    var d = new Date(),
      currentYear = d.getFullYear(),
      yearList = [];

    for (var i = 1983; i <= currentYear; i++) {
      yearList.push(
        <option key={"year" + i} value={i}>
          {i}
        </option>
      );
    }
    this.setState({ yearList });

    if (this.props.status === "edit") {
      this.checkMileagePermission();
      this.getMileageHistory();
    }
  }

  checkMileagePermission() {
    let compareDate = moment();
    const currentYear = compareDate?.format("YYYY");
    let startDate = moment(`01/01/${currentYear}`, "MM/DD/YYYY");
    let endDate = moment(`01/14/${currentYear}`, "MM/DD/YYYY");
    const isDateValidated = compareDate?.isBetween(startDate, endDate);
    this.setState({ disableMileageSection: !isDateValidated });
  }

  dltInvOpenModal(event) {
    event?.preventDefault();
    this.setState({ equipmentArchive: true }, () => {
      const err = this.validate();

      if (!err) {
        this.setState({ dltInvOpen: true });
      }
    });
  }

  dltInvCloseModal() {
    this.setState({ dltInvOpen: false });
  }

  deleteInventory() {
    this.setState({
      delClick: true,
      dltInvOpen: false,
      equipImgs: [],
      mileageMeterUploads: [],
    });
    let data = new FormData();
    data.append("id", this.props.inv_id);
    data.append("type", this.state.type);
    this.props.dispatch(inventoryActions.deleteInventory(data)).then((res) => {
      let dataOption = new FormData();

      for (let key in this.props.filter) {
        dataOption.append(key, this.props.filter[key]);
      }

      if (!dataOption.has("option")) {
        dataOption.append("option", "All");
      }

      this.props.dispatch(inventoryActions.getList(dataOption));
    });
  }

  getMileageHistory() {
      let data = new FormData();
      data.append("id", this.props.inventory_id);
      this.props.dispatch(inventoryActions?.getMileageHistory(data));
  }

  handleChange(e) {
    var name = e.target.name,
      value = e.target.value,
      errorVar = name + "Error";
    if (name === "cost") {
      value = value.replace(/[$,]/g, "");
    }
    this.setState({
      [name]: value,
      [errorVar]: "",
    });
    if (name === 'driver_name') {
      this.setState({
        hasUserInteractedWithDriver: true,
      });
    }
  }

  apply(file) {
    this.setState({
      imgPreview: window.URL.createObjectURL(file),
      pic: file,
    });
  }

  keyUpFnResource() {
    var textInput = document.getElementById("resource_search");
    if (textInput.value.length === 0) {
      this.setState({
        resource_show: "hide",
        resource_identifier_id: "",
        driver_name: "",
        driver_hash: "",
      });
    } else {
      this.setState({ driver_name: "", resourceClick: true, driver_hash: "" });
    }
    clearTimeout(this.state.timeout);
    this.state.timeout = setTimeout(
      function () {
        this.handleSearch(textInput.value);
      }.bind(this),
      700
    );
  }

  handleSearch(text) {
    if (text.length === 0) {
    } else if (text.length >= 1) {
      let data = new FormData();
      data.append("keyword", text);
      // data.append('resource_type', 'Firefighter')
      this.props.dispatch(resourceActions.searchResource(data));
      this.setState({ display: "show", driver_name: "" });
    }
  }

  setCrew(crew, name) {
    this.setState({
      resource_identifier_id: crew,
      resource_identifier: name,
      resource_show: "hide",
    });
  }

  setDriver(name, hash) {
    this.setState({
      driver_hash: hash,
      driver_name: name,
      driver_show: "hide",
      driverClick: false,
      asDateShow: true,
    });
  }

  keyUpFnDriver() {    
    if ((!this.state.resource_identifier || !this.state.resource_identifier_id) && this.state.subtype != INVENTORY_SUB_PACKAGE.IPad) {      
      return;
    }
    var textInput = document.getElementById("driver_search");
    if (textInput.value.length === 0) {
      this.setState({ driver_show: "hide", driver_hash: "", driver_name: "", hasUserInteractedWithDriver: false });
    } else {
      this.setState({ driverClick: true });
      this.setState({ hasUserInteractedWithDriver: true });
    }
    clearTimeout(this.state.timeout);
    this.state.timeout = setTimeout(
      function () {
        this.handleSearchDrivers(textInput.value);
      }.bind(this),
      700
    );
  }

  handleSearchDrivers(text) {
   if (text.length >= 1) {
      let drivers = new FormData();
      drivers.append("keyword", text);
      if((this.state.subtype !== INVENTORY_SUB_PACKAGE.IPad && this.state.resource_identifier_id) || 
      (this.state.subtype === INVENTORY_SUB_PACKAGE.IPad && this.state.resource_identifier_id))  {
       drivers.append("id", this.state.resource_identifier_id);
      }
      this.props.dispatch(resourceActions.searchDriver(drivers));
      this.setState({ display: "show", driver_show: "show" });
    }
  }

  _crop() {}

  statusCloseModal() {
    this.setState({ statusOpen: false, isMileageMeterUpload: false });
  }

  mileageHistoryModalClose() {
    this.setState({ isMileageHistoryModalOpen: false });
  }

  mileageHistoryModal() {
    this.setState({ isMileageHistoryModalOpen: true });
  }

  cropImage() {
    this.cropper
      .getCroppedCanvas({
        width: 600,
        height: 600,
        minWidth: 256,
        minHeight: 256,
        maxWidth: 4096,
        maxHeight: 4096,
      })
      .toBlob(
        (blob) => {
          // var imgData = this.cropper.getData();
          let equipImgs = this.state.equipImgs;
          let image = equipImgs[this.state.cropImgIndex];
          image.blob = blob;
          image.thumb = this.cropper
            .getCroppedCanvas({
              width: 600,
              height: 600,
              minWidth: 256,
              minHeight: 256,
              maxWidth: 4096,
              maxHeight: 4096,
            })
            .toDataURL();
          equipImgs.splice(this.state.cropImgIndex, 1, image);
          this.setState({
            equipImgs: equipImgs,
            statusOpen: false,
            submitDisabled: true,
            imageLoading: true,
            loading: true,
          });
          this.props
            .dispatch(
              inventoryActions.uploadImage(image, this.state.cropImgIndex)
            )
            .then((res) => {
              this.setState({
                submitDisabled: false,
                imageLoading: false,
                loading: false,
              });
              if (this.props.equip_id) {
                this.props.dispatch(
                  inventoryActions.equipmentDetails(this.props.equip_id)
                );
              }
              // commented out since edit and add equipment uses same component.
              // let dataOption = new FormData();
              // dataOption.append("option", "All");
              // this.props.dispatch(inventoryActions.getListEquipments());
              // this.props.dispatch(inventoryActions.getList(dataOption));
            })
            .catch((err) => console.log(err));
        },
        this.state.image.type,
        0.45
      );
  }

  handleSelect(event) {
    var name = event.target.name,
      errorVar = name + "Error",
      subtype_list = [],
      type_desc = "";

    this.setState(
      {
        [event.target.name]: event.target.value,
        [errorVar]: "",
      },
      () => {
        if (name === "type" || name === "division") {
          let subtype_list = this.state.SubPackages.filter((item) =>
            item.d_id.split(",").includes(this.state.division.toString())
          )
            .filter((item) => item.p_id.split(",").includes(this.state.type))
            .map((item, index) => (
              <option key={index} value={item.id}>
                {item.value}
              </option>
            ));

          this.setState({ subtype_list, type_desc, subtype: "" });
        }
      }
    );
  }

  handleDateChange(name, date) {
    this.setState({ [name]: date, mspa_dateError: "" });
  }

  goBack() {
    this.props.goBack();
    this.setState({ equipImgs: [], mileageMeterUploads: [] });
  }

  goToInventoryView = () => {
    this.props.inventoryView();
  };

  validate(e) {
    let isError = false;

    if (this.state.name === "") {
      isError = true;
      this.setState({
        nameError: "error",
      });
      document.getElementById("eq_name").focus();
    }

    if (this.state.division === "" || this.state.division === null) {
      isError = true;
      this.setState({
        divisionError: "error",
      });
      document.getElementById("division").focus();
    }
    
    if (this.state.hasUserInteractedWithDriver) {
      const inputValue = this.state.driver_name.trim();

      if (inputValue !== "") {
        const isValidDriver = this.state.drivers_list.some(
          (driver) => driver.props.children.toLowerCase() === inputValue.toLowerCase()
        );

        if (!isValidDriver) {
          isError = true;
          this.setState({ driver_nameError: "error" });
          window.scrollTo(0, 0);
        } else {
          this.setState({ driver_nameError: '' });
        }
      } else {
        this.setState({ driver_nameError: '' });
      }
    }

    if (this.props.status === "add") {
      if (
        this.state.mileage === "" &&
        this.state.mileageFieldsRequiredTypes.includes(Number(this.state.type))
      ) {
        isError = true;
        this.setState({
          mileageError: "error",
        });
        this.mileageRef.current.focus();
      } else {
        this.setState({
          mileageError: "",
        });
      }

      if (
        (this.state.milage_hobbs_date === "" &&
          this.state.mileageFieldsRequiredTypes.includes(
            Number(this.state.type)
          )) ||
        (this.state.milage_hobbs_date === null &&
          this.state.mileageFieldsRequiredTypes.includes(
            Number(this.state.type)
          ))
      ) {
        isError = true;
        /**
         Since all other fields are input elements and datePicker is a
         package, to highlight the field with error class name is passed.
         the error attribute can't be used.
         */
        this.setState({
          mileageDateErrorClass: "datePicker-error",
        });
      }
    }

    if (this.props.status === "edit" && this.state.mileage === "" &&
        this.state.mileageFieldsRequiredTypes.includes(Number(this.state.type))
        ||
        this.props.inventory.inventoryMileage?.data?.length > 0 && this.mileageRef.current?.value == ""
      ){
      isError = true;
      this.setState({
        mileageError: "error",
      });
      this.mileageRef.current.focus();
    }

    if (this.props.status === "edit" && this.state.equipmentArchive) {
      if (
        this.state.mileage === "" &&
        this.state.mileageFieldsRequiredTypes.includes(Number(this.state.type))
      ) {
        isError = true;
        this.setState({
          mileageError: "error",
        });
        this.mileageRef.current.focus();
      }

    
      if (
        (this.state.milage_hobbs_date === "" || this.state.milage_hobbs_date === null) &&
        this.state.mileageFieldsRequiredTypes.includes(Number(this.state.type))
      ) {
        isError = true;
        /**
         Since all other fields are input elements and datePicker is a
         package, to highlight the field with error class name is passed.
         the error attribute can't be used.
         */
        this.setState({
          mileageDateErrorClass: "datePicker-error",
        });
      }

      if (
        this.state.sold_value === "" &&
        this.state.mileageFieldsRequiredTypes.includes(Number(this.state.type))
      ) {
        isError = true;
        this.setState({
          soldValueErrorClass: "datePicker-error",
        });
      }

      if (
        (this.state.sold_date === "" || this.state.sold_date === null) &&
        this.state.mileageFieldsRequiredTypes.includes(Number(this.state.type))
      ) {
        isError = true;
        this.setState({
          soldDateErrorClass: "datePicker-error",
        });
      }

      if (
        this.state.sold_to === "" &&
        this.state.mileageFieldsRequiredTypes.includes(Number(this.state.type))
      ) {
        isError = true;
        this.setState({
          sold_toError: "error",
        });
        this.soldToRef.current.focus();
      }
    }

    if (this.state.type === "") {
      isError = true;
      this.setState({
        typeError: "error",
      });
      document.getElementById("eq_type").focus();
      window.scrollTo(0, 0);
    }    
    if (this.props.inventory.inventoryMileage?.data?.length > 0 && this.mileageRef.current.value == "") {      
      isError = true;
      this.setState({
        mileageError: "error",
      });
      this.mileageRef.current.focus();
    }
    return isError;
  }

  handleImgDelete(index) {
    let equipImgs = this.state.equipImgs;

    let deletingImage = equipImgs[index];

    equipImgs.splice(index, 1);

    this.setState({
      equipImgs: equipImgs,
      imageLoading: true,
    });

    if (deletingImage.id) {
      let data = new FormData();
      data.append("id", deletingImage.id);
      data.append("type", this.state.type);
      this.props
        .dispatch(inventoryActions.deleteInvFile(data))
        .then((res) => {
          if (this.props.equip_id) {
            this.props.dispatch(
              inventoryActions.equipmentDetails(this.props.equip_id)
            );
          }
          this.setState({ imageLoading: false });
          // let dataOption = new FormData();
          // dataOption.append("option", "All");
          // this.props.dispatch(inventoryActions.getListEquipments());
          // this.props.dispatch(inventoryActions.getList(dataOption));
        })
        .catch((err) => console.log(err));
    }
  }

  handleSubmit(event) {
    event?.preventDefault();
    const err = this.validate();
    this.setState({ saveClick: true, loading: true, submitDisabled: true });

    if (err) {
      this.setState({
        saveClick: false,
        loading: false,
        submitDisabled: false,
      });
    }
    if (!err) {
      let data = new FormData();

      data.append("skip_and_save", this.state.shouldSkipAndSave);
      data.append("inventoryId", this.props.inventory_id);
      data.append("type", this.state.type);
      data.append("pre_type", this.state.pre_type);

      if (
        this.state.type === "1" ||
        this.state.type === "11" ||
        this.state.type === "19" ||
        this.state.type === "14" ||
        this.state.type === "8"
      ) {
        data.append("subtype", this.state.subtype);
      }

      data.append("name", this.state.name);

      if (
        this.state.cost !== "" &&
        this.state.cost !== null &&
        this.state.cost !== undefined
      ) {
        data.append("cost", this.state.cost.replace(/[$,]/g, ""));
      }

      data.append("description", this.state.description);
      data.append("vehicle_model_or_make", this.state.make_model);
      data.append("serial_vin_number", this.state.serial_num);

      if (
        this.state.type === "1" ||
        this.state.type === "5" ||
        this.state.type === "6" ||
        this.state.type === "7" ||
        this.state.type === "8" ||
        this.state.type === "9" ||
        this.state.type === "10" ||
        this.state.type === "14"
      ) {
        data.append("vehicle_license", this.state.licenseNum || "");
        data.append("license_number", this.state.licenseNum || "");
        data.append("GVW", this.state.gvw);
        data.append("engine_family", this.state.engineFamily);
        data.append("engine_model", this.state.engineModel);
        data.append("engine_serial", this.state.engineSerial);
        data.append("engine_description", this.state.engineDescription);
        // data.append("engine_certification", this.state.engineCert || ""); engineCert not in use
        data.append("ARB", this.state.abr);
        data.append("non_op", this.state.non_op);
      }

      if (
        this.state.type === "1" ||
        this.state.type === "5" ||
        this.state.type === "6" ||
        this.state.type === "7" ||
        this.state.type === "8" ||
        this.state.type === "9" ||
        this.state.type === "10"
      ) {
        if (this.state.mspa_date !== undefined || this.state.mspa_date !== "") {
          data.append(
            "mspa_exp_date",
            moment(this.state.mspa_date)?.format("MM/DD/YYYY")
          );
        }
      }

      if (this.state.assignedDate !== undefined) {
        data.append("assign_date", this.state.assignedDate);
      }

      if (this.state.resource_identifier_id !== undefined) {
        data.append(
          "resource_identifier_id",
          this.state.resource_identifier_id
        );
      }

      data.append("hash", this.state.driver_hash);
      data.append("division_id", this.state.division);
      data.append("year", this.state.year);
      data.append("current_condition", this.state.condition);
      data.append("note", this.state.notes);
      data.append("year_purchased", this.state.yearPurchase);
      data.append("sold_value", this.state.sold_value);
      data.append("sold_to", this.state.sold_to);
      data.append(
        "sold_date",
        this.state.sold_date && moment(this.state.sold_date)?.isValid()
          ? moment(this.state.sold_date)?.format("YYYY-MM-DD")
          : ""
      );
      data.append(
        "mileage_date",
        this.state.milage_hobbs_date &&
          moment(this.state.milage_hobbs_date)?.isValid()
          ? moment(this.state.milage_hobbs_date)?.format("YYYY-MM-DD")
          : ""
      );

      data.append("state_of_licensure", this.state.state_of_licensure);
      data.append("equipment_owner", this.state.equipment_owner);
      data.append("insurance", this.state.insurance);
      data.append("job_code", this.state.job_code);
      data.append("class_code", this.state.class_code);
      data.append("arb_program", this.state.arb_program);
      data.append("mileage", this.state.mileage || "");
      if (this.state.equipImgs.length >= 1) {
        this.state.equipImgs.forEach((item, key) => {
          if (item.new) {
            data.append(
              "pic[]",
              JSON.stringify({
                thumbName: item.thumbName,
                imgName: item.imgName,
              })
            );
            item.new = false;
          }
        });
      } else {
        data.append("pic[]", "");
      }
      if (this.state.mileageMeterUploads.length && this.state.mileage) {
        this.state.mileageMeterUploads.forEach((item, key) => {
          if (item.new) {
            data.append(
              "mileage_pic[]",
              JSON.stringify({
                thumbName: item.thumbName,
                imgName: item.imgName,
              })
            );
            item.new = false;
          }
        });
      } else {
        data.append("mileage_pic[]", "");
      }

      if (this.props.inv_id && this.props.status === "edit") {
        data.append("id", this.props.inv_id);
      }

      this.props
        .dispatch(inventoryActions.createInventory(data))
        .then((res) => {
          this.setState({ equipImgs: [] });
          if (res.success) {
            let dataOption = new FormData();
            for (let key in this.props.filter) {
              dataOption.append(key, this.props.filter[key]);
            }
            if (!dataOption.has("option")) {
              dataOption.append("option", "All");
            }
            this.props.dispatch(inventoryActions.getList(dataOption));

            if (this.props.crew_id) {
              let data = new FormData();
              data.append("crew_identifier_id", this.props.crew_id);
              this.props.dispatch(inventoryActions.getAvailableList(data));
              this.props.dispatch(inventoryActions.getMemberList(data));
            }

            if (this.props.searchKey) {
              let searchData = new FormData();
              searchData.append("keyword", this.props.searchKey);
              this.props.dispatch(resourceActions.searchResource(searchData));
            }
          } else {
            this.setState({
              saveClick: false,
              loading: false,
              submitDisabled: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            saveClick: false,
            loading: false,
            submitDisabled: false,
          });
        });
    }
  }

  onCropChange(crop) {
    this.setState({ crop });
  }

  onCropComplete(crop, pixelCrop) {
    this.setState({
      pixelCrop,
    });
  }

  // Runs after the image drops on dropzone
  onImageDrop(files) {
    var maxCount = this.state.equipImgs.length;
    /*Validation */
    if (!files.length) {
      this.setState({
        statusOpen: false,
        alertPop: true,
        alertHead: "Error",
        alertMsg: "Invalid file type",
        alertFunction: "",
      });
      return;
    }
    /*Validation ends here*/

    let imageType = files[0].name.split(".").pop().toLowerCase();

    if (
      ["jpg", "jpeg", "png", "gif", "heic", "heif"].some(
        (type) => type === imageType
      )
    ) {
      this.setState({
        equipImgs: [
          ...this.state.equipImgs,
          {
            loading: true,
            image: files[0],
            new: true,
          },
        ],
        submitDisabled: true,
        cropImgIndex: this.state.equipImgs.length,
        pic: files[0],
        imgPreview: files[0].preview,
        fileName: files[0].name,
        image: files[0],
        statusOpen: true,
      });
    } else {
      this.setState({
        statusOpen: false,
        alertPop: true,
        alertHead: "Error",
        alertMsg: "File type must be in jpg, jpeg, png, gif, heic, heif format",
        alertFunction: "",
      });
    }
  } // end of image drop

  closeAlert() {
    this.setState({
      alertPop: false,
      shouldSkipAndSave: false,
    });
  }

  onImageError(e) {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altInvImg);
  }

  componentWillReceiveProps(nextProps) {
    const { equipments, equipList, dltInv, uploadedImage } =
      nextProps.inventory;
    const { permission, inventory, resource } = nextProps;

    var type_list = [],
      subPackages = [],
      conditionList = [],
      division_list = [],
      subtype_list = [],
      engineCert_list = [];
    let { equipImgs } = this.state;
    if (
      uploadedImage &&
      equipImgs[uploadedImage.index] &&
      equipImgs[uploadedImage.index].new
    ) {
      equipImgs[uploadedImage.index].loading = false;
      equipImgs[uploadedImage.index].new = true;
      equipImgs[uploadedImage.index].thumbName = uploadedImage.thumbName;
      equipImgs[uploadedImage.index].imgName = uploadedImage.imgName;
      this.setState({
        equipImgs: equipImgs,
      });
    } //imgName

    if (resource.items && this.state.resourceClick) {
      const { data } = resource.items;
      var resource_list = [];
      var res = [];
      for (var key in data) {
        var arr = data[key];
        if (arr.length) {
          res = res.concat(arr);
        }
      }

      this.setState({ resource_list: [], resource_show: "show" });

      if (res.length) {
        if (res.length > 0) {
          res.map((item, index) => {
            resource_list.push(
              <li
                key={index}
                className="item"
                onClick={this.setCrew.bind(this, item.id, item.resource_name)}
              >
                {item.resource_name}
              </li>
            );
            return resource_list;
          });
        } else {
          resource_list.push(
            <li key="nodata" className="item noitem">
              No results found
            </li>
          );
        }
      }
      this.setState({ resource_list });
    }

    if (resource.driver && this.state.driverClick) {
      const { data } = resource.driver;

      var drivers_list = [],
        name;

      this.setState({ drivers_list: [], driver_show: "show" });

      if (data.length > 0) {
        data.map((item, index) => {
          name = item.first_name + " " + item.last_name;
          drivers_list.push(
            <li
              key={index}
              className="item"
              onClick={this.setDriver.bind(this, name, item.hash)}
            >
              {name}
            </li>
          );
          return drivers_list;
        });
      } else {
        this.setState({
          driverClick: false,
        });
        drivers_list.push(
          <li key="nodata" className="item noitem">
            No results found
          </li>
        );
      }
      this.setState({ drivers_list });
    }

    if (equipList && !this.state.resourceClick) {
      if (this.props.status === "edit") {
        var inventoryImage = "",
          dName = "",
          dHash = "",
          resIden = "",
          resId = "",
          year = "",
          mhDate,
          msDate = "",
          assignDate,
          mkmodel;

        if (equipList.data.pic !== null && equipList.data.pic !== "") {
          inventoryImage = config.apiUrl + "/" + equipList.data.pic;
        }
        if (equipList.data.type_id === 1) {
          // check for type vehicle
        }
        if (equipList.data.user) {
          dName =
            equipList.data.user.first_name +
            " " +
            equipList.data.user.last_name;
          dHash = equipList.data.user.hash;
        }
        if (equipList.data.resource && equipList.data.resource !== undefined) {
          resIden = equipList.data.resource.resource_name;
          resId = equipList.data.resource.id;
        }
        if (equipList.data.mspa_exp_date) {
          msDate = moment(equipList.data.mspa_exp_date);
        }

        if (
          equipList.data.assign_date &&
          equipList.data.assign_date !== null &&
          equipList.data.assign_date !== undefined
        ) {
          assignDate = moment(equipList.data.assign_date);
        }
        if (equipList.data.type_id.toString() === "1") {
          mkmodel =
            equipList.data.vehicle_model_or_make &&
            equipList.data.vehicle_model_or_make !== null
              ? equipList.data.vehicle_model_or_make
              : "";
        } else {
          mkmodel =
            equipList.data.model_or_make &&
            equipList.data.model_or_make !== null
              ? equipList.data.model_or_make
              : "";
        }
        if (this.props.status === "edit" && this.state.driverFlag === true) {
          this.setState({
            type: equipList.data.type_id.toString(),
            pre_type: equipList.data.type_id.toString(),
            name: equipList.data.unique_id,
            condition:
              equipList.data.current_condition &&
              equipList.data.current_condition !== null
                ? equipList.data.current_condition
                : "",
            subtype:
              equipList.data.subpackage_id &&
              equipList.data.subpackage_id !== null
                ? equipList.data.subpackage_id
                : "",
            cost:
              equipList.data.cost && equipList.data.cost !== null
                ? equipList.data.cost
                : "",
            license_no:
              equipList.data.serial_vin_number &&
              equipList.data.serial_vin_number !== null
                ? equipList.data.serial_vin_number
                : "",
            serial_num:
              equipList.data.serial_vin_number &&
              equipList.data.serial_vin_number !== null
                ? equipList.data.serial_vin_number
                : "",
            notes:
              equipList.data.note && equipList.data.note !== null
                ? equipList.data.note
                : "",
            cropResult: inventoryImage,
            mspa_date: msDate,
            division:
              equipList.data.division_id && equipList.data.division_id !== null
                ? equipList.data.division_id
                : "",
            make_model: mkmodel,
            driver_name: dName,
            resource_identifier: resIden,
            driver_hash: dHash,
            resource_identifier_id: resId,
            driverFlag: false,
            resourceFlag: false,
            year:
              equipList.data.year && equipList.data.year !== null
                ? equipList.data.year
                : "",
            description:
              equipList.data.description && equipList.data.description !== null
                ? equipList.data.description
                : "",
            gvw:
              equipList.data.GVW && equipList.data.GVW !== null
                ? equipList.data.GVW
                : "",
            engineFamily:
              equipList.data.engine_family &&
              equipList.data.engine_family !== null
                ? equipList.data.engine_family
                : "",
            engineModel:
              equipList.data.engine_model &&
              equipList.data.engine_model !== null
                ? equipList.data.engine_model
                : "",
            engineSerial:
              equipList.data.engine_serial &&
              equipList.data.engine_serial !== null
                ? equipList.data.engine_serial
                : "",
            engineDescription:
              equipList.data.engine_description &&
              equipList.data.engine_description !== null
                ? equipList.data.engine_description
                : "",
            abr:
              equipList.data.ARB && equipList.data.ARB !== null
                ? equipList.data.ARB
                : "",
            non_op:
              equipList.data.non_op && equipList.data.non_op !== null
                ? equipList.data.non_op
                : "",
            sold_value:
              equipList.data.sold_value && equipList.data.sold_value !== null
                ? equipList.data.sold_value
                : "",
            sold_to:
              equipList.data.sold_to && equipList.data.sold_to !== null
                ? equipList.data.sold_to
                : "",
            yearPurchase:
              equipList.data.year_purchased &&
              equipList.data.year_purchased !== null
                ? equipList.data.year_purchased
                : "",
            sold_date: moment(equipList?.data?.sold_date)?.isValid()
              ? moment(equipList?.data?.sold_date).format("YYYY/MM/DD")
              : null,
            equipData: equipList.data,
            //equipment images from api
            equipImgs: equipList.data.equipment_files.map((file) => {
              return {
                loading: false,
                id: file.id,
                imgName: file.file_original_hash,
                thumb: file.file_hash,
                new: false,
              };
            }),
            assignedDate: assignDate,
            inventoryImage: equipList.data.pic,
            engineCert: equipList.data.engine_certification,
            licenseNum:
              equipList.data.vehicle_license || equipList.data.license_number,
            state_of_licensure: equipList.data.state_of_licensure || "",
            equipment_owner: equipList.data.equipment_owner || "",
            insurance: equipList.data.insurance || "",
            job_code: equipList.data.job_code || "",
            class_code: equipList.data.class_code || "",
            arb_program: equipList.data.arb_program || "",
            mileage: equipList.data.mileage || "",
            milage_hobbs_date: moment(equipList?.data?.mileage_date)?.isValid()
              ? moment(equipList?.data?.mileage_date).format("YYYY/MM/DD")
              : null,

            mileageMeterUploads: equipList.data.inventory_files.map((file) => {
              return {
                loading: false,
                id: file.id,
                imgName: file.file_original_hash,
                thumb: file.file_hash,
                new: false,
              };
            }),
          });

          if (equipList.data.equipment_files.length === 1) {
            this.setState({ imgId: equipList.data.equipment_files[0].id });
          }
        }
      } else {
        // init state
        // this.setState({
        //   type: "",
        //   pre_type: "",
        //   name: "",
        //   condition: "",
        //   subtype: "",
        //   cost: "",
        //   license_no: "",
        //   notes: "",
        //   imgPreview: "",
        //   mspa_date: moment(),
        //   make_model: "",
        //   driver_name: "",
        //   resource_identifier: "",
        // });
      }
    }
    if (
      inventory.inventory &&
      this.state.driverClick === false &&
      this.state.saveClick
    ) {
      //####
      if (inventory.inventory.success) {
        if (this.props.from === "view") {
          this.setState({
            alertPop: true,
            alertHead: "Success",
            alertMsg: inventory.inventory.message,
            alertFunction: this.goToInventoryView.bind(this),
            saveClick: false,
            shouldSkipAndSave: false,
          });
        } else {
          this.setState({
            alertPop: true,
            alertHead: "Success",
            alertMsg: inventory.inventory.message,
            alertFunction: this.goBack.bind(this),
            saveClick: false,
            shouldSkipAndSave: false,
          });
        }
      } else {
        var errMessage;
        if (typeof inventory.inventory.message === "object") {
          for (var key in inventory.inventory.message) {
            errMessage = JSON.parse(
              JSON.stringify(inventory.inventory.message[key][0])
            );
          }
        } else {
          errMessage = JSON.parse(JSON.stringify(inventory.inventory.message));
        }
        const response = inventory?.inventory;
        const shouldSkipAndSave = response?.data?.flag === IS_SKIP_AND_SAVE;
        this.setState({
          alertPop: true,
          alertHead: "Error",
          alertMsg: errMessage,
          alertFunction: "",
          saveClick: false,
          shouldSkipAndSave,
        });
      }
    }
    if (equipments) {
      equipments.EquipmentType.map((item, index) => {
        type_list.push(
          <option key={index} value={item.id}>
            {item.value}
          </option>
        );
        return type_list;
      });
      if (equipments.Division) {
        equipments.Division.map((item, index) => {
          division_list.push(
            <option key={index} value={item.id}>
              {item.value}
            </option>
          );
          return division_list;
        });
      }
      equipments.EngineCertification.forEach((item, index) => {
        engineCert_list.push(
          <option key={index} value={item.id}>
            {item.value}
          </option>
        );
      });
      equipments.CurrentCondition.map((item, index) => {
        conditionList.push(
          <option key={index} value={item.id}>
            {item.value}
          </option>
        );
        return conditionList;
      });

      this.setState({
        type_list,
        engineCert_list,
        conditionList,
        division_list,
        EquipmentType: equipments.EquipmentType,
        allSubtypes: equipments.SubPackages,
        SubPackages: equipments.SubPackages,
      });
    }

    if (dltInv && this.state.delClick) {
      var head = "";
      if (dltInv.success) {
        head = "Success";
      } else {
        head = "Error";
      }
      this.setState({
        alertPop: true,
        alertHead: head,
        alertMsg: JSON.parse(JSON.stringify(dltInv.message)),
        alertFunction: this.goBack.bind(this),
        emailSuccess: "",
        delClick: false,
      });
    }

    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
      this.setState({ permList });
    }
  }

  handleMileageMeterUpload(files) {
    try {
      if (files?.length) {
        const currentFile = files?.[0];
        const imageType = currentFile?.name?.split(".")?.pop()?.toLowerCase();
        if (validImageTypes?.includes(imageType)) {
          let mileageMeterUploads = [...this?.state?.mileageMeterUploads];
          const newImage = {
            loading: true,
            image: currentFile,
            new: true,
          };
          mileageMeterUploads = mileageMeterUploads?.concat(newImage);
          this.setState({
            mileageMeterUploads,
            statusOpen: true,
            imgPreview: currentFile?.preview,
            isMileageMeterUpload: true,
          });
        } else {
          throw new Error(errorMessages?.invalidType);
        }
      } else {
        throw new Error(errorMessages?.invalidImageType);
      }
    } catch (error) {
      this.setState({
        statusOpen: false,
        alertPop: true,
        alertHead: "Error",
        alertMsg: error?.message,
        alertFunction: "",
        isMileageMeterUpload: false,
      });
    }
  }

  handleMilageImgDelete(index) {
    let mileageMeterUploads = this.state.mileageMeterUploads;

    let deletingImage = mileageMeterUploads[index];

    mileageMeterUploads.splice(index, 1);

    this.setState({
      mileageMeterUploads: mileageMeterUploads,
      imageLoading: true,
    });

    if (deletingImage.id) {
      let data = new FormData();
      data.append("id", deletingImage.id);
      data.append("type", this.state.type);
      this.props
        .dispatch(inventoryActions.deleteMileageFile(data))
        .then((res) => {
          if (this.props.equip_id) {
            this.props.dispatch(
              inventoryActions.equipmentDetails(this.props.equip_id)
            );
          }
          this.setState({ imageLoading: false });
        })
        .catch((err) => console.log(err));
    }
  }

  async handleImageUpload() {
    let mileageMeterUploads = [...this?.state?.mileageMeterUploads];
    let imagePayload = {
      ...(mileageMeterUploads[mileageMeterUploads?.length - 1] || {}),
    };
    imagePayload.blob = await this.getCroppedCanvasBlob();
    imagePayload.thumb = this.cropper
      .getCroppedCanvas(CROP_CANVAS_OPTIONS)
      .toDataURL();
    this.setState({
      statusOpen: false,
      submitDisabled: true,
      imageLoading: true,
      loading: true,
    });
    let data = new FormData();
    data.append("type", imagePayload?.image?.name?.split(".")?.pop());
    const uploadUrl = await inventoryService?.getUploadUrl(data);
    const [response] = await Promise.all([
      uploadUrl.data,
      inventoryService.uploadToStore(
        imagePayload.image,
        uploadUrl.data.ORIGINAL_URL
      ),
      inventoryService.uploadToStore(
        imagePayload.blob,
        uploadUrl.data.THUMB_URL
      ),
    ]);
    imagePayload.thumbName = response?.THUMB_PATH;
    imagePayload.imgName = response.ORIGINAL_PATH;
    imagePayload.loading = false;
    mileageMeterUploads?.splice(-1, 1, imagePayload);
    this.setState({
      mileageMeterUploads,
      submitDisabled: false,
      imageLoading: false,
      loading: false,
    });
  }

  async getCroppedCanvasBlob() {
    return new Promise((resolve, reject) => {
      this.cropper
        .getCroppedCanvas(CROP_CANVAS_OPTIONS)
        .toBlob((blob) => resolve(blob));
    });
  }

  render() {
    var inv_breadcrumb,
      subtype_list = [];
    const { inventory } = this.props;
    if (
      this.state.type === "1" ||
      this.state.type === "11" ||
      this.state.type === "19" ||
      this.state.type === "14" ||
      this.state.type === "8"
    ) {
      if (this.state.SubPackages && this.state.division && this.state.type) {
        subtype_list = this.state.SubPackages.filter((item) =>
          item.d_id.split(",").includes(this.state.division.toString())
        )
          .filter((item) => item.p_id.split(",").includes(this.state.type))
          .map((item, index) => (
            <option key={index + "subtypelist"} value={item.id}>
              {item.value}
            </option>
          ));
      }
    }
    if (this.props.from === "view") {
      inv_breadcrumb = (
        <span className="green" onClick={() => this.props.inventoryView()}>
          Inventory
        </span>
      );
    } else {
      inv_breadcrumb = (
        <span className="green" onClick={this.goBack.bind(this)}>
          Inventory
        </span>
      );
    }
    return (
      <div className="equipment_container">
        <div className="breadcrumbs">
          {inv_breadcrumb} /{" "}
          {this.props.status === "edit" ? "Edit Equipment" : "Add Equipment"}
        </div>
        <div className="page_title float">
          <h1>
            {this.props.status === "edit" ? "Edit Equipment" : "Add Equipment"}
          </h1>
        </div>
        <div className="inner_container">
          <h3>Equipment Details</h3>
          <div className="addEquipmentForm row">
            <div className="col-md-6 col-sm-12">
              <div className="formField">
                <label>Division</label>
                <span className="mandatory">&nbsp;*</span>
                <select
                  name="division"
                  id="division"
                  value={this.state.division}
                  errors={this.state.divisionError}
                  className={
                    this.props.status === "edit" && this.state.division === "1"
                      ? "small_input disabled"
                      : "small_input"
                  }
                  onChange={this.handleSelect}
                >
                  <option value="">--</option>
                  {this.state.division_list}
                </select>
              </div>
              <div className="formField">
                <label>Category</label>
                <span className="mandatory">&nbsp;*</span>
                {/* <select
                  name="type"
                  id="eq_type"
                  disabled={
                    this.props.status === "edit" && this.state.type === "1"
                      ? "disabled"
                      : ""
                  }
                  value={this.state.type}
                  errors={this.state.typeError}
                  className={
                    this.props.status === "edit" && this.state.type === "1"
                      ? "small_input disabled"
                      : "small_input"
                  }
                  onChange={this.handleSelect}
                >
                  <option value="">--</option>
                  {this.state.type_list}
                </select> */}
                <select
                  name="type"
                  id="eq_type"
                  value={this.state.type}
                  errors={this.state.typeError}
                  className={"small_input"}
                  onChange={this.handleSelect}
                >
                  <option value="">--</option>
                  {this.state.type_list}
                </select>
              </div>
              {(this.state.type === "1" ||
                this.state.type === "11" ||
                this.state.type === "19" ||
                this.state.type === "8" ||
                this.state.type === "14") &&
                this.state.division != "" && (
                  <div className="formField">
                    <label>Subtype</label>
                    <select
                      name="subtype"
                      id="subtype"
                      value={this.state.subtype}
                      errors={this.state.subtypeError}
                      className={"small_input"}
                      onChange={this.handleSelect}
                    >
                      <option value="">--</option>
                      {this.state.subtype_list
                        ? this.state.subtype_list
                        : subtype_list}
                    </select>
                  </div>
                )}
              {this.state.type === "18" &&
                this.state.EquipmentType.length != 0 && (
                  <div className="formField package">
                    <div className="package_note">
                      {
                        this.state.EquipmentType.find(
                          (item) => item.id == this.state.type
                        ).type_desc
                      }
                    </div>
                  </div>
                )}
              <div className="formField">
                <label>Unique ID</label>
                <span className="mandatory">&nbsp;*</span>
                <input
                  type="text"
                  id="eq_name"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleChange}
                  className="small_input"
                  errors={this.state.nameError}
                />
              </div>
              <div className="formField">
                <label>Assign Resource</label>
                <input
                  type="text"
                  id="resource_search"
                  placeholder="Search..."
                  errors={this.state.resource_identifierError}
                  name="resource_identifier"
                  value={this.state.resource_identifier || ""}
                  onChange={this.handleChange}
                  onKeyUp={this.keyUpFnResource}
                  className="small_input"
                  autoComplete="off"
                />
                <ul id="resource_list" className={this.state.resource_show}>
                  {this.state.resource_list}
                </ul>
              </div>
              <div className={"formField "}>
                <label>Assign Driver/Employee</label>
                <div className="legal_name app_input">
                  <input
                    type="text"
                    id="driver_search"
                    placeholder="Search..."
                    name="driver_name"
                    value={this.state.driver_name}
                    className="small_input"
                    onKeyUp={this.keyUpFnDriver}
                    onChange={this.handleChange}
                    errors={this.state.driver_nameError}
                    autoComplete="off"
                  />
                  <ul id="drivers_list" className={this.state.driver_show}>
                    {this.state.drivers_list}
                  </ul>
                </div>
              </div>
              {(this.state.asDateShow || this.props.status === "edit") && (
                <div className="formField">
                  <label>Assigned Date</label>
                  <DatePicker
                    className="txt "
                    id="assignedDate"
                    name="assignedDate"
                    errors={this.state.assignedDate_dateError}
                    // selected={
                    //   moment(this.state.assignedDate).isValid()
                    //     ? moment(this.state.assignedDate)
                    //     : ""
                    // }
                    selected={this.state.assignedDate}
                    onChange={this.handleDateChange.bind(this, "assignedDate")}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>
              )}

              <div className="formField">
                <label>Description</label>
                <textarea
                  placeholder="Description"
                  className="small_input"
                  name="description"
                  value={this.state.description || ""}
                  onChange={this.handleChange}
                />
              </div>
              <div className={"formField "}>
                <label>Year</label>
                <div className="legal_name app_input">
                  <select
                    name="year"
                    id="vehYear"
                    errors={this.state.yearError}
                    value={this.state.year || ""}
                    className="small_input"
                    onChange={this.handleSelect}
                  >
                    <option value="">--</option>
                    {this.state.yearList}
                  </select>
                </div>
              </div>
              <div className="formField">
                <label>Make / Model</label>
                <input
                  type="text"
                  name="make_model"
                  id="eq_make_model"
                  value={this.state.make_model || ""}
                  className="small_input"
                  onChange={this.handleChange}
                  errors={this.state.make_modelError}
                />
              </div>
              <div className="formField">
                <label>Serial Number / VIN</label>
                <input
                  type="text"
                  name="serial_num"
                  id="serial_num"
                  value={this.state.serial_num ? this.state.serial_num : ""}
                  className="small_input"
                  onChange={this.handleChange}
                  errors={this.state.serial_numError}
                />
              </div>
              {(this.state.type === "1" ||
                this.state.type === "5" ||
                this.state.type === "6" ||
                this.state.type === "7" ||
                this.state.type === "8" ||
                this.state.type === "9" ||
                this.state.type === "10" ||
                this.state.type === "14") && (
                <div>
                  <div className="formField">
                    <label>License Number</label>
                    <input
                      type="text"
                      name="licenseNum"
                      id="licenseNum"
                      value={this.state.licenseNum}
                      className="small_input"
                      onChange={this.handleChange}
                      errors={this.state.licenseNumError}
                    />
                  </div>
                  <div className="formField">
                    <label>GVW</label>
                    <input
                      type="text"
                      name="gvw"
                      id="gvw"
                      value={this.state.gvw || ""}
                      className="small_input"
                      onChange={this.handleChange}
                      errors={this.state.gvwError}
                    />
                  </div>
                  <div className="formField">
                    <label>Engine Family</label>
                    <input
                      type="text"
                      name="engineFamily"
                      id="engineFamily"
                      value={this.state.engineFamily || ""}
                      className="small_input"
                      onChange={this.handleChange}
                      errors={this.state.engineFamilyError}
                    />
                  </div>
                  <div className="formField">
                    <label>Engine Model</label>
                    <input
                      type="text"
                      name="engineModel"
                      id="engineModel"
                      value={this.state.engineModel || ""}
                      className="small_input"
                      onChange={this.handleChange}
                      errors={this.state.engineModelError}
                    />
                  </div>
                </div>
              )}
              <div className="formField">
                <label>Job Code</label>
                <input
                  type="text"
                  name="job_code"
                  id="job_code"
                  value={this.state.job_code || ""}
                  className="small_input"
                  onChange={this.handleChange}
                  errors={this.state.job_codeError}
                />
              </div>
              <div className="formField">
                <label>Class Code</label>
                <input
                  type="text"
                  name="class_code"
                  id="class_code"
                  value={this.state.class_code || ""}
                  className="small_input"
                  onChange={this.handleChange}
                  errors={this.state.class_codeError}
                />
              </div>
              <div className="formField">
                <label>ARB Program</label>
                <select
                  name="arb_program"
                  value={this.state.arb_program}
                  className="small_input"
                  onChange={this.handleSelect}
                  errors={this.state.arb_programError}
                >
                  <option value="--">--</option>
                  <option value="TRUCRS">TRUCRS</option>
                  <option value="ROAR">ROAR</option>
                  <option value="PERP">PERP</option>
                  <option value="N/A">N/A</option>
                </select>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              {(this.state.type === "1" ||
                this.state.type === "5" ||
                this.state.type === "6" ||
                this.state.type === "7" ||
                this.state.type === "8" ||
                this.state.type === "9" ||
                this.state.type === "10" ||
                this.state.type === "14") && (
                <div>
                  <div className="formField">
                    <label>Engine Serial</label>
                    <input
                      type="text"
                      name="engineSerial"
                      id="engineSerial"
                      value={this.state.engineSerial || ""}
                      className="small_input"
                      onChange={this.handleChange}
                      errors={this.state.engineSerialError}
                    />
                  </div>
                  <div className="formField">
                    <label>Engine Description</label>
                    <input
                      type="text"
                      name="engineDescription"
                      id="engineDescription"
                      value={this.state.engineDescription || ""}
                      className="small_input"
                      onChange={this.handleChange}
                      errors={this.state.engineDescriptionError}
                    />
                  </div>
                  {/* <div className="formField">
                    <label>Engine Certification</label>
                    <select
                      name="engineCert"
                      value={this.state.engineCert || ""}
                      className="small_input"
                      id="engineCert"
                      errors={this.state.engineCertError}
                      onChange={this.handleSelect}
                    >
                      <option value="">--</option>
                      {this.state.engineCert_list}
                    </select>
                  </div> */}
                  <div className="formField">
                    <label>ARB #</label>
                    <input
                      type="text"
                      name="abr"
                      id="abr"
                      value={this.state.abr || ""}
                      className="small_input"
                      onChange={this.handleChange}
                      errors={this.state.abrError}
                    />
                  </div>
                  <div className="formField">
                    <label>Non-Op</label>
                    <select
                      name="non_op"
                      id="non_op"
                      value={this.state.non_op || ""}
                      className="small_input"
                      onChange={this.handleChange}
                      errors={this.state.non_opError}
                    >
                      <option value="">--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div>
              )}
              {(this.state.type === "1" ||
                this.state.type === "5" ||
                this.state.type === "6" ||
                this.state.type === "7" ||
                this.state.type === "8" ||
                this.state.type === "9" ||
                this.state.type === "10") && (
                <div>
                  <div
                    className="formField mspa"
                    errors={this.state.mspa_dateError}
                  >
                    <label>MSPA Exp. Date</label>
                    <DatePicker
                      className="txt "
                      id="eq_mspa_date"
                      name="mspa_date"
                      errors={this.state.mspa_dateError}
                      selected={this.state.mspa_date}
                      onChange={this.handleDateChange.bind(this, "mspa_date")}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      minDate={moment("19990101")}
                      maxDate={moment("20350101")}
                    />
                  </div>
                </div>
              )}
              <div className="formField">
                <label>Current Condition</label>
                <select
                  name="condition"
                  value={this.state.condition || ""}
                  className="small_input"
                  id="eq_condition"
                  errors={this.state.conditionError}
                  onChange={this.handleSelect}
                >
                  <option value="">--</option>
                  {this.state.conditionList}
                </select>
              </div>
              <div className="formField">
                <label>Year Purchased</label>
                <select
                  name="yearPurchase"
                  value={this.state.yearPurchase}
                  className="small_input"
                  onChange={this.handleSelect}
                >
                  <option value="--">--</option>
                  {this.state.yearList}
                </select>
              </div>
              <div className="formField">
                <label>Purchase Value</label>
                <NumberFormat
                  fixedDecimalScale={true}
                  decimalScale={2}
                  thousandSeparator={true}
                  prefix={"$"}
                  id="eq_cost"
                  name="cost"
                  errors={this.state.costError}
                  value={this.state.cost}
                  className="small_input"
                  onChange={this.handleChange}
                />
              </div>

              {this.state.permList &&
                this.state.permList.includes("Inventory Sold Info") && (
                  <div>
                    <div
                      className="formField mspa"
                      errors={this.state.sold_dateError}
                    >
                      <label>Sold Date</label>
                      <DatePicker
                        className={
                          this.props.status === "edit" &&
                          this.state.equipmentArchive &&
                          (this.state.sold_date === "" ||
                            this.state.sold_date === null) &&
                          this.state.mileageFieldsRequiredTypes.includes(
                            Number(this.state.type)
                          )
                            ? this.state.soldDateErrorClass
                            : "txt"
                        }
                        id="sold_date"
                        name="sold_date"
                        errors={"error"}
                        selected={
                          moment(this.state.sold_date).isValid()
                            ? moment(this.state.sold_date)
                            : null
                        }
                        onChange={this.handleDateChange.bind(this, "sold_date")}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </div>
                    <div className="formField">
                      <label>Sold To</label>
                      <input
                        type="text"
                        name="sold_to"
                        id="sold_to"
                        value={this.state.sold_to || ""}
                        className="small_input"
                        onChange={this.handleChange}
                        ref={this.soldToRef}
                        errors={
                          this.props.status === "edit" &&
                          this.state.equipmentArchive &&
                          this.state.sold_to === "" &&
                          this.state.mileageFieldsRequiredTypes.includes(
                            Number(this.state.type)
                          )
                            ? this.state.sold_toError
                            : null
                        }
                      />
                    </div>
                    <div className="formField">
                      <label>Sold Value</label>
                      <NumberFormat
                        fixedDecimalScale={true}
                        decimalScale={2}
                        thousandSeparator={true}
                        prefix={"$"}
                        type="text"
                        name="sold_value"
                        id="sold_value"
                        value={this.state.sold_value || ""}
                        className={
                          this.props.status === "edit" &&
                          this.state.equipmentArchive &&
                          this.state.sold_value === "" &&
                          this.state.mileageFieldsRequiredTypes.includes(
                            Number(this.state.type)
                          )
                            ? this.state.soldValueErrorClass
                            : "small_input"
                        }
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="formField">
                      <label>State of Licensure</label>
                      <input
                        type="text"
                        name="state_of_licensure"
                        id="state_of_licensure"
                        value={this.state.state_of_licensure || ""}
                        className="small_input"
                        onChange={this.handleChange}
                        errors={this.state.state_of_licensureError}
                      />
                    </div>
                    <div className="formField">
                      <label>Owner of Equipment</label>
                      <input
                        type="text"
                        name="equipment_owner"
                        id="equipment_owner"
                        value={this.state.equipment_owner || ""}
                        className="small_input"
                        onChange={this.handleChange}
                        errors={this.state.equipment_ownerError}
                      />
                    </div>
                    <div className="formField">
                      <label>Insurance</label>
                      <select
                        name="insurance"
                        value={this.state.insurance}
                        className="small_input"
                        onChange={this.handleSelect}
                        errors={this.state.insuranceError}
                      >
                        <option value="--">--</option>
                        <option value="Full Coverage">Full Coverage</option>
                        <option value="Liability Only">Liability Only</option>
                        <option value="Equipment">Equipment</option>
                        <option value="No Coverage">No Coverage</option>
                      </select>
                    </div>
                    <div className="formField">
                      <label>Notes</label>
                      <textarea
                        placeholder="Write notes here..."
                        className="small_input"
                        name="notes"
                        value={this.state.notes || ""}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                )}
              <div className="formField picture">
                <div className="upload_or_drag">
                  <Dropzone
                    id="file_name"
                    name="file_name"
                    multiple={false}
                    accept="image/*"
                    onDrop={this.onImageDrop.bind(this)}
                  >
                    <p>Upload or Drag/Drop Here</p>
                  </Dropzone>
                </div>
                <div className="clearFix" />
              </div>
              <div className="formField imageList">
                {/* {image_list} */}
                <div className="row">
                  {this.state.equipImgs.length > 0 &&
                    this.state.equipImgs.map((imgObj, index) => {
                      if (imgObj.thumb) {
                        return (
                          <div
                            className="col-6 col-xs-4 col-sm-4 col-md-4 col-lg-3 col-xl-2"
                            key={index}
                          >
                            <div className="upload_item" key={index}>
                              {imgObj.loading && (
                                <div
                                  className="loader"
                                  style={{
                                    backgroundImage:
                                      "url(" +
                                      require("../assets/images/loader.gif")
                                        .default +
                                      ")",
                                  }}
                                ></div>
                              )}
                              <img
                                width="200"
                                key={index}
                                src={
                                  imgObj.blob
                                    ? imgObj.thumb
                                    : config.s3BucketUrl + imgObj.thumb
                                }
                                alt=""
                              />
                              {!this.state.imageLoading && (
                                <span
                                  onClick={this.handleImgDelete.bind(
                                    this,
                                    index
                                  )}
                                />
                              )}
                            </div>
                          </div>
                        );
                      }
                    })}
                </div>

                <div className="clearFix" />
              </div>
            </div>
          </div>
          <hr />
          <div className="addEquipmentForm row pt-0">
            <div
              className="row pt-0"
              style={{
                pointerEvents:
                  this?.state?.disableMileageSection &&
                  !this?.state?.permList?.includes("Manage Vehicles")
                    ? "none"
                    : "inherit",
              }}
            >
              <div className="col-md-12 col-sm-12">
                {this?.state?.disableMileageSection &&
                !this?.state?.permList?.includes("Manage Vehicles") ? (
                  <div className="mileageValidationBackdrop">
                    <p className="mileageValidationBackdropText">
                      Please wait until the gate is open to fill the readings.
                    </p>
                  </div>
                ) : null}
                <div className="col-md-6 col-sm-12">
                  <div className="formField">
                    <div className="inventory-label-container">
                      <label> Mileage/Hobbs Meter</label>
                      {this.props.status === "edit" && (
                        <label
                          className="green"
                          onClick={this.mileageHistoryModal.bind(this)}
                        >
                          {" "}
                          Mileage/Hobbs History
                        </label>
                      )}
                    </div>
                    <input
                      type="text"
                      name="mileage"
                      id="mileage"
                      value={this.state.mileage || ""}
                      className="small_input"
                      onChange={this.handleChange}
                      ref={this.mileageRef}
                      errors={this.state.mileageError}
                    />
                  </div>
                </div>

                <div className="col-md-6 col-sm-12">
                  <div className="formField">
                    <label> Mileage/Hobbs Date</label>
                    <DatePicker
                      className={
                        (this.props.status === "add" &&
                          (this.state.milage_hobbs_date === "" ||
                            this.state.milage_hobbs_date === null) &&
                          this.state.mileageFieldsRequiredTypes.includes(
                            Number(this.state.type)
                          ) &&
                          (!this?.state?.disableMileageSection || this?.state?.permList?.includes("Manage Vehicles") )) ||
                        (this.props.status === "edit" &&
                          this.state.equipmentArchive &&
                          (this.state.milage_hobbs_date === "" ||
                            this.state.milage_hobbs_date === null) &&
                          this.state.mileageFieldsRequiredTypes.includes(
                            Number(this.state.type)
                          ))
                          ? this.state.mileageDateErrorClass
                          : null
                      }
                      id="milage_hobbs_date"
                      name="milage_hobbs_date"
                      errors={"error"}
                      selected={
                        moment(this.state.milage_hobbs_date).isValid()
                          ? moment(this.state.milage_hobbs_date)
                          : null
                      }
                      onChange={this.handleDateChange.bind(
                        this,
                        "milage_hobbs_date"
                      )}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                </div>

                <div className="col-md-6 col-sm-12">
                  {this.state?.mileage?.trim() && (
                    <React.Fragment>
                      <div className="formField picture">
                        <div className="upload_or_drag">
                          <Dropzone
                            id="file_name"
                            name="file_name"
                            multiple={false}
                            accept="image/*"
                            onDrop={(files) =>
                              this.handleMileageMeterUpload(files)
                            }
                          >
                            <p>Upload or Drag/Drop Here</p>
                          </Dropzone>
                        </div>
                        {/* <div className="clearFix" /> */}
                      </div>

                      <div className="formField imageList">
                        {/* {image_list} */}
                        <div className="row">
                          {this.state.mileageMeterUploads.length > 0 &&
                            this.state.mileageMeterUploads.map(
                              (imgObj, index) => {
                                if (imgObj.thumb) {
                                  return (
                                    <div
                                      className="col-6 col-xs-4 col-sm-4 col-md-4 col-lg-3 col-xl-2"
                                      key={index}
                                    >
                                      <div className="upload_item" key={index}>
                                        {imgObj.loading && (
                                          <div
                                            className="loader"
                                            style={{
                                              backgroundImage:
                                                "url(" +
                                                require("../assets/images/loader.gif")
                                                  .default +
                                                ")",
                                            }}
                                          ></div>
                                        )}
                                        <img
                                          width="200"
                                          key={index}
                                          src={
                                            imgObj.blob
                                              ? imgObj.thumb
                                              : config.s3BucketUrl +
                                                imgObj.thumb
                                          }
                                          alt=""
                                        />
                                        {!this.state.imageLoading && (
                                          <span
                                            onClick={this.handleMilageImgDelete.bind(
                                              this,
                                              index
                                            )}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  );
                                }
                              }
                            )}
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-12 col-sm-12">
              <div className="formField">
                <div className="right">
                  {/* (inventory.loading && this.state.saveClick) */}
                  {this.state.loading && (
                    <img
                      alt="loader"
                      className="login_loader"
                      src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                    />
                  )}
                  {this.props.status === "edit" && (
                    <input
                      type="submit"
                      name="submit"
                      onClick={this.dltInvOpenModal.bind(this)}
                      value="Archive"
                      className="button"
                    />
                  )}

                  <input
                    type="submit"
                    name="cancel"
                    onClick={this.goBack.bind(this)}
                    value="Cancel"
                    className="cancel_equipment button"
                  />
                  {this.state.submitDisabled ? (
                    <input
                      type="submit"
                      name="submit"
                      onClick={this.handleSubmit.bind(this)}
                      value="Save"
                      className="submit_equipment right button"
                      disabled
                    />
                  ) : (
                    <input
                      type="submit"
                      name="submit"
                      onClick={this.handleSubmit.bind(this)}
                      value="Save"
                      className="submit_equipment right button"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Crop Image */}
        <ReactModal
          isOpen={this.state.statusOpen}
          onClose={this.statusCloseModal.bind(this)}
          contentLabel="Minimal Modal Example"
          overlayClassName="imageCropPopup"
        >
          <div className="modal-head">
            Crop Image
            <img
              alt="close"
              className="closeModal"
              onClick={this.statusCloseModal.bind(this)}
              src={require("../assets/images/close_green.png").default}
            />
          </div>
          <div className="modal-body">
            {(this.state.equipImgs[this.state.cropImgIndex] ||
              this?.state?.isMileageMeterUpload) && (
              <Cropper
                ref={(cropper) => {
                  this.cropper = cropper;
                }}
                // src={this.state.equipImgs[this.state.cropImgIndex].image.preview}
                src={this.state.imgPreview}
                style={{ height: 400, width: "100%" }}
                // Cropper.js options
                aspectRatio={16 / 16}
                guides={false}
                crop={this._crop.bind(this)}
              />
            )}
          </div>
          <div className="button_list">
            <button
              onClick={() => {
                if (this?.state?.isMileageMeterUpload) {
                  this.handleImageUpload();
                } else {
                  this.cropImage();
                }
              }}
              className="button"
            >
              Crop Image
            </button>
          </div>
        </ReactModal>

        <ReactModal
          isOpen={this.state.isMileageHistoryModalOpen}
          onClose={this.mileageHistoryModalClose.bind(this)}
          contentLabel="Minimal Modal Example"
          overlayClassName="invMileageModal"
        >
          <div className="modal-head">
            <h4 className="modal-title">Mileage/Hobbs Meter History</h4>
            <img
              alt="close"
              className="closeModal"
              onClick={this.mileageHistoryModalClose.bind(this)}
              src={require("../assets/images/close_green.png").default}
            />
          </div>
          <div className="modal-body">
            {inventory?.inventoryMileage?.data?.length ? (
              inventory?.inventoryMileage?.data?.map((item) => (
                <InventoryMileageItem key={item?.id} data={item} />
              ))
            ) : (
              <h5>No Mileage History Found!</h5>
            )}
          </div>
        </ReactModal>

        {/* Archive Equipments */}
        <ReactModal
          isOpen={this.state.dltInvOpen}
          onClose={this.dltInvCloseModal.bind(this)}
          overlayClassName="dltInvPopUp"
        >
          <div id="dltInvPopup">
            <div className="modal-head">
              <h4 className="modal-title">Confirm</h4>
              <img
                alt="close"
                className="closeModal"
                onClick={this.dltInvCloseModal.bind(this)}
                src={require("../assets/images/close_green.png").default}
              />
            </div>
            <div className="modal-body">
              <p>
                Are you sure you want to archive this equipment? <br />
                <span className="note">
                  Note: All unsaved changes will be lost.
                </span>{" "}
              </p>
              <div className="btn_list">
                <button
                  className="button grey"
                  onClick={this.dltInvCloseModal.bind(this)}
                >
                  Cancel
                </button>
                <button
                  className="button"
                  onClick={this.deleteInventory.bind(this)}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </ReactModal>

        {this.state.alertPop && (
          <AlertPopup
            head={this.state.alertHead}
            message={this.state.alertMsg}
            viewOpen="true"
            alertFunction={this.state.alertFunction}
            close={this.closeAlert.bind(this)}
            secondaryButtonText={
              this.state.shouldSkipAndSave ? "skip and save" : null
            }
            onSecondaryButtonClick={() => this.handleSubmit()}
          />
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { permission, inventory, resource } = state;
  return {
    permission,
    inventory,
    resource,
    currentUserInfo: getCurrentUserInfo(state),
  };
}
const connectedHomePage = connect(mapStateToProps)(AddEquipment);

const InventoryMileageItem = ({ data }) => {
  return (
    <div className="mileageItem">
      <p className="mileageItemTitle">
        {moment(data?.created_at).format(mileageDateFormat)}
      </p>
      {
        <div>
          {!!data.status == 1 && (
            <p>
              <span>Updated by: </span>
              {data.first_name + " " + data.last_name}
            </p>
          )}
        </div>
      }
      <p className="mileageItemDescription">{data?.mileage}</p>
    </div>
  );
};

export { connectedHomePage as AddEquipment };
